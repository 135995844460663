<template>
  <div class="row w-100 translation-list-item" @input="setSavedStates">
    <div class="col-3 d-flex align-items-center">
      <div class="p-1 translation-list-item_key">{{ translation.key }}</div>
    </div>
    <div class="col d-flex align-items-center">
      <TextInput
        ref="from"
        class="w-100 my-0 mr-1"
        :value="translationFrom"
        :dir="from === 'ar' ? 'rtl' : 'ltr'"
        @input="fromInputHandler"
      />
      <Btn label="Save" :disabled="isFromSaved" @click.native="fromSave" />
    </div>
    <div class="col d-flex align-items-center">
      <TextInput
        ref="to"
        class="w-100 my-0 mr-1"
        :value="translationTo"
        :dir="to === 'ar' ? 'rtl' : 'ltr'"
        @input="toInputHandler"
      />
      <Btn label="Save" :disabled="isToSaved" @click.native="toSave" />
    </div>
  </div>
</template>

<script>
import TextInput from '@/components/form/HeraFormTextInput';
import Btn from '@/components/form/HeraFormButton';
import { mapActions } from 'vuex';

export default {
  components: {
    TextInput,
    Btn,
  },

  props: {
    translation: {
      type: Object,
      required: true,
    },
    from: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isFromSaved: true,
      isToSaved: true,
    };
  },

  computed: {
    translationFrom() {
      return (
        (this.translation.new && this.translation.new[this.from]) ||
        this.translation[this.from]
      );
    },
    translationTo() {
      return (
        (this.translation.new && this.translation.new[this.to]) ||
        this.translation[this.to]
      );
    },
  },

  mounted() {
    this.setSavedStates();
  },

  methods: {
    ...mapActions(['saveTranslation', 'errorNotify', 'successNotify']),

    fromInputHandler(value) {
      this.$emit('change', { locale: this.from, value });
      this.setSavedStates();
    },

    toInputHandler(value) {
      this.$emit('change', { locale: this.to, value });
      this.setSavedStates();
    },

    setSavedStates() {
      this.isFromSaved =
        this.$refs.from.$refs.heraTextInput.value ===
        (this.translation[this.from] || '');
      this.isToSaved =
        this.$refs.to.$refs.heraTextInput.value ===
        (this.translation[this.to] || '');
    },

    fromSave() {
      this.save(
        this.translation.key,
        this.from,
        this.translation.new[this.from]
      );
    },

    toSave() {
      this.save(this.translation.key, this.to, this.translation.new[this.to]);
    },

    save(key, locale, value) {
      this.saveTranslation({
        key,
        locale,
        value,
      })
        .then(() => {
          this.$emit('save', { locale, value });
          this.$nextTick(this.setSavedStates);
          this.successNotify(
            `The translation for <strong>${locale} locale</strong> has been updated`
          );
        })
        .catch(this.errorNotify);
    },
  },
};
</script>

<style lang="scss">
.translation-list-item {
  &_key {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    background-color: $hera-white-fill;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    z-index: 1;
    &:hover {
      position: absolute;
      text-overflow: initial;
      border-color: $border-color;
    }
  }
}
</style>
