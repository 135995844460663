import Vue from 'vue';
import Vuex from 'vuex';
import account from './modules/user.js';
import settings from './modules/settings.js';
import leagues from './modules/leagues.js';
import status from './modules/status.js';
import preregistration from './modules/preregistration.js';
import translations from './modules/translations.js';
import conversations from './modules/conversations.js';
import notifications from './modules/notification.js';
import clans from './modules/clans.js';
import tournaments from './modules/tournaments.js';
import battles from './modules/battles.js';
import badges from './modules/badges.js';
import points from './modules/points.js';
import leaderboards from './modules/leaderboards.js';
import complaints from './modules/complaints.js';
import requestCancel from './modules/requestCancel.js';
import popularPlayers from './modules/popularPlayers.js';
import organization from './modules/organization.js';
import files from './modules/files.js';
import newsfeedSettings from './modules/newsfeedSettings.js';
import socialReposts from './modules/socialReposts.js';
import subscriptions from './modules/subscriptions.js';
import coupons from './modules/coupons.js';
import invoices from './modules/invoices.js';
import chart from './modules/chart.js';
import flushCache from './modules/flushCache.js';
import orders from './modules/orders.js';
import customers from './modules/customers.js';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    chart,
    status,
    account,
    settings,
    leagues,
    translations,
    conversations,
    notifications,
    clans,
    preregistration,
    tournaments,
    battles,
    badges,
    points,
    leaderboards,
    complaints,
    requestCancel,
    popularPlayers,
    organization,
    files,
    newsfeedSettings,
    socialReposts,
    subscriptions,
    coupons,
    invoices,
    flushCache,
    orders,
    customers,
  },
  strict: process.env.NODE_ENV !== 'production',
});
