<template>
  <svgicon
    :class="{
      [$options.name]: true,
      [`${$options.name}--${size}`]: size,
    }"
    :name="name"
    :color="fill || ''"
    :width="width"
    height="auto"
    :original="original"
    @click.native="$emit('click', $event)"
  >
  </svgicon>
</template>

<script>
export default {
  name: 'AppIcon',
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: null,
    },
    fill: {
      type: null,
      default: null,
    },
    width: {
      type: String,
      default: '18',
    },
    original: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    // The `[request]` placeholder is replaced
    // by the filename of the file which is
    // loaded (e.g. `AppIcon-music.js`).
    import(/* webpackChunkName: "AppIcon-[request]" */ `./icons/${
      this.name
    }.js`);
  },
  updated() {
    this.$nextTick(function() {
      import(/* webpackChunkName: "AppIcon-[request]" */ `./icons/${
        this.name
      }.js`);
    });
  },
};
</script>

<style lang="scss" scoped>
.AppIcon {
  display: inline-block;
  height: 1em;
  line-height: 1rem;
  color: inherit;
  vertical-align: middle;
  fill: none;
  stroke: currentColor;
}

.AppIcon--fill {
  fill: currentColor;
  stroke: none;
}

.AppIcon--s {
  height: 0.5em;
}

.AppIcon--m {
  height: 1em;
}

.AppIcon--l {
  height: 3em;
}
</style>
