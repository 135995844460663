import api from '@/api';

const actions = {
  getProfiles(
    {
      getters: { accessToken },
    },
    payload
  ) {
    return api
      .getProfiles()(accessToken, payload)
      .then(response => response.data);
  },
  suggestProfiles(
    {
      getters: { accessToken },
    },
    containedText
  ) {
    const params = { contains: containedText };
    return api
      .getProfiles()(accessToken, { params })
      .then(({ data: { items = [] } }) =>
        items.filter(({ type }) => type === 'profile')
      );
  },
  setAmbassador({ getters }, { login, value }) {
    return api
      .setAmbassador(login)(getters.accessToken, { value })
      .then(response => response.data);
  },
  suggestProfilesRequest() {
    return Promise.resolve({});
  },
  getClans({ getters }, payload) {
    return api
      .getClans(payload.pageToken, payload.query)(getters.accessToken)
      .then(response => response.data);
  },
  setClanVerification({ getters }, { clan, validated }) {
    return api
      .setClanVerification(clan)(getters.accessToken, { validated })
      .then(response => response.data);
  },
  getAutofollowedProfiles(
    {
      getters: { accessToken },
    },
    { params }
  ) {
    return api
      .getProfiles({ preventCancelRequest: true })(accessToken, {
        params: { ...params, auto_follow: true },
      })
      .then(res => res.data);
  },
  setAutofollowedProfiles(
    {
      getters: { accessToken },
    },
    payload
  ) {
    return api.putAutofollow(accessToken, payload).then(res => res.data);
  },
};

export default {
  actions,
};
