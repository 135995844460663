import Vue from 'vue';

export const INITIAL_FLAGS_STATE = () => ({
  conversationsFetched: false,
  countriesFetched: false,
  gamesFetched: false,
  tournamentsFetched: false,
  translationsFetched: false,
  platformsFetched: false,
});

const getters = {
  isConversationsFetched: state => state.conversationsFetched,
  isCountriesFetched: state => state.countriesFetched,
  isPlatformsFetched: state => state.platformsFetched,
  isGamesFetched: state => state.gamesFetched,
  isTournamentsFetched: state => state.tournamentsFetched,
  isTranslationsFetched: state => state.translationsFetched,
};
const mutations = {
  changeStatus(state, parameter) {
    state[`${parameter}Fetched`] = true;
  },
  clearToFlagsDefaultState(state) {
    const defaultState = INITIAL_FLAGS_STATE();

    Object.keys(defaultState).forEach(key => {
      if (key !== 'countriesFetched') {
        Vue.set(state, key, defaultState[key]);
      }
    });
  },
};

const actions = {};

export default {
  state: INITIAL_FLAGS_STATE(),
  getters,
  mutations,
  actions,
};
