import { BASE_URL } from '@/api/config';
import { authorizedGetRequest, authorizedPutRequest } from '@/api/requests';

const ROLES_BASE = BASE_URL + 'auth/roles/';
const IDENTITIES_BASE = BASE_URL + 'auth/identities/';

const getRoles = authorizedGetRequest(ROLES_BASE);
const setIdentitieRoles = id =>
  authorizedPutRequest(`${IDENTITIES_BASE}${id}/roles`);
const getIdentitieRoles = id =>
  authorizedGetRequest(`${IDENTITIES_BASE}${id}/roles`);

export default {
  getRoles,
  setIdentitieRoles,
  getIdentitieRoles,
};
