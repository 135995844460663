import { ifAuthorized } from '@/router/guards';

import PaidTournamentWrapper from '@/views/admin/PaidTournament/';
import PaidTournament from '@/views/admin/PaidTournament/PaidTournamentList';

export default [
  {
    path: '/paid-tournaments',
    title: 'Paid tournaments',
    component: PaidTournamentWrapper,
    beforeEnter: ifAuthorized,
    children: [
      {
        path: '',
        name: 'invoices',
        component: PaidTournament,
      },
    ],
  },
];
