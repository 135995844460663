<script>
import { debounce } from 'lodash';

export default {
  name: 'InfiniteScroll',
  props: {
    inContainer: {
      type: Boolean,
      default: false,
    },
    container: {
      type: null,
      default: null,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    list: {
      // required: true,
      type: null,
      default: () => [],
    },
    listName: {
      // required: true,
      type: String,
      default: 'items',
    },
    isDisabled: {
      required: true,
      type: Boolean,
      default: true, // not trigger callback by default
    },
    activationGap: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      isLoading: false,
      prevParentHeight: 0,
      listIsChanged: false,
      debounceTestPage: () => {},
      debounceTime: 200, // ms
    };
  },
  watch: {
    list() {
      this.isLoading = false;
      this.listIsChanged = !!(this.reverse && this.$el.parentElement);
    },
  },
  mounted() {
    this.$nextTick(function() {
      this.setDebounceTestPage();

      // todo use rxjs
      if (this.inContainer) {
        if (this.container) {
          this.container.addEventListener('scroll', this.debounceTestPage);
        } else {
          this.$el.parentElement.addEventListener(
            'scroll',
            this.debounceTestPage
          );
        }
      } else {
        window.addEventListener('scroll', this.debounceTestPage);
      }
    });
  },
  beforeDestroy() {
    if (this.inContainer) {
      if (this.container) {
        this.container.removeEventListener('scroll', this.debounceTestPage);
      } else {
        this.$el.parentElement.removeEventListener(
          'scroll',
          this.debounceTestPage
        );
      }
    } else {
      window.removeEventListener('scroll', this.debounceTestPage);
    }
  },
  beforeUpdate() {
    if (this.listIsChanged) {
      this.prevParentHeight =
        this.$el.parentElement.scrollHeight - this.$el.parentElement.scrollTop;
    }
  },
  updated() {
    if (this.listIsChanged) {
      this.$el.parentElement.scrollTop =
        this.$el.parentElement.scrollHeight - this.prevParentHeight;
      this.listIsChanged = false;
    }
  },
  methods: {
    setDebounceTestPage() {
      this.debounceTestPage = debounce(this.testPageBottom, this.debounceTime);
    },
    testPageBottom(e) {
      const $wrapper = this.inContainer
        ? this.container || this.$el.parentElement
        : null;

      if (this.reverse) {
        const scrollY = $wrapper ? $wrapper.scrollTop : window.pageYOffset;

        if (+scrollY <= this.activationGap && !this.isDisabled) {
          this.isLoading = true;
          this.$emit('ScrollToBottom', e);
        }

        return;
      }

      let viewHeight = $wrapper
        ? $wrapper.clientHeight
        : document.documentElement.clientHeight; //viewport height

      let pageHeight = $wrapper
        ? Math.max(
            $wrapper.scrollHeight,
            $wrapper.offsetHeight,
            $wrapper.clientHeight
          )
        : Math.max(
            document.body.scrollHeight,
            document.documentElement.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.offsetHeight,
            document.body.clientHeight,
            document.documentElement.clientHeight
          );

      const scrollY = $wrapper ? $wrapper.scrollTop : window.pageYOffset;

      if (
        pageHeight - scrollY - viewHeight <= this.activationGap &&
        !this.isDisabled
      ) {
        this.isLoading = true;
        this.$emit('ScrollToBottom', e);
        // this.callback()
      }
    },
  },
  render(createElement) {
    return createElement(
      'div',
      {
        class: {
          'w-100': true,
          'infinite-wrapper': true,
          'loader-after': !this.reverse,
          'loader-before': this.reverse,
          fetching: this.isLoading,
        },
      },
      [
        this.$scopedSlots.default({
          [this.listName]: this.list,
        }),
      ]
    );
  },
};
</script>

<style lang="scss">
$spin-wrap-height: 5rem;
$spin-size: 2rem;
$spin-offset-y: ($spin-wrap-height/2 - $spin-size/2); // 1.5rem

%spin-style {
  content: '';
  display: block;
  height: $spin-size;
  width: $spin-size;
  position: absolute;
  left: 50%;
  border: 2px solid transparent;
  border-left-color: $primary;
  border-bottom-color: $primary;
  border-radius: 50%;
  transform: translateX(-50%);
  animation: spinAround 0.5s infinite linear;
}

div.infinite-wrapper {
  &.fetching {
    position: relative;

    &.loader-after {
      padding-bottom: $spin-wrap-height;
      &:after {
        @extend %spin-style !optional;
        bottom: $spin-offset-y;
      }
    }

    &.loader-before {
      padding-top: $spin-wrap-height;
      &:before {
        @extend %spin-style !optional;
        top: $spin-offset-y;
      }
    }
  }

  @keyframes spinAround {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
}
</style>
