<template>
  <div
    class="home d-flex w-100 h-100 align-items-center justify-content-center"
  >
    <!--<h3 class="text-muted text-center mt-5">-->
    <!--Admin home page-->
    <!--</h3>-->
    <template>
      <div class="container">
        <div class="row my-4 align-items-center">
          <h3 class="mb-3 pb-0 col">Home page heading</h3>
        </div>
        <div class="row">
          <div class="col">
            <div class="card">
              <div class="card-body pb-0 px-4">
                <div class="row px-0 pt-3">
                  <div class="col-12">
                    <div class="section-description">
                      Home page subheading
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-auto">
                    WORK IN PROGRESS
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import store from '@/store';
export default {
  name: 'Home',
  created() {
    store.dispatch('getPlatforms');
    store.dispatch('getGames');
  },
};
</script>
