export const getNextPageData = {
  data() {
    return {
      nextPageToken: undefined,
      isLoading: true,
    };
  },
  methods: {
    getNextPageData(listName, dataFetcher = Promise.resolve()) {
      if (this.nextPageToken !== null) {
        this.isLoading = true;

        return dataFetcher
          .then(response => {
            this.isLoading = false;
            if (response) {
              this[listName] = this[listName].concat(response.items || []);
              this.nextPageToken = response.next_page_token;
            }
          })
          .catch(error => {
            if (!error.silent) {
              this.isLoading = false;
            }
            this.$store.dispatch('errorNotify', error);
          });
      }
    },
  },
};

export default [getNextPageData];
