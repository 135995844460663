import api from '@/api';

const actions = {
  fetchCustomers(
    {
      getters: { accessToken },
    },
    { pageToken = null, pageSize = 20, props }
  ) {
    return api.customer
      .getCustomerss(accessToken, pageToken, pageSize, props)
      .then(res => res.data);
  },
  exportAllCustomers(
    {
      getters: { accessToken },
    },
    { props }
  ) {
    return api.customer.exportAllCustomers(
      accessToken,
      `Playhera_customer.csv`,
      props
    );
  },
};
export default {
  actions,
};
