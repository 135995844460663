<template>
  <div class="d-flex flex-column px-4 platforms">
    <h3 class="my-4">Platforms</h3>

    <div class="card flex-grow-1">
      <div
        class="card-body position-relative d-flex flex-column p-0 custom-scrollbar"
      >
        <div v-if="isLoading" class="text-center text-muted py-4">
          Fetching platforms...
        </div>

        <div v-else class="position-absolute p-4 platforms_table-container">
          <button
            class="btn btn-link d-inline-flex align-items-baseline"
            @click="addPlatform"
          >
            <Icon name="add" width="14" class="fill-primary mr-2" />&nbsp;Add
            Platform
          </button>

          <table class="w-100 mt-3 platforms_table">
            <thead class="pb-2 platforms_table-head">
              <tr>
                <th></th>
                <th class="platforms_logo-cell">Icon</th>
                <th>Platform name</th>
                <th>Enabled</th>
                <th class="platforms_action-cell">Action</th>
              </tr>
            </thead>

            <SortableList
              v-model="platforms"
              container-tag="tbody"
              item-tag="tr"
              handle-tag="td"
            >
              <template slot="item" slot-scope="{ item }">
                <td class="platforms_logo-cell">
                  <div class="position-relative d-flex">
                    <FileBase64
                      :id="item.key"
                      v-model="item.newLogo"
                      image-preview-mode
                      class="position-relative mr-2 platforms_logo"
                      preupload
                      output-as="binary"
                      :predefined-file-url="item.logo && item.logo.uri"
                      is-required
                      :name="`platform-logo-${item.key}`"
                      validate-as="Platform Logo"
                    />
                    <Icon
                      v-if="item.newLogo || item.logo"
                      name="trash"
                      width="14"
                      class="cursor-pointer fill-primary"
                      @click="removeLogo(item)"
                    />
                  </div>
                </td>
                <td>
                  <TextInput
                    v-model="item.name"
                    v-validate="'required'"
                    class="position-relative w-75 m-0"
                    :data-vv-name="`platform-name-${item.key}`"
                    data-vv-as="Platform Name"
                    :error="
                      errors.first(`platform-logo-${item.key}`)
                        ? ''
                        : errors.first(`platform-name-${item.key}`)
                    "
                  />
                </td>
                <td>
                  <Toggle v-model="item.published" />
                </td>
                <td>
                  <button
                    class="btn btn-danger platforms_delete-btn"
                    @click="removePlatform(item)"
                  >
                    Remove
                  </button>
                </td>
                <td
                  v-if="
                    errors.first(`platform-logo-${item.key}`) ||
                      errors.first(`platform-name-${item.key}`)
                  "
                  class="m-0 p-0"
                  style="width: 0; height: 4.35rem;"
                ></td>
              </template>
            </SortableList>
          </table>
        </div>
      </div>

      <div class="card-footer">
        <button
          class="btn btn-primary"
          :disabled="!isSaveBtnActive"
          @click="save"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { uniqueId, isEqual, get, cloneDeep } from 'lodash';
import { mapActions } from 'vuex';

import SortableList from '@/components/common/SortableList';
import Icon from '@/components/common/Icon';
import TextInput from '@/components/form/HeraFormTextInput';
import FileBase64 from '@/components/form/fileBase64';
import Toggle from '@/components/form/HeraFormSwitch';

export default {
  name: 'PlatformsListView',

  inject: ['$validator'],

  components: {
    SortableList,
    Icon,
    TextInput,
    FileBase64,
    Toggle,
  },

  data() {
    return {
      isLoading: false,
      platforms: [],
      platformsLoaded: false,
      isSaveBtnActive: false,
      isSaving: false,
      removedPlatforms: [],
    };
  },

  watch: {
    platforms: {
      deep: true,
      handler() {
        if (this.platformsLoaded) {
          this.isSaveBtnActive = true;
        }
      },
    },
  },

  created() {
    this.isLoading = true;
    this.getPlatforms()
      .then(res => {
        this.platforms = res.map(item => ({
          ...item,
          key: item.id,
          newLogo: null,
          published: item.published || false,
        }));
        this.$options.savedPlatforms = cloneDeep(this.platforms);
      })
      .catch(this.errorNotify)
      .finally(() => {
        this.isLoading = false;
        this.platformsLoaded = true;
      });
  },

  methods: {
    ...mapActions([
      'getPlatforms',
      'createPlatform',
      'updatePlatform',
      'updatePlatformsOrder',
      'deletePlatform',
      'errorNotify',
      'successNotify',
    ]),

    addPlatform() {
      this.platforms.push({
        name: '',
        logo: {},
        key: uniqueId('platforms-table-key'),
        newLogo: null,
        published: false,
      });
    },

    removePlatform(item) {
      const index = this.platforms.findIndex(platform =>
        isEqual(platform, item)
      );
      this.platforms.splice(index, 1);
      if (item.id) {
        this.removedPlatforms.push(item);
      }
    },

    removeLogo(item) {
      item.newLogo = null;
      item.logo = {};
    },

    getPlatformDataForSave(platform) {
      return {
        name: platform.name,
        logo: {
          token: get(platform, 'newLogo.id') || get(platform, 'logo.token'),
          uri:
            get(platform, 'newLogo.display_uri') || get(platform, 'logo.uri'),
          type: 'image',
        },
        published: platform.published,
      };
    },

    save() {
      this.$validator
        .validateAll()
        .then(
          // Custom validation for unique names of platforms.
          isValide =>
            new Promise(resolve => {
              const notUniqNewPlatform = this.platforms
                .filter(({ id }) => !id)
                .find(({ name, key }) =>
                  this.platforms.find(
                    item => item.name === name && item.key !== key
                  )
                );
              if (notUniqNewPlatform) {
                // console.log(`platform-name-${notUniqNewPlatform.key}`);
                this.errors.add({
                  field: `platform-name-${notUniqNewPlatform.key}`,
                  msg: 'Platform name should be unique',
                });
                return resolve(false);
              }
              return resolve(true && isValide);
            })
        )
        .then(isValide => {
          if (!isValide) {
            throw { silent: true };
          }
        })
        .then(() => (this.isSaving = true))
        .then(this.updateExistingPlatforms)
        .then(this.createNewPlatforms)
        .then(this.updateRemovedPlatforms)
        .then(this.updateOrder)
        .then(() => {
          this.successNotify('Platforms saved!');
          this.isSaveBtnActive = false;
        })
        .catch(this.errorNotify)
        .finally(() => (this.isSaving = false));
    },

    updateExistingPlatforms() {
      return Promise.all(
        this.platforms
          .filter(item => item.id)
          .filter(
            item =>
              !isEqual(
                item,
                this.$options.savedPlatforms.find(({ id }) => id === item.id)
              )
          )
          .map(item =>
            this.updatePlatform({
              platform: this.getPlatformDataForSave(item),
              id: item.id,
            })
          )
      );
    },

    createNewPlatforms() {
      return Promise.all(
        this.platforms
          .filter(item => !item.id)
          .map(item =>
            this.createPlatform(this.getPlatformDataForSave(item)).then(res => {
              item.id = res.id || '';
              item.logo.uri = item.newLogo.display_uri;
              item.logo.token = item.newLogo.id;
              item.newLogo = null;
            })
          )
      );
    },

    updateRemovedPlatforms() {
      return Promise.all(
        this.removedPlatforms.map(({ id }) => this.deletePlatform(id))
      ).then(() => (this.removedPlatforms = []));
    },

    updateOrder() {
      return this.updatePlatformsOrder({
        list: this.platforms.map(({ id }) => id),
      });
    },
  },
};
</script>

<style lang="scss">
.platforms {
  &_table-head th {
    padding-bottom: 1rem;
    font-size: 0.625rem;
    font-weight: normal;
    text-transform: uppercase;
    color: $hera-secondary-text-color;
  }

  &_table {
    max-width: 37rem;
  }

  &_table-container {
    width: 75%;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  &_action-cell {
    width: 3rem;
  }

  &_logo {
    width: 2.375rem;
    height: 2.375rem;
  }

  &_logo-cell {
    width: 5rem;
  }

  &_delete-btn {
    font-size: 0.75rem;
  }

  .card-footer {
    background-color: transparent;
  }

  .error-text {
    position: absolute;
    margin: 0;
    bottom: -1.15rem;
    white-space: nowrap;
  }
}
</style>
