<template>
  <div class="edit-tournament-page-wrapper flex-grow-1 d-flex flex-column">
    <div class="container">
      <div class="row my-4 align-items-center">
        <h3 class="mb-0 pb-0 col-auto">Badges</h3>
        <div class="col text-center">
          <div class="btn-group" role="group" aria-label="Basic example">
            <router-link
              :to="{ name: 'clansVerification' }"
              class="btn px-4"
              :class="[
                matchCurrentRoute(['clansVerification'])
                  ? 'border-primary btn-primary'
                  : 'border btn-light',
              ]"
            >
              Verification
            </router-link>
            <router-link
              :to="{ name: 'ambassadors' }"
              class="btn px-4"
              :class="[
                matchCurrentRoute(['ambassadors'])
                  ? 'border-primary btn-primary'
                  : 'border btn-light',
              ]"
            >
              Approve
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <router-view class="flex-grow-1 d-flex flex-column" />
  </div>
</template>

<script>
import { routeMatching } from '@/mixins/routeUtils';
import store from '@/store';
export default {
  name: 'BadgesWrapper',
  mixins: [routeMatching],
  created() {
    store.dispatch('getPlatforms');
    store.dispatch('getGames');
  },
};
</script>
<style lang="scss" scoped>
th {
  text-transform: uppercase;
  font-size: 0.625rem;
  color: #b4bac6;
  letter-spacing: 0.09375rem;
  margin-bottom: 1.25rem;
}

td {
  font-weight: bold;
  font-size: 0.75rem;
}
tr:first-child td {
  border-top: 0;
}
.section-description {
  text-transform: uppercase;
}
</style>
