var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"coupon-list-item"},[_c('td',{staticClass:"px-2 text-truncate voucher-list-amount"},[_c('span',[_vm._v(" "+_vm._s(_vm.voucher.title))])]),_c('td',{staticClass:"px-2 text-truncate voucher-list-amount"},[_c('span',[_vm._v(" "+_vm._s(_vm.voucher.code))])]),_c('td',{staticClass:"px-2 text-truncate voucher-list-amount"},[_c('span',[_vm._v(" "+_vm._s(_vm.voucher.period))])]),_c('td',{staticClass:"px-2 text-truncate voucher-list-amount"},[_c('span',[_vm._v("\n      "+_vm._s(_vm.voucher.expires_at !== null
          ? new Date(_vm.voucher.expires_at * 1000).toLocaleDateString() +
            ' ' +
            new Date(_vm.voucher.expires_at * 1000).toLocaleTimeString()
          : ''))])]),_c('td',{staticClass:"px-2 text-truncate voucher-list-amount"},[_c('span',[_vm._v(" "+_vm._s(_vm.voucher.used_by))])]),_c('td',{staticClass:"px-2 text-truncate voucher-list-amount"},[_c('span',[_vm._v(" "+_vm._s(_vm.voucher.promoter))])]),_c('td',{staticClass:"px-2 text-truncate voucher-list-amount"},[_c('span',[_vm._v("\n      "+_vm._s(_vm.voucher.created_on !== null
          ? new Date(_vm.voucher.created_on * 1000).toLocaleDateString() +
            ' ' +
            new Date(_vm.voucher.created_on * 1000).toLocaleTimeString()
          : ''))])]),_c('td',[_c('button',{staticClass:"btn btn-sm btn-outline-primary",on:{"click":function($event){return _vm.$emit('edit', _vm.voucher)}}},[_vm._v("\n      Edit\n    ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }