<template>
  <div :class="{ onlineClass: isOnline, offlineClass: !isOnline }">
    <slot v-if="isOnline" name="online" />
    <slot v-else name="offline" />
  </div>
</template>

<script>
const EVENTS = ['online', 'offline', 'load'];
export default {
  name: 'DetectOffline',
  props: {
    onlineClass: {
      type: String,
      default: 'is-online',
      required: false,
    },
    offlineClass: {
      type: String,
      default: 'is-offline',
      required: false,
    },
  },
  data: () => ({
    isOnline: navigator.onLine || false,
  }),
  mounted() {
    EVENTS.forEach(event =>
      window.addEventListener(event, this.updateOnlineStatus)
    );
  },
  beforeDestroy() {
    EVENTS.forEach(event =>
      window.removeEventListener(event, this.updateOnlineStatus)
    );
  },
  methods: {
    updateOnlineStatus() {
      this.isOnline = navigator.onLine || false;
      if (this.isOnline) {
        this.$emit('goOnline', this.isOnline);
      } else {
        this.$emit('goOffline', this.isOnline);
      }
    },
  },
};
</script>
