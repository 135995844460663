import { startCase, isEmpty } from 'lodash';
import api from '@/api';

export const INITIAL_ORGANIZATION_STATE = () => {
  return {
    organizationTypes: [],
  };
};

const getters = {
  getOrganizationTypeCaption: state => type => {
    const orgType = state.organizationTypes.find(({ value }) => value === type);
    return (orgType && orgType.text) || type;
  },
};

const mutations = {
  setOrganizationTypes(state, payload) {
    state.organizationTypes = payload.map(value => ({
      value,
      text: startCase(value),
    }));
  },
};

const actions = {
  getOrganizationAdminsList({ getters }) {
    return (
      api
        // TODO api dont use page query param yet
        .getOrganizationMembers(getters.login)(getters.accessToken)()
        .then(response => response.data)
    );
  },
  setOrganizationMember({ getters }, login) {
    return api
      .setOrganizationMember(getters.accessToken, {
        organizationId: getters.myProfile.id,
        memberId: login,
      })
      .then(response => response.data);
  },
  removeOrganizationMember({ getters }, login) {
    return api
      .deleteOrganizationMember(getters.myProfile.id, login)(
        getters.accessToken
      )
      .then(response => response.data);
  },
  fetchOrganizations(
    {
      getters: { accessToken },
    },
    payload
  ) {
    return api
      .getOrganizations(accessToken, payload)
      .then(response => response.data);
  },
  getOrganization(
    {
      getters: { accessToken },
    },
    payload
  ) {
    return api
      .getOrganizationProfile(payload)(accessToken)
      .then(resp => resp.data);
  },

  createOrganization(
    {
      getters: { accessToken },
      dispatch,
    },
    {
      name,
      owner,
      organizationType,
      country,
      country_id,
      city,
      city_id,
      roles,
      avatar,
      cover,
    }
  ) {
    return Promise.all([
      avatar ? dispatch('fileUpload', avatar.file) : Promise.resolve(null),
      cover ? dispatch('fileUpload', cover.file) : Promise.resolve(null),
    ])
      .then(([avatarRes, coverRes]) =>
        api.createOrganization(accessToken, {
          name,
          owner,
          organizationType,
          country,
          country_id,
          city,
          city_id,
          ...(avatarRes ? { avatar: avatarRes.file_id } : {}),
          ...(coverRes ? { cover: coverRes.file_id } : {}),
        })
      )
      .then(({ data: { id } }) =>
        api.setIdentitieRoles(id)(accessToken, { roles })
      );
  },

  getAvailableRoles({ getters: { accessToken } }) {
    return api.getRoles(accessToken).then(res => res.data);
  },

  getOrganizationRoles(
    {
      getters: { accessToken },
    },
    id
  ) {
    return api
      .getIdentitieRoles(id)(accessToken)
      .then(res => res.data);
  },

  updateOrganization(
    {
      getters: { accessToken },
      dispatch,
    },
    {
      name,
      owner,
      organizationType,
      country,
      country_id,
      city,
      city_id,
      roles,
      avatar,
      cover,
      id,
    }
  ) {
    const data = {
      name,
      organizationType,
      country,
      country_id,
      city,
      city_id,
    };

    const avatarPromise = avatar
      ? dispatch('fileUpload', avatar.file)
      : Promise.resolve();

    const coverPromise = cover
      ? dispatch('fileUpload', cover.file)
      : Promise.resolve();

    return Promise.all([avatarPromise, coverPromise])
      .then(([avatarRes, coverRes]) => {
        if (avatar) {
          data.avatar = avatarRes.file_id;
        }
        if (cover) {
          data.cover = coverRes.file_id;
        }
      })
      .then(() => {
        const promises = [];

        if (!isEmpty(data)) {
          promises.push(api.setOrganizationDetails(id)(accessToken, data));
        }
        if (roles) {
          promises.push(api.setIdentitieRoles(id)(accessToken, { roles }));
        }
        if (owner) {
          promises.push(
            api.setOrganizationOwner(accessToken, {
              OrganizationId: id,
              MemberId: owner,
            })
          );
        }

        return Promise.all(promises);
      });
  },

  deleteOrganization(
    {
      getters: { accessToken },
    },
    id
  ) {
    return api
      .deleteOrganizationProfile(id)(accessToken)
      .then(res => res.data);
  },

  fetchOrganizationTypes({ getters: { accessToken }, commit }) {
    return api.getOrganizationTypes(accessToken).then(({ data }) => {
      commit('setOrganizationTypes', data);
      return data;
    });
  },
};

export default {
  state: INITIAL_ORGANIZATION_STATE(),
  getters,
  mutations,
  actions,
};
