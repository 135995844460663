<template>
  <notifications
    v-if="!isHidden"
    group="social"
    class="social"
    position="top right"
  >
    <template slot="body" slot-scope="props">
      <div
        class="vue-notification d-flex-column shadow"
        :class="[props.classes, props.item.type]"
      >
        <div class="row main-part no-gutters">
          <div class="col-auto notification-image-wrapper">
            <div
              class="image"
              :style="{ backgroundImage: props.item.data.image }"
            ></div>
          </div>
          <div class="col notification-content-wrapper pl-2 pr-3">
            <div class="row px-0 mx-0">
              <div class="col-6 notification-type text-left pl-0">
                <span>{{ props.item.type }}</span>
              </div>
              <div class="col-6 notification-date text-right pr-0">
                {{ props.item.data.datetime }}
              </div>
            </div>
            <div
              v-if="props.item.data && props.item.data.details"
              class="row mt-3 mb-2"
            >
              <div class="col-12 notification-details">
                {{ props.item.data.details }}
              </div>
            </div>
            <div
              class="row mb-2"
              :class="[
                props.item.data && props.item.data.details ? 'mt-2' : 'mt-3',
              ]"
            >
              <!-- eslint-disable vue/no-v-html -->
              <div
                class="col-12 notification-text"
                v-html="props.item.text"
              ></div>
            </div>
          </div>
        </div>
        <!-- <div class="row notification-addons" v-if="props.item.data&&props.item.data.addons">
          
        </div> -->
        <div
          v-if="props.item.data && props.item.data.actions"
          class="row notification-actions justify-content-end mb-2"
        >
          <div
            v-for="(url, action) in props.item.data.actions"
            :key="action"
            class="col-auto"
          >
            <a :href="url" class="btn btn-link">{{ action }}</a>
          </div>
        </div>
        <AppIcon
          name="close"
          width="16"
          class="notify-destroy"
          @click="destroy"
        />
      </div>
    </template>
  </notifications>
</template>

<script>
import { mapActions } from 'vuex';
import AppIcon from './Icon';
export default {
  name: 'SocialNotifications',
  components: { AppIcon },
  props: {
    channel: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isHidden: false,
    };
  },
  created() {
    this.channel.subscribe(notification => {
      this.socialNotify({
        title: notification.subject,
        text: notification.text,
        data: {
          image: notification.sender.avatar,
        },
      });
    });
  },
  methods: {
    ...mapActions(['socialNotify']),
    destroy() {
      this.isHidden = true;
    },
  },
};
</script>

<style lang="scss">
$transparency: 0.8;

.notifications {
  &.social {
    * {
      color: $hera-main-text-color;
    }
    .notify-destroy {
      position: absolute;
      z-index: 2;
      right: 0.75rem;
      top: 0.75rem;
      cursor: pointer;
      opacity: 0.7;
      &:hover {
        opacity: 1;
      }
    }
    .vue-notification {
      position: relative;
      padding: 0;
      overflow: hidden;
      margin: 0.75rem;

      background: $hera-white-fill;
      border: 0px solid $primary;
      border-radius: 10px;
      .notification-image-wrapper {
        width: 60px;
        height: 82px;
        .image {
          height: 100%;
          background-color: #ccc;
          &:after {
            content: '';
            position: absolute;
            width: 100%;
            top: 50%;
            height: 50%;
            background: linear-gradient(transparent, $hera-white-fill);
          }
          &:before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            right: 0;
            border-style: solid;
            border-width: 82px 16px 0px 0;
            border-color: transparent $hera-white-fill transparent transparent;
          }
        }
      }
      .notification-content-wrapper {
        flex-grow: 1;
        padding-top: 0.75rem;
        .notification-date,
        .notification-date *,
        .notification-type,
        .notification-type * {
          line-height: 13px;
          font-size: 11px;
          letter-spacing: 0.00636364rem;
          color: $hera-secondary-text-color !important;
        }
        .notification-details {
          line-height: 18px;
          font-size: 13px;
          letter-spacing: -0.00615385rem;
        }
        .notification-text {
          line-height: 22px;
          font-size: 17px;
          letter-spacing: -0.0241176rem;
        }
      }
      .notification-actions {
        a {
          line-height: 21px;
          font-size: 16px;
          letter-spacing: -0.02rem;

          color: $primary;
        }
      }
    }
  }
}
</style>
