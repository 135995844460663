<template>
  <tr class="coupon-list-item">
    <td class="px-2 pl-4 text-truncate subscription-list-item_name">
      <span>{{ orders.pos_id }}</span>
    </td>
    <td class="px-2 text-truncate invoice-list-amount">
      <span>{{ orders.login }}</span>
    </td>
    <td class="px-2 text-truncate invoice-list-amount">
      <span>{{ orders.items[0].name }}</span>
    </td>
    <td class="px-2 text-truncate invoice-list-amount">
      <span>{{ ordersPlaced }}</span>
    </td>
    <td class="px-2 text-truncate">
      <span>{{ ordersProcessed }}</span>
    </td>
    <td class="px-2 text-truncate">
      <span>{{ orders.price.total }} {{ orders.currency }}</span>
    </td>
    <td class="px-2 text-truncate invoice-list-trial-amount">
      <span>{{ getOrderStatus(orders.status) }}</span>
    </td>
    <td class="px-2 text-truncate invoice-list-trial-amount">
      <span>{{ orders.country_id }}</span>
    </td>
    <td>
      <span class="view-order-btn"
        ><router-link
          v-if="orders.status === 5"
          :to="{
            name: 'SingleOrderMagento',
            params: { login: orders.login, magento: orders.pos_id },
          }"
          class="btn btn-sm btn-outline-info"
        >
          View Order
        </router-link></span
      >

      <router-link
        :to="{
          name: 'SingleOrder',
          params: { login: orders.login },
        }"
        class="btn btn-sm ml-3 btn-outline-info"
      >
        View All Orders
      </router-link>
    </td>
  </tr>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'OrderListItem',
  inject: ['$validator'],
  props: {
    orders: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      email: '',
    };
  },
  computed: {
    ordersPlaced() {
      let orderPlaced = this.orders.order_dates.order_placed
        ? new Date(this.orders.order_dates.order_placed)
        : null;

      return orderPlaced
        ? `${this.addLeadingZero(
            orderPlaced.getUTCDate()
          )}/${this.addLeadingZero(
            orderPlaced.getUTCMonth() + 1
          )}/${orderPlaced.getUTCFullYear()} ${this.addLeadingZero(
            orderPlaced.getUTCHours()
          )}:${this.addLeadingZero(
            orderPlaced.getUTCMinutes()
          )}:${this.addLeadingZero(orderPlaced.getUTCSeconds())}`
        : '-';
    },
    ordersProcessed() {
      let ordersProcessed = this.orders.order_dates.order_processed
        ? new Date(this.orders.order_dates.order_processed)
        : null;

      return ordersProcessed
        ? `${this.addLeadingZero(
            ordersProcessed.getUTCDate()
          )}/${this.addLeadingZero(
            ordersProcessed.getUTCMonth() + 1
          )}/${ordersProcessed.getUTCFullYear()} ${this.addLeadingZero(
            ordersProcessed.getUTCHours()
          )}:${this.addLeadingZero(
            ordersProcessed.getUTCMinutes()
          )}:${this.addLeadingZero(ordersProcessed.getUTCSeconds())}`
        : '-';
    },
  },
  methods: {
    ...mapActions(['openModal', 'closeModal', 'resendInvoice', 'errorNotify']),
    addLeadingZero(num) {
      return (num < 10 ? '0' : '') + num;
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    resend() {
      this.isLoading = true;

      this.resendInvoice({
        id: this.orders.id,
        email: this.email,
      })
        .then(() => {})
        .catch(this.errorNotify)
        .finally(() => (this.isLoading = false));
    },
    getOrderStatus(status) {
      switch (status) {
        case 1:
          return 'Placed';
        case 2:
          return 'Reserved';
        case 3:
          return 'Processing';
        case 4:
          return 'Failed';
        case 5:
          return 'Success';
        case 6:
          return 'Cancelled';
        case 7:
          return 'DeliveryFailed';
        default:
          return 'Unknown Status';
      }
    },
  },
};
</script>

<style lang="scss">
.disc-desc {
  max-width: 100px;
  word-break: break-all;
}
.view-order-btn {
  display: inline-block !important;
  width: 90px;
}
</style>
