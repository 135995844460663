import api from '@/api';

const actions = {
  fetchInvoices(
    {
      getters: { accessToken },
    },
    { pageToken = null, pageSize = 20, props }
  ) {
    return api.invoices
      .getInvoices(accessToken, pageToken, pageSize, props)
      .then(res => res.data);
  },
  exportInvoice(
    {
      getters: { accessToken },
    },
    { id, invoiceId }
  ) {
    return api.invoices.exportInvoice(
      accessToken,
      id,
      `Playhera_invoice_${invoiceId}.pdf`
    );
  },
  exportAllInvoices(
    {
      getters: { accessToken },
    },
    { props }
  ) {
    return api.invoices.exportAllInvoices(
      accessToken,
      `Playhera_invoice_export.csv`,
      props
    );
  },
  resendInvoice(
    {
      getters: { accessToken },
    },
    { id, email }
  ) {
    return api.invoices.resendInvoice(accessToken, id, email);
  },
};
export default {
  actions,
};
