<!-- eslint-disable prettier/prettier -->
<template>
  <div>
    <h2>Charts</h2>
    <div ref="activeSubscriptionChart" class="graph1"></div>
  </div>
</template>

<script>
import { charts, sdk } from './MongoCharts.js';

export default {
  mounted() {
    this.createDashboard(
      charts.activeSubscriptionChartId,
      this.$refs.activeSubscriptionChart
    );
    // this.createChart(
    //   charts.canceledSubscriptionChartId,
    //   this.$refs.canceledSubscriptionChart
    // );
    //
  },

  methods: {
    createChart(chartId, container) {
      try {
        const chart = sdk.createChart({ chartId });
        chart.render(container);
      } catch (error) {
        alert('An error occurred: ' + error);
      }
    },
    createDashboard(dashboardId, container) {
      try {
        const chart = sdk.createDashboard({ dashboardId });
        chart.render(container);
      } catch (error) {
        alert('An error occurred: ' + error);
      }
    },
  },
};
</script>

<style>
.graph1 {
  height: 90vh;
}
</style>
