'use strict';

import {
  CALLING_CODES,
  SUGGEST_COUNTRIES,
  SUGGEST_CITY,
  GAMES,
  PLATFORMS,
  SETTINGS_BASE,
} from './settingsConstants';

import {
  simpleGetRequest,
  authorizedGetRequest,
  authorizedPostRequest,
  authorizedPutRequest,
  authorizedDeleteRequest,
} from '@/api/requests.js';
import genresApi from './settingsGenres';

export const getCallingCodes = simpleGetRequest(CALLING_CODES);

export const suggestCountries = query =>
  simpleGetRequest(`${SUGGEST_COUNTRIES}?Prefix=${query}`);

export const suggestCities = (country, query) =>
  simpleGetRequest(`${SUGGEST_CITY}?Country=${country}&Prefix=${query}`);

export const getGames = (published = false) =>
  authorizedGetRequest(GAMES + `?published_only=${published.toString()}`);

export const createGame = authorizedPostRequest(GAMES);

export const publishGame = ID =>
  authorizedPutRequest(GAMES + `/published/${ID}`);

const updateGame = id => authorizedPutRequest(`${GAMES}/${id}`);

export const getPlatforms = authorizedGetRequest(PLATFORMS);

export const getCountryById = countryId =>
  authorizedGetRequest(`${SETTINGS_BASE}/countries/${countryId}`);

const createPlatform = authorizedPostRequest(PLATFORMS);

const updatePlatform = id => authorizedPutRequest(`${PLATFORMS}/${id}`);

const updatePlatformsOrder = authorizedPutRequest(`${PLATFORMS}/all/ordering`);

const deletePlatform = id => authorizedDeleteRequest(`${PLATFORMS}/${id}`);

export default {
  getCallingCodes,
  suggestCountries,
  suggestCities,
  getGames,
  createGame,
  publishGame,
  updateGame,
  getPlatforms,
  getCountryById,
  createPlatform,
  updatePlatform,
  updatePlatformsOrder,
  deletePlatform,
  ...genresApi,
};
