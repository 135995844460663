<template>
  <div class="page-wrapper">
    <div class="container">
      <div class="row my-4 align-items-center">
        <h3 class="mb-0 pb-0 col">Ambassadors</h3>
      </div>
      <div class="row mb-2">
        <div class="col">
          <div class="card py-3">
            <div class="card-body">
              <div class="row mx-2">
                <div class="col-12">
                  <div class="section-description">
                    List of ambassadors({{ profilesList.length + 'PROFILES' }})
                  </div>
                </div>
              </div>
              <div class="row mx-2">
                <div class="col-auto">
                  <label class="d-block">Name Search:</label>
                  <textInput
                    v-model="filters.contains"
                    placeholder="Start typing..."
                    :debounce="debounce"
                    @input="onFilterChange"
                  />
                </div>
                <div class="col-auto">
                  <label class="d-block">Location:</label>
                  <autosuggest
                    v-model="filters.countries"
                    placeholder="Start typing..."
                    display-property="text"
                    :source="suggestCountriesRequest"
                    multiple
                    @resultPicked="onFilterChange"
                  />
                </div>
              </div>
            </div>
            <div class="card-body border-top border-muted pt-0">
              <div class="row mb-3 px-3 pt-3">
                <infiniteScroll
                  :list="profilesList"
                  list-name="list"
                  :is-disabled="isLoading || nextPageToken === null"
                  :activation-gap="100"
                  @ScrollToBottom="fetchProfiles"
                >
                  <table
                    id="profiles-list"
                    slot-scope="{ list }"
                    class="table table-hover mx-0 px-0"
                  >
                    <caption
                      v-if="!profilesList.length && isLoading"
                      class="text-center text-muted"
                    >
                      Fetching profiles...
                    </caption>
                    <caption
                      v-else-if="!profilesList.length"
                      class="text-center text-muted"
                    >
                      No profiles
                    </caption>
                    <template v-else>
                      <thead>
                        <tr>
                          <th class="border-top-0 border-bottom-0 pr-2 pl-4">
                            Name
                          </th>
                          <th class="border-top-0 border-bottom-0 pr-2 pl-4">
                            Location
                          </th>
                          <th class="border-top-0 border-bottom-0 px-2">
                            Verification badge
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="profile in list" :key="profile.login">
                          <td class="pr-2 pl-4">
                            <avatar
                              :img-url="profile.avatar || profile.logo"
                              :size="22"
                              class="d-inline-block mr-2 profile-avatar"
                            />
                            {{ profile.name || profile.login }}
                          </td>
                          <td class="pr-4 pl-2">{{ profile.location }}</td>
                          <td class="pr-4 pl-2">
                            <switcher
                              :value="profile.is_ambassador"
                              @change="setBadge(profile.login, $event)"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </table>
                </infiniteScroll>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import avatar from '@/components/common/avatar.vue';
import { mapActions } from 'vuex';
import infiniteScroll from '@/components/common/infiniteScroll.vue';
import switcher from '@/components/form/HeraFormSwitch.vue';
import textInput from '@/components/form/HeraFormTextInput.vue';
import autosuggest from '@/components/form/HeraAutosuggest';
import listMixin from '@/mixins/lists';

const defaultFilterState = () => ({
  contains: '',
  countries: [],
});
export default {
  name: 'Ambassadors',
  components: {
    avatar,
    textInput,
    infiniteScroll,
    switcher,
    autosuggest,
  },
  mixins: [...listMixin],
  data() {
    return {
      debounce: 700,
      profilesList: [],
      filters: defaultFilterState(),
    };
  },
  created() {
    this.fetchProfiles();
  },
  methods: {
    ...mapActions([
      'getProfiles',
      'setAmbassador',
      'errorNotify',
      'successNotify',
      'suggestCountriesRequest',
    ]),
    onFilterChange() {
      this.profilesList = [];
      this.nextPageToken = undefined;
      this.fetchProfiles();
    },
    fetchProfiles() {
      const params = {
        page: this.nextPageToken,
        contains: this.filters.contains,
        country: this.filters.countries.map(country => country.id),
      };
      return this.getNextPageData('profilesList', this.getProfiles({ params }));
    },
    setBadge(login, value) {
      this.setAmbassador({ login, value })
        .then(() => {
          if (value) {
            this.successNotify('User is ambassador from now');
          } else {
            this.successNotify('User is not ambassador from now');
          }
        })
        .catch(this.errorNotify);
    },
  },
};
</script>
