import api from '../../api';
import Vue from 'vue';

export const INITIAL_CLANS_STATE = () => ({
  approveRequests: [],
  requestsPageToken: undefined,
});

// const state = {
//   approveRequests: [],
//   requestsPageToken: undefined
// }

const getters = {
  approveRequests: state => state.approveRequests,
  requestsPageToken: state => state.requestsPageToken,
  requestIndexByClan: state => clan =>
    state.approveRequests.findIndex(request => request.clan === clan),
};

const mutations = {
  addApproveRequests(state, payload) {
    const requests = payload.map(el => ({ ...{}, ...el, ...{ status: null } }));
    if (state.approveRequests.length) {
      requests.forEach(request => {
        if (state.approveRequests.findIndex(r => r.clan === request.clan) < 0) {
          Vue.set(state.approveRequests, state.approveRequests.length, request);
        }
      });
    } else {
      state.approveRequests = [].concat(state.approveRequests, requests);
    }
  },
  updateApproveRequest(state, payload) {
    Vue.set(state.approveRequests, payload.index, {
      ...{},
      ...state.approveRequests[payload.index],
      ...{ status: payload.approved ? 'Approved' : 'Rejected' },
    });
  },
  setRequestsPageToken(state, payload) {
    state.requestsPageToken = payload;
  },
  clearToClansDefaultState(state) {
    const defaultState = INITIAL_CLANS_STATE();

    Object.keys(defaultState).forEach(key => {
      Vue.set(state, key, defaultState[key]);
    });
  },
};

const actions = {
  getApproveRequests({ commit, getters }) {
    if (!getters.accessToken) throw 'No Access token';
    return new Promise((resolve, reject) => {
      api
        .getClanValidations(getters.requestsPageToken)(getters.accessToken)()
        .then(response => {
          commit('addApproveRequests', response.data.items);
          commit('setRequestsPageToken', response.data.next_page_token);
          resolve();
        })
        .catch(error => reject(error));
    });
  },
  approveClan({ commit, getters }, clan) {
    if (!getters.accessToken) throw 'No Access token';
    return new Promise((resolve, reject) => {
      api
        .approveClanValidation(clan)(getters.accessToken)
        .then(response => {
          commit('updateApproveRequest', {
            index: getters.requestIndexByClan(clan),
            approved: true,
          });
          resolve(response);
        })
        .catch(error => reject(error));
    });
  },
  rejectClan({ commit, getters }, clan) {
    if (!getters.accessToken) throw 'No Access token';
    return new Promise((resolve, reject) => {
      api
        .rejectClanValidation(clan)(getters.accessToken)
        .then(response => {
          commit('updateApproveRequest', {
            index: getters.requestIndexByClan(clan),
            approved: false,
          });
          resolve(response);
        })
        .catch(error => reject(error));
    });
  },
  suggestClansRequest(_state, query) {
    return new Promise((resolve, reject) => {
      return api
        .suggestClans(query)()
        .then(response => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
};

export default {
  state: INITIAL_CLANS_STATE(),
  getters,
  mutations,
  actions,
};
