export const localDate = (value, options = []) => {
  value = +value;
  if (!value) return '';
  if (value < 1e10) value *= 1000;
  return new Date(value).toLocaleString(...options);
};

/**
 * parse unix-time (in seconds) to ISO format
 * @param input (sec)
 * @param now (sec)
 * @returns {string} Ex: "11 December 2018, 17:29"
 */
export const getTimeAgo = (input, now = 0) => {
  if (!now) {
    now = Date.now();
  }
  const deltaSeconds = now / 1e3 - input;

  if (deltaSeconds < 60) {
    return 'recently';
  }

  const deltaMinutes = deltaSeconds / 60;
  if (deltaMinutes < 60) {
    return `${deltaMinutes | 0} minutes ago`;
  }

  const deltaHours = deltaMinutes / 60;
  if (deltaMinutes < 24) {
    return `${deltaHours | 0} hours ago`;
  }

  return new Date(input * 1e3).toLocaleString('en-GB', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
};

/**
 * parse unix-time (in seconds) to ISO time (d/m/y)
 * @param input
 * @returns {string}
 */
export const parseDate = input => {
  const date = new Date(input * 1e3);

  return date.toLocaleDateString('en-US');
};

/**
 * parse unix-time (in seconds) to ISO time (hh:MM (A|P)M)
 * @param input
 * @returns {string}
 */
export const parseTime = input => {
  const date = new Date(input * 1e3);

  return date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
};

export const capitalize = value => {
  if (!value) return '';
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export default {
  localDate,
  getTimeAgo,
  parseDate,
  parseTime,
  capitalize,
};
