<script>
import * as signalR from '@aspnet/signalr';
import { mapActions, mapMutations } from 'vuex';
import { Subject } from 'rxjs';
export default {
  name: 'SignalRHub',
  data() {
    return {
      hubName: 'notification',
      hub: null,
      notifications: new Subject(),
      messages: new Subject(),
    };
  },
  created() {
    this.initHubConnection(this.hubName)
      .then(payload => this.setConnectionInfo(payload))
      .then(hub => {
        this.hub = hub;
        return this.startConnection();
      })
      .then(() => {
        this.hub.on('notify', data => {
          this.notifications.next(data);
        });
        this.hub.on('message', data => {
          this.messagesNext(data);
        });
        this.hub.on('system', data => {
          this.systemNext(data);
        });
      });
  },
  methods: {
    ...mapMutations(['systemNext', 'messagesNext']),
    ...mapActions(['initHubConnection', 'errorNotify']),
    setConnectionInfo(payload) {
      let options = {
        accessTokenFactory: () => payload.info.access_key,
      };
      return new signalR.HubConnectionBuilder()
        .withUrl(payload.info.endpoint, options)
        .configureLogging(signalR.LogLevel.Information)
        .build();
    },
    // start hub signalR flow
    startConnection() {
      return this.hub.start().catch(this.errorNotify);
    },
  },

  render() {
    return this.$scopedSlots.default({
      notifications: this.notifications,
    });
  },
};
</script>
