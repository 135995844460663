import { ifAuthorized } from '@/router/guards';

import ExternalSubscriptions from '@/views/admin/ExternalSubs/';
import ExtSubs from '@/views/admin/ExternalSubs/lists';

export default [
  {
    path: '/extSubscriptions',
    title: 'External subscriptions',
    component: ExternalSubscriptions,
    beforeEnter: ifAuthorized,
    children: [
      {
        path: '',
        name: 'ExtSubs',
        component: ExtSubs,
      },
    ],
  },
];
