<template>
  <div class="dropdown">
    <button
      type="button"
      :disabled="disabled"
      class="btn btn-secondary dropdown-toggle border-0"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      :class="{ 'text-right': dir === 'rtl' }"
      tabindex="0"
    >
      <span v-if="isEmpty" class="placeholder-text">{{ chosenItem }}</span>
      <slot v-else :chosenItem="chosenItem" name="chosen">
        <span>{{ chosenItem.text || chosenItem }}</span
        ><span class="sr-only">{{ label }}</span>
      </slot>
      <icon
        name="dropdown-chevron"
        class="dropdown-icon"
        fill="#DCE0E6"
        width="10"
      />
    </button>
    <div class="dropdown-menu bg-white">
      <input
        v-if="showFilter"
        id="dropdownFilter"
        v-model.trim="dropdownFilter"
        type="text"
        class="form-control shadow-none mb-2 border-top-0 border-left-0 border-right-0 rounded-0"
        placeholder="Type to filter list..."
        name="dropdownFilter"
        autocomplete="off"
      />
      <div class="custom-scrollbar" :class="{ 'is-loading py-3': isLoading }">
        <a
          v-if="emptyOption && emptyText && couldBeEmpty"
          class="dropdown-item"
          :class="{ disabled: !couldBeEmpty }"
          @click.prevent="couldBeEmpty && changeItem(null)"
          >{{ emptyText }}</a
        >
        <a
          v-for="(item, index) in filteredList"
          :key="index"
          class="dropdown-item text-truncate"
          :class="{ 'text-right': dir === 'rtl' }"
          @click.prevent="changeItem(item)"
        >
          <slot :item="item" name="item">
            {{ item.text }}
          </slot>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import icon from '@/components/common/Icon';
export default {
  name: 'HeraFormDropdown',
  components: {
    icon,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    emptyOption: {
      type: Boolean,
      default: false,
    },
    emptyText: {
      type: String,
      default: 'Open to select...',
    },
    list: {
      type: Array,
      default: () => [],
      required: true,
    },
    value: {
      type: null,
      default: null,
    },
    label: {
      type: String,
      default: 'Choose option',
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    showFilter: {
      type: Boolean,
      default: false,
    },
    couldBeEmpty: {
      type: Boolean,
      default: false,
    },
    dir: {
      type: String,
      default: 'ltr',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dropdownFilter: '',
    };
  },
  computed: {
    filteredList() {
      return this.list.filter(e => {
        return this.showFilter && this.dropdownFilter
          ? e.name.toLowerCase().indexOf(this.dropdownFilter.toLowerCase()) > -1
          : true;
      });
    },
    chosenItem: {
      get() {
        return this.value || (this.emptyOption ? this.emptyText : this.list[0]);
      },
      set(value) {
        this.$emit('input', value);
        this.$emit('change', value);
        this.$emit('itemChanged', value);
      },
    },
    isEmpty() {
      return !this.value || (this.emptyOption && this.value === this.emptyText);
    },
  },
  created() {},
  updated() {},
  methods: {
    changeItem(item) {
      this.chosenItem = item || this.emptyText;
    },
  },
};
</script>
<style lang="scss">
.dropdown {
  .dropdown-toggle {
    // background-image: url(../../assets/images/dropdown-chevron.svg)!important;
    // background-size: .75em .5em;
    // background-position: 90% center;
    // background-repeat: no-repeat;
    padding-left: 0.5rem;
    padding-right: 1.4375rem;
    background-color: transparent !important;
    outline: none !important;
    box-shadow: none !important;
    position: relative;
    min-width: unset;

    &::first-letter {
      text-transform: uppercase;
    }
    &:after {
      content: none !important;
      border: none !important;
    }
    .placeholder-text {
      color: #6c757d !important;
    }
    .dropdown-icon {
      position: absolute;
      right: 0.3125rem;
      top: calc(50% - 5px / 2);
    }
    &.text-right {
      padding-right: 0.5rem;
      padding-left: 1.4375rem;
      .dropdown-icon {
        right: unset;
        left: 0.3125rem;
      }
      // background-position: 10% center;
    }
  }
  .dropdown-item {
    padding: 0.25rem 0.5rem 0.25rem 0.5rem;
    font-weight: bold;
    &::first-letter {
      text-transform: uppercase;
    }
    &:hover {
      cursor: pointer !important;
      background: $hera-hover-color;
    }
    &.text-right {
      padding: 0.25rem 0.5rem 0.25rem 0.5rem;
    }
  }
  .dropdown-menu {
    max-height: 250px;
    min-width: auto !important;
    max-width: 200px;
    width: 100%;
    height: auto;
    & > div {
      overflow: auto;
    }

    &::before {
      position: absolute;
      content: '';
      width: 11px;
      height: 11px;
      transform: rotate(45deg);
      border-radius: 3px;
      right: 0.45rem;
      background-color: $hera-white-fill;
    }
    &[x-placement='bottom-start'] {
      &::before {
        top: -4px;
      }
    }
    &[x-placement='top-start'] {
      &::before {
        bottom: -4px;
      }
    }

    &.show {
      display: flex !important;
      flex-direction: column !important;
      & > div {
        flex-grow: 1 !important;
      }
    }
  }
  &.input-like {
    * {
      font-size: 12px;
    }
    .dropdown-toggle {
      width: 100%;
      background-position: 95% center;
      text-align: left;
      font-weight: bold;
      border-width: 1px !important;
      border-style: solid !important;
      border-color: $hera-strokes-color !important;

      &:focus {
        border-color: $primary !important;
        box-shadow: inset 0 1px 1px rgba($primary, 0.075),
          0 0 0 0.2rem rgba($primary, 0.25) !important;
      }
      &.text-right {
        background-position: 5% center;
      }
    }
    &.is-invalid {
      .dropdown-toggle {
        border-color: $danger !important;
        &:focus {
          box-shadow: inset 0 1px 1px rgba($danger, 0.075),
            0 0 0 0.2rem rgba($danger, 0.25) !important;
        }
      }
    }
    &.w-auto {
      .dropdown-toggle {
        width: auto;
      }
      .dropdown-menu {
        width: auto;
      }
    }

    &.primary {
      .dropdown-toggle {
        background: $primary !important;
        border-color: transparent !important;
        * {
          color: white !important;
        }
      }
    }
  }

  .dropdown--button {
    &.primary {
      background: $primary;
    }
  }
}
</style>
