export default {
  types: {
    chosen: null,
    options: {
      post: 'Post',
      clan_post: 'Clan post',
      comment: 'Comment',
      profile: 'Profile',
    },
  },

  reasons: {
    chosen: null,
    options: {
      dont_like: 'I just don`t like it',
      nudity: 'Nudity or pornography',
      hate_speech: 'Hate Speach or Symbols',
      violence: 'Violence or threat of violence',
      drugs_promotion: 'Sale or promotion of drugs',
      harassment: 'Harrassment or bullying',
      copyright_infringement: 'Intellectual property violation',
      self_injury: 'Self injury',
    },
  },

  actions: {
    chosen: null,
    options: ['Pending', 'Approved', 'Ignored'],
  },
};
