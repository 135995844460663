import api from '@/api';
import Vue from 'vue';

export const DEFAULT_SETTINGS_STATE = () => ({
  ranks: {
    not_ranked: 0,
  },
});

const getters = {
  ranks: state => state.ranks,
  rankKeys: state => Object.keys(state.ranks),
  rankLabelByKey: () => key => {
    return key.split('_').join(' ');
  },
};

const mutations = {
  clearToSettingsDefaultState(state) {
    const defaultState = DEFAULT_SETTINGS_STATE();

    Object.keys(defaultState).forEach(key => {
      Vue.set(state, key, defaultState[key]);
    });
  },
  updateRanks(state, payload) {
    state.ranks = { ...state.ranks, ...payload };
  },
};

const actions = {
  getAllRanks({ getters: { accessToken }, commit }) {
    // return Promise.resolve()
    return api.getRanks(accessToken).then(response => {
      commit('updateRanks', response.data);
      return response.data;
    });
  },
  updateRanks(
    {
      getters: { accessToken, ranks },
      commit,
    },
    newRanks
  ) {
    const oldRanks = { ...ranks };
    const requestData = { ...newRanks };
    return api
      .putRanks(accessToken, requestData)
      .then(() => commit('updateRanks', newRanks))
      .catch(error => {
        commit('updateRanks', oldRanks);
        throw error;
      });
  },
};

export default {
  state: DEFAULT_SETTINGS_STATE(),
  getters,
  mutations,
  actions,
};
