var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column px-4 platforms"},[_c('h3',{staticClass:"my-4"},[_vm._v("Platforms")]),_c('div',{staticClass:"card flex-grow-1"},[_c('div',{staticClass:"card-body position-relative d-flex flex-column p-0 custom-scrollbar"},[(_vm.isLoading)?_c('div',{staticClass:"text-center text-muted py-4"},[_vm._v("\n        Fetching platforms...\n      ")]):_c('div',{staticClass:"position-absolute p-4 platforms_table-container"},[_c('button',{staticClass:"btn btn-link d-inline-flex align-items-baseline",on:{"click":_vm.addPlatform}},[_c('Icon',{staticClass:"fill-primary mr-2",attrs:{"name":"add","width":"14"}}),_vm._v(" Add\n          Platform\n        ")],1),_c('table',{staticClass:"w-100 mt-3 platforms_table"},[_vm._m(0),_c('SortableList',{attrs:{"container-tag":"tbody","item-tag":"tr","handle-tag":"td"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"platforms_logo-cell"},[_c('div',{staticClass:"position-relative d-flex"},[_c('FileBase64',{staticClass:"position-relative mr-2 platforms_logo",attrs:{"id":item.key,"image-preview-mode":"","preupload":"","output-as":"binary","predefined-file-url":item.logo && item.logo.uri,"is-required":"","name":("platform-logo-" + (item.key)),"validate-as":"Platform Logo"},model:{value:(item.newLogo),callback:function ($$v) {_vm.$set(item, "newLogo", $$v)},expression:"item.newLogo"}}),(item.newLogo || item.logo)?_c('Icon',{staticClass:"cursor-pointer fill-primary",attrs:{"name":"trash","width":"14"},on:{"click":function($event){return _vm.removeLogo(item)}}}):_vm._e()],1)]),_c('td',[_c('TextInput',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"position-relative w-75 m-0",attrs:{"data-vv-name":("platform-name-" + (item.key)),"data-vv-as":"Platform Name","error":_vm.errors.first(("platform-logo-" + (item.key)))
                      ? ''
                      : _vm.errors.first(("platform-name-" + (item.key)))},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})],1),_c('td',[_c('Toggle',{model:{value:(item.published),callback:function ($$v) {_vm.$set(item, "published", $$v)},expression:"item.published"}})],1),_c('td',[_c('button',{staticClass:"btn btn-danger platforms_delete-btn",on:{"click":function($event){return _vm.removePlatform(item)}}},[_vm._v("\n                  Remove\n                ")])]),(
                  _vm.errors.first(("platform-logo-" + (item.key))) ||
                    _vm.errors.first(("platform-name-" + (item.key)))
                )?_c('td',{staticClass:"m-0 p-0",staticStyle:{"width":"0","height":"4.35rem"}}):_vm._e()]}}]),model:{value:(_vm.platforms),callback:function ($$v) {_vm.platforms=$$v},expression:"platforms"}})],1)])]),_c('div',{staticClass:"card-footer"},[_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":!_vm.isSaveBtnActive},on:{"click":_vm.save}},[_vm._v("\n        Save\n      ")])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',{staticClass:"pb-2 platforms_table-head"},[_c('tr',[_c('th'),_c('th',{staticClass:"platforms_logo-cell"},[_vm._v("Icon")]),_c('th',[_vm._v("Platform name")]),_c('th',[_vm._v("Enabled")]),_c('th',{staticClass:"platforms_action-cell"},[_vm._v("Action")])])])}]

export { render, staticRenderFns }