import { isArray } from 'lodash';

const TOKEN_VALIDITY_THRESOLD = 5000;
//const TOKEN_VALIDITY_THRESOLD = 1000 * 60 * 60 - 1000 * 10 // token expires in 10 sec

export const parseObjectToUri = obj => {
  return Object.entries(obj)
    .map(e => {
      if (isArray(e[1])) {
        e[1] = e[1].join(',');
      }
      return e[1] === 0 || e[1] ? e.join('=') : '';
    })
    .filter(e => e)
    .join('&');
};

export const isTokenValid = token => {
  let now = Date.now();
  return token.expires - now > TOKEN_VALIDITY_THRESOLD;
};

export default {
  parseObjectToUri,
};
