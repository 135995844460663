import { ifAuthorized } from '@/router/guards';

import VouchersWrapper from '@/views/admin/Vouchers/';
import Vouchers from '@/views/admin/Vouchers/VouchersList';
import VoucherEdit from '@/views/admin/Vouchers/VoucherEdit';

export default [
  {
    path: '/vouchers',
    title: 'Vouchers',
    component: VouchersWrapper,
    beforeEnter: ifAuthorized,
    children: [
      {
        path: '',
        name: 'vouchers',
        component: Vouchers,
      },
      {
        path: 'edit/:voucherID?',
        name: 'voucherEdit',
        component: VoucherEdit,
        props: true,
      },
      {
        path: 'create',
        name: 'voucherCreate',
        component: VoucherEdit,
      },
    ],
  },
];
