import api from '@/api';

export const INITIAL_NEWS_FEED_SETTINGS_STATE = () => {
  return {
    injectionsVersion: 0,
  };
};

const getters = {
  injectionsVersion: state => state.injectionsVersion,
};

const mutations = {
  setInjectionsVersion: (state, version) => (state.injectionsVersion = version),
};

const actions = {
  getPostsInjections({ getters: { accessToken }, commit }) {
    return api.getPostsInjections(accessToken).then(res => {
      commit('setInjectionsVersion', res.data.version);
      return res.data.injections;
    });
  },

  updatePostsInjections(
    {
      getters: { accessToken, injectionsVersion },
      commit,
    },
    injections
  ) {
    return api
      .updatePostsInjections(accessToken, {
        injections,
        version: injectionsVersion,
      })
      .then(res => {
        commit('setInjectionsVersion', res.data.version);
        return res.data.injections;
      });
  },
};

export default {
  state: INITIAL_NEWS_FEED_SETTINGS_STATE(),
  getters,
  mutations,
  actions,
};
