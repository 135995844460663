<template>
  <div id="app">
    <detectOffline>
      <div
        slot="offline"
        class="text-white bg-danger text-center py-2 d-flex align-items-center justify-content-center"
      >
        <AppIcon name="info" fill="#fff" width="18" class="mr-2" />
        <strong>You're offline</strong>
      </div>
    </detectOffline>
    <component :is="layout">
      <router-view
        :agent-profile.sync="agentProfile"
        :is-chat-open.sync="isChatOpen"
      />
    </component>
    <hub v-if="isAuthorized && username">
      <div slot-scope="{ messages, notifications }">
        <!--<chat :agentProfile="agentProfile" :isOpen="isChatOpen" :channel="messages"/>-->
        <socialNotification :channel="notifications" />
      </div>
    </hub>
    <systemNotification />
  </div>
</template>

<script>
import store from '@/store';
import hub from '@/components/common/hub.vue';
import systemNotification from '@/components/common/SystemNotification.vue';
import socialNotification from '@/components/common/SocialNotification.vue';
import detectOffline from '@/components/common/detectOffline';
import { mapGetters } from 'vuex';
import AppIcon from '@/components/common/Icon';

export default {
  name: 'App',
  components: {
    hub,
    AppIcon,
    systemNotification,
    socialNotification,
    detectOffline,
  },
  data() {
    return {
      agentProfile: {
        teamName: '',
        imageUrl: '',
      },
      isChatOpen: false,
    };
  },
  computed: {
    ...mapGetters(['isAuthorized', 'username']),
    layout() {
      return `${this.$route.meta.layout || 'default'}-layout`;
    },
  },
  watch: {
    isAuthorized(isAuthorized) {
      if (isAuthorized) {
        store.dispatch('getMyDetails');
      }
    },
  },
  created() {
    if (this.isAuthorized) {
      store.dispatch('getMyDetails');
    }
  },
};
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  min-height: 100vh;
  .layout-wrapper {
    min-height: 100vh;
  }
}
</style>
