import { ifAuthorized } from '@/router/guards';
import Translations from '@/views/admin/Translations';

export default [
  {
    path: '/translations',
    name: 'translations',
    title: 'Translations',
    component: Translations,
    beforeEnter: ifAuthorized,
  },
];
