import { DateTime } from 'luxon';
import { get } from 'lodash';

import api from '@/api';

import { isTokenValid } from '@/utils';
/* Info
 * tempToken exp 1hour
 * accessToken expires : 1 hour
 * refreshToken expires : 10 hours
 *
 *    isAuthorized :  has accessT + has refreshT + isAdmin valid
 *    isAuthenticated:  check is tempToken is valid
 *    isPartiallyAuthorized: accessT + refreshT valid but crashed all queryes !== submitPasswordRequest (not checked it)
 *
 */

//TODO: create real vuex module, with namespase "auth"
const state = {
  status: '',
  tempToken: JSON.parse(localStorage.getItem('tempToken')) || {},
  accessToken: JSON.parse(localStorage.getItem('accessToken')) || {},
  refreshToken: JSON.parse(localStorage.getItem('refreshToken')) || {},
  isAdmin: !!localStorage.getItem('isAdmin'),
  isRefreshProcessing: false,
  refreshStopover: Promise.resolve(),
};

const getters = {
  tempToken: state => state.tempToken.token,
  accessToken: state => state.accessToken.token,
  refreshToken: state => state.refreshToken.token,
  hasToken: state => tokenType => Boolean(state[tokenType].token),
  isRefreshProcessing: state => state.isRefreshProcessing,
  tokenObject: state => tokenType => state[tokenType],
  // isTokenValid: (state, getters) => tokenType => {
  //     debugger
  //     return
  //     // let now = Date.now()
  //     // return (getters.hasToken(tokenType) && Boolean(state[tokenType].expires))
  //     //   ? state[tokenType].expires - now > TOKEN_VALIDITY_THRESOLD
  //     //   : false
  // },
  isAuthorized: (state, getters) => {
    return (
      getters.hasToken('accessToken') &&
      getters.hasToken('refreshToken') &&
      state.isAdmin
    );
  },
  isAuthenticated: (state, getters) => {
    return isTokenValid(getters.tokenObject('tempToken'));
  },
  isPartiallyAuthorized(state, getters) {
    return isTokenValid(getters.tokenObject('accessToken')) && !state.isAdmin;
  },
  // isNeedRefresh: (state) => {
  //     debugger
  //     return !isTokenValid(state['accessToken']) && isTokenValid(state['refreshToken'])
  // },
  refreshStopover: state => state.refreshStopover,
};

const mutations = {
  setIsAdmin(state, payload) {
    state.isAdmin = get(payload, 'administrative[0]') === 'all';
    state.isAdmin
      ? localStorage.setItem('isAdmin', state.isAdmin)
      : localStorage.removeItem('isAdmin');
  },
  setToken(state, payload) {
    let token = {
      token: payload.token,
      expires: DateTime.fromSeconds(payload.expires).toMillis(),
    };
    localStorage.setItem(payload.type, JSON.stringify(token));
    state[payload.type] = token;
  },
  setStatus(state, payload) {
    state.status = payload;
  },
  clearToken(state, tokenType) {
    state[tokenType] = {};
    localStorage.removeItem(tokenType);
  },
  setRefreshProcessing(state, payload) {
    state.isRefreshProcessing = payload;
  },
  changeRefreshStopover(state, promise) {
    const newStopover = promise || Promise.resolve();
    state.refreshStopover = newStopover;
  },
};

const actions = {
  clearAllTokens: ({ commit }) => {
    commit('clearToken', 'tempToken');
    commit('clearToken', 'accessToken');
    commit('clearToken', 'refreshToken');
    commit('setIsAdmin', false);
  },
  authRequest: ({ commit }, payload) => {
    return api.signIn(payload).then(response => {
      commit('setToken', {
        type: 'accessToken',
        token: response.data.access_token,
        expires: response.data.access_token_expires,
      });
      commit('setToken', {
        type: 'refreshToken',
        token: response.data.refresh_token,
        expires: response.data.refresh_token_expires,
      });
      commit('setIsAdmin', response.data.permissions);
      commit('setStatus', 'authorized');
      commit('clearToken', 'tempToken');
      /* signin logic was changed 
                commit('setToken', {
                    type: 'tempToken',
                    token: response.data.temporary_token,
                    expires: response.data.expires 
                })
                commit('setStatus', 'authenticated');
                */
    });
  },
  sendVerificationCode: ({ getters, commit }) => {
    return api.sendCode(getters.tempToken).then(() => {
      commit('setStatus', 'code sended');
    });
  },
  verifyUser: ({ getters, commit }, payload) => {
    return api
      .verifyCode(getters.tempToken, {
        code: payload.code,
      })
      .then(response => {
        const isAdmin = !!payload.isAdmin;
        commit('setStatus', 'user verification');
        commit('setIsAdmin', isAdmin);
        commit('setToken', {
          type: 'accessToken',
          token: response.data.access_token,
          expires: response.data.access_token_expires,
        });
        if (isAdmin) {
          commit('setToken', {
            type: 'refreshToken',
            token: response.data.refresh_token,
            expires: response.data.refresh_token_expires,
          });
        } else {
          commit('clearToken', 'refreshToken');
        }

        commit('setStatus', 'authorized');
        commit('clearToken', 'tempToken');
      });
  },
  requestPass: ({ commit }, login) => {
    commit('setStatus', 'requesting for forgotten password');
    return api.resetPass(login).then(response => {
      commit('setToken', {
        type: 'tempToken',
        token: response.data.temporary_token,
        expires: response.data.expires,
      });
      commit('setStatus', 'request for password done');
    });
  },
  submitPass: ({ getters, commit }, newPassword) => {
    commit('setState', 'submit new pass call');
    return api.submitPass(getters.accessToken, newPassword).then(() => {
      commit('setStatus', 'new password submitted');
    });
  },
  refreshAccessToken: ({ getters, commit, state }) => {
    //prevent multiple refresh queries
    if (state.isRefreshProcessing) {
      return Promise.resolve();
    }
    commit('setRefreshProcessing', true);
    return api.refreshToken(getters.refreshToken).then(response => {
      commit('setToken', {
        type: 'accessToken',
        token: response.data.access_token,
        expires: response.data.access_token_expires,
      });
      commit('setToken', {
        type: 'refreshToken',
        token: response.data.refresh_token,
        expires: response.data.refresh_token_expires,
      });
      commit('setStatus', 'token pare refreshed');
      commit('setRefreshProcessing', false);
      commit('changeRefreshStopover');
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
