import api from '@/api';
import Vue from 'vue';

export const INITIAL_BATTLES_STATE = () => ({});

const getters = {};

const mutations = {
  clearToBattlesDefaultState(state) {
    const defaultState = INITIAL_BATTLES_STATE();

    Object.keys(defaultState).forEach(key => {
      Vue.set(state, key, defaultState[key]);
    });
  },
};

const actions = {
  getJudgingList({ dispatch, getters }, pageToken) {
    return api.battles
      .getConflicted(pageToken)(getters.accessToken)
      .then(response => response.data)
      .catch(error => dispatch('errorNotify', error));
  },
  getBattleResults({ dispatch, getters }, battleID) {
    return api.battles
      .getResults(battleID)(getters.accessToken)
      .then(response => response.data)
      .catch(error => dispatch('errorNotify', error));
  },
  getBattleResultsGroup({ getters }, battlesIds) {
    return api.battles
      .getResultsAll()(getters.accessToken, {
        params: { battle_ids: battlesIds },
      })
      .then(response => response.data);
  },
  resolveBattle(
    {
      dispatch,
      getters: { accessToken },
    },
    { battleID = 0, resolution = null }
  ) {
    return api.battles
      .resolve(accessToken, battleID, resolution)
      .then(response => response.data)
      .catch(error => dispatch('errorNotify', error));
  },
  getBattleInfo({ dispatch, getters }, battleID) {
    return api.battles
      .getById(battleID)(getters.accessToken)
      .then(response => response.data)
      .catch(error => dispatch('errorNotify', error));
  },
  cancelBattle(
    {
      getters: { accessToken },
    },
    battleID
  ) {
    return api.battles.cancel(battleID)(accessToken, { status: 'cancelled' });
  },
  provideBattleResult: (
    { getters: { accessToken } },
    { battleId, playerIndex, results }
  ) =>
    api.battles
      .provideResult(battleId, playerIndex)(accessToken, {
        match_results: results,
      })
      .then(response => response.data),
  provideBattleResultsByAdmin: (
    { getters: { accessToken } },
    { battleId, results }
  ) =>
    api.battles
      .provideResultByAdmin(battleId)(accessToken, {
        match_player_results: results,
      })
      .then(response => response.data),
  //will be deprecated soon
  provideOfflineBattleResults: ({ getters: { accessToken } }, payload) =>
    api.battles
      .provideOfflineResult(payload.battleId)(accessToken, {
        match_results: payload.results,
      })
      .then(response => response.data),
};

export default {
  state: INITIAL_BATTLES_STATE(),
  getters,
  mutations,
  actions,
};
