<template>
  <div class="card-body media m-0 cursor-pointer" :title="data.title">
    <div class="align-self-center mr-2">
      <avatar :size="50" :img-url="data.avatar" />
    </div>
    <div class="media-body">
      <div class="d-flex chat-list-msg-header">
        <div class="w-100 chats-list-title text-ellipses">
          {{ data.title }}
        </div>
        <div class="flex-shrink-1">
          <!--<AppIcon name="checked_2" width="18" :original="true" class="mt-0 mr-1"/>-->
        </div>
        <div class="flex-shrink-1 text-muted">
          {{ data.last_activity | parseDate }}
        </div>
      </div>
      <span class="mt-1 text-muted text-break-word chats-list-msg-preview">{{
        data.last_message
      }}</span>
    </div>
    <badge v-if="+data.unread_count" height="16px" class="chats-list-badge">
      {{ data.unread_count }}
    </badge>
  </div>
</template>

<script>
import avatar from '@/components/common/avatar';
import badge from '@/components/common/badge';

export default {
  name: 'ChatsListItem',
  components: {
    badge,
    avatar,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
