<template>
  <notifications
    group="system"
    class="system"
    position="bottom center"
    width="100%"
  >
    <template slot="body" slot-scope="props">
      <div
        class="d-flex vue-notification row"
        :class="[props.classes, props.item.type]"
      >
        <!-- eslint-disable vue/no-v-html -->
        <div
          class="col-auto flex-grow-1 ml-0 pl-0"
          v-html="props.item.text"
        ></div>
        <div class="close mr-0 pr-0" aria-label="Close" @click="props.close">
          <AppIcon name="close" width="10" original />
        </div>
        <div>{{ props.item.data }}</div>
      </div>
    </template>
  </notifications>
</template>

<script>
import AppIcon from './Icon.vue';
export default {
  name: 'SystemNotifications',
  components: {
    AppIcon,
  },
};
</script>

<style lang="scss">
$transparency: 0.8;
.notifications {
  &.system {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      width: 90%;
      max-width: 520px;
    }
    .vue-notification {
      padding: 12px 24px;
      margin: 0px 0px 12px 0px;
      font-size: 12px;
      line-height: 14px;
      color: #ffffff;
      background: rgba($primary, $transparency);
      border: none;
      border-radius: 4px;
      &.warn {
        background: rgba($warning, $transparency);
      }
      &.error {
        background: rgba($danger, $transparency);
      }
      &.success {
        background: rgba($success, $transparency);
      }
      .close {
        cursor: pointer;
      }
    }
  }
}
</style>
