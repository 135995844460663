<template>
  <div class="d-flex flex-column align-self-center">
    <div class="row d-flex align-items-center no-gutters genre-list-item">
      <div v-if="hasImage" class="col-auto genre-list-item_icon">
        <fileBase64
          :id="value.key"
          v-model="imageValue"
          image-preview-mode
          :name="`itemImage-${value.key}`"
          class="image-wrapper"
          preupload
          output-as="binary"
          :predefined-file-url="imageUri"
          :disabled="!editMode || !!imageValue || isLoading"
          validate-as="Genre Icon"
          is-required
          hide-error-text
        />
      </div>
      <div
        class="col-auto mx-2 genre-list-item_remove-icon cursor-pointer"
        @click.stop="removeImage"
      >
        <AppIcon
          v-if="canRemoveImage && editMode && imageValue && !isLoading"
          name="trash"
          width="14"
          class="fill-primary"
        />
      </div>
      <div class="col-auto genre-list-item_text-field">
        <HeraFormTextInput
          v-model="textValue"
          v-validate="'required'"
          class="w-100 my-0 mr-1"
          :disable="!editMode || isLoading"
          :variant="textVariant"
          :data-vv-name="`genreName_${value.key}`"
          data-vv-as="Genre Name"
          :error="errors.first(`genreName_${value.key}`)"
          hide-error-text
          @change="onChangeTextValue"
        />
      </div>
      <div class="col-auto ml-3 genre-list-item_actions">
        <button
          v-if="editMode"
          :disabled="errorText"
          :class="{ 'is-loading': isLoading, disabled: errorText }"
          class="btn btn-sm btn-primary mr-3"
          @click="onSave"
        >
          Save
        </button>
        <button
          v-else
          :disabled="errorText"
          :class="{ 'is-loading': isLoading, disabled: errorText }"
          class="btn btn-sm btn-outline-primary mr-3"
          @click="$emit('editItem', value)"
        >
          Edit
        </button>
        <button
          :disabled="errorText"
          :class="{ 'is-loading': isLoading, disabled: errorText }"
          class="btn btn-sm btn-outline-danger"
          @click="$emit('deleteItem', value)"
        >
          Remove
        </button>
      </div>
    </div>
    <div
      v-if="errorText"
      class="row d-flex align-items-center no-gutters genre-list-item-error"
    >
      <div class="col-12 text-danger error-text text-center">
        {{ errorText }}
      </div>
    </div>
  </div>
</template>

<script>
import { isObject, isEmpty, get, isUndefined } from 'lodash';
import { mapActions } from 'vuex';
import HeraFormTextInput from '@/components/form/HeraFormTextInput';
import fileBase64 from '@/components/form/fileBase64';
import AppIcon from '@/components/common/Icon';
export default {
  name: 'ListItemComponentWithImage',
  inject: ['$validator'],
  components: {
    HeraFormTextInput,
    fileBase64,
    AppIcon,
  },
  props: {
    value: {
      type: [Object, String],
      required: true,
      default: '',
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    canRemoveImage: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    objectMode() {
      return isObject(this.value);
    },
    isEmpty() {
      return isEmpty(this.value);
    },
    hasImage() {
      return this.objectMode && !isUndefined(this.imageValue);
    },
    errorText() {
      return (
        this.errors.first(`itemImage-${this.value.key}`) ||
        this.errors.first(`genreName_${this.value.key}`)
      );
    },
    imageValue: {
      get() {
        return get(this.value, 'logo');
      },
      set(newVal) {
        this.$validator.validate(`itemImage-${this.value.key}`);

        this.$emit('input', {
          ...this.value,
          logo: isObject(newVal)
            ? { ...newVal, uri: newVal.display_uri }
            : newVal,
        });
      },
    },
    imageUri() {
      return get(this.imageValue, 'uri', '');
    },
    textValue: {
      get() {
        return this.objectMode ? get(this.value, 'name', '') : this.value;
      },
      set(newValue) {
        if (!newValue) {
          this.$validator.validate(`genreName_${this.value.key}`);
        } else {
          this.onChangeTextValue(newValue);
          this.errors.remove(`genreName_${this.value.key}`);
        }
      },
      //     (newVal) {
      //   this.$emit('input', this.objectMode
      //     ? {...this.value, text: newVal}
      //     : newVal)
      // }
    },
    textVariant() {
      return this.editMode ? 'regular' : 'boundless';
    },
  },
  methods: {
    ...mapActions(['errorNotify']),
    removeImage() {
      this.imageValue = null;
    },
    onChangeTextValue(value) {
      this.$emit(
        'input',
        this.objectMode ? { ...this.value, name: value } : value
      );
    },
    onSave() {
      this.$validator
        .validateAll()
        .then(result => (result ? result : Promise.reject('Validation failed')))
        .then(() => this.$emit('saveItem', this.value))
        .catch(this.errorNotify);
    },
  },
};
</script>
<style scoped lang="scss">
.genre-list-item {
  &_icon {
    width: 2.5rem;
    height: 2.5rem;
    .image-wrapper {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
  &_remove-icon {
    min-width: 0.75rem;
  }
  &_actions {
    button {
      min-width: 3.125rem;
    }
  }
}
</style>
