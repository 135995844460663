<!-- eslint-disable vue/camelcase -->
<template>
  <div class="container-fluid">
    <div class="row my-4 align-items-center">
      <h3 class="mb-0 pb-0 col">Vouchers</h3>
      <router-link
        class="btn btn-primary ml-auto mr-4"
        :to="{ name: 'voucherCreate' }"
      >
        Add new Voucher
      </router-link>
    </div>
    <div class="row">
      <div class="col">
        <div class="card mt-4">
          <div class="card-body">
            <form @submit.prevent="applyFilters">
              <div class="form-row"></div>
              <!--  -->

              <!--  -->
              <div class="d-flex flex-wrap">
                <div class="form-group col-md-3">
                  <label for="code">Code</label>
                  <input
                    id="code"
                    v-model="code"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="form-group col-md-3">
                  <label for="title">Title</label>
                  <input
                    id="title"
                    v-model="title"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="form-group col-md-3">
                  <label for="promoter">Associated with</label>
                  <input
                    id="promoter"
                    v-model="promoter"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="form-group col-md-3">
                  <label for="used_by">used by</label>
                  <input
                    id="used_by"
                    v-model="used_by"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="form-group col-md-3">
                  <label for="dateTo">Creation date</label>
                  <dateInput
                    v-model="created_date"
                    :first-day-of-week="1"
                    :minute-step="5"
                    value-type="timestamp"
                    type="datetime"
                    confirm
                    range
                    lang="en"
                    format="DD/MM/YYYY/HH:mm"
                    class="date-time"
                  >
                  </dateInput>
                </div>
                <div class="form-group col-md-3">
                  <label for="dateTo">Expiration date</label>
                  <dateInput
                    v-model="expiresDate"
                    :first-day-of-week="1"
                    :minute-step="5"
                    value-type="timestamp"
                    type="datetime"
                    confirm
                    range
                    lang="en"
                    format="DD/MM/YYYY/HH:mm"
                    class="date-time"
                  >
                  </dateInput>
                </div>
                <div class="form-group col-md-3">
                  <label for="dateTo">Used date</label>
                  <dateInput
                    v-model="usedDate"
                    :first-day-of-week="1"
                    :minute-step="5"
                    value-type="timestamp"
                    type="datetime"
                    confirm
                    range
                    lang="en"
                    format="DD/MM/YYYY/HH:mm"
                    class="date-time"
                  >
                  </dateInput>
                </div>
              </div>

              <div class="vouchers-button">
                <div :class="{ disabled: isLoading }">
                  <button
                    type="submit"
                    class="btn btn-primary mr-3"
                    :class="{ disabled: isLoading }"
                  >
                    Apply Filters
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    :class="{ disabled: isLoading }"
                    @click="clearFilters"
                  >
                    Clear Filters
                  </button>
                </div>
              </div>
            </form>
            <div class="flush-div">
              <button
                :class="{ 'is-loading': isExportingAll }"
                class="btn btn-primary ml-auto mr-4"
                @click="exportAll"
              >
                Export to XLS
              </button>
            </div>
          </div>
        </div>

        <div class="card">
          <div
            class="card-body border-top border-muted pt-0 px-0 overflow-auto"
          >
            <table class="table table-hover mx-0 px-0 subscriptions-list">
              <caption v-if="isLoading" class="text-center text-muted">
                Fetching vouchers...
              </caption>
              <caption
                v-else-if="!vouchersList.length"
                class="text-center text-muted"
              >
                No vouchers to show
              </caption>
              <template v-else>
                <thead>
                  <tr>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Title
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Code
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Duration
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Expiration Date
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Used by
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Associated with
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Generated at
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <ListItem
                    v-for="voucher in vouchersList"
                    :key="voucher.id"
                    v-bind="{ voucher }"
                    @edit="editVoucher"
                  /></tbody
              ></template>
            </table>
          </div>
          <nav
            v-if="total > 0 && !isLoading"
            class="mt-3 d-flex flex-column align-items-center"
          >
            <ul class="pagination justify-content-center">
              <li
                class="page-item-btn"
                :class="{ disabled: isLoading || currentPage === 1 }"
              >
                <a class="page-link" href="#" @click.prevent="prevPage"
                  >Previous</a
                >
              </li>
              <li class="page-item">
                <span class="page-link h-100">
                  {{ currentPage || 1 }}/ {{ totalPages }}</span
                >
              </li>
              <li
                class="page-item-btn"
                :class="{ disabled: isLoading || nextPageToken === null }"
              >
                <a class="page-link" href="#" @click.prevent="nextPage">Next</a>
              </li>
            </ul>
            <span
              v-if="pageInput > totalPages || isNaN(pageInput)"
              class="error-pagination"
              >{{
                pageInput > totalPages
                  ? `Max page is ${totalPages}`
                  : '' || isNaN(pageInput)
                  ? 'Please enter number'
                  : ''
              }}</span
            >
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import ListItem from './ListItem';
import dateInput from 'vue2-datepicker';
export default {
  name: 'VouchersList',
  components: { ListItem, dateInput },
  data() {
    return {
      //

      //
      nextPageToken: undefined,
      prevPageToken: undefined,
      isLoading: false,
      isExporting: false,
      currentPage: 1,
      itemsPerPage: 20,
      vouchersList: [],
      total: 0,
      code: '',
      title: '',
      promoter: '',
      used_by: '',
      used_on_start: null,
      used_on_end: null,
      expires_at_start: null,
      expires_at_end: null,
      created_on_start: null,
      created_on_end: null,
      voucher_id: '',
      isExportingAll: false,
      pageInput: '',
      created_date: [],
      usedDate: [],
      expiresDate: [],
      thisPage: null,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.total / this.itemsPerPage);
    },
    isFirstPage() {
      return this.currentPage === 1;
    },
    isLastPage() {
      return this.currentPage === this.totalPages;
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions([
      'fetchVouchers',
      'exportAllVouchers',
      'createVoucher',
      'updateVoucher',
      'errorNotify',
      'successNotify',
    ]),

    exportAll() {
      this.isExportingAll = true;
      const filterProps = {
        voucher_code: this.code,
        title: this.title,
        promoter: this.promoter,
        used_by: this.used_by,
        used_on_start: this.usedDate[0] / 1000 || null,
        used_on_end: this.usedDate[1] / 1000 || null,
        expires_at_start: this.expiresDate[0] / 1000 || null,
        expires_at_end: this.expiresDate[1] / 1000 || null,
        created_on_start: this.created_date[0] / 1000 || null,
        created_on_end: this.created_date[1] / 1000 || null,
      };

      const filteredProps = Object.fromEntries(
        Object.entries(filterProps).filter(
          // eslint-disable-next-line no-unused-vars
          ([_, value]) => value !== '' && value !== null
        )
      );
      this.exportAllVouchers({ props: filteredProps })
        .catch(() => this.errorNotify('export failed'))
        .finally(() => (this.isExportingAll = false));
    },
    fetchData() {
      this.isLoading = true;

      const filterProps = {
        page: this.thisPage,

        voucher_code: this.code,
        title: this.title,
        promoter: this.promoter,
        used_by: this.used_by,
        used_on_start: this.usedDate[0] / 1000 || null,
        used_on_end: this.usedDate[1] / 1000 || null,
        expires_at_start: this.expiresDate[0] / 1000 || null,
        expires_at_end: this.expiresDate[1] / 1000 || null,
        created_on_start: this.created_date[0] / 1000 || null,
        created_on_end: this.created_date[1] / 1000 || null,
      };

      const filteredProps = Object.fromEntries(
        Object.entries(filterProps).filter(
          // eslint-disable-next-line no-unused-vars
          ([_, value]) => value !== '' && value !== null
        )
      );

      this.fetchVouchers({
        props: filteredProps,
      })
        .then(res => {
          this.total = res.total;
          this.nextPageToken = res.next_page_token;
          this.prevPageToken = res.prev_page_token;
          this.currentPage = res.page_index + 1;

          if (res.items) {
            this.vouchersList = res.items.map(el => ({
              ...el,
              editMode: false,
              key: `voucher_${el.id}`,
            }));
          } else {
            this.vouchersList = [];
          }
        })
        .catch(this.errorNotify)
        .finally(() => {
          this.isLoading = false;
        });
    },

    editVoucher(voucher) {
      this.$router.push({
        name: 'voucherEdit',
        params: { voucherID: voucher.id },
      });
    },

    applyFilters() {
      this.nextPageToken = undefined;
      this.prevPageToken = undefined;
      this.isLoading = true;
      this.currentPage = 1;
      this.fetchData();
    },

    flush() {
      this.setFlushCache();
    },

    clearFilters() {
      this.code = '';
      this.title = '';
      this.promoter = '';
      this.used_by = '';
      this.nextPageToken = undefined;
      this.prevPageToken = undefined;
      this.currentPage = 1;
      this.isLoading = true;
      this.created_date = [];
      this.usedDate = [];
      this.expiresDate = [];
      this.thisPage = null;
      this.fetchData();
    },

    generateSaudiTime(date, isEndDate) {
      if (!date) {
        return null;
      }

      const saudiTimeZone = 'Asia/Riyadh'; // Saudi Arabia's time zone
      const dateObject = new Date(date); // Convert the string to a Date object

      // If isEndDate is true, set the time to 23:59:59 to represent the end of the day
      if (isEndDate) {
        dateObject.setHours(23, 59, 59, 999);
      } else {
        dateObject.setHours(0, 0, 0, 0);
      }

      const saudiTimestamp = dateObject.toLocaleString('en-US', {
        timeZone: saudiTimeZone,
      });
      return new Date(saudiTimestamp).getTime() / 1000; // Get the timestamp in milliseconds
    },

    prevPage() {
      if (this.prevPageToken) {
        this.thisPage = this.prevPageToken;
        this.fetchData();
      }
    },
    nextPage() {
      if (this.nextPageToken) {
        this.thisPage = this.nextPageToken;
        this.fetchData();
      }
    },

    goToPage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.currentPage = page;
        this.pageInput = '';
        this.fetchData();
      }
    },

    goToPageInput() {
      const pageNumber = parseInt(this.pageInput, 10);
      if (pageNumber > 0 && pageNumber <= this.totalPages) {
        this.currentPage = pageNumber;
        this.fetchData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-item {
  cursor: not-allowed;
}

.page-link:hover {
  background-color: white !important;
}
.page-item-btn {
  .page-link {
    padding: 0.7rem $pagination-padding-x;
  }
}

.vouchers-button {
  display: flex;
  justify-content: space-between;
  margin-left: 16px;
}

.flush-div {
  display: flex;
  justify-content: flex-end;
  margin-top: -38px;
}
.flush-button {
  background-color: red;
  color: white;
}
.form-control-pagination {
  width: 30px;
}
.error-pagination {
  font-size: 13px;
  color: red;
}
.date-time {
  width: 100%;
}
</style>
