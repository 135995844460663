import GenresSettings from '@/views/admin/Genres';
import { ifAuthorized } from '@/router/guards';

export default [
  {
    path: '/genres-settings',
    title: 'Genres',
    name: 'genresSettings',
    component: GenresSettings,
    beforeEnter: ifAuthorized,
  },
];
