<template>
  <label
    ref="label"
    dir="ltr"
    class="switch mb-0"
    :class="[size, { 'is-disabled': disabled }]"
    :disabled="disabled"
    :tabindex="disabled ? false : 0"
    @keydown.prevent.enter.space="$refs.label.click"
    @mousedown="isMouseDown = true"
    @mouseup="isMouseDown = false"
    @mouseout="isMouseDown = false"
    @blur="isMouseDown = false"
  >
    <input
      v-model="newValue"
      type="checkbox"
      :disabled="disabled"
      :name="name"
      :value="nativeValue"
      :true-value="trueValue"
      :false-value="falseValue"
      @click.stop
    />
    <span
      class="check"
      :class="[{ 'is-elastic': isMouseDown && !disabled }, type]"
    />
    <span class="control-label" :class="{ 'p-0': !$slots.default }"
      ><slot
    /></span>
  </label>
</template>

<script>
export default {
  name: 'BSwitch',
  props: {
    value: {
      type: null,
      default: null,
    },
    nativeValue: {
      type: null,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: null,
    },
    trueValue: {
      type: null,
      default: true,
    },
    falseValue: {
      type: null,
      default: false,
    },
  },
  data() {
    return {
      newValue: this.value,
      isMouseDown: false,
    };
  },
  watch: {
    /**
     * When v-model change, set internal value.
     */
    value(value) {
      this.newValue = value;
    },
    /**
     * Emit input event to update the user v-model.
     */
    newValue(value) {
      this.$emit('input', value);
      this.$emit('change', value);
    },
  },
};
</script>
<style lang="scss" scoped>
// $size-1: 3rem !default;
// $size-2: 2.5rem !default;
// $size-3: 2rem !default;
// $size-4: 1.5rem !default;
// $size-5: 1.25rem !default;
// $size-6: 1rem !default;
// $size-7: 1rem !default;

// $size-small: $size-7 !default;
// $size-normal: $size-5 !default;
// $size-medium: $size-4 !default;
// $size-large: $size-2 !default;

$switch-width-number: 3;
$switch-height-number: $switch-width-number / 2.66666667;
$switch-width: $switch-width-number * 1em;
$switch-padding: 0.0625em;
$switch-height: $switch-height-number * 1em + $switch-padding * 2;
$switch-trigger-size: $switch-width-number * 0.5625 * 1em;
$switch-trigger-elastic: $switch-width-number * 0.5625 * 1.11111111 * 1em;
$switch-active-background-color: $primary !default;
$speed-slow: 150ms !default;
$easing: ease-out !default;

$radius-small: 2px !default;
$control-radius-small: $radius-small !default;
.switch {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  & + .switch {
    margin-left: 0.5em;
  }
  input[type='checkbox'] {
    display: none;
    + .check {
      display: flex;
      align-items: center;
      width: $switch-width;
      height: $switch-height;
      padding: $switch-padding 0;
      background: rgba(#16161c, 0.15);
      border-radius: 1em;
      border: 1px solid rgba(#a7a7b8, 0.15);
      transition: background $speed-slow $easing;
      &:before {
        content: '';
        border-radius: 50%;
        width: $switch-trigger-size; // 1.125em;
        height: $switch-trigger-size; // 1.125em;
        transform: translate3d(0%, 0, 0);
        background: $switch-active-background-color;
        box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.05),
          0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 3px 0 rgba(0, 0, 0, 0.05);
        transition: transform $speed-slow $easing, width $speed-slow $easing;
        will-change: transform;
      }
      &.is-elastic:before {
        width: $switch-trigger-elastic; //1.25em;
      }
    }
    &:checked + .check {
      border-color: lighten($switch-active-background-color, 20%);
      background: lighten($switch-active-background-color, 20%);
      &.is-success {
        border-color: lighten($switch-active-background-color, 20%);
        background: lighten($switch-active-background-color, 20%);
      }
      // @each $name, $pair in $colors {
      //   $color: nth($pair, 1);
      //   &.is-#{$name} {
      //     background: $color;
      //   }
      // }
      &:before {
        transform: translate3d(75%, 0, 0);
      }
      &.is-elastic:before {
        // Might be a little offset if base font is not 16px
        transform: translate3d(
          percentage(16 / ($switch-width-number * 16)),
          0,
          0
        );
      }
    }
  }
  .control-label {
    padding-left: 0.5em;
  }
  &:hover {
    input[type='checkbox'] + .check {
      background: rgba($hera-hover-color, 0.2);
    }
    input[type='checkbox']:checked + .check {
      background: lighten($switch-active-background-color, 20%);
      &.is-success {
        background: lighten($switch-active-background-color, 20%);
      }
      // @each $name, $pair in $colors {
      //   $color: nth($pair, 1);
      //   &.is-#{$name} {
      //     background: rgba($color, 0.9);
      //   }
      // }
    }
  }
  &:focus {
    outline: none;
    // input[type=checkbox] + .check {
    //   box-shadow: 0 0 0.5em rgba($hera-strokes-color, 0.6);
    // }
    // input[type=checkbox]:checked + .check {
    //   box-shadow: 0 0 0.5em rgba($switch-active-background-color, 0.8);
    // @each $name, $pair in $colors {
    //   $color: nth($pair, 1);
    //   &.is-#{$name} {
    //     box-shadow: 0 0 0.5em rgba($color, 0.8);
    //   }
    // }
    // }
  }
  // &.is-small {
  //   border-radius: $control-radius-small;
  //   font-size: $size-small;
  // }
  // &.is-medium{
  //   font-size: $size-medium;
  // }
  // &.is-large {
  //   font-size: $size-large;
  // }
  // &[disabled] {
  //   opacity: 0.5;
  //   cursor: not-allowed;
  //   color: $hera-strokes-color;
  // }
}
</style>
