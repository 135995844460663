<!-- eslint-disable vue/camelcase -->
<template>
  <div class="container-fluid">
    <div class="row my-4 align-items-center">
      <h3 class="mb-0 pb-0 col">Invoices</h3>
    </div>
    <div class="row">
      <div class="col">
        <div class="card mt-4">
          <div class="card-body">
            <form @submit.prevent="applyFilters">
              <div class="form-row">
                <div class="form-group col-md-3">
                  <label for="login">Username</label>
                  <input
                    id="login"
                    v-model="login"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="form-group col-md-3">
                  <label for="source">Source</label>
                  <select id="source" v-model="source" class="form-control">
                    <option value="any">All</option>
                    <option value="phmax">PH MAX</option>
                    <option value="shop">PH Shop</option>
                    <option value="tournament_entry">Tournament Entry</option>
                  </select>
                </div>
                <div class="form-group col-md-3">
                  <label for="invoiceId">Invoice ID</label>
                  <input
                    id="invoiceId"
                    v-model="invoice_id"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="form-group col-md-3">
                  <label for="invoiceId">Magento Order Number</label>
                  <input
                    id="invoiceId"
                    v-model="magento_order_id"
                    type="text"
                    class="form-control"
                  />
                </div>
                <!--  -->
                <div class="form-group col-md-3">
                  <label for="countryId">Country ID</label>
                  <input
                    id="countryId"
                    v-model="country_id"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="form-group col-md-3">
                  <label for="price_options">Price options</label>
                  <select
                    id="price_options"
                    v-model="price_options"
                    class="form-control"
                  >
                    <option value="any">All</option>
                    <option value="zero_price">Zero price</option>
                    <option value="non_zero">Non-zero price</option>
                  </select>
                </div>
                <!--  -->
                <div class="form-group col-md-3">
                  <label for="isProcessed">Is Processed</label>
                  <select
                    id="isProcessed"
                    v-model="isProcessed"
                    class="form-control"
                  >
                    <option value="">All</option>
                    <option value="true">Processed</option>
                    <option value="false">Not Processed</option>
                  </select>
                </div>
                <div class="form-group col-md-3">
                  <label for="dateFrom">Date From</label>
                  <input
                    id="dateFrom"
                    v-model="dateFrom"
                    type="date"
                    class="form-control"
                  />
                </div>
                <div class="form-group col-md-3">
                  <label for="dateTo">Date To</label>
                  <input
                    id="dateTo"
                    v-model="dateTo"
                    type="date"
                    class="form-control"
                  />
                </div>
                <div class="form-group col-md-3">
                  <label for="email">Email</label>
                  <input
                    id="email"
                    v-model="email"
                    type="email"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="invoices-button">
                <div :class="{ disabled: isLoading }">
                  <button
                    type="submit"
                    class="btn btn-primary mr-3"
                    :class="{ disabled: isLoading }"
                  >
                    Apply Filters
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    :class="{ disabled: isLoading }"
                    @click="clearFilters"
                  >
                    Clear Filters
                  </button>
                </div>
              </div>
            </form>
            <div class="flush-div">
              <button
                :class="{ 'is-loading': isExportingAll }"
                class="btn btn-primary ml-auto mr-4"
                @click="exportAll"
              >
                Export to XLS
              </button>
              <button type="submit" class="btn flush-button" @click="flush">
                FLUSH SALES GATEWAY
              </button>
            </div>
          </div>
        </div>

        <div class="card">
          <div
            class="card-body border-top border-muted pt-0 px-0 overflow-auto"
          >
            <table class="table table-hover mx-0 px-0 subscriptions-list">
              <caption v-if="isLoading" class="text-center text-muted">
                Fetching invoices...
              </caption>
              <caption
                v-else-if="!invoicesList.length"
                class="text-center text-muted"
              >
                No invoices to show
              </caption>
              <template v-else>
                <thead>
                  <tr>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Is Processed
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Invoice Id
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Magento Order Number
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      RefId
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Username
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Country
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Email
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Created
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Total without VAT
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Vat Amount
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Total Amount
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Coupon
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Coupon amount
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Currency
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Mode of payment
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Source
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Exp. Date
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Item description
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <ListItem
                    v-for="invoice in invoicesList"
                    :key="invoice.id"
                    v-bind="{ invoice }"
                    @download="downloadInvoice"
                  />
                </tbody>
              </template>
            </table>
          </div>
          <nav
            v-if="total > 0 && !isLoading"
            class="mt-3 d-flex flex-column align-items-center"
          >
            <ul class="pagination justify-content-center">
              <li
                class="page-item-btn"
                :class="{ disabled: isLoading || isFirstPage }"
              >
                <a class="page-link" href="#" @click.prevent="prevPage"
                  >Previous</a
                >
              </li>
              <li class="page-item">
                <span class="page-link"
                  ><input
                    v-model="pageInput"
                    min="1"
                    :max="totalPages"
                    class="form-control-pagination"
                    :placeholder="currentPage"
                    @keyup.enter="goToPageInput"
                  />
                  / {{ totalPages }}</span
                >
              </li>
              <li
                class="page-item-btn"
                :class="{ disabled: isLoading || isLastPage }"
              >
                <a
                  class="page-link"
                  href="#"
                  @click.prevent="goToPage(currentPage + 1)"
                  >Next</a
                >
              </li>
            </ul>
            <span
              v-if="pageInput > totalPages || isNaN(pageInput)"
              class="error-pagination"
              >{{
                pageInput > totalPages
                  ? `Max page is ${totalPages}`
                  : '' || isNaN(pageInput)
                  ? 'Please enter number'
                  : ''
              }}</span
            >
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import ListItem from './ListItem';

export default {
  name: 'InvoicesList',
  components: { ListItem },
  data() {
    return {
      nextPageToken: undefined,
      prevPageToken: undefined,
      isLoading: false,
      isExporting: false,
      currentPage: 1,
      itemsPerPage: 20,
      invoicesList: [],
      total: 0,
      login: '',
      source: '',
      invoice_id: '',
      magento_order_id: '',
      isProcessed: null,
      dateFrom: null,
      dateTo: null,
      email: '',
      country_id: null,
      price_options: null,
      isExportingAll: false,
      pageInput: '',
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.total / this.itemsPerPage);
    },
    isFirstPage() {
      return this.currentPage === 1;
    },
    isLastPage() {
      return this.currentPage === this.totalPages;
    },
    sourceOptions() {
      const uniqueSources = [
        ...new Set(this.invoicesList.map(item => item.source)),
      ];
      return uniqueSources;
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions([
      'fetchInvoices',
      'exportInvoice',
      'exportAllInvoices',
      'errorNotify',
      'successNotify',
      'setFlushCache',
    ]),

    exportAll() {
      this.isExportingAll = true;
      const filterProps = {
        login: this.login,
        source: this.source,
        price_options: this.price_options,
        invoice_id: this.invoice_id,
        country_id: this.country_id,
        magento_order_id: this.magento_order_id,
        isProcessed: this.isProcessed,
        date_from: this.generateSaudiTime(this.dateFrom, false),
        date_to: this.generateSaudiTime(this.dateTo, true),
        email: this.email,
      };

      const filteredProps = Object.fromEntries(
        Object.entries(filterProps).filter(
          // eslint-disable-next-line no-unused-vars
          ([_, value]) => value !== '' && value !== null
        )
      );
      this.exportAllInvoices({ props: filteredProps })
        .catch(() => this.errorNotify('export failed'))
        .finally(() => (this.isExportingAll = false));
    },
    fetchData() {
      this.isLoading = true;

      const filterProps = {
        login: this.login,
        source: this.source,
        price_options: this.price_options,
        invoice_id: this.invoice_id,
        country_id: this.country_id,
        magento_order_id: this.magento_order_id,
        isProcessed: this.isProcessed,
        date_from: this.generateSaudiTime(this.dateFrom, false),
        date_to: this.generateSaudiTime(this.dateTo, true),
        email: this.email,
      };

      const filteredProps = Object.fromEntries(
        Object.entries(filterProps).filter(
          // eslint-disable-next-line no-unused-vars
          ([_, value]) => value !== '' && value !== null
        )
      );

      this.fetchInvoices({
        page: this.currentPage,
        pageToken: this.nextPageToken,
        prevPageToken: this.prevPageToken,

        pageSize: this.itemsPerPage,
        props: filteredProps,
      })
        .then(res => {
          this.total = res.total;
          this.nextPageToken = res.next_page_token;
          this.prevPageToken = res.prev_page_token;

          if (res.items) {
            this.invoicesList = res.items.map(el => ({
              ...el,
              editMode: false,
              key: `invoice_${el.id}`,
            }));
          } else {
            this.invoicesList = [];
          }
        })
        .catch(this.errorNotify)
        .finally(() => {
          this.isLoading = false;
        });
    },

    applyFilters() {
      this.nextPageToken = undefined;
      this.prevPageToken = undefined;
      this.isLoading = true;
      this.currentPage = 1;
      this.fetchData();
    },

    flush() {
      this.setFlushCache()
        .then(() => {
          this.successNotify(`Cache flushed`);
        })
        .catch(() => {
          this.errorNotify;
        });
    },

    clearFilters() {
      this.login = '';
      this.source = '';
      this.invoice_id = '';
      this.magento_order_id = '';
      this.price_options = null;
      this.country_id = null;
      this.isProcessed = null;
      this.dateFrom = null;
      this.dateTo = null;
      this.email = '';
      this.nextPageToken = undefined;
      this.prevPageToken = undefined;
      this.currentPage = 1;
      this.isLoading = true;
      this.fetchData();
    },

    downloadInvoice({ id, invoiceId }) {
      this.isExporting = true;

      this.exportInvoice({
        id,
        invoiceId,
      })
        .then(() => {})
        .catch(this.errorNotify)
        .finally(() => {
          this.isExporting = false;
        });
    },

    generateSaudiTime(date, isEndDate) {
      if (!date) {
        return null;
      }

      const saudiTimeZone = 'Asia/Riyadh'; // Saudi Arabia's time zone
      const dateObject = new Date(date); // Convert the string to a Date object

      // If isEndDate is true, set the time to 23:59:59 to represent the end of the day
      if (isEndDate) {
        dateObject.setHours(23, 59, 59, 999);
      } else {
        dateObject.setHours(0, 0, 0, 0);
      }

      const saudiTimestamp = dateObject.toLocaleString('en-US', {
        timeZone: saudiTimeZone,
      });
      return new Date(saudiTimestamp).getTime(); // Get the timestamp in milliseconds
    },

    prevPage() {
      if (this.prevPageToken) {
        this.nextPageToken = this.prevPageToken;
        this.prevPageToken = null;
        this.currentPage--;
        this.pageInput = '';
        this.fetchData();
      }
    },

    goToPage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.currentPage = page;
        this.pageInput = '';
        this.fetchData();
      }
    },

    goToPageInput() {
      const pageNumber = parseInt(this.pageInput, 10);
      if (pageNumber > 0 && pageNumber <= this.totalPages) {
        this.currentPage = pageNumber;
        this.fetchData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-item {
  cursor: not-allowed;
}

.page-link:hover {
  background-color: white !important;
}

.page-item-btn {
  .page-link {
    padding: 0.7rem $pagination-padding-x;
  }
}

.invoices-button {
  display: flex;
  justify-content: space-between;
}

.flush-div {
  display: flex;
  justify-content: flex-end;
  margin-top: -38px;
}

.flush-button {
  background-color: red;
  color: white;
}

.form-control-pagination {
  width: 30px;
}

.error-pagination {
  font-size: 13px;
  color: red;
}
</style>
