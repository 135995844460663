import { BASE_URL } from '@/api/config.js';

import { authorizedGetRequest, authorizedPutRequest } from '@/api/requests.js';

const POSTS_BASE = BASE_URL + 'posts/newsfeed/';
const POSTS_INJECTIONS_BASE = POSTS_BASE + 'injections';

const getPostsInjections = authorizedGetRequest(POSTS_INJECTIONS_BASE);
const updatePostsInjections = authorizedPutRequest(POSTS_INJECTIONS_BASE);

export default {
  getPostsInjections,
  updatePostsInjections,
};
