import { BASE_URL } from '@/api/config.js';
import {
  authorizedGetRequest,
  authorizedPostRequest,
  authorizedPutRequest,
  authorizedDeleteRequest,
} from '@/api/requests.js';

const TOURNAMENTS = BASE_URL + 'tournaments';

export const getTournaments = authorizedGetRequest(`${TOURNAMENTS}/draft/my`);

export const createTournament = authorizedPostRequest(TOURNAMENTS);

export const announceTournament = tournamentID =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/announce`);

export const publishTournament = tournamentID =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/publish`);

export const rejectTournament = tournamentID =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/reject`);

export const drawTournament = tournamentID =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/lineup`);

export const restartTournament = tournamentID =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/restart`);

export const getTournamentClans = (pageToken = null, tournamentID, status) =>
  authorizedGetRequest(
    `${TOURNAMENTS}/${tournamentID}/clans${pageToken || status ? '?' : ''}${
      pageToken ? `page=${encodeURIComponent(pageToken)}` : ``
    }${status ? `&status=${status}` : ``}`
  );

export const approveClanOnTournament = (clanID, tournamentID) =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/clans/${clanID}`);

export const rejectClanOnTournament = (clanID, tournamentID) =>
  authorizedDeleteRequest(`${TOURNAMENTS}/${tournamentID}/players/${clanID}`);

export const updateTournament = tournamentID =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/draft`);

export const approveAllClansOnTournament = tournamentID =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/clans`);

export const getTournament = tournamentID =>
  authorizedGetRequest(`${TOURNAMENTS}/${tournamentID}/draft`);

export const getTournamentConfigById = tournamentID =>
  authorizedGetRequest(`${TOURNAMENTS}/${tournamentID}/config`);

export const updateTournamentConfig = tournamentID =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/config`);

export const getTournamentScheduleById = tournamentID =>
  authorizedGetRequest(`${TOURNAMENTS}/${tournamentID}/schedule`);

export const updateTournamentSchedule = tournamentID =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/schedule`);

export const getTournamentCustomSettingsById = tournamentID =>
  authorizedGetRequest(
    `${TOURNAMENTS}/${tournamentID}/registration/custom_fields`
  );

export const updateTournamentCustomSettingsById = tournamentID =>
  authorizedPutRequest(
    `${TOURNAMENTS}/${tournamentID}/registration/custom_fields`
  );

export const getTournamentPlayOffRounds = tournamentID =>
  authorizedGetRequest(`${TOURNAMENTS}/${tournamentID}/rounds`);

export const forceStartPlayOffRound = (tournamentID, round) =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/rounds/${round}/start`);

export const restartPlayOffRound = (tournamentID, round) =>
  authorizedPutRequest(
    `${TOURNAMENTS}/${tournamentID}/rounds/${round}/restart`
  );

export const finishPlayOffRound = (tournamentID, round) =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/rounds/${round}/finish`);

export const getTournamentRoundMatches = (tournamentID, round, query = '') =>
  authorizedGetRequest(
    `${TOURNAMENTS}/${tournamentID}/rounds/${round}/matches${
      query ? `?${query}` : ``
    }`
  );

export const getTournamentRoundMatchesTable = (tournamentID, round) =>
  authorizedGetRequest(
    `${TOURNAMENTS}/${tournamentID}/grid_view/round/${round}`
  );

export const getTournamentGroups = tournamentID =>
  authorizedGetRequest(`${TOURNAMENTS}/${tournamentID}/groups`);

export const getTournamentGroupRounds = tournamentID =>
  authorizedGetRequest(`${TOURNAMENTS}/${tournamentID}/group_rounds`);

export const forceStartGroupRound = (tournamentID, round) =>
  authorizedPutRequest(
    `${TOURNAMENTS}/${tournamentID}/group_rounds/${round}/start`
  );

export const restartGroupRound = (tournamentID, round) =>
  authorizedPutRequest(
    `${TOURNAMENTS}/${tournamentID}/group_rounds/${round}/restart`
  );

export const finishGroupRound = (tournamentID, round) =>
  authorizedPutRequest(
    `${TOURNAMENTS}/${tournamentID}/group_rounds/${round}/finish`
  );

export const getTournamentGroupRoundMatches = (tournamentID, round) =>
  authorizedGetRequest(
    `${TOURNAMENTS}/${tournamentID}/group_rounds/${round}/matches`
  );

export const updatePlayOffRoundStart = (tournamentID, round) =>
  authorizedPutRequest(
    `${TOURNAMENTS}/${tournamentID}/rounds/${round}/matches`
  );

export const updatePlayOffMatchStart = (tournamentID, round, matchID) =>
  authorizedPutRequest(
    `${TOURNAMENTS}/${tournamentID}/rounds/${round}/matches/${matchID}`
  );
/* will be deprecated soon */
export const proceedPlayOffMatch = (tournamentID, round, matchID) =>
  authorizedPutRequest(
    `${TOURNAMENTS}/${tournamentID}/rounds/${round}/matches/${matchID}/proceed`
  );
/* ======================= */
export const forceStartPlayOffMatch = (tournamentID, round, matchID) =>
  authorizedPutRequest(
    `${TOURNAMENTS}/${tournamentID}/rounds/${round}/matches/${matchID}/start`
  );

export const forceFinishPlayOffMatch = (tournamentID, round, matchID) =>
  authorizedPutRequest(
    `${TOURNAMENTS}/${tournamentID}/rounds/${round}/matches/${matchID}/finish`
  );

export const forceRestartPlayOffMatch = (tournamentID, round, matchID) =>
  authorizedPutRequest(
    `${TOURNAMENTS}/${tournamentID}/rounds/${round}/matches/${matchID}/reset`
  );

export const swapPlayOffPlayers = (tournamentID, round) =>
  authorizedPutRequest(
    `${TOURNAMENTS}/${tournamentID}/rounds/${round}/swap_players`
  );

export const updateGroupRoundStart = (tournamentID, round) =>
  authorizedPutRequest(
    `${TOURNAMENTS}/${tournamentID}/group_rounds/${round}/matches`
  );

export const updateGroupMatchStart = (tournamentID, round, matchID) =>
  authorizedPutRequest(
    `${TOURNAMENTS}/${tournamentID}/group_rounds/${round}/matches/${matchID}`
  );
/* will be deprecated soon */
export const proceedGroupMatch = (tournamentID, round, matchID) =>
  authorizedPutRequest(
    `${TOURNAMENTS}/${tournamentID}/group_rounds/${round}/matches/${matchID}/proceed`
  );
/* ======================= */
export const forceStartGroupMatch = (tournamentID, round, matchID) =>
  authorizedPutRequest(
    `${TOURNAMENTS}/${tournamentID}/group_rounds/${round}/matches/${matchID}/start`
  );

export const forceFinishGroupMatch = (tournamentID, round, matchID) =>
  authorizedPutRequest(
    `${TOURNAMENTS}/${tournamentID}/group_rounds/${round}/matches/${matchID}/finish`
  );

export const forceRestartGroupMatch = (tournamentID, round, matchID) =>
  authorizedPutRequest(
    `${TOURNAMENTS}/${tournamentID}/group_rounds/${round}/matches/${matchID}/reset`
  );

export const swapGroupPlayers = (tournamentID, round) =>
  authorizedPutRequest(
    `${TOURNAMENTS}/${tournamentID}/group_rounds/${round}/swap_players`
  );

const pinTournament = tournamentID =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/pin`);
const unpinTournament = tournamentID =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/unpin`);

const markSpecialEventTournament = tournamentID =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/special_event/mark`);
const unmarkSpecialEventTournament = tournamentID =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/special_event/unmark`);

export default {
  getTournaments,
  createTournament,
  publishTournament,
  announceTournament,
  drawTournament,
  getTournamentClans,
  approveClanOnTournament,
  approveAllClansOnTournament,
  rejectClanOnTournament,
  updateTournament,
  getTournament,
  getTournamentConfigById,
  updateTournamentConfig,
  getTournamentScheduleById,
  updateTournamentSchedule,
  getTournamentPlayOffRounds,
  forceStartPlayOffRound,
  restartPlayOffRound,
  finishPlayOffRound,
  getTournamentRoundMatches,
  getTournamentRoundMatchesTable,
  getTournamentGroups,
  getTournamentGroupRounds,
  forceStartGroupRound,
  restartGroupRound,
  finishGroupRound,
  getTournamentGroupRoundMatches,
  updatePlayOffRoundStart,
  updatePlayOffMatchStart,
  swapPlayOffPlayers,
  updateGroupRoundStart,
  updateGroupMatchStart,
  swapGroupPlayers,
  restartTournament,
  rejectTournament,
  proceedPlayOffMatch, // will be deprecated soon
  forceStartPlayOffMatch,
  forceRestartPlayOffMatch,
  forceFinishPlayOffMatch,
  proceedGroupMatch, // will be deprecated soon
  forceStartGroupMatch,
  forceRestartGroupMatch,
  forceFinishGroupMatch,
  updateTournamentCustomSettingsById,
  getTournamentCustomSettingsById,
  pinTournament,
  unpinTournament,
  markSpecialEventTournament,
  unmarkSpecialEventTournament,
};
