<template>
  <ModalBackdrop
    v-model="openModal"
    :esc-key="hideOnEscape"
    :before-close="closeOnEscape"
    class="radar-popup-modal"
  >
    <div class="card modal-animation">
      <div v-if="hasTitle" class="radar-header card-header">
        <h1 v-if="title">
          {{ title }}
        </h1>
        <h2 v-if="subTitle">
          {{ subTitle }}
        </h2>
      </div>
      <div :class="{ 'card-body': contentPadding }">
        <div class="radar-popup">
          <slot></slot>
        </div>
      </div>
      <div v-if="$slots.actions" class="radar-footer card-footer">
        <slot name="actions"></slot>
      </div>
    </div>
  </ModalBackdrop>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalBackdrop from 'v-modal-backdrop';

export default {
  name: 'ModalTextarea',

  components: {
    ModalBackdrop,
  },

  props: {
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    autoHideDelay: {
      type: Number,
      default: 3000,
    },
    hideOnClick: {
      type: Boolean,
      default: true,
    },
    hideOnEscape: {
      type: Boolean,
      default: true,
    },
    openModal: {
      default: false,
      type: Boolean,
    },
    contentPadding: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      open: this.openModal,
    };
  },

  computed: {
    ...mapGetters(['userAvatar']),

    hasTitle() {
      return this.$props.title || this.$props.subTitle;
    },
  },

  mounted() {
    this.$nextTick(this.showAnimation);
  },

  methods: {
    showAnimation() {
      if (this.isPlaying) {
        return;
      }

      this.isPlaying = true;

      //  TODO: UNCOMMENT WHEN ANIMATIONS ARRIVE
      // let timelineIn = this.$anime.timeline();
      // let timelineIn = {};

      //   let shapeAnimationIn = {
      //     scale: [0, 1],
      //     opacity: [0, 1],
      //     offset: '-=600',
      //     duration: 800,
      //   };
      //
      //   timelineIn.add({
      //     targets: '.radar-wrapper .icon',
      //     scale: [0, 1],
      //     duration: 1000,
      //     delay: 1000,
      //   })
      //     .add({
      //       targets: '.radar-wrapper .shape-1',
      //       ...shapeAnimationIn
      //     })
      //     .add({
      //       targets: '.radar-wrapper .shape-2',
      //       ...shapeAnimationIn
      //     })
      //     .add({
      //       targets: '.radar-wrapper .shape-3',
      //       scale: [0, 1],
      //       ...shapeAnimationIn
      //     })
      //     .finished.then(() => {
      //     this.isPlaying = false;
      //     this.autoHideDelay && setTimeout(this.hideAnimation, this.autoHideDelay);
      //   });
      //
      //   this.hasTitle && timelineIn.add({
      //     targets: '.radar-header',
      //     opacity: [0, 1],
      //     duration: 1000,
      //     easing: 'easeOutCubic'
      //   })
      //
      //   this.$slots.actions && timelineIn.add({
      //     targets: '.radar-footer',
      //     opacity: [0, 1],
      //     offset: '-=500',
      //     duration: 800,
      //     easing: 'easeOutCubic'
      //   })
    },

    // hideAnimation() {
    //   if (this.isPlaying) {
    //     return;
    //   }
    //
    //   this.isPlaying = true;
    //
    //   let timelineOut = this.$anime.timeline();
    //
    //   let shapeAnimationOut = {
    //     scale: [1, 2],
    //     opacity: [1, 0],
    //     offset: '-=200',
    //     duration: 400,
    //     easing: 'easeInCubic'
    //   };
    //
    //   this.$slots.actions && timelineOut.add({
    //     targets: '.radar-footer',
    //     opacity: [1, 0],
    //     duration: 300,
    //     easing: 'easeOutCubic'
    //   })
    //
    //   timelineOut.add({
    //     targets: '.radar-wrapper .shape-3',
    //     ...shapeAnimationOut,
    //   })
    //     .add({
    //       targets: '.radar-wrapper .shape-2',
    //       ...shapeAnimationOut,
    //     })
    //     .add({
    //       targets: '.radar-wrapper .shape-1',
    //       ...shapeAnimationOut,
    //     })
    //     .add({
    //       targets: '.radar-wrapper .icon',
    //       ...shapeAnimationOut,
    //       opacity: [1, 0]
    //     })
    //     .finished.then(this.close)
    //
    //   this.hasTitle && timelineOut.add({
    //     targets: '.radar-header',
    //     opacity: [1, 0],
    //     duration: 1000,
    //     easing: 'easeOutCubic',
    //   })
    // },

    closeOnEscape() {
      this.hideOnEscape;
      return false;
    },

    closeOnClick() {
      this.hideOnClick;
    },

    close() {
      this.isPlaying = false;
      this.showModal = false;
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
.modal {
  z-index: 1050;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  outline: 0;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  &.modal-opened {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.modal-backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: 0;
  transition: all 0.15s;
}
.modal-backdrop.modal-opened {
  opacity: 0.6;
}
.modal-content {
  min-width: 300px;
  z-index: 1050;
  display: inline-flex;
  height: fit-content;
}
</style>

<style lang="scss" scoped>
@mixin appearAnimation($name, $time) {
  animation: $name $time cubic-bezier(0.45, 0.65, 0.59, 1.16) forwards;
}

h1 {
  font-size: 14px;
  color: #354052;
}

h2 {
  font-size: 12px;
  font-weight: normal;
  color: #354052;
}

.card {
  width: 100%;
}

.radar-popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .radar-header {
    position: relative;
    text-align: center;
    width: 100%;
  }

  .radar-wrapper {
    width: 260px;
    height: 260px;

    .shape-wrapper {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .shape {
      &.shape-3 {
        width: 260px;
        height: 260px;
      }
      &.shape-2 {
        width: 190px;
        height: 190px;
      }
      &.shape-1 {
        width: 125px;
        height: 125px;
      }
      &.icon {
        width: 60px;
        height: 60px;
        opacity: 1;
      }
    }
  }

  .clan-name {
    font-size: 1.125rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 38px);
  }
}
.card-header {
  @include appearAnimation(dropDown, 0.5s);
  background-color: #ffffff;
  border: none;
  padding-bottom: 0;
}
.card-body {
  @include appearAnimation(dropDown, 0.6s);
  padding-top: 6px;
  padding-bottom: 9px;
}
.card-footer {
  @include appearAnimation(dropDown, 0.7s);
  background-color: #ffffff;
  border: none;
  padding-top: 0;
}
.modal-animation {
  animation: ease-in-out 0.3s forwards;
}

@keyframes dropDown {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  60% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes appear {
  0% {
    opacity: 0;
    transform: scale(0.7) translate(100px, 100px) rotateZ(-30deg);
  }
  70% {
    transform: scale(1.1) translate(-40px, -40px);
  }
  100% {
    opacity: 100%;
    transform: scale(1) translate(0, 0) rotateZ(0);
  }
}
</style>
