import { ifAuthorized } from '@/router/guards';

import CustomersWrapper from '@/views/admin/Costumers/';
import Customer from '@/views/admin/Costumers/CostumerList';
import OrderDetails from '@/views/admin/Costumers/CostumerList/OrderDetails';

export default [
  {
    path: '/customers',
    title: 'Customers',
    component: CustomersWrapper,
    beforeEnter: ifAuthorized,
    children: [
      {
        path: '',
        name: 'customer',
        component: Customer,
      },
      {
        path: ':login/:phone',
        name: 'OrderDetails',
        component: OrderDetails,
      },
    ],
  },
];
