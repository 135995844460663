'use strict';

import authEndpoints from './endpoints/auth.js';
import settingsEndpoints from './endpoints/settings.js';
import tournamentEndpoints from './endpoints/tournaments.js';
import leagueEndpoints from './endpoints/leagues.js';
import translationsEndpoints from './endpoints/translations.js';
import battlesEndpoints from './endpoints/battles.js';
import conversationsEndpoints from './endpoints/conversations.js';
import notificationsEndpoints from './endpoints/notifications.js';
import profilesEndpoints from './endpoints/profiles.js';
import clansEndpoints from './endpoints/clans.js';
import pointsEndpoints from './endpoints/points.js';
import leaderboardEndpoints from './endpoints/leaderboards.js';
import complaintsEndpoints from './endpoints/complaints.js';
import popularPlayers from './endpoints/popularPlayers.js';
import organization from './endpoints/organization';
import reposts from './endpoints/socialReposts';
import files from './endpoints/files';
import roles from './endpoints/roles';
import posts from './endpoints/posts.js';
import subscriptions from './endpoints/subscriptions.js';
import coupons from './endpoints/coupons.js';
import invoices from './endpoints/invoices.js';
import customer from './endpoints/customer.js';
import chart from './endpoints/chart.js';
import flush from './endpoints/flush.js';
import orders from './endpoints/orders.js';

// const PREREGISTRATION = CONFIG.preregistration
// const PREREGISTRATION_EXPORT = CONFIG.preregistration + '/export/excel.xlsx'
// export const getPreregistration = (pageToken = null) => authorizedGetRequest(PREREGISTRATION + (pageToken ? `?page=${pageToken}` : ''))

export default {
  ...authEndpoints,
  ...settingsEndpoints,
  ...tournamentEndpoints,
  ...leagueEndpoints,
  ...translationsEndpoints,
  ...battlesEndpoints,
  ...conversationsEndpoints,
  ...notificationsEndpoints,
  ...profilesEndpoints,
  ...clansEndpoints,
  ...pointsEndpoints,
  ...leaderboardEndpoints,
  ...complaintsEndpoints,
  ...popularPlayers,
  ...organization,
  ...files,
  ...roles,
  ...posts,
  ...reposts,
  ...subscriptions,
  ...coupons,
  ...invoices,
  ...customer,
  ...chart,
  ...flush,
  ...orders,
};
