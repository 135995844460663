<template>
  <div class="subscriptions-page-wrapper">
    <div class="container-fluid">
      <div class="row my-4 align-items-center">
        <h3 class="mb-0 pb-0 col">Plans</h3>
        <router-link
          class="btn btn-primary ml-auto mr-4"
          :to="{ name: 'subscriptionCreate' }"
        >
          Create Plan
        </router-link>
      </div>
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body pb-0 px-4">
              <div class="row mb-3">
                <div
                  v-for="filter in allSubscriptionFilters"
                  :key="filter.name"
                  class="col-auto"
                >
                  <label class="d-block">{{ filter.label }}:</label>
                  <autosuggest
                    v-if="filter.name === 'country'"
                    id="country"
                    v-model="filters[filter.name]"
                    placeholder="Start typing..."
                    display-property="text"
                    :source="suggestCountriesRequest"
                    @resultPicked="onFilterChange"
                  />
                  <dropdown
                    v-else
                    v-model="filters[filter.name]"
                    :list="getFiltersOptions(filter)"
                    empty-text="All"
                    :empty-option="true"
                    :could-be-empty="true"
                    class="input-like w-auto"
                    :disabled="
                      !getFiltersOptions(filter).length && !subscriptions.length
                    "
                    @input="onFilterChange"
                  >
                    <template slot="chosen" slot-scope="chosen">
                      <span v-if="filter.name === 'teamSize'">
                        {{ chosen.chosenItem }}&nbsp;v&nbsp;{{
                          chosen.chosenItem
                        }}
                      </span>
                      <span v-else>{{ chosen.chosenItem }}</span>
                      <span class="sr-only">Choose option</span>
                    </template>
                    <template slot="item" slot-scope="options">
                      <span v-if="filter.name === 'teamSize'">
                        {{ options.item }}&nbsp;v&nbsp;{{ options.item }}
                      </span>
                      <span v-else>
                        {{ options.item }}
                      </span>
                    </template>
                  </dropdown>
                </div>
                <div class="col-auto">
                  <label class="d-block">&nbsp;</label>
                  <button
                    v-if="isFiltered"
                    class="btn mx-2 bg-transparent text-primary py-0"
                    @click.self.prevent="clearFilters"
                  >
                    Clear filters
                  </button>
                </div>
              </div>
            </div>
            <div class="card-body border-top border-muted pt-0 px-0">
              <table class="table table-hover mx-0 px-0 subscriptions-list">
                <caption
                  v-if="!subscriptions.length && isLoading"
                  class="text-center text-muted"
                >
                  Fetching subscriptions...
                </caption>
                <caption
                  v-else-if="!subscriptions.length"
                  class="text-center text-muted"
                >
                  No subscriptions to show
                </caption>
                <template v-else>
                  <thead>
                    <tr>
                      <th
                        class="border-top-0 border-bottom-0 px-2 pl-4 section-description text-center"
                      >
                        Enabled
                      </th>
                      <th
                        class="border-top-0 border-bottom-0 px-2 section-description text-center"
                      >
                        Name
                      </th>
                      <th
                        class="border-top-0 border-bottom-0 px-2 section-description text-center"
                      >
                        SKU
                      </th>
                      <th
                        class="border-top-0 border-bottom-0 px-2 section-description text-center"
                      >
                        Price
                      </th>
                      <th
                        class="border-top-0 border-bottom-0 px-2 section-description text-center"
                      >
                        Discount
                      </th>
                      <th
                        class="border-top-0 border-bottom-0 px-2 section-description text-center"
                      >
                        Currency
                      </th>
                      <th
                        class="border-top-0 border-bottom-0 px-2 section-description text-center"
                      >
                        Period
                      </th>
                      <th
                        class="border-top-0 border-bottom-0 px-2 section-description text-center"
                      >
                        Description
                      </th>
                      <th
                        class="border-top-0 border-bottom-0 px-2 section-description text-center"
                      >
                        Location
                      </th>
                      <th
                        class="border-top-0 border-bottom-0 px-2 section-description text-center"
                      >
                        Trial <br />Enabled
                      </th>

                      <th
                        class="border-top-0 border-bottom-0 px-2 section-description text-center"
                      >
                        Trial <br />Days
                      </th>
                      <th
                        class="border-top-0 border-bottom-0 px-2 section-description text-center"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <ListItem
                      v-for="subscription in subscriptions"
                      :key="subscription.id"
                      :create-mode="!!newSubscriptions[subscription.id]"
                      :subscription="subscription"
                      @saveItem="onSaveItem"
                      @editItem="turnEditMode"
                      @subscriptionDeleted="onSubscriptionDeleted"
                    />
                  </tbody>
                </template>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { uniqueId, uniqBy, cloneDeep } from 'lodash';
import { mapActions, mapMutations } from 'vuex';
import dropdown from '@/components/form/HeraFormDropdown.vue';
import autosuggest from '@/components/form/HeraAutosuggest';
import ListItem from './ListItem';

const DEFAULT_SUBSCRIPTION = (id, country) => ({
  name: '',
  key: `subscription_${uniqueId()}`,
  id,
  country,
  countryId: country.id,
  period: 'monthly',
  editMode: true,
  description: '',
  amount: 0,
  hasTrial: false,
  trialDays: 7,
  trialAmount: 0.01,
});
export default {
  name: 'SubscriptionsList',
  components: { dropdown, autosuggest, ListItem },
  data() {
    return {
      filters: {},
      subscriptions: [],
      updatingItemId: null,
      isLoading: true,
      newSubscriptions: {},
      subscriptionsBackup: [],
    };
  },
  computed: {
    isFiltered() {
      const allFilters = Object.keys(this.filters);
      return (
        allFilters.length && allFilters.some(filter => this.filters[filter])
      );
    },
    allSubscriptionFilters() {
      return [
        {
          name: 'country',
          label: 'Location',
          options: null,
        },
      ];
    },
  },
  created() {
    this.setLoading();
    this.initializeFilterValues();
    this.getSubscriptions();
  },
  methods: {
    ...mapMutations(['setSubscription', 'removeSubscription']),
    ...mapActions([
      'fetchSubscriptions',
      'suggestCountriesRequest',
      'errorNotify',
      'successNotify',
      'addSubscription',
      'createSubscription',
      'deleteSubscription',
      'updateSubscription',
    ]),
    onSubscriptionDeleted(subscriptionId) {
      const index = this.subscriptions.findIndex(
        subscription => subscription.id === subscriptionId
      );

      this.subscriptions.splice(index, 1);
    },
    getSubscriptions() {
      this.isLoading = true;
      const filters = this.getFilters();
      if (
        Object.keys(filters).length === 0 ||
        Object.values(filters).every(v => v === null)
      ) {
        // no filters set, get all subscriptions
        return this.fetchSubscriptions()
          .then(res => {
            this.subscriptions = uniqBy([...this.subscriptions, ...res], 'id');
          })
          .catch(this.errorNotify)
          .finally(() => (this.isLoading = false));
      } else {
        // apply filters and get matching subscriptions
        return this.fetchSubscriptions(filters)
          .then(res => {
            this.subscriptions = uniqBy(res, 'id');
          })
          .catch(this.errorNotify)
          .finally(() => (this.isLoading = false));
      }
    },
    getFiltersOptions(filter) {
      return [...filter.options];
    },
    getFilters() {
      // countryId currency period name enabled
      return {
        ...this.filters,
        ...{
          countryId: this.filters.country ? this.filters.country.id : null,
        },
      };
    },
    setUpdatingItemId(id) {
      this.updatingItemId = id;
    },
    setLoading() {
      this.isLoading = true;
    },
    unsetLoading() {
      this.setUpdatingItemId(null);
      this.isLoading = false;
    },
    // countryFilter() {
    //   return this.filters['country'] ? this.filters['country'].id : '';
    // },
    onFilterChange() {
      this.isLoading = true;
      this.getSubscriptions().then(() => {
        this.isLoading = false;
      });
    },

    clearFilters() {
      this.initializeFilterValues();
      this.onFilterChange();
    },
    initializeFilterValues() {
      this.filters = {};
    },

    backupSubscriptions(subscriptions) {
      this.subscriptionsBackup = cloneDeep(subscriptions);
    },
    restoreSubscriptionsBackup() {
      this.subscription = cloneDeep(this.subscriptionsBackup);
    },
    turnEditMode(item) {
      this.setSubscription({ ...item, editMode: true });
    },
    turnOffEditMode(item) {
      this.setSubscription({ ...item, editMode: false });
    },
    onDeleteItem({ id }) {
      if (this.newSubscriptions[id]) {
        this.removeSubscription(id);
        delete this.newSubscriptions[id];
        return;
      }

      if (!confirm('Are you sure? This action cannot be cancelled.')) {
        return;
      }
      this.setLoading();

      this.deleteSubscription({ id })
        .then(() => this.successNotify('Subscription removed!'))
        .catch(this.errorNotify)
        .finally(this.unsetLoading);
    },

    onAddItem() {
      const subscription = DEFAULT_SUBSCRIPTION(
        this.subscriptions.length,
        this.filters['country']
      );
      this.addSubscription(subscription);
      this.newSubscriptions[subscription.id] = subscription;
    },
    onSaveItem(item) {
      this.setUpdatingItemId(item.id);
      this.setLoading();
      if (this.newSubscriptions[item.id]) {
        this.createSubscription(item)
          .then(() => {
            delete this.newSubscriptions[item.id];
            this.successNotify('Subscription created');
          })
          .then(() => {
            this.turnOffEditMode(item);
            this.backupSubscriptions(this.subscriptions);
          })
          .catch(err => {
            this.restoreSubscriptionsBackup();
            this.errorNotify(err);
          })
          .finally(this.unsetLoading);
      } else {
        this.updateSubscription(item)
          .then(() => {
            this.successNotify('Subscription updated');
          })
          .then(() => {
            this.turnOffEditMode(item);
            this.backupSubscriptions(this.subscriptions);
          })
          .catch(err => {
            this.restoreSubscriptionsBackup();
            this.errorNotify(err);
          })
          .finally(this.unsetLoading);
      }
    },
  },
};
</script>
