<template>
  <div class="page-wrapper">
    <div class="container">
      <div class="row my-4 align-items-center">
        <h3 class="mb-0 pb-0 col">Verification</h3>
      </div>
      <div class="row mb-2">
        <div class="col">
          <div class="card py-3">
            <div class="card-body">
              <div class="row mx-2">
                <div class="col-12">
                  <div class="section-description">
                    List of clans({{ clansList.length + 'CLANS' }})
                  </div>
                </div>
              </div>
              <div class="row mx-2">
                <div class="col-auto">
                  <label class="d-block">Name Search:</label>
                  <textInput
                    v-model="contains"
                    placeholder="Start typing..."
                    :debounce="debounce"
                    @input="onFilterChange"
                  />
                </div>
                <div class="col-auto">
                  <label class="d-block">Location:</label>
                  <autosuggest
                    v-model="countries"
                    placeholder="Start typing..."
                    display-property="text"
                    :source="suggestCountriesRequest"
                    @resultPicked="onFilterChange"
                  />
                </div>
              </div>
            </div>
            <div class="card-body border-top border-muted pt-0">
              <div class="row mb-3 px-3 pt-3">
                <infiniteScroll
                  :list="clansList"
                  list-name="list"
                  :is-disabled="isLoading || nextPageToken === null"
                  :activation-gap="100"
                  @ScrollToBottom="fetchClans"
                >
                  <table
                    id="clans-list"
                    slot-scope="{ list }"
                    class="table table-hover mx-0 px-0"
                  >
                    <caption
                      v-if="!list.length && isLoading"
                      class="text-center text-muted"
                    >
                      Fetching clans...
                    </caption>
                    <caption
                      v-else-if="!list.length"
                      class="text-center text-muted"
                    >
                      No clans found
                    </caption>
                    <template v-else>
                      <thead>
                        <tr>
                          <th class="border-top-0 border-bottom-0 pr-2 pl-4">
                            Clan name
                          </th>
                          <th class="border-top-0 border-bottom-0 px-2">
                            Location
                          </th>
                          <th class="border-top-0 border-bottom-0 px-2">
                            Verification badge
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="clan in list" :key="clan.id">
                          <td class="pr-2 pl-4">
                            <avatar
                              type="clan"
                              :img-url="clan.avatar || clan.logo"
                              :size="22"
                              class="d-inline-block mr-2 clan-avatar"
                            />
                            {{ clan.login || clan.name }}
                          </td>
                          <td class="pr-4 pl-2">{{ clan.country }}</td>
                          <td class="pr-4 pl-2">
                            <switcher
                              :value="clan.approved"
                              @change="setBadge(clan.id, $event)"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </table>
                </infiniteScroll>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import avatar from '@/components/common/avatar.vue';
import { mapActions } from 'vuex';
import infiniteScroll from '@/components/common/infiniteScroll.vue';
import switcher from '@/components/form/HeraFormSwitch.vue';
import textInput from '@/components/form/HeraFormTextInput.vue';
import autosuggest from '@/components/form/HeraAutosuggest';
import listMixin from '@/mixins/lists';
import { parseObjectToUri } from '@/utils';

export default {
  name: 'ClanVerification',
  components: {
    avatar,
    textInput,
    infiniteScroll,
    switcher,
    autosuggest,
  },
  mixins: [...listMixin],
  data() {
    return {
      debounce: 700,
      clansList: [],
      filters: {},
      contains: '',
      countries: null,
    };
  },
  created() {
    this.fetchClans();
  },
  methods: {
    ...mapActions([
      'getClans',
      'setClanVerification',
      'errorNotify',
      'successNotify',
      'suggestCountriesRequest',
    ]),
    onFilterChange() {
      this.clansList = [];
      this.nextPageToken = undefined;
      this.fetchClans();
    },
    fetchClans() {
      return this.getNextPageData(
        'clansList',
        this.getClans({
          pageToken: this.nextPageToken,
          query: parseObjectToUri({
            contains: this.contains,
            country: this.countries ? this.countries.id : '',
          }),
        })
      );
    },
    setBadge(clan, validated) {
      this.setClanVerification({ clan, validated })
        .then(() => {
          if (validated) {
            this.successNotify('Clan verified');
          } else {
            this.successNotify('Clan unverified');
          }
        })
        .catch(this.errorNotify);
    },
  },
};
</script>
