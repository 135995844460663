<template>
  <div class="d-flex flex-column align-items-center w-100 auth">
    <!-- Title -->
    <section class="text-center">
      <h1 class="auth_title">
        {{ isROSelectorVisible ? 'Select Regional Operator' : 'Login' }}
      </h1>
    </section>

    <section class="card w-100">
      <form
        class="card-body remove-autofill-styles"
        @submit.prevent="formSubmit"
      >
        <!-- Select Regional Operator -->
        <ul v-if="isROSelectorVisible" class="list-unstyled w-100">
          <li v-for="operator in operators" :key="operator.login">
            <label
              class="d-flex align-items-center w-100 px-4 cursor-pointer auth_ro-select-item"
            >
              <Radio
                v-model="selectedRO"
                name="ro"
                class="mb-1 mr-3"
                :val="operator"
              />
              <Avatar :size="36" :img-url="operator.avatar" class="mr-3" />
              <span class="font-weight-bold text-truncate">{{
                operator.name || operator.login
              }}</span>
            </label>
          </li>
        </ul>

        <!-- Sign In -->
        <template v-else>
          <TextInput
            v-model.trim="login"
            v-validate="'required'"
            name="username"
            label="Username / Email / Phone"
            :autofocus="true"
            :error="errors.first('username')"
          />
          <PasswordInput
            v-model.trim="password"
            v-validate="'required'"
            name="password"
            type="password"
            label="Password"
            :error="errors.first('password')"
            class="mb-4"
          />
        </template>

        <!-- Footer -->
        <div class="d-flex justify-content-between align-items-center">
          <router-link
            :to="{ name: isROSelectorVisible ? 'signout' : 'passwordReset' }"
          >
            {{ isROSelectorVisible ? 'Logout' : 'Forgot password?' }}
          </router-link>
          <Btn
            :label="isROSelectorVisible ? 'Select' : 'Login'"
            :loading="isLoading"
            class="btn-primary"
            :disabled="!isBtnActive"
          />
        </div>
      </form>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TextInput from '@/components/form/HeraFormTextInput';
import PasswordInput from '@/components/form/HeraFormPasswordInput';
import Radio from '@/components/form/HeraFormRadio';
import Btn from '@/components/form/HeraFormSubmitBtn';
import Avatar from '@/components/common/avatar';

const ASSOCIATIONS_FILTER = ['Administrator', 'Regional_Operator'];

export default {
  name: 'SignIn',

  components: {
    TextInput,
    PasswordInput,
    Btn,
    Avatar,
    Radio,
  },

  data() {
    return {
      login: null,
      password: null,
      isLoading: false,
      operators: [],
      selectedRO: null,
    };
  },

  computed: {
    ...mapGetters([
      'callingCodes',
      'username',
      'isAuthorized',
      'isPartiallyAuthorized',
    ]),

    isROSelectorVisible() {
      return (
        (this.isAuthorized || this.isPartiallyAuthorized) &&
        !!this.operators.length
      );
    },

    isBtnActive() {
      return this.isROSelectorVisible ? !!this.selectedRO : true;
    },
  },

  inject: ['$validator'],

  created() {
    if (!this.callingCodes.length) {
      this.getCallingCodes();
    }
    this.clearAllTokens();
  },

  methods: {
    ...mapActions([
      'authRequest',
      'sendVerificationCode',
      'errorNotify',
      'clearAllTokens',
      'getCallingCodes',
      'getMyProfileAssociations',
      'switchAccount',
      'getMyDetails',
    ]),

    formSubmit() {
      if (this.isROSelectorVisible) {
        this.switchProfile();
      } else {
        this.signin();
      }
    },

    signin() {
      this.isLoading = true;
      this.$validator
        .validateAll()
        .then(result =>
          result
            ? Promise.resolve({
                login: this.login,
                password: this.password,
                scopes: 'administrative,application',
              })
            : Promise.reject({ silent: true })
        )
        .then(this.authRequest)
        .then(() =>
          this.getMyProfileAssociations({ roles: ASSOCIATIONS_FILTER })
        )
        .then(items => (this.operators = items))
        .then(() =>
          this.operators.length
            ? Promise.resolve()
            : this.$router.push({ name: 'signout' })
        )
        .catch(this.errorNotify)
        .finally(() => (this.isLoading = false));
    },

    switchProfile() {
      this.isLoading = true;
      (this.username === this.selectedRO.login
        ? Promise.resolve()
        : this.switchAccount({
            accountId: this.selectedRO.id || this.selectedRO.login,
            accountType: this.selectedRO.type,
          })
      )
        .then(this.getMyDetails)
        .then(() => this.$router.push({ name: 'home' }))
        .catch(this.errorNotify)
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style lang="scss">
.auth {
  &_title {
    font-size: 2.25rem;
  }
  .card {
    max-width: 33.125rem;
    border: 1px solid $border-color;
    .card-body {
      padding: 3.75rem 6rem;
    }
  }
  &_ro-select-item {
    height: 5rem;
    border: 1px solid $border-color;
    border-radius: 0.25rem;
  }
}
</style>
