<template>
  <span class="avatar-wrapper">
    <img
      class="avatar avatar-wrapper__avatar"
      :src="avatar"
      :width="size"
      :height="size"
      @error="onBrokenLink"
    />
    <span
      v-if="$slots.addon"
      class="addon avatar-wrapper__addon"
      :style="{
        top: `${addonY}px`,
        left: `${addonX}px`,
        width: `${addonSize}px`,
        height: `${addonSize}px`,
      }"
    >
      <slot name="addon"> </slot>
    </span>
  </span>
</template>

<script>
import defaultAvatar from '@/assets/images/defaultAvatar.png';
import defaultClan from '@/assets/images/defaultClan.png';
import defaultPlaceholder from '@/assets/images/defaultPlaceholder.png';

export default {
  name: 'Avatar',

  props: {
    size: {
      type: Number,
      default: 96,
    },
    imgUrl: {
      type: String,
      default: '',
    },
    fillColor: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'profile',
      validator(val) {
        return ['clan', 'profile', 'custom'].indexOf(val) !== -1;
      },
    },
    addonXPos: {
      type: String,
      validator(val) {
        return ['left', 'center', 'right'].indexOf(val) !== -1;
      },
      default: 'right',
    },
    addonYPos: {
      type: String,
      validator(val) {
        return ['top', 'center', 'bottom'].indexOf(val) !== -1;
      },
      default: 'bottom',
    },
    addonSize: {
      type: Number,
      default: 16,
    },
  },
  data() {
    return {
      imgWidth: 0,
      imgHeight: 0,
    };
  },
  computed: {
    avatar() {
      return this.imgUrl || this.fallBackSrc;
    },
    fallBackSrc() {
      return this.type === 'profile'
        ? defaultAvatar
        : this.type === 'clan'
        ? defaultClan
        : defaultPlaceholder;
    },
    addonX() {
      return this.addonXPos === 'center'
        ? (this.size - this.addonSize) / 2
        : this.addonXPos === 'right'
        ? this.size - this.addonSize / 2
        : -this.addonSize / 2;
    },
    addonY() {
      return this.addonYPos === 'center'
        ? (this.size - this.addonSize) / 2
        : this.addonYPos === 'bottom'
        ? this.size - this.addonSize
        : 0;
    },
  },
  methods: {
    onBrokenLink(event) {
      event.target.src = this.fallBackSrc;
    },
  },
};
</script>

<style lang="scss">
/* TODO: create mixin from shape mask */
.avatar-wrapper {
  position: relative;
  &__avatar {
    object-fit: cover;
    mask-image: url(~@/assets/images/icons/shape-mask.svg);
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-position: top;
    position: relative;
    z-index: 1;
  }
  &__addon {
    mask-image: url(~@/assets/images/icons/shape-mask.svg);
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-position: top;
    background: #eee;
    position: absolute;
    z-index: 2;
    display: block;
    line-height: 1rem;
    text-align: center;
  }
}
</style>
