<template>
  <div
    v-if="username"
    class="h-100 d-flex align-items-start flex-column popover-chat"
  >
    <DummyList
      v-if="!conversationInfo"
      icon-name="message-outline"
      size="24"
      position="center"
      class="w-100 mb-auto"
    >
      Select a chat to start messaging
    </DummyList>
    <DummyList
      v-else-if="conversationInfo && !items.length"
      icon-name="message-outline"
      size="24"
      position="center"
      class="w-100 mb-auto d-flex flex-column align-items-center justify-content-center"
    >
      No message here yet...
    </DummyList>
    <div
      v-else-if="conversationInfo && items.length"
      ref="chatDialog"
      class="w-100 h-100 mt-auto mb-0 custom-scrollbar chat-dialog"
      :class="{ 'form-is-visible': formIsVisible }"
    >
      <infiniteScroll
        :reverse="true"
        :list="items"
        list-name="messages"
        :is-disabled="scrollIsDisabled || nextPageToken === null"
        :in-container="true"
        class="position-relative pt-5rem min-h-100"
        :activation-gap="200"
        @ScrollToBottom="getMessages"
      >
        <div slot-scope="{ messages }" class="d-flex flex-column-reverse">
          <template v-for="item in messages">
            <div
              v-if="username !== item.sender && item.text"
              :key="item.id"
              class="msg-not-my"
            >
              <div class="media justify-content-start msg-popover-wrap">
                <avatar
                  :size="50"
                  :img-url="item.sender_avatar"
                  class="align-self-end ml-2 mr-3"
                />
                <div
                  class="media-body d-flex align-items-start flex-column msg"
                >
                  <div class="w-100 mb-auto pb-1 text-break-word msg-origin">
                    <template v-for="(line, index) in item.text.split('\n')"
                      >{{ line }}<br :key="index"
                    /></template>
                  </div>
                  <div class="w-100 text-right msg-date">
                    {{ item.sent_date | parseTime }}
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="username === item.sender && item.text"
              :key="item.id"
              class="msg-my"
            >
              <div
                class="media justify-content-start msg-popover-wrap"
                :class="{ 'msg-muted': !!(item.isLoading || item.isShadowMsg) }"
              >
                <avatar
                  :size="50"
                  :img-url="item.sender_avatar"
                  class="align-self-end ml-2 mr-3"
                />
                <div
                  class="media-body d-flex align-items-start flex-column msg"
                >
                  <div class="w-100 mb-auto pb-1 text-break-word">
                    <template v-for="(line, index) in item.text.split('\n')"
                      >{{ line }}<br :key="index"
                    /></template>
                  </div>
                  <div class="w-100 text-right msg-date">
                    <span
                      class="cursor-pointer"
                      @click="setDropdownOptions($event, item)"
                    >
                      {{ item.sent_date | parseTime }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <PopoverMenu
            :position="optionsPosition"
            :closest="$refs.chatDialog"
            :float="['right']"
            regard="bottom"
            :items="[1]"
          >
            <span @click="deleteMsg">Delete</span>
          </PopoverMenu>
        </div>
      </infiniteScroll>
    </div>
    <div
      class="w-100 position-relative align-self-end mb-0 chat-dialog-popover-form"
      :class="{ 'has-focus': formHasFocus || messageText.trim() }"
    >
      <form
        v-show="formIsVisible"
        action="#"
        class="form-group mb-0"
        @submit.prevent="sendMsg"
      >
        <label class="d-block mb-0">
          <textarea
            ref="msgTextarea"
            v-model="messageText"
            class="form-control custom-scrollbar border-left-0 border-right-0 border-bottom-0 px-2"
            rows="1"
            placeholder="What do you want to say?"
            @focus="formHasFocus = true"
            @blur="formHasFocus = false"
            @keypress.enter="textareaOnKeypress"
          >
          </textarea>
        </label>
        <BtnIcon
          type="submit"
          icon="enter"
          class="chat-dialog-popover-submit"
          :class="{ active: messageText.trim() }"
          width="24"
          @click.native="sendMsg"
        />
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { uniqBy } from 'lodash';
import { converter } from '@/mixins/helpers';
import DummyList from '@/components/common/DummyList';
import avatar from '@/components/common/avatar';
import infiniteScroll from '@/components/common/infiniteScroll';
import PopoverMenu from '@/components/common/popover/menu';
import BtnIcon from '@/components/common/btnIcon';

export default {
  name: 'ChatDialogPopover',
  components: {
    PopoverMenu,
    infiniteScroll,
    BtnIcon,
    avatar,
    DummyList,
  },
  mixins: [converter],
  props: {
    chatId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      formHasFocus: false,
      messageText: '',
      items: [],
      conversationInfo: null,

      nextPageToken: '',
      scrollIsDisabled: false,

      optionsPosition: null,
      optionsMsg: null,

      listIsShifted: false,
    };
  },
  computed: {
    ...mapGetters([
      'username',
      'avatar',
      'system',
      'conversationSubscribeData',
      'activeConversationUnreadCount',
    ]),
    conversationId() {
      return this.chatId;
    },
    formIsVisible() {
      return !!this.conversationInfo;
    },
  },
  watch: {
    conversationId(id) {
      this.reloadDialog(id);
    },
    conversationSubscribeData(res) {
      if (res && res.chat === this.conversationId) {
        this.items = [
          {
            attachments: res.attachments,
            chat_id: res.chat,
            id: res.id,
            read: false,
            sender: res.sender.id,
            sender_avatar: res.sender.avatar,
            sent_date: res.sent_date,
            text: res.text,
          },
          ...this.items,
        ];

        this.conversationInfo = {
          ...this.conversationInfo,
          last_message: res.text,
          avatar: res.sender.avatar,
        };

        this.checkMessageAsRead({
          chatId: this.conversationId,
          msgId: res.id,
        });
      }
    },
  },
  mounted() {
    this.reloadDialog(this.conversationId);
  },
  methods: {
    ...mapActions([
      'fetchMessages',
      'getConversationInfo',
      'sendMessage',
      'deleteMessage',
      'checkConversationAsRead',
      'checkMessageAsRead',
      'errorNotify',
    ]),
    getMessages() {
      if (this.nextPageToken === null) return;

      this.scrollIsDisabled = true;
      this.fetchMessages({
        conversationId: this.conversationId,
        pageToken: this.nextPageToken,
      }).then(res => {
        this.nextPageToken =
          this.nextPageToken === res.next_page_token
            ? null
            : res.next_page_token;
        if (this.listIsShifted) {
          this.items = uniqBy([...this.items, ...res.items], 'id');
        } else {
          this.items = [...this.items, ...res.items];
        }

        this.scrollIsDisabled = false;
      });
    },
    reloadDialog(id) {
      if (id) {
        this.listIsShifted = false;
        this.items = [];

        this.getConversationInfo(id)
          .then(res => {
            this.conversationInfo = { ...res };
            this.$refs.msgTextarea.focus();
            this.messageText = '';
            this.$emit('updateChatInfo', { ...res });
          })
          .then(() =>
            this.fetchMessages({
              conversationId: id,
            })
          )
          .then(res => {
            this.items = [...res.items];
            this.scrollDialogToBottom();

            this.nextPageToken = res.next_page_token;

            if (this.activeConversationUnreadCount) {
              return this.checkConversationAsRead(id);
            }
          })
          .catch(error => {
            this.errorNotify(error);
            this.items = [];
            this.conversationInfo = null;
            this.messageText = '';
          });
      } else {
        this.items = [];
        this.conversationInfo = null;
      }
    },
    textareaOnKeypress(event) {
      if (event.shiftKey || event.ctrlKey) {
        return;
      }
      event.preventDefault();
      this.sendMsg();
    },
    sendMsg() {
      this.listIsShifted = true;
      if (this.conversationId && this.messageText.trim()) {
        const id = this.conversationId;
        const text = this.messageText.trim();

        const date = this.msecToSec(Date.now());
        const newMsg = {
          attachments: null,
          read: false,
          sender: this.username,
          sender_avatar: this.avatar,
          sent_date: date,
          text: text,
          isShadowMsg: true,
        };

        this.items = [newMsg, ...this.items];
        this.scrollDialogToBottom();

        this.sendMessage({
          id,
          data: {
            text,
            sent_date: date,
          },
        })
          .then(res => {
            newMsg.id = res.id;
            newMsg.chat_id = res.chat_id;
            newMsg.isShadowMsg = false;
          })
          .catch(this.errorNotify);
        this.messageText = '';
      }
      return false;
    },
    deleteMsg() {
      if (this.optionsMsg) {
        this.listIsShifted = true;
        this.optionsPosition = null; // hide dropbox
        this.optionsMsg.isLoading = true;

        this.deleteMessage({
          chatId: this.optionsMsg.chat_id,
          msgId: this.optionsMsg.id,
        })
          .then(() => {
            const index = this.items.indexOf(this.optionsMsg);
            this.items.splice(index, 1); // remove item from list
          })
          .catch(this.errorNotify);
      }
    },
    setDropdownOptions(event, msgData = null) {
      this.optionsPosition = event.target.getBoundingClientRect();
      this.optionsMsg = msgData;
    },
    scrollDialogToBottom() {
      if (this.$refs.chatDialog) {
        this.$refs.chatDialog.scrollTop = 0xf ** 0xf; // very big number [437893890380859400]
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-dialog-popover-form {
  textarea {
    $textarea-padding-y: 12px;

    padding: $textarea-padding-y 20px;
    resize: none;
    min-height: calc(3rem + #{$textarea-padding-y * 2}); // 24 * 2 + 12*2
    height: auto;
    transition: min-height 0.1s, height 0.1s;
    border-radius: 0 0 ($popover-border-radius - 1) ($popover-border-radius - 1);
  }

  .chat-dialog-popover-submit {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 12px 6px !important;
    opacity: 0.5;
    transition: opacity 0.1s;
    &:hover {
      opacity: 0.8;
    }
    &.active {
      opacity: 1;
    }
  }
}

.msg-not-my {
  margin-bottom: 1rem;
  & + .msg-not-my {
    margin-bottom: 2px;
    .avatar {
      visibility: hidden;
    }
  }
}

.msg-my {
  margin-bottom: 1rem;
  & + .msg-my {
    margin-bottom: 2px;
    .avatar {
      visibility: hidden;
    }
  }
}

.msg-popover-wrap {
  transition: opacity 0.1s;
  &.msg-muted {
    opacity: 0.7;
    pointer-events: none;
  }

  .msg {
    $msg-background: #eee;
    $msg-background-my: #ddd;

    width: auto;
    min-width: 96px;
    max-width: 280px;
    padding: 12px 12px 4px 12px;

    border-radius: 2px;
    min-height: 54px;
    position: relative;
    flex: unset;

    .msg-my &,
    .msg-my &:after {
      background-color: $msg-background-my;
    }
    .msg-not-my &,
    .msg-not-my &:after {
      background-color: $msg-background;
    }

    &:after {
      content: '';
      position: absolute;
      z-index: 0;
      left: 0;
      bottom: 0;
      width: 24px;
      height: 22px;
      transform: skewX(-47.5deg);
      border-bottom-left-radius: 6px;

      .msg-not-my + .msg-not-my &,
      .msg-my + .msg-my & {
        content: none;
      }
    }

    .msg-date {
      z-index: 1;
      font-size: 0.625rem;
    }

    .msg-origin {
      & > br:last-of-type {
        display: none;
      }
    }
  }
}

.pt-5rem {
  padding-top: 5rem;
}

.min-h-100 {
  min-height: 100%;
}
</style>
