'use strict';

import { BASE_URL } from '@/api/config.js';

import {
  authorizedGetRequest,
  authorizedDeleteRequest,
} from '@/api/requests.js';

const COMPL_BASE = BASE_URL + 'complaints';

export const complaints = {
  getList: (query = '') =>
    authorizedGetRequest(COMPL_BASE + (query ? `?${query}` : '')),
  getReasons: () => authorizedGetRequest(COMPL_BASE + '/reasons'),
  delete: complaintID =>
    authorizedDeleteRequest(`${COMPL_BASE}/${complaintID}`),
  deleteContent: (contentType, contentID) =>
    authorizedDeleteRequest(`${BASE_URL}${contentType}s/${contentID}`),
};

export default {
  complaints,
};
