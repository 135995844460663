<template>
  <button
    :id="id"
    type="button"
    class="btn"
    :disabled="disabled"
    @click="$emit('click', $event)"
  >
    {{ label }}
  </button>
</template>
<script>
import { uniqueId } from 'lodash';
export default {
  props: {
    label: {
      type: String,
      default: 'Submit',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: uniqueId('btn_'),
    },
  },
};
</script>
<style lang="scss" scoped>
button {
  font-size: 12px;
  font-weight: bold;
  position: relative;
  // min-width: 10em;
}
button.btn.is-loading {
  color: transparent !important;
  pointer-events: none;
}
button.btn.is-loading::after {
  -webkit-animation: spinAround 0.5s infinite linear;
  animation: spinAround 0.5s infinite linear;
  border: 2px solid #dbdbdb;
  border-radius: 290486px;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 1rem;
  position: relative;
  width: 1rem;
}

button.btn.is-loading::after {
  position: absolute;
  left: calc(50% - (1rem / 2));
  top: calc(50% - (1rem / 2));
  position: absolute !important;
}

button.btn.btn-primary.is-loading::after {
  border-color: transparent transparent #fff #fff !important;
}

@-webkit-keyframes spinAround {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spinAround {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
</style>
