<!-- eslint-disable vue/camelcase -->
<template>
  <div>
    <div class="tabs-ext-subs">
      <div
        class="btn px-4"
        :class="
          activeTab === 'report'
            ? 'border-primary btn-primary'
            : 'border btn-light'
        "
        @click="setTabState('report')"
      >
        External subscription report
      </div>
      <div
        class="btn px-4"
        :class="
          activeTab === 'audit'
            ? 'border-primary btn-primary'
            : 'border btn-light'
        "
        @click="setTabState('audit')"
      >
        External subscription audit trails
      </div>
    </div>
    <Report v-if="activeTab === 'report'" />
    <AuditTrail v-if="activeTab === 'audit'" />
  </div>
</template>

<script>
import Report from './Report.vue';
import AuditTrail from './AuditTrail.vue';
export default {
  name: 'ExtSubs',
  components: { Report, AuditTrail },

  data() {
    return {
      activeTab: 'report',
    };
  },
  methods: {
    setTabState(state) {
      this.activeTab = state;
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs-ext-subs {
  margin-top: 10px;
  display: flex;
  width: 100%;
  justify-content: center;
}
.btn-tab {
  height: 40px;
  background: transparent;
  border: none;
  display: flex;
  flex-basis: 50%;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  cursor: pointer;
}
.btn-ext-active {
  border-bottom: 2px solid lightblue;
}
</style>
