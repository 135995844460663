'use strict';

import { BASE_URL } from '@/api/config.js';

import { authorizedGetRequest, authorizedPutRequest } from '@/api/requests.js';

const LEADERBOARDS_BASE = BASE_URL + 'leaderboards/';
const RANKS = LEADERBOARDS_BASE + 'ranks';

export const putRanks = authorizedPutRequest(RANKS);

export const getRanks = authorizedGetRequest(RANKS);

export default {
  putRanks,
  getRanks,
};
