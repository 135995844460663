<template>
  <Draggable
    v-model="valueModel"
    :element="containerTag"
    class="sortable-list"
    ghost-class="sortable-list_item--ghost"
    handle=".sortable-list_handle"
    :animation="200"
    @start="onStartDragging"
    @end="onStopDragging"
  >
    <component
      :is="itemTag"
      v-for="item in valueModel"
      :key="item.key"
      class="sortable-list_item"
    >
      <component :is="handleTag" class="cursor-move sortable-list_handle">
        <Icon
          name="draggable"
          width="24"
          :fill="$scss.secondaryBackgroundColor"
        />
      </component>
      <slot name="item" v-bind="{ item }" />
    </component>
  </Draggable>
</template>

<script>
import Draggable from 'vuedraggable';
import Icon from '@/components/common/Icon';

export default {
  components: {
    Draggable,
    Icon,
  },

  props: {
    value: {
      type: Array,
      required: true,
    },
    containerTag: {
      type: String,
      default: 'div',
    },
    itemTag: {
      type: String,
      default: 'div',
    },
    handleTag: {
      type: String,
      default: 'div',
    },
  },

  data() {
    return {
      drag: false,
    };
  },

  computed: {
    valueModel: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
  methods: {
    onStartDragging() {
      this.drag = true;
      this.$emit('start');
    },
    onStopDragging() {
      this.drag = false;
      this.$emit('end');
    },
  },
};
</script>

<style lang="scss">
.sortable-list {
  &_item {
    min-height: 3.25rem;
    border-bottom: 1px solid $hera-strokes-color;

    &--ghost {
      opacity: 0.5;
      background-color: $hera-strokes-color;
    }
  }

  &_handle {
    margin-right: 1.25rem;
  }
  div.sortable-list_item {
    display: flex;
  }
  div.sortable-list_handle {
    display: flex;
    align-items: center;
  }
}
</style>
