import { render, staticRenderFns } from "./PromoCodeInfo.vue?vue&type=template&id=15d15aae&scoped=true&"
import script from "./PromoCodeInfo.vue?vue&type=script&lang=js&"
export * from "./PromoCodeInfo.vue?vue&type=script&lang=js&"
import style0 from "./PromoCodeInfo.vue?vue&type=style&index=0&id=15d15aae&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15d15aae",
  null
  
)

export default component.exports