<template>
  <div class="chat-popover-wrap">
    <button
      class="btn btn-primary rounded-circle chat-popover-toggle"
      @click="togglePopover"
    >
      <icon v-if="popoverChatIsOpened" name="close" size="l" />
      <template v-else>
        <icon name="message-outline" size="l" />
        <Badge v-if="unreadCount" class="badge-light" height="1rem">{{
          unreadCountString
        }}</Badge>
      </template>
    </button>
    <div class="chat-popover" :class="{ open: popoverChatIsOpened }">
      <div class="card h-100">
        <div
          class="card-header bg-primary font-weight-bold d-flex align-items-center"
        >
          <template v-if="chatInfo">
            <div class="cursor-pointer" @click.prevent="clearChatInfo">
              <icon name="back" class="cursor-pointer" width="18" />
            </div>
            <!-- <icon name="back" class="cursor-pointer d-block" @click.prevent="clearChatInfo" width="18"/> -->
            <a
              :href="webLink"
              class="text-light flex-shrink-1 px-2"
              target="_blank"
              style="max-width: calc(100% - 40px)"
            >
              <span
                class="text-decoration-none d-flex align-items-center flex-nowrap"
              >
                <avatar
                  :size="50"
                  :img-url="chatInfo.avatar"
                  class="align-self-end mx-2"
                />
                <span class="d-inline-block w-100 text-truncate">{{
                  chatInfo.title
                }}</span>
                <span v-if="unreadCount">({{ unreadCountString }})</span>
              </span>
            </a>
          </template>
          <span v-else class="text-light">Chats</span>
          <div class="cursor-pointer ml-auto" @click="togglePopover">
            <icon name="close" width="18" />
          </div>
        </div>
        <div v-if="isGroupChat" class="card-body p-0 peoples-search-wrapper">
          <Autosuggest
            ref="userSearch"
            v-model="newParticipant"
            class="w-100 mb-0 peoples-search"
            placeholder="Search for people..."
            display-property="login"
            :source="fetchProfiles"
            @resultPicked="addChatParticipant"
          >
            <span
              slot="result-item"
              slot-scope="{ item }"
              class="w-100 d-flex align-items-center justify-content-start"
            >
              <avatar
                :size="24"
                type="profile"
                :img-url="item.avatar"
                background-fill="#eee"
                class="mr-2"
              />
              <span v-text="item.login"></span>
              <small
                v-if="isParticipant(item.login)"
                class="ml-auto text-secondary"
                >chat member</small
              >
              <icon v-else class="ml-auto fill-primary" name="add" width="12" />
            </span>
          </Autosuggest>
        </div>
        <div
          class="card-body p-0"
          :class="{ 'has-people-search': isGroupChat }"
        >
          <ChatDialogPopover
            v-if="activePopoverChat"
            :chat-id="activePopoverChat"
            @updateChatInfo="updateChatInfo"
          />
          <ChatsList v-else is-popover @setActiveDialog="setDialogId" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { WEB_BASE_URL } from '@/api/config';
import icon from '@/components/common/Icon';
import avatar from '@/components/common/avatar';
import ChatsList from './ChatsList';
import ChatDialogPopover from './ChatDialogPopover';
import Badge from '@/components/common/badge';
import Autosuggest from '@/components/form/HeraAutosuggest';
export default {
  name: 'ChatPopover',
  components: {
    Badge,
    ChatDialogPopover,
    icon,
    avatar,
    ChatsList,
    Autosuggest,
  },
  data() {
    return {
      maxUnreadCount: 10,
      chatInfo: null,
      newParticipant: null,
    };
  },
  computed: {
    ...mapGetters(['unreadCount', 'activePopoverChat', 'popoverChatIsOpened']),
    webLink() {
      if (!this.chatInfo) {
        return '#';
      } else if (this.chatInfo.group_chat) {
        return `${WEB_BASE_URL}clans/${this.chatInfo.id}`;
      }
      return `${WEB_BASE_URL}profiles/${this.chatInfo.title}`;
    },
    unreadCountString() {
      return this.unreadCount > this.maxUnreadCount
        ? `${this.maxUnreadCount}+`
        : this.unreadCount;
    },
    isGroupChat() {
      return this.chatInfo && this.chatInfo.group_chat;
    },
  },
  watch: {
    activePopoverChat(id) {
      if (id && !this.popoverChatIsOpened) {
        this.showPopoverChat();
      }
    },
  },
  methods: {
    ...mapMutations([
      'setActivePopoverChat',
      'removeActivePopoverChat',
      'showPopoverChat',
      'hidePopoverChat',
    ]),
    ...mapActions([
      'getProfiles',
      'updateConversation',
      'successNotify',
      'errorNotify',
    ]),
    setDialogId(dialog) {
      this.setActivePopoverChat(dialog.id);
    },
    updateChatInfo(info) {
      this.chatInfo = { ...info };
    },
    clearChatInfo() {
      this.removeActivePopoverChat();
      this.chatInfo = null;
    },
    suggestProfiles(text) {
      return this.getProfiles({
        options: {
          params: {
            page: null,
            contains: text,
          },
        },
      }).then(response => response.items);
    },
    addChatParticipant(newMember) {
      const newParticipant = newMember.login;
      const participants = [
        ...this.chatInfo.participants.map(el => el.id),
        newParticipant,
      ];
      return (
        this.chatInfo.id &&
        newParticipant &&
        this.updateConversation({
          id: this.chatInfo.id,
          props: { participants },
        })
          .then(() => {
            this.successNotify(`${newParticipant} added to group chat`);
            this.clearNewParticipant();
          })
          .catch(this.errorNotify)
      );
    },
    isParticipant(login) {
      return !!this.chatInfo.participants.find(el => el.id === login);
    },
    clearNewParticipant() {
      this.newParticipant = null;
      if (this.$refs.userSearch) {
        this.$refs.userSearch.clearSearchText();
      }
    },
    togglePopover() {
      if (this.popoverChatIsOpened) {
        this.clearNewParticipant();
      }
      this[this.popoverChatIsOpened ? 'hidePopoverChat' : 'showPopoverChat']();
    },
  },
};
</script>

<style lang="scss">
$button-size: 62px;

.chat-popover-wrap {
  position: fixed;
  z-index: 4;
  bottom: 2rem;
  right: 2rem;
}

.chat-popover-toggle {
  width: $button-size;
  height: $button-size;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);

  .badge {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-100%);
  }
}

.chat-popover {
  position: absolute;
  bottom: $button-size + 18px;
  right: 0;
  width: 370px;
  height: 590px;
  max-height: 60vh;
  display: none;
  border-radius: $popover-border-radius;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);

  &.open {
    display: block;
    .peoples-search {
      max-width: unset;
      input {
        border-top: none;
        border-left: none;
        border-right: none;
        border-radius: 0px;
      }
    }
  }

  & > .card {
    border-radius: $popover-border-radius;
    overflow: hidden;

    .card-header {
      line-height: 54px; // 3.(3) * 16 ~= 54
      border-radius: ($popover-border-radius - 1) ($popover-border-radius - 1) 0
        0;
    }

    .card-body {
      height: calc(100% - 71px);
      border-radius: 0 0 ($popover-border-radius - 1)
        ($popover-border-radius - 1);
      &.peoples-search-wrapper {
        height: 31px;
      }
      &.has-people-search {
        height: calc(100% - 102px);
      }
    }
  }
}

.chat-popover-toggle,
.chat-popover .card-header {
  svg path {
    fill-opacity: 1;
    fill: #fff;
  }
}
</style>
