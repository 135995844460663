import api from '@/api';
import Vue from 'vue';

export const INITIAL_TRANSLATIONS_STATE = () => ({
  translations: {},
});

const getters = {
  translations: state => state.translations,
};

const mutations = {
  addLocaleToStore(state, payload) {
    let keys = Object.keys(payload.data);
    keys.forEach(key => {
      if (!state.translations[key]) {
        Vue.set(state.translations, key, {});
      }
      Vue.set(state.translations[key], payload.locale, payload.data[key]);
    });
  },
  updateTranslation(state, payload) {
    if (!state.translations[payload.key]) {
      Vue.set(state.translations, payload.key, {});
    }
    Vue.set(state.translations[payload.key], payload.locale, payload.value);
  },
  clearToTranslationsDefaultState(state) {
    const defaultState = INITIAL_TRANSLATIONS_STATE();

    Object.keys(defaultState).forEach(key => {
      Vue.set(state, key, defaultState[key]);
    });
  },
};

const actions = {
  getTranslationsLocales() {
    return api.getTranslationsLocales().then(response => response.data.locales);
  },

  getTranslationsByLocale(state, payload) {
    const locales = payload instanceof Array ? [...payload] : [payload];

    return Promise.all(
      locales.map(lang => api.getTranslationByLocale(lang)())
    ).then(res => {
      const translations = {};
      res.forEach(({ data }, i) => {
        const locale = locales[i];
        Object.entries(data.translations).forEach(([key, translation]) => {
          if (!translations[key]) {
            translations[key] = {};
          }
          translations[key][locale] = translation;
        });
      });
      return translations;
    });
  },

  saveTranslation({ dispatch, commit, getters }, translation) {
    commit('updateTranslation', translation);
    return api
      .putTranslation(translation.locale)(
        getters.accessToken,
        { [translation.key]: translation.value },
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then(data => Promise.resolve(data), error => Promise.reject(error))
      .catch(error => {
        dispatch('errorNotify', error);
      });
  },
  bulkSaveTranslationsAction({ dispatch, getters }, translations) {
    return Promise.all(
      // eslint-disable-next-line
      Object.entries(translations).map(([locale, localeTranslations]) => {
        return Promise.all(
          Object.entries(localeTranslations).map(([key, value]) => {
            return api
              .putTranslation(key)(getters.accessToken, value, {
                headers: { 'Content-Type': 'application/json' },
              })
              .then(data => {
                return Promise.resolve(data);
              })
              .catch(error => {
                dispatch('errorNotify', error);
                return Promise.reject(error);
              });
          })
        );
      })
    );
  },
};
export default {
  state: INITIAL_TRANSLATIONS_STATE(),
  getters,
  mutations,
  actions,
};
