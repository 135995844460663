import api from '@/api';

const actions = {
  setFlushCache({ getters: { accessToken } }) {
    return api.flush.flushCache(accessToken);
  },
};

export default {
  actions,
};
