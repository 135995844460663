export const distinctTournamentsStatus = {
  draft: 'Draft',
  announced: 'Announced',
  registration_opened: 'Registration open',
  registration_closed: 'Registration closed',
  started: 'Started',
  closed: 'Closed',
  rejected: 'Rejected',
  archived: 'Archived',
  qualification_completed: 'Qualification Completed',
};
