import store from '@/store';

export const ifNotAuthorized = (to, from, next) => {
  !store.getters.isAuthorized ? next() : next('/signin');
};
export const ifAuthenticated = (to, from, next) => {
  store.getters.isAuthenticated ? next() : next('/signin');
};
export const ifPartiallyAuthorized = (to, from, next) => {
  store.getters.isPartiallyAuthorized &&
  from.name === 'verifyCode' &&
  from.params.from === 'reset'
    ? next()
    : next('/signin');
};
export const ifAuthorized = (to, from, next) => {
  store.getters.isAuthorized ? next() : next('/signin');
};

export default {
  ifNotAuthorized,
  ifAuthenticated,
  ifPartiallyAuthorized,
  ifAuthorized,
};
