<template>
  <div class="page-wrapper organization-edit">
    <div class="container">
      <div class="my-4">
        <h3 class="mb-0 pb-0">
          {{ organizationID ? 'Edit' : 'Create' }} Organiser Account
        </h3>
      </div>
      <div class="card">
        <div class="card-body px-0 d-flex flex-wrap">
          <div class="col">
            <label>Organiser cover</label>
            <div class="outer cover my-2">
              <div
                :style="{
                  backgroundImage: `url(${getImageUrl('cover')})`,
                  backgroundSize: hasImage('cover') ? 'cover' : 'auto',
                }"
                class="inner"
                :class="{
                  'has-image': hasImage('cover'),
                  'is-invalid': errors.first('cover'),
                }"
              ></div>
            </div>
            <fileBase64
              id="cover"
              :preupload="false"
              :accept="[
                'png',
                'bmp',
                'jpg',
                'jpeg',
                'PNG',
                'BMP',
                'JPG',
                'JPEG',
              ]"
              name="cover"
              :size-ceil="30000"
              :disabled="isInputsDisabled"
              @filesSelected="imageSelected('cover', $event)"
            />
          </div>
          <div class="col-6 col-lg-2">
            <label>Organiser avatar</label>
            <avatar
              class="avatar mb-2 d-block"
              :size="60"
              :img-url="getImageUrl('avatar')"
              background-fill="#BBB"
            />
            <fileBase64
              id="avatar"
              :preupload="false"
              :accept="[
                'png',
                'bmp',
                'jpg',
                'jpeg',
                'PNG',
                'BMP',
                'JPG',
                'JPEG',
              ]"
              name="avatar"
              :size-ceil="30000"
              :disabled="isInputsDisabled"
              @filesSelected="imageSelected('avatar', $event)"
            />
          </div>
          <div class="col-6 col-lg-3">
            <TextInput
              id="organization-name"
              v-model="organization.name"
              v-validate="'required'"
              required
              class="w-100"
              :class="{ 'is-invalid': errors.first('league_title') }"
              label="Name:"
              name="organization-name"
              data-vv-as="Name"
              data-vv-name="organization-name"
              :error="errors.first('organization-name')"
              :disabled="isInputsDisabled"
            />
            <AutoSuggest
              id="country"
              v-model="organization.country"
              v-validate="'required'"
              class="mw-100"
              required
              label="Country:"
              name="country"
              placeholder="Start typing..."
              display-property="text"
              data-vv-name="country"
              :source="suggestCountriesRequest"
              :error="errors.first('country')"
              :disabled="isInputsDisabled"
            />
            <AutoSuggest
              id="city"
              v-model="organization.city"
              class="mw-100"
              label="City:"
              name="city"
              placeholder="Start typing..."
              display-property="text"
              data-vv-name="city"
              data-vv-as="City"
              :source="suggestCity"
              :disabled="!organization.country || isInputsDisabled"
              :error="errors.first('city')"
            />
          </div>
          <div class="col-6 col-lg-3">
            <div class="form-group">
              <label class="label-required-field">Type:</label>
              <Dropdown
                v-model="organization.orgType"
                v-validate="'required'"
                required
                empty-option
                class="input-like w-100"
                :class="{ 'is-invalid': errors.first('type') }"
                data-vv-name="type"
                :list="organizationTypes"
                :disabled="isInputsDisabled"
                :is-loading="!organizationTypes.length"
              />
              <span
                v-show="errors.first('type')"
                id="platformsHelp"
                class="form-text text-danger error-text"
                v-text="'Please select type of organization'"
              ></span>
            </div>
            <AutoSuggest
              id="owner"
              v-model="organization.owner"
              v-validate="'required'"
              class="mw-100"
              required
              name="owner"
              label="Owner:"
              placeholder="Start typing..."
              display-property="login"
              data-vv-as="Owner"
              data-vv-name="owner"
              :source="suggestProfiles"
              :error="errors.first('owner')"
              :disabled="isInputsDisabled"
            >
              <template slot="result-item" slot-scope="owner">
                {{ owner.item.login }}
              </template>
            </AutoSuggest>
          </div>

          <div class="col-6 mt-4 pt-4 border-top">
            <h5>Organization role:</h5>
            <Checkbox
              v-for="role in roles"
              :key="role.id"
              v-model="role.isActive"
              :label="role.name"
              :disabled="isInputsDisabled"
            />
          </div>
          <div class="col-6 mt-4 pt-4 border-top border-left">
            <h5>Share posts to PH accounts:</h5>
            <Checkbox
              v-for="(value, name) in repostOptions"
              :key="name"
              v-model="repostOptions[name].value"
              :label="repostOptions[name].label"
              :disabled="isInputsDisabled"
            />
          </div>
        </div>
        <div class="card-footer bg-transparent text-right">
          <button
            v-if="organizationID"
            class="btn btn-link text-danger text-center mr-2"
            @click="remove"
          >
            Remove
          </button>
          <router-link
            :to="{ name: 'organizationList' }"
            class="btn btn-link text-danger text-center mr-2"
          >
            Cancel
          </router-link>
          <button
            class="btn btn-primary text-center"
            :class="{ 'is-loading': isLoading }"
            :disabled="isInputsDisabled"
            @click="save"
          >
            {{ organizationID ? 'Save' : 'Create' }}
          </button>
        </div>
        <div
          v-if="isPreloaderShow"
          class="preloader position-absolute d-flex justify-content-center align-items-center"
        >
          <div class="spinner"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { isEqual, cloneDeep, get } from 'lodash';
import { diff } from '@/mixins/helpers';
import avatar from '@/components/common/avatar';
import fileBase64 from '@/components/form/fileBase64';
import imgPlaceholder from '@/assets/images/image-placeholder.png';
import TextInput from '@/components/form/HeraFormTextInput';
import Dropdown from '@/components/form/HeraFormDropdown';
import AutoSuggest from '@/components/form/HeraAutosuggest';
import Checkbox from '@/components/form/HeraFormCheckbox';

export default {
  name: 'OrganizationEdit',
  inject: ['$validator'],
  components: {
    avatar,
    fileBase64,
    TextInput,
    Dropdown,
    AutoSuggest,
    Checkbox,
  },
  mixins: [diff],
  data() {
    return {
      isLoading: false,
      isRolesLoading: false,
      isOrganizationLoaded: false,
      organization: {
        name: null,
        orgType: null,
        country: null,
        city: null,
        avatar: null,
        cover: null,
        owner: null,
      },
      roles: [],
      repostOptions: {
        repostToFacebook: {
          value: false,
          label: 'Facebook',
        },
        repostToTwitter: {
          value: false,
          label: 'Twitter',
        },
      },
      organizationBackup: {},
      rolesBackup: {},
      repostOptionsBackup: {},
    };
  },

  computed: {
    ...mapState({
      organizationTypes: state => state.organization.organizationTypes,
    }),
    organizationID() {
      return this.$route.params.organizationID || '';
    },
    isInputsDisabled() {
      return this.isLoading || this.isRolesLoading;
    },
    isPreloaderShow() {
      return (
        this.organizationID &&
        (!this.isOrganizationLoaded || this.isRolesLoading)
      );
    },
  },

  created() {
    this.fetchOrganization();
    this.fetchRoles();
    this.fetchRepostsSettings(this.organizationID)
      .then(response => {
        this.repostOptionsBackup = response;
        for (const key in response) {
          if (this.repostOptions[key]) {
            this.repostOptions[key].value = response[key];
          }
        }
      })
      .catch(this.errorNotify);
  },
  methods: {
    ...mapActions([
      'suggestCountriesRequest',
      'suggestCitiesRequest',
      'successNotify',
      'errorNotify',
      'suggestProfiles',
      'getOrganization',
      'createOrganization',
      'updateOrganization',
      'getAvailableRoles',
      'getOrganizationRoles',
      'deleteOrganization',
      'fetchRepostsSettings',
      'saveRepostsSettings',
    ]),

    imageSelected(key, [image]) {
      this.$set(this.organization, key, { ...image });
    },
    getImageUrl(imageType) {
      return typeof this.organization[imageType] === 'string'
        ? this.organization[imageType]
        : get(this, `organization[${imageType}].output`) || imgPlaceholder;
    },
    hasImage(image) {
      const url = this.getImageUrl(image);
      return !!url && url !== imgPlaceholder;
    },
    suggestCity(city) {
      let payload = {
        countryId: this.organization.country.id,
        query: city,
      };
      return this.suggestCitiesRequest(payload);
    },

    /**
     * get organization data
     */
    fetchOrganization() {
      if (this.organizationID) {
        this.getOrganization(this.organizationID)
          .then(this.setOrgData)
          .catch(this.errorNotify)
          .finally(() => (this.isLoading = false));
      }
    },

    setOrgData(data) {
      this.organization.name = data.name || null;
      this.organization.country = data.country
        ? {
            id: data.country_id,
            text: data.country,
            value: data.country,
          }
        : null;
      this.organization.city = data.city
        ? {
            id: data.city_id,
            text: data.city,
            value: data.city,
          }
        : null;
      this.organization.cover = data.cover || null;
      this.organization.avatar = data.avatar || null;
      this.organization.orgType =
        cloneDeep(
          this.organizationTypes.find(
            item => item.value === data.organizationType
          )
        ) || null;
      this.organization.owner = data.owner ? { login: data.owner } : null;

      this.organizationBackup = cloneDeep(this.organization);

      this.isOrganizationLoaded = true;
    },

    getDataForSave() {
      const fields = [
        { key: 'name', path: 'name' },
        { key: 'organizationType', path: 'orgType.value' },
        { key: 'country', path: 'country.value' },
        { key: 'country_id', path: 'country.id' },
        { key: 'city', path: 'city.value' },
        { key: 'city_id', path: 'city.id' },
        { key: 'avatar', path: 'avatar' },
        { key: 'cover', path: 'cover' },
        { key: 'owner', path: 'owner.login' },
      ];

      const data = {};

      fields.forEach(({ key, path }) => {
        const value = get(this.organization, path);
        const backupValue = get(this.organizationBackup, path);

        if (value === backupValue) {
          return;
        }

        data[key] = value;
      });

      if (!isEqual(this.rolesBackup, this.roles) && this.roles.length) {
        data.roles = this.roles
          .filter(role => role.isActive)
          .map(({ id }) => id);
      }
      if (this.organizationID) {
        data.id = this.organizationID;
      }
      return data;
    },

    save() {
      const repostsModel = Object.keys(this.repostOptions).reduce(
        (acc, key) => {
          acc[key] = this.repostOptions[key].value;
          return acc;
        },
        {}
      );
      const needToSaveRepostSettings = !isEqual(
        this.repostOptionsBackup,
        repostsModel
      );
      if (
        isEqual(this.organizationBackup, this.organization) &&
        isEqual(this.rolesBackup, this.roles) &&
        !needToSaveRepostSettings
      ) {
        return this.$router.push({ name: 'organizationList' });
      }
      if (needToSaveRepostSettings) {
        this.saveRepostsSettings({
          profileId: this.organizationID,
          data: repostsModel,
        }).catch(this.errorNotify);
      }
      this.isLoading = true;
      this.$validator
        .validateAll()
        .then(isValid =>
          isValid
            ? (this.organizationID
                ? this.updateOrganization
                : this.createOrganization)(this.getDataForSave())
            : Promise.reject('Form validation failed')
        )
        .then(() => {
          this.successNotify(
            this.organizationID
              ? 'Organisation was successful updated'
              : 'Organisation was successful created'
          );
          this.$router.push({ name: 'organizationList' });
        })
        .catch(this.errorNotify)
        .finally(() => (this.isLoading = false));
    },
    /**
     * click on remove button
     */
    remove() {
      if (confirm('Are you sure wont to delete this organisation?')) {
        this.isLoading = true;
        this.deleteOrganization(this.organizationID)
          .then(() => {
            this.successNotify('Organisation was successful deleted');
            this.$router.push({ name: 'organizationList' });
          })
          .catch(this.errorNotify)
          .finally(() => (this.isLoading = false));
      }
    },

    fetchRoles() {
      this.isRolesLoading = true;
      this.getAvailableRoles()
        .then(
          roles =>
            (this.roles = roles.map(role => ({
              ...cloneDeep(role),
              isActive: false,
            })))
        )
        .then(() =>
          this.organizationID
            ? this.getOrganizationRoles(this.organizationID)
            : Promise.resolve([])
        )
        .then(organizationRoles =>
          organizationRoles.forEach(roleId => {
            const currentRole = this.roles.find(({ id }) => id === roleId);
            if (currentRole) {
              currentRole.isActive = true;
            }
          })
        )
        .then(() => (this.rolesBackup = cloneDeep(this.roles)))
        .catch(this.errorNotify)
        .finally(() => (this.isRolesLoading = false));
    },
  },
};
</script>

<style lang="scss">
.organization-edit {
  .outer {
    position: relative;
    width: 100%;
    background-color: $hera-cover-bg;
    border-radius: 0.25rem; // 4px / 16px
    overflow: hidden;

    &::before {
      display: block;
      content: '';
      width: 100%;
    }

    > .inner {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px solid transparent;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      &.has-image {
        background-color: transparent;
      }

      &.is-invalid {
        border-color: $danger !important;
      }
    }

    &.cover {
      width: 250px;

      &::before {
        padding-top: (141 / 250) * 100%;
      }
    }
  }

  .switcher-label {
    font-size: 0.75rem;
  }

  .autosuggest_label {
    padding-left: 0 !important; //for replace boostrap class ml-1
  }

  .preloader {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $modal-backdrop-color;
    z-index: 2;
    border-radius: inherit;
  }

  .spinner {
    $size: 3.5rem;
    width: $size;
    height: $size;
    border: 2px solid transparent;
    border-left-color: $primary;
    border-bottom-color: $primary;
    border-radius: 50%;
    transform: translateX(-50%);
    animation: spinAround 0.5s infinite linear;
  }
}
</style>
