<!-- eslint-disable vue/no-parsing-error -->
<!-- eslint-disable vue/camelcase -->
<template>
  <div class="container-fluid">
    <div class="row my-4 align-items-center">
      <h3 class="mb-0 pb-0 col">External subscription report</h3>
    </div>
    <div class="row">
      <div class="col">
        <div class="card mt-4">
          <div class="card-body">
            <form @submit.prevent="applyFilters">
              <div class="form-row">
                <div class="form-group col-md-3">
                  <label for="externalId">External subscription ID</label>
                  <input
                    id="externalId"
                    v-model="externalId"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="form-group col-md-3">
                  <label for="internalUserId">Playhera username</label>
                  <input
                    id="internalUserId"
                    v-model="internalUserId"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="form-group col-md-3">
                  <label for="merchantUserId">Merchant user id</label>
                  <input
                    id="merchantUserId"
                    v-model="merchantUserId"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="form-group col-md-3">
                  <label for="merchant">Merchant</label>
                  <input
                    id="merchant"
                    v-model="merchant"
                    type="text"
                    class="form-control"
                  />
                </div>
                <!--  -->
                <div class="form-group col-md-3">
                  <label for="isActivated">Is activated</label>
                  <select
                    id="isActivated"
                    v-model="isActivated"
                    class="form-control"
                  >
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                </div>
                <div class="form-group col-md-3">
                  <label for="isExpired">Is expired</label>
                  <select
                    id="isExpired"
                    v-model="isExpired"
                    class="form-control"
                  >
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                </div>
                <div class="form-group col-md-3">
                  <label for="dateFrom">From date</label>
                  <input
                    id="dateFrom"
                    v-model="dateFrom"
                    type="date"
                    class="form-control"
                  />
                </div>
                <div class="form-group col-md-3">
                  <label for="dateTo">To date</label>
                  <input
                    id="dateTo"
                    v-model="dateTo"
                    type="date"
                    class="form-control"
                  />
                </div>
                <div class="form-group col-md-3">
                  <label for="CreatedAt">Creation date from</label>
                  <input
                    id="CreatedAt"
                    v-model="CreatedAt"
                    type="date"
                    class="form-control"
                  />
                </div>
                <div class="form-group col-md-3">
                  <label for="CreatedTo">Creation date to</label>
                  <input
                    id="CreatedTo"
                    v-model="CreatedTo"
                    type="date"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="invoices-button">
                <div :class="{ disabled: isLoading }">
                  <button
                    type="submit"
                    class="btn btn-primary mr-3"
                    :class="{ disabled: isLoading }"
                  >
                    Apply Filters
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    :class="{ disabled: isLoading }"
                    @click="clearFilters"
                  >
                    Clear Filters
                  </button>
                </div>
              </div>
            </form>
            <div class="flush-div">
              <button
                :class="{ 'is-loading': isExportingAll }"
                class="btn btn-primary ml-auto mr-4"
                @click="exportAll"
              >
                Export to XLS
              </button>
            </div>
          </div>
        </div>

        <div class="card">
          <div
            class="card-body border-top border-muted pt-0 px-0 overflow-auto"
          >
            <table class="table table-hover mx-0 px-0 subscriptions-list">
              <caption v-if="isLoading" class="text-center text-muted">
                Fetching external reports...
              </caption>
              <caption
                v-else-if="!reportList.length"
                class="text-center text-muted"
              >
                No reports to show
              </caption>
              <template v-else>
                <thead>
                  <tr>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      external subscription id
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description cur-pointer"
                    >
                      <div
                        class="d-flex justify-content-center align-items-center"
                      >
                        <img
                          class="arrow-sort"
                          :class="
                            sort === 'created_on:asc' ? 'arrow-sort-active' : ''
                          "
                          :src="
                            require('../../../../assets/images/icons/up-arrow.svg')
                          "
                          alt="sorting"
                          @click="handleSortOpt('created_on', 'asc')"
                        />
                        <img
                          class="arrow-sort"
                          :class="
                            sort === 'created_on:desc'
                              ? 'arrow-sort-active'
                              : ''
                          "
                          :src="
                            require('../../../../assets/images/icons/down-arrow.svg')
                          "
                          alt="sorting"
                          @click="handleSortOpt('created_on', 'desc')"
                        />
                      </div>
                      <br />
                      CREATION DATE&TIME
                      <!--arrow to show sorting direction -->
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description cur-pointer"
                    >
                      <div
                        class="d-flex justify-content-center align-items-center"
                      >
                        <img
                          class="arrow-sort"
                          :class="
                            sort === 'internal_user_id:asc'
                              ? 'arrow-sort-active'
                              : ''
                          "
                          :src="
                            require('../../../../assets/images/icons/up-arrow.svg')
                          "
                          alt="sorting"
                          @click="handleSortOpt('internal_user_id', 'asc')"
                        />
                        <img
                          class="arrow-sort"
                          :class="
                            sort === 'internal_user_id:desc'
                              ? 'arrow-sort-active'
                              : ''
                          "
                          :src="
                            require('../../../../assets/images/icons/down-arrow.svg')
                          "
                          alt="sorting"
                          @click="handleSortOpt('internal_user_id', 'desc')"
                        />
                      </div>
                      <br />
                      PLAYHERA USERNAME
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description cur-pointer"
                    >
                      <div
                        class="d-flex justify-content-center align-items-center"
                      >
                        <img
                          class="arrow-sort"
                          :class="
                            sort === 'is_activated:asc'
                              ? 'arrow-sort-active'
                              : ''
                          "
                          :src="
                            require('../../../../assets/images/icons/up-arrow.svg')
                          "
                          alt="sorting"
                          @click="handleSortOpt('is_activated', 'asc')"
                        />
                        <img
                          class="arrow-sort"
                          :class="
                            sort === 'is_activated:desc'
                              ? 'arrow-sort-active'
                              : ''
                          "
                          :src="
                            require('../../../../assets/images/icons/down-arrow.svg')
                          "
                          alt="sorting"
                          @click="handleSortOpt('is_activated', 'desc')"
                        />
                      </div>
                      <br />
                      is activated
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description cur-pointer"
                    >
                      <div
                        class="d-flex justify-content-center align-items-center"
                      >
                        <img
                          class="arrow-sort"
                          :class="
                            sort === 'is_expired:asc' ? 'arrow-sort-active' : ''
                          "
                          :src="
                            require('../../../../assets/images/icons/up-arrow.svg')
                          "
                          alt="sorting"
                          @click="handleSortOpt('is_expired', 'asc')"
                        />
                        <img
                          class="arrow-sort"
                          :class="
                            sort === 'is_expired:desc'
                              ? 'arrow-sort-active'
                              : ''
                          "
                          :src="
                            require('../../../../assets/images/icons/down-arrow.svg')
                          "
                          alt="sorting"
                          @click="handleSortOpt('is_expired', 'desc')"
                        />
                      </div>
                      <br />
                      is expired
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description cur-pointer"
                    >
                      <div
                        class="d-flex justify-content-center align-items-center"
                      >
                        <img
                          class="arrow-sort"
                          :class="
                            sort === 'merchant:asc' ? 'arrow-sort-active' : ''
                          "
                          :src="
                            require('../../../../assets/images/icons/up-arrow.svg')
                          "
                          alt="sorting"
                          @click="handleSortOpt('merchant', 'asc')"
                        />
                        <img
                          class="arrow-sort"
                          :class="
                            sort === 'merchant:desc' ? 'arrow-sort-active' : ''
                          "
                          :src="
                            require('../../../../assets/images/icons/down-arrow.svg')
                          "
                          alt="sorting"
                          @click="handleSortOpt('merchant', 'desc')"
                        />
                      </div>
                      <br />
                      merchant
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      merchant user id
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description cur-pointer"
                    >
                      <div
                        class="d-flex justify-content-center align-items-center"
                      >
                        <img
                          class="arrow-sort"
                          :class="
                            sort === 'from_date_time:asc'
                              ? 'arrow-sort-active'
                              : ''
                          "
                          :src="
                            require('../../../../assets/images/icons/up-arrow.svg')
                          "
                          alt="sorting"
                          @click="handleSortOpt('from_date_time', 'asc')"
                        />
                        <img
                          class="arrow-sort"
                          :class="
                            sort === 'from_date_time:desc'
                              ? 'arrow-sort-active'
                              : ''
                          "
                          :src="
                            require('../../../../assets/images/icons/down-arrow.svg')
                          "
                          alt="sorting"
                          @click="handleSortOpt('from_date_time', 'desc')"
                        />
                      </div>
                      <br />
                      FROM DATE&TIME
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description cur-pointer"
                    >
                      <div
                        class="d-flex justify-content-center align-items-center"
                      >
                        <img
                          class="arrow-sort"
                          :class="
                            sort === 'to_date_time:asc'
                              ? 'arrow-sort-active'
                              : ''
                          "
                          :src="
                            require('../../../../assets/images/icons/up-arrow.svg')
                          "
                          alt="sorting"
                          @click="handleSortOpt('to_date_time', 'asc')"
                        />
                        <img
                          class="arrow-sort"
                          :class="
                            sort === 'to_date_time:desc'
                              ? 'arrow-sort-active'
                              : ''
                          "
                          :src="
                            require('../../../../assets/images/icons/down-arrow.svg')
                          "
                          alt="sorting"
                          @click="handleSortOpt('to_date_time', 'desc')"
                        />
                      </div>
                      <br />
                      TO DATE&TIME
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <ReportListItem
                    v-for="report in reportList"
                    :key="report.external_id"
                    v-bind="{ report }"
                  /></tbody
              ></template>
            </table>
          </div>
          <nav v-if="total > 0 && !isLoading" class="mt-3">
            <ul class="pagination justify-content-center">
              <li
                class="page-item"
                :class="{ disabled: isLoading || isFirstPage }"
              >
                <a class="page-link" href="#" @click.prevent="prevPage"
                  >Previous</a
                >
              </li>
              <li class="page-item">
                <span class="page-link"
                  >{{ currentPage + 1 }} / {{ totalPages }}</span
                >
              </li>
              <li
                class="page-item"
                :class="{ disabled: isLoading || isLastPage }"
              >
                <a
                  class="page-link"
                  href="#"
                  @click.prevent="goToPage(currentPage + 1)"
                  >Next</a
                >
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import ReportListItem from './ListItem.vue';

export default {
  name: 'Report',
  components: { ReportListItem },
  data() {
    return {
      nextPageToken: undefined,
      prevPageToken: undefined,
      isExportingAll: false,
      total: 0,
      reportList: [],
      currentPage: 0,
      page: null,
      page_size: null,
      isLoading: false,
      externalId: '',
      internalUserId: '',
      merchantUserId: '',
      merchant: '',
      isActivated: null,
      isExpired: null,
      dateFrom: null,
      dateTo: null,
      CreatedTo: null,
      CreatedAt: null,
      sort: null,
      sortedBy: null,
    };
  },
  computed: {
    needFetch() {
      return Boolean(
        this.externalId ||
          this.internalUserId ||
          this.merchantUserId ||
          this.merchant ||
          this.isActivated ||
          this.isExpired ||
          this.dateFrom ||
          this.dateTo ||
          this.CreatedTo ||
          this.CreatedAt ||
          this.sort
      );
    },
    totalPages() {
      return Math.ceil(this.total / this.page_size);
    },
    isFirstPage() {
      return this.currentPage === 0;
    },
    isLastPage() {
      return this.currentPage === this.totalPages - 1;
    },
  },

  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions([
      'fetchExternalReports',
      'fetchExtAudit',
      'errorNotify',
      'successNotify',
      'exportAllReports',
    ]),

    handleSort(sort) {
      if (this.sort === `${sort}:asc`) {
        this.sort = null;
        this.sortedBy = null;
        this.fetchData();
        return;
      }
      if (this.sortedBy === sort) {
        this.sort = `${sort}:asc`;
        this.sortedBy = sort;
        this.fetchData();
        return;
      }
      this.sort = `${sort}:desc`;
      this.sortedBy = sort;
      this.fetchData();
    },

    handleSortOpt(sort, dir) {
      if (this.sort === `${sort}:${dir}`) {
        this.sort = null;
        this.sortedBy = null;
        this.fetchData();
        return;
      }
      // if (this.sortedBy === sort) {
      //   this.sort = `${sort}:${dir}`;
      //   this.sortedBy = sort;
      //   this.fetchData();
      //   return;
      // }
      this.sort = `${sort}:${dir}`;
      this.sortedBy = sort;
      this.fetchData();
    },

    exportAll() {
      this.isExportingAll = true;
      const filterProps = {
        merchant: this.merchant,
        from_date_time_start: new Date(this.dateFrom).getTime() / 1000,
        to_date_time_end: new Date(this.dateTo).getTime() / 1000,
        merchant_user_id: this.merchantUserId,
        is_expired: this.isExpired,
        is_activated: this.isActivated,
        ph_user_id: this.internalUserId,
        subscription_id: this.externalId,
        created_on_start: new Date(this.CreatedAt).getTime() / 1000,
        created_on_end: new Date(this.CreatedTo).getTime() / 1000,
        orderBy: this.sort,
        page_size: this.total,
      };

      const filteredProps = Object.fromEntries(
        Object.entries(filterProps).filter(
          // eslint-disable-next-line no-unused-vars
          ([_, value]) => value !== '' && value !== null && value !== 0
        )
      );
      this.exportAllReports({ props: filteredProps })
        .catch(() => this.errorNotify('export failed'))
        .finally(() => (this.isExportingAll = false));
    },
    async fetchData() {
      this.isLoading = true;

      const filterProps = {
        merchant: this.merchant,
        from_date_time_start: new Date(this.dateFrom).getTime() / 1000,
        to_date_time_end: new Date(this.dateTo).getTime() / 1000,
        merchant_user_id: this.merchantUserId,
        is_expired: this.isExpired,
        is_activated: this.isActivated,
        ph_user_id: this.internalUserId,
        subscription_id: this.externalId,
        created_on_start: new Date(this.CreatedAt).getTime() / 1000,
        created_on_end: new Date(this.CreatedTo).getTime() / 1000,
        orderBy: this.sort,
      };

      const filteredProps = Object.fromEntries(
        Object.entries(filterProps).filter(
          // eslint-disable-next-line no-unused-vars
          ([_, value]) => value !== '' && value !== null && value !== 0
        )
      );

      this.fetchExternalReports({
        pageToken: this.currentPage,
        props: filteredProps,
      })
        .then(res => {
          this.total = res.total;
          this.page_size = res.page_size;

          if (res.items) {
            this.reportList = res.items;
            this.isLoading = false;
          }
        })
        .catch(this.errorNotify)
        .finally(() => {
          this.isLoading = false;
        });
    },

    applyFilters() {
      if (!this.needFetch) {
        return;
      }
      this.isLoading = true;
      this.currentPage = 0;
      this.fetchData();
    },

    flush() {
      this.setFlushCache();
    },

    clearFilters() {
      if (!this.needFetch) {
        return;
      }
      (this.externalId = ''),
        (this.internalUserId = ''),
        (this.merchantUserId = ''),
        (this.merchant = ''),
        (this.isActivated = null),
        (this.isExpired = null),
        (this.dateFrom = null),
        (this.dateTo = null),
        (this.CreatedTo = null),
        (this.CreatedAt = null),
        this.fetchData();
    },

    downloadInvoice({ id, invoiceId }) {
      this.isExporting = true;

      this.exportInvoice({
        id,
        invoiceId,
      })
        .then(() => {})
        .catch(this.errorNotify)
        .finally(() => {
          this.isExporting = false;
        });
    },

    generateSaudiTime(date, isEndDate) {
      if (!date) {
        return null;
      }

      const saudiTimeZone = 'Asia/Riyadh'; // Saudi Arabia's time zone
      const dateObject = new Date(date); // Convert the string to a Date object

      // If isEndDate is true, set the time to 23:59:59 to represent the end of the day
      if (isEndDate) {
        dateObject.setHours(23, 59, 59, 999);
      } else {
        dateObject.setHours(0, 0, 0, 0);
      }

      const saudiTimestamp = dateObject.toLocaleString('en-US', {
        timeZone: saudiTimeZone,
      });
      return new Date(saudiTimestamp).getTime(); // Get the timestamp in milliseconds
    },

    prevPage() {
      if (this.currentPage > 0) {
        this.currentPage--;
        this.fetchData();
      }
    },

    goToPage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.currentPage = page;
        this.fetchData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-item {
  cursor: not-allowed;
}
.cur-pointer {
  cursor: pointer;
}
.arrow-sort {
  width: 10px;
  height: 10px;
  margin-left: -40px;
}
.page-link:hover {
  background-color: white !important;
}

.invoices-button {
  display: flex;
  justify-content: space-between;
}

.flush-div {
  display: flex;
  justify-content: flex-end;
  margin-top: -38px;
}
.flush-button {
  background-color: red;
  color: white;
}
.arrow-sort-active {
  background-color: rgba(128, 128, 128, 0.322);
  width: 13px;
  height: 13px;
  padding: 2px;
}
</style>
