/* eslint-disable prettier/prettier */
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import store from '../../../store';

const sdk = new ChartsEmbedSDK({
  baseUrl: 'https://charts.mongodb.com/charts-prod-deadp',
  getUserToken: async () => {
    const token = await store.dispatch('fetchToken');
    return token;
  },
});
const charts = {
  activeSubscriptionChartId: '64a815a7-2925-468a-8e12-15cc0a814e6d',
  canceledSubscriptionChartId: '64a815a7-2925-4650-8ae1-15cc0a814e73',
  pendingSubscriptionChartId: 'f0b40dcf-8cc5-4219-b880-de89205635df',
  subscriptionTableChartId: '64a815a7-2925-4fcf-8c15-15cc0a814e6f',
  invoicePerMonthAndCountryGraphChartId: '64a8279f-810f-4e6f-8d73-3e040f18db3f',
};

async function getChartById(chartId) {
  const url = `https://charts.mongodb.com/charts-prod-deadp/${chartId}`;
  const response = await fetch(url);
  const chart = await response.json();
  return chart;
}

export { sdk, charts, getChartById };
