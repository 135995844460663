<template>
  <div class="page-wrapper subscription-edit">
    <div class="container">
      <div class="my-4">
        <h3 class="mb-0 pb-0">
          {{ subscriptionID ? 'Edit' : 'Create' }} Subscription Plan
        </h3>
      </div>
      <div class="card-body px-0 d-flex flex-wrap">
        <div class="col-6 col-lg-4">
          <div class="d-flex mb-22">
            <div>
              <h6>Enabled:</h6>
              <Toggle v-model="subscription.enabled" />
            </div>
            <div class="ml-5">
              <h6>Period:</h6>
              <Dropdown
                v-model="subscription.period"
                :list="periods"
                class="input-like w-auto w-100"
              >
                <template slot="item" slot-scope="options">
                  <span>
                    {{ options.item }}
                  </span>
                </template>
              </Dropdown>
            </div>
          </div>

          <TextInput
            id="sku"
            v-model="subscription.sku"
            v-validate="'required'"
            data-vv-validate-on="blur"
            required
            class="w-100"
            :class="{ 'is-invalid': errors.first('sku') }"
            label="SKU:"
            name="sku"
            data-vv-as="SKU"
            data-vv-name="sku"
            :error="errors.first('sku')"
            :disabled="isLoading"
          />

          <TextInput
            id="subscription-amount"
            v-model="subscription.amount"
            v-validate="'required'"
            input-type="number"
            required
            class="w-100"
            :class="{ 'is-invalid': errors.first('subscription-amount') }"
            label="Price:"
            name="subscription-amount"
            data-vv-as="Amount"
            data-vv-name="subscription-amount"
            :error="errors.first('subscription-amount')"
            :disabled="isLoading"
          />
        </div>
        <div class="col-6 col-lg-4">
          <TextInput
            id="subscription-name"
            v-model="subscription.name"
            v-validate="'required'"
            required
            class="w-100 mt-80"
            :class="{ 'is-invalid': errors.first('subscription-name') }"
            label="Description Title:"
            name="subscription-name"
            data-vv-as="Name"
            data-vv-name="subscription-name"
            :error="errors.first('subscription-name')"
            :disabled="isLoading"
          />

          <TextInput
            id="subscription-discount"
            v-model="subscription.discount"
            input-type="number"
            class="w-100"
            label="Discount, %:"
            name="subscription-discount"
            :disabled="isLoading"
          />
        </div>
        <div class="col-6 col-lg-4">
          <div class="trial-enabled">
            <h6>Trial Enabled:</h6>
            <Toggle v-model="subscription.hasTrial" />
          </div>
          <TextInput
            v-if="subscription.hasTrial"
            id="subscription-trial-days"
            v-model="subscription.trialDays"
            v-validate="subscription.hasTrial ? 'required' : ''"
            input-type="number"
            :required="subscription.hasTrial ? 'required' : ''"
            class="w-100"
            :class="{ 'is-invalid': errors.first('subscription-trial-days') }"
            label="Trial period (days):"
            name="subscription-trial-days"
            data-vv-as="Trial Days"
            data-vv-name="subscription-trial-days"
            :error="errors.first('subscription-trial-days')"
            :disabled="isLoading"
          />
          <AutoSuggest
            id="country"
            v-model="subscription.country"
            v-validate="'required'"
            class="mw-100"
            required
            label="Country (where plan is available):"
            name="country"
            placeholder="Start typing..."
            display-property="text"
            data-vv-name="country"
            :source="suggestCountriesRequest"
            :error="errors.first('country')"
            :disabled="isLoading"
          />
          <AutoSuggest
            id="subscription-currency"
            v-model="subscription.currency"
            v-validate="'required'"
            class="mw-100 mt-2"
            required
            label="Currency:"
            name="subscription-currency"
            placeholder="Start typing..."
            display-property="id"
            data-vv-name="subscription-currency"
            :list="currencies"
            :error="errors.first('subscription-currency')"
            :disabled="isLoading"
          />
        </div>
        <div class="col-12">
          <TextInput
            id="subscription-description"
            v-model="subscription.description"
            v-validate="'required'"
            required
            class="w-100"
            :class="{ 'is-invalid': errors.first('subscription-description') }"
            label="Description (additional important info for players):"
            name="subscription-description"
            data-vv-as="Description"
            data-vv-name="subscription-description"
            :error="errors.first('subscription-description')"
            :disabled="isLoading"
          />
        </div>
        <div class="card-footer bg-transparent text-right w-100">
          <router-link
            :to="{ name: 'subscriptions' }"
            class="btn btn-link text-danger text-center mr-2"
          >
            Cancel
          </router-link>
          <button
            class="btn btn-primary text-center"
            :class="{ 'is-loading': isLoading }"
            :disabled="isLoading"
            @click="save"
          >
            {{ subscriptionID ? 'Save' : 'Create' }}
          </button>
        </div>
        <div
          v-if="isLoading"
          class="preloader position-absolute d-flex justify-content-center align-items-center"
        >
          <div class="spinner"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { uniqueId } from 'lodash';
import { diff } from '@/mixins/helpers';
import TextInput from '@/components/form/HeraFormTextInput';
import Dropdown from '@/components/form/HeraFormDropdown';
import AutoSuggest from '@/components/form/HeraAutosuggest';
import Toggle from '@/components/form/HeraFormSwitch';
import * as currencies from '@/assets/json/currencies.json';

export default {
  name: 'SubscriptionEdit',
  inject: ['$validator'],
  components: {
    TextInput,
    Dropdown,
    AutoSuggest,
    Toggle,
  },
  mixins: [diff],
  props: { country: { type: Object, required: false, default: () => null } },
  data() {
    return {
      isLoading: false,
      temp: null,
      subscription: {
        key: `subscription_${uniqueId()}`,
        enabled: true,
        name: null,
        sku: '',
        country: null,
        amount: null,
        discount: null,
        currency: null,
        period: 'monthly',
        description: null,
        hasTrial: false,
        trialDays: null,
      },
      currencies: currencies.default.list,
      subscriptionBackup: {},
    };
  },

  computed: {
    subscriptionID() {
      return this.$route.params.subscriptionID || '';
    },
    periods() {
      return [
        'regular',
        'monthly',
        'quarterly',
        'semiannual',
        'yearly',
        'quarterhour',
        'daily',
      ];
    },
  },

  created() {
    this.fetchSubscription();
  },

  methods: {
    ...mapActions([
      'suggestCountriesRequest',
      'successNotify',
      'errorNotify',
      'getSubscription',
      'createSubscription',
      'updateSubscription',
    ]),
    /* get subscription data*/
    fetchSubscription() {
      if (this.subscriptionID) {
        this.getSubscription(this.subscriptionID)
          .then(data => {
            this.subscription = {
              ...data,
              country: { id: data.countryId, text: data.country },
              currency: { id: data.currency },
            };
          })
          .catch(this.errorNotify)
          .finally(() => (this.isLoading = false));
      }
    },
    getDataForSave() {
      return {
        ...this.subscription,
        countryID: this.subscription.country.id,
        currency: this.subscription.currency.id,
      };
    },

    save() {
      this.isLoading = true;
      this.$validator
        .validateAll()
        .then(isValid =>
          isValid
            ? (this.subscriptionID
                ? this.updateSubscription
                : this.createSubscription)(this.getDataForSave())
            : Promise.reject('Form validation failed')
        )
        .then(() => {
          this.successNotify(
            this.subscriptionID
              ? 'Subscription was successfuly updated'
              : 'Subscription was successfuly created'
          );
          this.$router.push({ name: 'subscriptions' });
        })
        .catch(this.errorNotify)
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style lang="scss">
.subscription-edit {
  h6 {
    font-size: 12px;
    font-weight: 100;
  }

  .mb-22 {
    margin-bottom: 22px;
  }

  .mb-29 {
    margin-bottom: 29px;
  }

  .trial-enabled {
    flex-direction: column;
    display: flex;
    align-items: flex-end;
    height: 50px;
    margin-bottom: 33px;
  }

  .mt-80 {
    margin-top: 80px;
  }

  .switcher-label {
    font-size: 0.75rem;
  }

  .autosuggest_label {
    padding-left: 0 !important; //for replace boostrap class ml-1
  }

  .preloader {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $modal-backdrop-color;
    z-index: 2;
    border-radius: inherit;
  }

  .spinner {
    $size: 3.5rem;
    width: $size;
    height: $size;
    border: 2px solid transparent;
    border-left-color: $primary;
    border-bottom-color: $primary;
    border-radius: 50%;
    transform: translateX(-50%);
    animation: spinAround 0.5s infinite linear;
  }
}
</style>
