<template>
  <div class="container-fluid d-flex flex-column">
    <div class="d-flex justify-content-between align-items-center ">
      <h1>Popular users</h1>
      <div v-if="countrySwitcher.length" class="btn-group" role="group">
        <router-link
          v-for="query in countrySwitcher"
          :key="query.area"
          :to="query.route"
          :class="[
            $route.params.area === query.area
              ? 'border-primary btn-primary'
              : 'border btn-light',
          ]"
          class="btn text-capitalize switch-btn"
        >
          {{ query.area }}
        </router-link>
      </div>
    </div>
    <div class="row flex-nowrap h-100">
      <div
        v-if="!isGlobal()"
        class="col-2 h-100 d-flex flex-column flex-grow-1"
      >
        <h3>Selected countries</h3>
        <label class="d-block">Search by country:</label>
        <TextInput
          v-model="filtered.country"
          placeholder="Start typing..."
          :debounce="debounce"
        />
        <div class="wrapper-country h-100">
          <div v-if="filteredCountries.length" class="custom-scrollbar h-100">
            <label
              v-for="(country, index) in filteredCountries"
              :key="country.country.id + index"
              class="d-flex justify-content-between font-weight-bold"
            >
              <router-link
                :key="country.country.id"
                :to="{ ...$route, query: { country: country.country.id } }"
                class="pointer"
              >
                <span>{{ country.country.text }}</span>
              </router-link>
              <span class="ml-1">
                <span>
                  {{ country.count || 0 }}
                </span>
                <span>
                  <AppIcon
                    class="d-none ml-2 country-icon pointer"
                    name="close"
                    width="18"
                    @click="onRemovePopularPlayersList(country)"
                  />
                </span>
              </span>
            </label>
          </div>
          <div v-else class="text-center text-muted">
            No countries. Please add country
          </div>
        </div>
        <label class="font-weight-bold d-block mt-3">Add new country:</label>
        <Autosuggest
          placeholder="Country"
          display-property="text"
          :source="suggestCountriesRequest"
          @resultPicked="onAddCountry"
        />
      </div>
      <div class="d-flex flex-grow-1 h-100">
        <div class="col-6 d-flex flex-column flex-grow-1">
          <h3 v-if="isLoadingPlayersPopular">Loading...</h3>
          <h3 v-else>Popular users in {{ currentCountryName }}</h3>
          <label class="font-weight-bold isLoading"
            >{{ playersPopularForEdit.length }} popular users</label
          >
          <div class="card h-100">
            <div class="row pt-3 px-4">
              <div class="col-auto">
                <label class="d-block">Search by username:</label>
                <TextInput
                  v-model="filtered.users"
                  placeholder="Start typing..."
                  :debounce="debounce"
                />
              </div>
              <div class="col-auto">
                <label class="d-block">&nbsp;</label>
                <button
                  v-show="filtered.users.length"
                  class="btn mx-2 bg-transparent text-primary py-0"
                  @click.prevent="clearFilterByKey('users')"
                >
                  Clear filters
                </button>
              </div>
            </div>
            <div class="custom-scrollbar position-relative h-100">
              <table
                class="table table-hover mx-0 px-0 w-100 position-absolute"
              >
                <caption
                  v-if="!filteredUsers.length && isLoadingPlayersPopular"
                  class="text-center text-muted"
                >
                  Fetching popular players...
                </caption>
                <caption
                  v-else-if="!filteredUsers.length && !isLoadingPlayersPopular"
                  class="text-center text-muted"
                >
                  No profiles
                </caption>
                <template v-else>
                  <thead>
                    <tr>
                      <th class="border-top-0 border-bottom-0 pr-1"></th>
                      <th
                        v-for="title in tableTitles"
                        :key="title"
                        class="border-top-0 border-bottom-0 pr-2 pl-1 section-description"
                      >
                        {{ title }}
                      </th>
                    </tr>
                  </thead>
                  <Draggable
                    v-model="playersPopularForEditCopy"
                    :element="'tbody'"
                  >
                    <ItemPlayer
                      v-for="(player, index) in filteredUsers"
                      :key="player.login"
                      draggable
                      :index="player.index + 1"
                      :player="player"
                    >
                      <button
                        slot="button"
                        type="button"
                        class="btn btn-outline-danger btn-player btn-sm"
                        @click="onRemovePlayer(player, index)"
                      >
                        Remove
                      </button>
                    </ItemPlayer>
                  </Draggable>
                </template>
              </table>
            </div>
          </div>
        </div>
        <div class="col-6 d-flex flex-column flex-grow-1">
          <h3>Players</h3>
          <label class="font-weight-bold"
            >{{ profilesList.length }} users</label
          >
          <div class="card h-100">
            <div class="row pt-3 px-4">
              <div class="col-auto">
                <label class="d-block">Search by username:</label>
                <TextInput
                  v-model="filter.contains"
                  placeholder="Start typing..."
                  :debounce="debounce"
                  @input="onFilterChange"
                />
              </div>
              <div class="col-auto">
                <label class="d-block">&nbsp;</label>
                <button
                  v-show="isFiltered"
                  class="btn mx-2 bg-transparent text-primary py-0"
                  @click.prevent="setDefaultFilter"
                >
                  Clear filters
                </button>
              </div>
            </div>
            <div class="position-relative h-100 custom-scrollbar">
              <InfiniteScroll
                :list="profilesList"
                list-name="list"
                :is-disabled="isLoading || nextPageToken === null"
                :activation-gap="100"
                in-container
                class="position-absolute"
                @ScrollToBottom="fetchProfiles"
              >
                <table
                  slot-scope="{ list }"
                  class="table table-hover mx-0 px-0"
                >
                  <caption
                    v-if="!list.length && isLoading"
                    class="text-center text-muted"
                  >
                    Fetching profiles...
                  </caption>
                  <caption
                    v-else-if="!list.length"
                    class="text-center text-muted"
                  >
                    No profiles
                  </caption>
                  <template v-else>
                    <thead>
                      <tr>
                        <th class="border-top-0 border-bottom-0 pr-1"></th>
                        <th
                          v-for="title in tableTitles"
                          :key="title"
                          class="border-top-0 border-bottom-0 pr-2 pl-1 section-description"
                        >
                          {{ title }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <ItemPlayer
                        v-for="(player, index) in list"
                        :key="player.login + index"
                        :index="index + 1"
                        :player="player"
                      >
                        <template slot="button">
                          <button
                            v-if="isCheckedPlayer(player)"
                            type="button"
                            class="btn btn-outline-primary btn-player btn-sm"
                            @click="onAddPlayer(player, index)"
                          >
                            Add
                          </button>
                          <button
                            v-else
                            type="button"
                            class="btn btn-link disabled btn-player btn-sm"
                          >
                            Added
                          </button>
                        </template>
                      </ItemPlayer>
                    </tbody>
                  </template>
                </table>
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Draggable from 'vuedraggable';
import InfiniteScroll from '@/components/common/infiniteScroll.vue';
import TextInput from '@/components/form/HeraFormTextInput.vue';
import Autosuggest from '@/components/form/HeraAutosuggest';
import listMixin from '@/mixins/lists';
import AppIcon from '@/components/common/Icon';
import { isEqual, isEmpty, cloneDeep } from 'lodash';
import ItemPlayer from './ItemPlayer';

const defaultFilterState = () => ({
  contains: '',
  countries: null,
  city: null,
});

const MAX_POPULAR_LIMIT = 100;

export default {
  name: 'Popular',
  components: {
    TextInput,
    InfiniteScroll,
    Autosuggest,
    AppIcon,
    ItemPlayer,
    Draggable,
  },
  mixins: [...listMixin],
  data() {
    return {
      debounce: 700,
      filter: defaultFilterState(),
      filtered: {
        country: '',
        users: '',
      },
      isOpenCountriesModal: true,
      isLoadingPlayersPopular: false,
      isPopularPlayersUpdating: false,
      profilesList: [],
      tableTitles: ['Username', 'Type', 'Country', 'Action'],
      countrySwitcher: [
        {
          area: 'all',
          route: { name: 'popular', params: { area: 'all' } },
        },
        {
          area: 'global',
          route: { name: 'popular', params: { area: 'global' }, query: {} },
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['countriesPopular', 'playersPopularForEdit']),

    playersPopularForEditCopy: {
      get() {
        return [...this.playersPopularForEdit];
      },
      set(arr) {
        const popularPeoples = [...arr];

        this.setPopularPeopleList(popularPeoples);
        this.onUpdatePopularPeople(popularPeoples);

        if (!this.isGlobal()) {
          this.changePopularCountryCount({
            country_id: this.checkedCountry(),
            value: popularPeoples.length,
          });
        }
      },
    },

    filteredCountries() {
      const contains = this.filtered.country.toLowerCase();
      return this.countriesPopular.filter(
        el => el.country.text.toLowerCase().indexOf(contains) > -1
      );
    },
    filteredUsers() {
      const contains = this.filtered.users.toLowerCase();
      return this.playersPopularForEdit.filter(
        el => el.login.toLowerCase().indexOf(contains) > -1
      );
    },
    currentCountryName() {
      return this.isGlobal()
        ? 'Global list'
        : !this.countriesPopular.length
        ? 'All'
        : this.countriesPopular.filter(
            el => el.country.id === this.checkedCountry()
          )[0].country.text;
    },
    isFiltered() {
      return !isEqual(this.filter, defaultFilterState());
    },
  },
  watch: {
    'filter.countries'() {
      this.filter.city = null;
    },
  },
  created() {
    if (!this.$route.params.area) {
      this.$router.replace({ name: 'popular', params: { area: 'global' } });
    }

    const country = this.checkedCountry() || this.popularCountry();

    this.getPopularPeopleCountries();
    this.fetchProfiles();
    this.fetchPopularPlayersForEdit(country);
  },
  beforeRouteUpdate(to, from, next) {
    if (this.isPopularPlayersUpdating) {
      return next(false);
    }

    const country = this.checkedCountry(to) || this.popularCountry(to);

    this.fetchPopularPlayersForEdit(country);
    if (isEmpty(to.query)) {
      next({
        ...to,
        query: { country },
      });
    } else {
      next();
    }
  },
  methods: {
    ...mapActions([
      'getProfiles',
      'getPopularPeople',
      'getPopularPeopleForEdit',
      'getPopularPeopleCountries',
      'updatePopularPeople',
      'errorNotify',
      'successNotify',
      'suggestCountriesRequest',
      'getCountryById',
    ]),
    ...mapMutations([
      'setPopularPeopleList',
      'changePopularCountryCount',
      'addPopularCountry',
    ]),

    setDefaultFilter() {
      this.filter = defaultFilterState();
      this.onFilterChange();
    },
    onFilterChange() {
      this.profilesList = [];
      this.nextPageToken = undefined;
      this.fetchProfiles();
    },

    fetchPopularPlayersForEdit(country = 'global') {
      if (!this.isLoadingPlayersPopular) {
        this.isLoadingPlayersPopular = true;

        this.getPopularPeopleForEdit(country)
          .catch(this.errorNotify)
          .finally(() => {
            this.isLoadingPlayersPopular = false;
          });
      }
    },

    fetchProfiles() {
      return this.getNextPageData(
        'profilesList',
        this.getProfiles({
          params: {
            page: this.nextPageToken,
            contains: this.filter.contains,
            country: this.filter.countries ? this.filter.countries.id : '',
            city: this.filter.city ? this.filter.city.id : '',
            //TODO: check endpoints
            // skip for now, approved with Slava Diachenko
            // clan: this.filter.clan,
            // approved: this.filter.verified,
          },
        })
      );
    },

    isCheckedPlayer(player) {
      return !this.playersPopularForEdit.some(el => el.login === player.login);
    },

    onUpdatePopularPeople(nextArr) {
      if (this.isPopularPlayersUpdating) {
        return;
      }

      this.isPopularPlayersUpdating = true;

      const profilesListForSave = cloneDeep(nextArr);

      const country_id = this.checkedCountry();
      const profiles = profilesListForSave.map(el => ({
        profile_id: el.login,
      }));

      return this.updatePopularPeople({ country_id, props: { profiles } })
        .catch(err => {
          this.setPopularPeopleList(err.profiles);
          this.errorNotify(err);
        })
        .finally(() => {
          this.isPopularPlayersUpdating = false;

          if (!isEqual(this.playersPopularForEditCopy, profilesListForSave)) {
            this.onUpdatePopularPeople(this.playersPopularForEditCopy);
          }
        });
    },

    onRemovePlayer(player) {
      const newArr = cloneDeep(this.playersPopularForEdit);

      newArr.splice(player.index, 1);
      this.playersPopularForEditCopy = newArr;
    },

    onAddPlayer(player) {
      if (MAX_POPULAR_LIMIT === this.playersPopularForEdit.length) {
        this.errorNotify('Popular players limit is exceeded');
        return;
      }

      this.playersPopularForEditCopy = [
        player,
        ...cloneDeep(this.playersPopularForEdit),
      ];
    },

    onAddCountry(obj) {
      this.getCountryById(obj.id).then(res => {
        const responseCountry = { country: res };
        const findedIndex = this.countriesPopular.findIndex(
          el => el.country.id === obj.id
        );
        let index;

        findedIndex > -1
          ? (index = findedIndex)
          : (index = this.countriesPopular.length);

        const country = {
          ...this.countriesPopular[index],
          ...responseCountry,
        };

        if (!country.count) {
          country.count = 0;
        }

        this.addPopularCountry({
          index,
          country,
        });

        this.$router.push({
          query: {
            country: obj.id,
          },
        });
      });
    },

    onRemovePopularPlayersList({ country }) {
      this.onUpdatePopularPeople([], country.id);
    },

    isGlobal(area = this.$route.params.area) {
      return area === 'global';
    },

    checkedCountry(route = this.$route) {
      return this.isGlobal(route.params.area)
        ? route.params.area
        : route.query.country;
    },

    clearFilterByKey(key) {
      this.filtered[key] = '';
    },

    popularCountry(route = this.$route) {
      return route.params.area !== 'all'
        ? 'global'
        : this.countriesPopular.length
        ? this.countriesPopular[0].country.id
        : 'all';
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-player {
  min-width: 85px;
}

.switch-btn {
  min-width: 96px;
}

.card,
.wrapper-country {
  overflow: hidden;

  .router-link-active {
    color: $hera-main-text-color;
  }

  .router-link-exact-active {
    color: $hera-main-color;
  }
}

.pointer {
  cursor: pointer;
}

.country-link:hover {
  text-decoration: none;
}

.country-icon {
  padding: 4px;
  border: 1px solid $hera-secondary-text-color;
  border-radius: 50%;
}
</style>
