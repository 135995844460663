import { BASE_URL } from '@/api/config.js';
import {
  authorizedGetRequest,
  authorizedPostRequest,
  fileDownload,
} from '@/api/requests.js';

const INVOICES = BASE_URL + 'invoices';

export const getInvoices = (
  accessToken,
  pageToken = null,
  pageSize = 20,
  filters = {}
) =>
  authorizedGetRequest(INVOICES)(accessToken, {
    params: { page: pageToken, page_size: pageSize, ...filters },
  });

const resendInvoice = (accessToken, id, email) =>
  authorizedPostRequest(`${INVOICES}/${id}/resend_invoice`)(accessToken, {
    email,
  });

const exportInvoice = (accessToken, id, fileName) =>
  fileDownload(accessToken, `${INVOICES}/${id}/download`, fileName);

const exportAllInvoices = (accessToken, fileName, filters = {}) => {
  return fileDownload(accessToken, `${INVOICES}/export`, fileName, {
    queryParams: { ...filters },
  });
};
export default {
  invoices: {
    getInvoices,
    resendInvoice,
    exportInvoice,
    exportAllInvoices,
  },
};
