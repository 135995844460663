import '@babel/polyfill';

require('intersection-observer');

import Vue from 'vue';

// main modules
import App from '@/App.vue';
import store from '@/store';
import router from '@/router';
import { API } from '@/api/config.js';

// third side libs
import VeeValidate from 'vee-validate';
import Notifications from 'vue-notification';
import vuexI18n from 'vuex-i18n';
import VueTheMask from 'vue-the-mask';
// import fileBase64 from 'vue-file-base64'
import SvgIcon from 'vue-svgicon';
import VueImg from 'v-img';
import BootstrapVue from 'bootstrap-vue';
import VueObserveVisibility from 'vue-observe-visibility';
import ScrollSync from 'vue-scroll-sync';

// global app parts
import layouts from '@/layouts/';
import filters from '@/filters';
import directives from '@/directives';
import interceptors from '@/api/interceptors';

import scssVariables from '@/assets/scss/hera/export.scss';

layouts.forEach(layout => {
  Vue.component(layout.name, layout.component);
});

for (let filterName in filters) {
  Vue.filter(filterName, filters[filterName]);
}

for (let directiveName in directives) {
  Vue.directive(directiveName, directives[directiveName]);
}

// TODO: turn on before production deployment
//       for optimize tips
// =============================
Vue.config.productionTip = false;
// =============================

Vue.prototype.$scss = Object.freeze(scssVariables);

Vue.use(vuexI18n.plugin, store, {
  moduleName: 'i18n',
});
Vue.use(SvgIcon, {
  tagName: 'svgicon',
  classPrefix: 'AppIcon-',
});
// Vue.use(fileBase64)
Vue.use(Notifications);
Vue.use(VueTheMask);
Vue.use(BootstrapVue);
Vue.use(VeeValidate, {
  fieldsBagName: 'veeFields',
  inject: false,
});
Vue.use(VueImg);
Vue.use(VueObserveVisibility);
Vue.use(ScrollSync);

new Vue({
  router,
  store,
  created() {
    this.$i18n.set('en');
    API.interceptors.request.use(interceptors.onRequestSuccess.bind(this));
    API.interceptors.response.use(
      interceptors.onResponseSuccess.bind(this),
      interceptors.onResponseError.bind(this)
    );
  },
  render: h => h(App),
}).$mount('#app');
