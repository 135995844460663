<template>
  <div class="form-group" :class="{ 'text-right': dir === 'rtl' }">
    <label for="phone-input" :dir="dir" v-text="label"></label>
    <div
      class="input-group"
      dir="ltr"
      :class="{ 'is-invalid': error, focused: isFocused }"
    >
      <div
        class="input-group-prepend border-right-0"
        :class="{ show: isDropdownOpen }"
      >
        <button
          id="dropdownCountryButton"
          type="button"
          class="btn btn-outline-secondary coutry-dropdown border-right-0"
          :class="{ 'is-invalid': error }"
          data-toggle="dropdown"
          :aria-expanded="isDropdownOpen"
          @click="toggleCountryCodesList"
        >
          <span><img :src="chosenCountry.logo" class="country-logo mr-1"/></span
          >&nbsp;<span>+{{ chosenCountry.calling_code }}</span>
        </button>
        <div
          id="dropdownCountryList"
          class="w-100 dropdown-menu"
          :class="{ show: isDropdownOpen }"
        >
          <span
            v-if="isLoading"
            class="preloading-data is-loading py-5 px-2 w-100 dropdown-item"
          >
          </span>
          <template v-else>
            <input
              id="countryFilter"
              v-model.trim="countryFilter"
              type="text"
              class="form-control shadow-none mb-2 border-top-0 border-left-0 border-right-0 rounded-0"
              placeholder="Type to filter list..."
              name="countryFilter"
              autocomplete="nope"
            />
            <a
              v-for="(country, index) in filteredCountries"
              :key="index"
              class="dropdown-item"
              @click="chooseCountry(index)"
            >
              <img :src="country.logo" class="country-logo mr-1" />&nbsp;
              <span>+{{ country.calling_code }}</span
              >&nbsp;
              {{ country.name }}
            </a>
          </template>
        </div>
      </div>
      <input
        id="phone-input"
        v-model.trim="inputValue"
        v-focus
        v-mask="'###############'"
        class="form-control bg-transparent border-left-0 border-right-0 pl-0"
        :class="{ 'is-invalid': error }"
        aria-describedby="phoneHelp"
        :type="type"
        :autofocus="autofocus"
        :tabindex="tabindex"
        :aria-label="label"
        :placeholder="placeholder"
        @input="updateValue"
        @change="updateValue"
        @blur="onBlur"
        @focus="onFocus"
      />
      <div class="input-group-append border-left-0">
        <button
          id="dropdownCountryToggler"
          type="button"
          class="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split border-left-0"
          :class="{ 'is-invalid': error }"
          @click="toggleCountryCodesList"
        >
          <span class="sr-only">Toggle Dropdown</span>
        </button>
      </div>
    </div>
    <span
      v-show="error"
      id="phoneHelp"
      class="form-text text-danger error-help"
      v-text="error"
    ></span>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import SAUDI_FLAG from '@/assets/images/saudi-arabia.png';

const DEFAULT_FALBACK_CALLING_CODE = {
  name: 'Saudi Arabia',
  code: null,
  calling_code: '966',
  logo: SAUDI_FLAG,
};
export default {
  $_veeValidate: {
    name() {
      return this.name;
    },
    value() {
      return this.fullPhoneNumber;
    },
  },
  props: {
    name: {
      type: String,
      default: null,
    },
    direction: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    error: {
      type: String,
      default: null,
    },
    tabindex: {
      type: Number,
      default: null,
    },
    autofocus: {
      type: Boolean,
      default: null,
    },
    placeholder: {
      type: String,
      default: 'Phone',
    },
  },
  data() {
    return {
      isDropdownOpen: false,
      inputValue: null,
      chosenCountry: {},
      countryFilter: null,
      isFocused: false,
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters(['callingCodes', 'countryByCode']),
    fullPhoneNumber() {
      let phone = this.inputValue ? this.inputValue : null;
      return phone ? this.chosenCountry.calling_code + phone : null;
    },
    phoneNumberWithoutCode() {
      return this.chosenCountry.calling_code && this.value
        ? this.value.slice(this.chosenCountry.calling_code.length)
        : null;
    },
    filteredCountries() {
      if (!this.countryFilter) {
        return this.callingCodes;
      }
      return this.callingCodes.filter(
        ({ name, calling_code }) =>
          name.toLowerCase().includes(this.countryFilter) ||
          calling_code.includes(this.countryFilter)
      );
    },
    dir() {
      return this.direction;
    },
  },
  watch: {
    'callingCodes.length': function(newVal, oldVal) {
      if (oldVal === 0 && newVal) {
        this.chooseCountry();
        this.isLoading = false;
      }
    },
  },
  created() {
    this.chooseCountry();
    let self = this;

    if (this.callingCodes.length) {
      this.isLoading = false;
    }

    document.body.addEventListener('click', event => {
      // console.log(event)
      let target = event.target;
      if (
        target.id !== 'dropdownCountryButton' &&
        target.id !== 'dropdownCountryToggler' &&
        target.id !== 'dropdownCountryList' &&
        target.id !== 'countryFilter'
      ) {
        self.isDropdownOpen = false;
      }
    });
  },
  methods: {
    toggleCountryCodesList() {
      this.isDropdownOpen = false;
      let ariaExpanded = document
        .getElementById('dropdownCountryButton')
        .getAttribute('aria-expanded');
      if (ariaExpanded !== 'true') {
        document
          .getElementById('dropdownCountryButton')
          .removeAttribute('aria-expanded');
        document
          .getElementById('dropdownCountryButton')
          .setAttribute('aria-expanded', 'true');
        this.isDropdownOpen = true;
      }
    },
    chooseCountry(index) {
      let selected = this.filteredCountries[index];
      if (selected) {
        this.chosenCountry = { ...this.chosenCountry, ...selected };
      } else {
        this.chosenCountry = {
          ...this.chosenCountry,
          ...DEFAULT_FALBACK_CALLING_CODE,
        };
      }
      if (this.isDropdownOpen) {
        this.toggleCountryCodesList();
      }
      this.$emit('input', this.fullPhoneNumber);
    },
    updateValue() {
      this.$emit('input', this.fullPhoneNumber);
    },
    onFocus() {
      this.isFocused = true;
      this.$emit('focus', this.fullPhoneNumber);
    },
    onBlur() {
      this.isFocused = false;
      this.$emit('blur', this.fullPhoneNumber);
    },
  },
};
</script>
<style lang="scss" scoped>
.form-group {
}
small.error-help {
  max-width: 200px;
}
.country-logo {
  width: 1rem !important;
  height: 1rem !important;
  position: relative;
  top: -1px;
}
.dropdown-menu {
  max-height: 250px;
  // max-width: 200px;
  height: auto;
  overflow: auto;
}
.dropdown-item {
  cursor: pointer;
  padding-left: 1em !important;
  padding-right: 1em !important;
  span {
    display: inline-block;
    min-width: 3.25rem !important;
  }
  &.preloading-data.is-loading {
    &:before {
      top: calc(50% - 1rem);
    }
  }
}
#phone-input,
.dropdown-toggle,
.country-drop-down,
.input-group-prepend,
.btn-outline-secondary,
.dropdown-menu * {
  font-size: 12px;
}
#phone-input {
  box-shadow: none !important;
  font-weight: bold;
}

.dropdown-toggle {
  background-image: url(../../assets/images/dropdown-chevron.svg);
  background-size: 0.75em 0.5em;
  background-position: center;
  background-repeat: no-repeat;
  &:after {
    content: none !important;
    border: none !important;
  }
}

body {
  .input-group {
    button {
      &:hover {
        background-color: rgba(255, 255, 255, 0.1) !important;
      }
    }
    &.focused {
      border-radius: 0.25rem;
      box-shadow: 0 0 0 0.2rem rgba($hera-main-color, 0.25) !important;
      &.is-invalid {
        box-shadow: 0 0 0 0.2rem rgba($hera-error-color, 0.25) !important;
      }
    }
  }
  &.dark-theme {
    #phone-input,
    .dropdown-toggle,
    .country-drop-down,
    .input-group-prepend,
    .btn-outline-secondary {
      border-color: #575c88 !important;
      color: #fff;
      &.is-invalid {
        border-color: $hera-error-color !important;
      }
    }
    .btn-outline-secondary:not(:disabled):not(.disabled):active,
    .btn-outline-secondary:not(:disabled):not(.disabled).active,
    .show > .btn-outline-secondary.dropdown-toggle {
      color: #fff;
    }
  }
}
@media (max-width: 575px) and (orientation: landscape) {
}
@media (min-width: 576px) {
  // .form-group{
  //   max-width: 200px;
  // }
}
.input-group[dir='rtl'] {
  .input-group-append button {
    border-radius: 0 !important;
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
    border-right: none !important;
    border-left-width: 1px !important;
    border-left-style: solid !important;
  }
  .input-group-prepend button {
    border-radius: 0 !important;
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
    border-left: none !important;
    border-right-width: 1px !important;
    border-right-style: solid !important;
  }
  .dropdown-item {
    text-align: right !important;
  }
}
</style>
