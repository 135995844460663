<template>
  <div class="auth">
    <section class="title">
      <div class="container">
        <div class="row">
          <div class="col-auto mx-auto">
            <h1>Enter sms code</h1>
          </div>
        </div>
      </div>
    </section>
    <section class="sms">
      <div class="container">
        <div class="row no-gutters">
          <div class="col-12 m-0 p-0">
            <div class="card">
              <div class="card-body">
                <form @submit.prevent="formSubmit">
                  <div class="form-group text-center pb-4">
                    <icon name="sms" original width="75" />
                  </div>
                  <div class="form-group">
                    <div class="form-row">
                      <div class="col text-center">
                        <input
                          v-model.trim="digit1"
                          v-focus
                          v-validate="'required|numeric|max:1'"
                          v-mask="'#'"
                          :class="{
                            'is-invalid': errors.first('first-digit'),
                            'border-primary': digit1,
                          }"
                          name="first-digit"
                          type="tel"
                          autocomplete="off"
                          tabindex="1"
                          autofocus
                          placeholder="-"
                          class="form-control text-center bg-transparent mx-auto w-100"
                          @input="goToNextInput"
                        />
                      </div>
                      <div class="col text-center">
                        <input
                          v-model.trim="digit2"
                          v-validate="'required|numeric|max:1'"
                          v-mask="'#'"
                          :class="{
                            'is-invalid': errors.first('second-digit'),
                            'border-primary': digit2,
                          }"
                          name="second-digit"
                          type="tel"
                          autocomplete="off"
                          tabindex="2"
                          placeholder="-"
                          class="form-control text-center bg-transparent mx-auto w-100"
                          @input="goToNextInput"
                          @keydown.delete="goToPrevInput"
                        />
                      </div>
                      <div class="col text-center">
                        <input
                          v-model.trim="digit3"
                          v-validate="'required|numeric|max:1'"
                          v-mask="'#'"
                          :class="{
                            'is-invalid': errors.first('third-digit'),
                            'border-primary': digit3,
                          }"
                          name="third-digit"
                          type="tel"
                          autocomplete="off"
                          tabindex="3"
                          placeholder="-"
                          class="form-control text-center bg-transparent mx-auto w-100"
                          @input="goToNextInput"
                          @keydown.delete="goToPrevInput"
                        />
                      </div>
                      <div class="col text-center">
                        <input
                          v-model.trim="digit4"
                          v-validate="'required|numeric|max:1'"
                          v-mask="'#'"
                          :class="{
                            'is-invalid': errors.first('fourth-digit'),
                            'border-primary': digit4,
                          }"
                          name="fourth-digit"
                          type="tel"
                          autocomplete="off"
                          tabindex="4"
                          placeholder="-"
                          class="form-control text-center bg-transparent mx-auto w-100"
                          @input="goToSubmitButton"
                          @keydown.delete="goToPrevInput"
                        />
                      </div>
                    </div>
                    <small
                      v-show="!!errorText"
                      id="codeHelp"
                      class="form-text text-danger text-center"
                      v-text="errorText"
                    ></small>
                  </div>
                  <div class="form-group">
                    <div
                      class="form-row d-flex justify-content-end align-items-center"
                    >
                      <div class="col-auto">
                        <button
                          type="button"
                          class="btn btn-link"
                          @click.prevent="resendSMSCode"
                        >
                          Resend code
                        </button>
                      </div>
                      <div class="col-auto text-right">
                        <HeraFormSubmitBtn
                          id="submitCode"
                          tabindex="5"
                          :disabled="!smsCode || !!errorText"
                          label="Check"
                          :loading="isLoading"
                          class="btn-primary"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import HeraFormSubmitBtn from '@/components/form/HeraFormSubmitBtn';
import { mapActions, mapGetters } from 'vuex';
import icon from '@/components/common/Icon';
export default {
  name: 'SignIn',
  inject: ['$validator'],
  components: {
    HeraFormSubmitBtn,
    icon,
  },
  data() {
    return {
      digit1: null,
      digit2: null,
      digit3: null,
      digit4: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      token: 'tempToken',
    }),
    smsCode() {
      return this.digit1 && this.digit2 && this.digit3 && this.digit4
        ? this.digit1 + this.digit2 + this.digit3 + this.digit4
        : null;
    },
    errorText() {
      let text =
        this.errors.first('first-digit') ||
        this.errors.first('second-digit') ||
        this.errors.first('third-digit') ||
        this.errors.first('fourth-digit') ||
        null;
      return text;
    },
  },
  methods: {
    ...mapActions(['errorNotify', 'verifyUser', 'successNotify', 'sendCode']),
    formSubmit() {
      this.isLoading = true;
      this.$validator
        .validateAll()
        .then(result =>
          result
            ? Promise.resolve({
                code: this.smsCode,
                from: this.$route.params.from,
                isAdmin: false,
              })
            : Promise.reject()
        )
        .then(this.verifyUser)
        .then(() => {
          this.isLoading = false;
          this.successNotify('Code verified!');
          if (this.$route.params.from === 'signin') {
            this.$router.push({ name: 'home' });
          } else {
            this.$router.push({
              name: 'submitPassword',
              params: { from: 'reset' },
            });
          }
        })
        .catch(error => {
          this.isLoading = false;
          this.errorNotify(error);
        });
    },
    resendSMSCode() {
      this.sendCode(this.token).catch(error => {
        this.errorNotify(error);
      });
    },
    goToNextInput(e) {
      if (e.srcElement.value) {
        e.srcElement.parentNode.nextSibling.firstElementChild.focus();
      }
    },
    goToPrevInput(e) {
      if (!e.srcElement.defaultValue && !e.srcElement.value) {
        e.srcElement.parentNode.previousSibling.firstElementChild.focus();
      }
    },
    goToSubmitButton(e) {
      if (e.srcElement.value) {
        document.getElementById('submitCode').focus();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
input[type='tel'] {
  font-size: 39px !important;
  text-align: center;
  font-weight: 900;
  // max-width: 62px;
  // color: #fff;
  // border-color: #575C88;
  &.is-invalid {
    border-color: #eb5757;
  }
}
.auth {
  h1 {
    font-size: 36px;
  }
  section.sms {
    .card {
      border-color: #ebedf8;
      .card-body {
        padding: 3.75rem 6rem;
      }
    }
  }
  label {
    font-size: 12px;
  }
}
</style>
