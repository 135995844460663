<template>
  <tr class="coupon-list-item">
    <td class="px-2 text-truncate voucher-list-amount">
      <span> {{ voucher.title }}</span>
    </td>
    <td class="px-2 text-truncate voucher-list-amount">
      <span> {{ voucher.code }}</span>
    </td>
    <td class="px-2 text-truncate voucher-list-amount">
      <span> {{ voucher.period }}</span>
    </td>
    <td class="px-2 text-truncate voucher-list-amount">
      <span>
        {{
          voucher.expires_at !== null
            ? new Date(voucher.expires_at * 1000).toLocaleDateString() +
              ' ' +
              new Date(voucher.expires_at * 1000).toLocaleTimeString()
            : ''
        }}</span
      >
    </td>
    <td class="px-2 text-truncate voucher-list-amount">
      <span> {{ voucher.used_by }}</span>
    </td>
    <td class="px-2 text-truncate voucher-list-amount">
      <span> {{ voucher.promoter }}</span>
    </td>
    <td class="px-2 text-truncate voucher-list-amount">
      <span>
        {{
          voucher.created_on !== null
            ? new Date(voucher.created_on * 1000).toLocaleDateString() +
              ' ' +
              new Date(voucher.created_on * 1000).toLocaleTimeString()
            : ''
        }}</span
      >
    </td>

    <td>
      <button
        class="btn btn-sm btn-outline-primary"
        @click="$emit('edit', voucher)"
      >
        Edit
      </button>
    </td>
  </tr>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'VouchersListItem',

  inject: ['$validator'],
  props: {
    voucher: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      email: '',
    };
  },
  computed: {},
  methods: {
    ...mapActions(['openModal', 'closeModal', 'resendvoucher', 'errorNotify']),
    addLeadingZero(num) {
      return (num < 10 ? '0' : '') + num;
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    resend() {
      this.isLoading = true;

      this.resendvoucher({
        id: this.voucher.id,
        email: this.email,
      })
        .then(() => {})
        .catch(this.errorNotify)
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style lang="scss">
.disc-desc {
  max-width: 100px;
  word-break: break-all;
}
</style>
