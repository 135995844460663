'use strict';

import { BASE_URL } from '@/api/config.js';

import { simplePostRequest, authorizedPostRequest } from '@/api/requests.js';

const AUTH_BASE = BASE_URL + 'auth/';
const SIGNIN = AUTH_BASE + 'signin';
const SIGNOUT = AUTH_BASE + 'signout';
const SEND_CODE = AUTH_BASE + 'sendcode';
const VERIFY_CODE = AUTH_BASE + 'verify';
const RESET_PASS = AUTH_BASE + 'reset_password';
const SUBMIT_PASSWORD = AUTH_BASE + 'submit_password';
const REFRESH_TOKEN = AUTH_BASE + 'refresh';

export const refreshToken = authorizedPostRequest(REFRESH_TOKEN);

export const resetPass = simplePostRequest(RESET_PASS);

export const sendCode = authorizedPostRequest(SEND_CODE);

export const signIn = simplePostRequest(SIGNIN);

export const signOut = authorizedPostRequest(SIGNOUT);

export const submitPass = authorizedPostRequest(SUBMIT_PASSWORD);

export const verifyCode = authorizedPostRequest(VERIFY_CODE);

const switchAccount = authorizedPostRequest(`${AUTH_BASE}switch`);

export default {
  refreshToken,
  resetPass,
  sendCode,
  signIn,
  signOut,
  submitPass,
  verifyCode,
  switchAccount,
};
