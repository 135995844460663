import { ifAuthorized } from '@/router/guards';
const Wrapper = () =>
  import(/* webpackChunkName: "autofollow" */ '@/views/admin/Autofollow/Wrapper');
const Autofollow = () =>
  import(/* webpackChunkName: "autofollow" */ '@/views/admin/Autofollow/');

export default [
  {
    path: '/autofollow/',
    title: 'Autofollow',
    beforeEnter: ifAuthorized,
    component: Wrapper,
    redirect: { name: 'autofollow', params: { type: 'players' } },
    children: [
      {
        name: 'autofollow',
        path: ':type',
        component: Autofollow,
      },
    ],
  },
];
