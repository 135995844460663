<template>
  <b-nav-item :to="path" class="navbar-link">
    {{ title }}
  </b-nav-item>
</template>
<script>
export default {
  name: 'HeraNavbarLink',
  props: {
    linkText: {
      type: String,
      required: true,
    },
    linkUrl: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      title: this.linkText,
      path: this.linkUrl,
    };
  },
};
</script>
<style lang="scss" scoped>
.nav-item {
  outline: none;
  &:first-child {
    margin-top: 1.2rem;
  }
  & > .nav-link {
    font-size: 0.875rem;
    padding: 0;
    line-height: 50px;
    padding-left: 25px;
    border-left: 4px solid transparent;
    &:hover {
      color: $primary !important;
    }
    &:focus {
      outline: none;
    }
    &.active {
      outline: none;
      color: $primary !important;
      background-color: rgba(0, 199, 225, 0.05);
      font-weight: bold;
      border-left: 4px solid $hera-main-color;
    }
  }
}
</style>
