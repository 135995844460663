// import { HubConnection } from '@aspnet/signalr'
// import * as signalR from '@aspnet/signalr'
import { isObject } from 'lodash';
// import { SignalRConnectionInfo } from "./signalr-connection-info.model";

import api from '@/api';
import Vue from 'vue';

export const NOTIFICATIONS_DEFAULT_STATE = () => ({
  hubs: {},
  lastMessage: '',
});

const getters = {
  // notifications: state => state.notifications,
  hubs: state => state.hubs,
};

const mutations = {
  createHub(state, hub) {
    if (!state.hubs[hub]) {
      Vue.set(state.hubs, hub, {});
    }
  },
  setHubInfo(state, payload) {
    if (state.hubs[payload.hub]) {
      state.hubs[payload.hub] = { ...state.hubs[payload.hub], ...payload.info };
    }
  },
  clearToNotificationsDefaultState(state) {
    const defaultState = NOTIFICATIONS_DEFAULT_STATE();

    Object.keys(defaultState).forEach(key => {
      Vue.set(state, key, defaultState[key]);
    });
  },
  setLastMessage(state, payload) {
    state.lastMessage = payload;
  },
};

const actions = {
  getHubConnectionInfo({ dispatch, commit, getters }, hub) {
    let token = getters.accessToken;
    if (!token) return;
    return api
      .getHubConnection(hub)(token)
      .then(response => {
        commit('createHub', hub);
        return response.data;
      })
      .catch(error => dispatch('errorNotify', error));
  },
  initHubConnection({ commit, dispatch }, hub) {
    return dispatch('getHubConnectionInfo', hub).then(hubInfo => {
      const payload = {
        hub: hub,
        info: hubInfo,
      };
      commit('setHubInfo', payload);
      return payload;
    });
  },
  systemNotify({ state }, payload) {
    if (payload.text !== state.lastMessage) {
      Vue.notify({
        group: 'system',
        text: payload.text,
        type: payload.type,
        duration: 3000,
      });
      // commit('setLastMessage', payload.text);
    }
  },
  errorNotify({ dispatch }, error) {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    const errorIsObject = isObject(error);
    const isSilent = errorIsObject && error.silent;
    if (!isSilent) {
      const message =
        errorIsObject && error.hasOwnProperty('message')
          ? error.message
          : error;

      dispatch('systemNotify', {
        type: 'error',
        text: message,
      });
    }
  },
  successNotify({ dispatch }, text) {
    dispatch('systemNotify', {
      type: 'success',
      text,
    });
  },
  socialNotify(_state, payload) {
    Vue.notify({
      group: 'social',
      text: payload.text,
      type: payload.type,
      duration: 120000,
      data: payload.data,
    });
  },
};

export default {
  state: NOTIFICATIONS_DEFAULT_STATE(),
  getters,
  mutations,
  actions,
};
