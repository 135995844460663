export const routeMatching = {
  methods: {
    matchCurrentRoute(name) {
      const names = Array.isArray(name) ? [...name] : [name];
      return this.$router.currentRoute.matched.some(route =>
        names.includes(route.name)
      );
    },
  },
};

export default [routeMatching];
