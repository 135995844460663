<template>
  <button
    class="bg-transparent btn-icon p-0 m-0 border-0 d-flex align-items-center justify-content-center"
    :style="{ height: `${width}px` }"
    :class="[
      direction,
      {
        disabled: disabled,
        'flex-row-reverse': direction === 'left',
        'initial-cursor btn-icon--no-action': noAction,
      },
    ]"
  >
    <div
      class="btn-icon-icon-wrapper d-flex align-items-center justify-content-center"
      :style="{ width: `${width}px`, height: `${width}px` }"
    >
      <AppIcon
        v-if="bgIcon"
        :key="noAction + disabled"
        :name="bgIcon"
        class="btn-icon-bg"
        :width="width"
        original
      />
      <AppIcon
        v-if="icon"
        :key="icon"
        :name="icon"
        :original="original"
        :width="iconWidth"
        :fill="fill"
        class="btn-icon-icon"
      />
      <!-- <AppIcon v-show="icon!=='done'" :name="icon" :original="original" :width="iconWidth" :fill="fill" class="btn-icon-icon"/>
      <AppIcon v-show="icon==='done'" name="done" :original="original" :width="iconWidth" :fill="fill" class="btn-icon-icon"/> -->
    </div>
    <span
      v-if="text.length"
      class="btn-icon-text"
      :class="{ 'text-right': direction === 'right' }"
      :style="{ color: color }"
      >{{ text }}</span
    >
  </button>
</template>

<script>
import AppIcon from './Icon';

export default {
  name: 'BtnIcon',
  components: {
    AppIcon,
  },
  props: {
    icon: {
      type: String,
      default: null,
    },
    iconSize: {
      type: String,
      default: '18',
    },
    bgIcon: {
      type: String,
      default: 'shape',
    },
    fill: {
      type: null,
      default: 'white',
    },
    color: {
      type: String,
      default: '#fff',
    },
    width: {
      type: String,
      default: '18',
    },
    original: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    noAction: {
      type: Boolean,
      default: false,
    },
    textPosition: {
      type: String,
      default: 'right',
      validator: function(value) {
        return ['right', 'left'].indexOf(value) !== -1;
      },
    },
  },
  computed: {
    iconWidth() {
      return this.iconSize || Math.ceil(+this.width * 0.66) + '';
    },
    direction() {
      return this.textPosition;
    },
  },
};
</script>

<style lang="scss">
.btn-icon {
  cursor: pointer;
  width: auto;
  box-shadow: none !important;
  outline: none !important;
  min-width: auto !important;
  .btn-icon-icon-wrapper {
    position: relative;
    .btn-icon-bg {
      position: absolute;
      top: 0;
      left: 0;
    }
    .btn-icon-icon {
      position: relative;
    }
  }
  .btn-icon-text {
    font-size: 12px;
    margin: 0 5px;
    color: white;
  }
  &.btn-icon--no-action {
    .btn-icon-bg,
    .btn-icon-bg path {
      fill: #8261fc !important;
      // fill: red !important;
    }
  }
  &.disabled {
    cursor: initial;
    .btn-icon-bg,
    .btn-icon-bg path {
      fill: #dedede !important;
    }
    .btn-icon-icon,
    .btn-icon-icon path {
      fill: #898b9b !important;
    }
    .btn-icon-text {
      color: #898b9b !important;
    }
  }
  &.ltr {
    &.left {
      .btn-icon-text {
        margin-left: unset;
        margin-right: 0.5rem;
      }
    }
    &.right {
      .btn-icon-text {
        margin-right: unset;
        margin-left: 0.5rem;
      }
    }
  }
  &.rtl {
    &.left {
      .btn-icon-text {
        margin-left: unset;
        margin-right: 0.5rem;
      }
    }
    &.right {
      .btn-icon-text {
        margin-right: unset;
        margin-left: 0.5rem;
      }
    }
  }
  &.initial-cursor {
    cursor: initial !important;
  }
}
</style>
