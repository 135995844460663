import { ifAuthorized } from '@/router/guards';
import Complaints from '@/views/admin/Complaints';

export default [
  {
    path: '/complaints',
    name: 'complaints',
    title: 'Complaints',
    component: Complaints,
    beforeEnter: ifAuthorized,
  },
];
