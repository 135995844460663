<template>
  <popover
    class="dropbox-menu"
    :position="positionReplaced"
    v-bind="{ closest, float, regard }"
    @toggle="$emit('toggle', $event)"
  >
    <div v-if="!items || !items.length" class="position-relative popover-dummy">
      <slot name="dummy"></slot>
    </div>
    <div v-else class="position-relative custom-scrollbar">
      <ul class="list-unstyled mb-0">
        <li v-for="(item, i) in items" :key="i" v-on="$handler">
          <slot :item="item"></slot>
        </li>
      </ul>
    </div>
    <slot name="footer"></slot>
  </popover>
</template>

<script>
import popover from './';

export default {
  name: 'Menu',
  components: { popover },
  props: {
    position: {
      type: null,
      default: null,
    },
    closest: {
      type: Node,
      default: null,
    },
    float: {
      type: Array,
      default: () => [],
    },
    regard: {
      type: String,
      default: 'top', // top or bottom
    },
    items: {
      type: Array, // each item: {}
      default: () => [],
      // validator: function (value) {
      //   return value.includes('id')
      // }
    },
    autoClose: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      positionReplaced: this.position,
    };
  },
  computed: {
    $handler() {
      if (this.autoClose) {
        const $this = this;

        return {
          click() {
            $this.positionReplaced = null;
          },
        };
      }

      return null;
    },
  },
  watch: {
    position(value) {
      this.positionReplaced = value;
    },
  },
};
</script>

<style lang="scss">
@import '~@/assets/scss/hera/hera'; // not remove!

.dropbox-menu {
  & > div {
    max-height: 200px;
    min-height: 10px;
    &.popover-dummy {
      height: 128px;
    }
  }
  ul {
    position: relative;
    z-index: 2;
  }
  li {
    & > * {
      cursor: pointer;
      display: block;
      line-height: 24px;
      padding: 4px 12px;
      @extend .text-ellipses;

      &.active {
        background-color: rgba(0, 0, 0, 0.1);
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
  }
}
</style>
