import guards from '@/router/guards';

// auth routes
import SignIn from '@/views/auth/Signin.vue';
import PasswordReset from '@/views/auth/PasswordReset.vue';
import SubmitPassword from '@/views/auth/SubmitPassword.vue';
import VerifyCode from '@/views/auth/VerifyCode.vue';

import store from '@/store';

export default [
  {
    path: '/signin',
    name: 'signin',
    component: SignIn,
    beforeEnter: guards.ifNotAuthorized,
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/password-reset',
    name: 'passwordReset',
    component: PasswordReset,
    beforeEnter: guards.ifNotAuthorized,
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/verify-code/:from', //TODO: rename "from" params
    name: 'verifyCode',
    component: VerifyCode,
    beforeEnter: guards.ifAuthenticated,
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/submit-new-password',
    name: 'submitPassword',
    component: SubmitPassword,
    beforeEnter: guards.ifPartiallyAuthorized,
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/signout',
    name: 'signout',
    beforeEnter(to, from, next) {
      store.dispatch('logout').then(() => next({ name: 'signin' }));
    },
  },
];
