'use strict';

import { BASE_URL } from '@/api/config.js';

import {
  authorizedPutRequest,
  authorizedGetRequest,
  authorizedPostRequest,
} from '@/api/requests.js';

const BATTLES_BASE = BASE_URL + 'battles';

export const battles = {
  getList: (pageToken = null, eventId = '') =>
    authorizedGetRequest(
      `${BATTLES_BASE}?event_id=${encodeURIComponent(eventId)}${
        pageToken ? `&page=${encodeURIComponent(pageToken)}` : ``
      }`
    ),
  getById: battleID => authorizedGetRequest(`${BATTLES_BASE}/${battleID}`),
  getConflicted: (pageToken = null) =>
    authorizedGetRequest(
      `${BATTLES_BASE}/conflicted${
        pageToken ? `?page=${encodeURIComponent(pageToken)}` : ``
      }`
    ),
  getResults: battleID =>
    authorizedGetRequest(`${BATTLES_BASE}/${battleID}/results`),
  getResultsAll: () => authorizedGetRequest(`${BATTLES_BASE}/null/admin/grid`), //null is OK! backend trick
  resolve: (accessToken, battleID, resolution) =>
    authorizedPostRequest(`${BATTLES_BASE}/${battleID}/resolution`)(
      accessToken,
      resolution
    ),
  cancel: battleID =>
    authorizedPutRequest(`${BATTLES_BASE}/${battleID}/cancel`),
  provideResult: (battleID, playerIndex) =>
    authorizedPostRequest(`${BATTLES_BASE}/${battleID}/results/${playerIndex}`),
  provideResultByAdmin: battleID =>
    authorizedPostRequest(`${BATTLES_BASE}/${battleID}/admin/results`),
  // will be deprecated soon
  provideOfflineResult: battleID =>
    authorizedPostRequest(`${BATTLES_BASE}/${battleID}/results/offline`),
};

export default {
  battles,
};
