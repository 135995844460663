'use strict';

import { BASE_URL } from '@/api/config.js';

import { simpleGetRequest, authorizedPutRequest } from '@/api/requests.js';

import locale from 'browser-locale';

const userLocale = locale();

const TRANSLATIONS_BASE = BASE_URL + 'translations/';
const LOCALES = TRANSLATIONS_BASE;

export const getTranslationsLocales = simpleGetRequest(LOCALES + `locales`);
export const getTranslationByLocale = (lang = userLocale, skipCache = true) =>
  simpleGetRequest(LOCALES + lang.toLowerCase() + `?skip-cache=${skipCache}`);
export const putTranslation = (lang = userLocale) =>
  authorizedPutRequest(LOCALES + lang.toLowerCase());

export default {
  getTranslationsLocales,
  getTranslationByLocale,
  putTranslation,
};
