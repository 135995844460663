<template>
  <div class="d-flex flex-column px-4 translation">
    <h3 class="my-4">Translations</h3>

    <div class="card flex-grow-1">
      <div class="card-body d-flex flex-column">
        <div class="row align-items-end mb-3">
          <div class="col-4">
            <TextInput
              v-model.trim.lazy="filter"
              label="Filter:"
              class="w-100 mb-0"
            />
          </div>
          <div class="col-auto">
            <Btn
              class="mt-4 bg-transparent text-primary"
              label="Clear"
              :disabled="!filter"
              @click="clearFilter"
            />
          </div>
          <div class="col-auto align-bottom">
            <Btn
              class="mt-4"
              :label="isAddNewVisible ? 'Cancel' : 'Add new item'"
              @click="toggleAddNew"
            />
          </div>
        </div>

        <transition name="fade">
          <AddNew
            v-if="isAddNewVisible"
            v-bind="{ locales, formatLocales }"
            @add="addTranslation"
          />
        </transition>

        <div class="row mb-1 pr-5">
          <div class="col-3 font-weight-bold d-flex align-items-center">
            Translation key
          </div>
          <div class="col d-flex align-items-center">
            <label class="my-0">From:</label>
            <!-- TODO: Use popover -->
            <Dropdown
              v-model="translateFrom"
              class="input-like mx-2"
              :list="translateFromLocales"
            >
              <template slot="item" slot-scope="options">
                {{ options.item.text }}
              </template>
            </Dropdown>
            <Checkbox
              v-model="fromEmptyOnly"
              label="Empty only"
              class="my-0 text-nowrap"
            />
          </div>
          <div class="col d-flex align-items-center">
            <label class="my-0">To:</label>
            <!-- TODO: Use popover -->
            <Dropdown
              v-model="translateTo"
              class="input-like mx-2"
              :list="translateToLocales"
            >
              <template slot="item" slot-scope="options">
                {{ options.item.text }}
              </template>
            </Dropdown>
            <Checkbox
              v-model="toEmptyOnly"
              label="Empty only"
              class="my-0 text-nowrap"
            />
          </div>
        </div>

        <div class="flex-grow-1 position-relative border-top">
          <div
            ref="listContainer"
            class="position-absolute w-100 custom-scrollbar translations_list-container"
          >
            <VirtualScroll
              v-if="!isLoading"
              :key="`virtual-scroll-${filter}-${filteredItems.length}`"
              ref="list"
              :visible-count="listVisibleCount"
              :item-height="40"
              :item="ListItem"
              :items-count="filteredItems.length"
              :item-props="getItemProps"
              spy-scroll-on=".translations_list-container"
              multiple-mode
            />
            <div v-else class="text-center text-muted py-4">
              Fetching translation data...
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { uniq } from 'lodash';
import tags from 'language-tags';

import VirtualScroll from '@/components/common/VirtualScroll';
import TextInput from '@/components/form/HeraFormTextInput';
import Btn from '@/components/form/HeraFormButton';
import Dropdown from '@/components/form/HeraFormDropdown';
import Checkbox from '@/components/form/HeraFormCheckbox';

import AddNew from './AddNew';
import ListItem from './ListItem';

const LIST_ITEM_HEIGHT = 40; //px
const DEFAULT_TRANSLATE_FROM = 'en';
const DEFAULT_TRANSLATE_TO = 'ar';

export default {
  name: 'Translations',

  components: {
    VirtualScroll,
    TextInput,
    Btn,
    Dropdown,
    Checkbox,
    AddNew,
  },

  data() {
    return {
      isLoading: true,
      locales: [],
      formatLocales: [],
      translateFrom: null,
      translateTo: null,
      filter: '',
      fromEmptyOnly: false,
      toEmptyOnly: false,
      isAddNewVisible: false,
      listContainerStyles: null,
      listVisibleCount: 0,
      translations: {},
      ListItem,
    };
  },

  computed: {
    filteredItems() {
      return (
        Object.entries(this.translations)
          .map(([key, value]) => ({
            key,
            ...value,
          }))
          .filter(item =>
            this.fromEmptyOnly && this.toEmptyOnly
              ? !item[this.translateFrom.value] && !item[this.translateTo.value]
              : this.fromEmptyOnly
              ? !item[this.translateFrom.value]
              : this.toEmptyOnly
              ? !item[this.translateTo.value]
              : true
          )
          .filter(item => {
            return this.filter
              ? Object.values(item)
                  .join('')
                  .toLowerCase()
                  .indexOf(this.filter.toLowerCase()) !== -1
              : true;
          }) || []
      );
    },

    translateFromLocales() {
      return this.locales.filter(
        ({ value }) => value !== this.translateTo.value
      );
    },

    translateToLocales() {
      return this.locales.filter(
        ({ value }) => value !== this.translateFrom.value
      );
    },
  },

  watch: {
    translateFrom(newFrom) {
      if (newFrom && newFrom.hasOwnProperty('value')) {
        sessionStorage.setItem('translateFrom', newFrom.value);
      }
    },

    translateTo(newTo) {
      if (newTo && newTo.hasOwnProperty('value')) {
        sessionStorage.setItem('translateTo', newTo.value);
      }
    },
  },

  created() {
    this.getTranslationsLocales()
      .then(res => {
        this.formatLocales = res;
        this.locales = uniq(res).map(languageKey => {
          if (!tags(languageKey).valid()) {
            throw new Error(`Wrong locale key: ${languageKey} provided.`);
          }

          const lang = tags.language(languageKey);

          if (!lang) {
            throw new Error(
              `Can't find valid language for this key: ${languageKey}. Please fix locales.`
            );
          }

          return {
            value: languageKey,
            text: lang ? lang.descriptions()[0] : languageKey,
          };
        });

        const from =
          sessionStorage.getItem('translateFrom') || DEFAULT_TRANSLATE_FROM;
        this.translateFrom = this.locales.find(({ value }) => value === from);
        if (!this.translateFrom) {
          this.translateFrom = this.locales[0];
        }

        const to =
          sessionStorage.getItem('translateTo') || DEFAULT_TRANSLATE_TO;
        this.translateTo = this.locales.find(({ value }) => value === to);
        if (!this.translateTo) {
          this.translateTo = this.locales[0];
        }

        return this.getTranslationsByLocale(res);
      })
      .then(res => (this.translations = res))
      .catch(this.errorNotify)
      .finally(() => (this.isLoading = false));
  },

  mounted() {
    this.listContainerStyles = window.getComputedStyle(
      this.$refs.listContainer
    );
    this.calcListVisibleCount();
    window.addEventListener('resize', this.calcListVisibleCount);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.calcListVisibleCount);
  },

  methods: {
    ...mapActions([
      'getTranslationsByLocale',
      'getTranslationsLocales',
      'saveTranslation',
      'errorNotify',
      'successNotify',
    ]),

    getItemProps(index) {
      return {
        props: {
          translation: this.filteredItems[index],
          from: this.translateFrom.value,
          to: this.translateTo.value,
        },
        key: `${this.filteredItems[index].key}-${this.translateFrom.value}-${
          this.translateTo.value
        }`,
        on: {
          change: ({ locale, value }) => {
            if (!this.filteredItems[index].new) {
              this.$set(this.filteredItems[index], 'new', {});
            }
            this.$set(this.filteredItems[index].new, locale, value);
          },
          save: ({ locale, value }) => {
            this.save({ key: this.filteredItems[index].key, locale, value });
          },
        },
      };
    },

    clearFilter() {
      this.filter = '';
    },

    toggleAddNew() {
      this.isAddNewVisible = !this.isAddNewVisible;
    },

    calcListVisibleCount() {
      this.listVisibleCount = Math.round(
        parseInt(this.listContainerStyles.height) / LIST_ITEM_HEIGHT
      );
    },

    addTranslation({ key, translations }) {
      this.$set(this.translations, key, translations);
      this.isAddNewVisible = false;
    },

    save({ key, locale, value }) {
      this.$set(this.translations[key], locale, value);
    },
  },
};
</script>

<style lang="scss">
.translations {
  &_list-container {
    // TODO: use fill-border mixin from hera-web
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    overflow-y: scroll;
  }
}
</style>
