'use strict';

import { GENRES } from './settingsConstants.js';

import {
  authorizedGetRequest,
  authorizedDeleteRequest,
  authorizedPostRequest,
  authorizedPutRequest,
} from '@/api/requests.js';

export default {
  genres: {
    getList: authorizedGetRequest(GENRES),
    create: authorizedPostRequest(GENRES),
    updateById: id => authorizedPutRequest(`${GENRES}/${id}`),
    deleteById: id => authorizedDeleteRequest(`${GENRES}/${id}`),
    updateOrder: authorizedPutRequest(`${GENRES}/all/ordering`),
  },
};
