<template>
  <div
    v-dragscroll:nochilddrag.y="isDraggable || false"
    class="layout-wrapper d-flex flex-column"
    :class="{ isDraggable }"
  >
    <HeraNavbar />
    <main class="flex-grow-1 d-flex">
      <b-navbar id="nav-left" variant="white" class="mt-0">
        <b-navbar-nav class="d-flex flex-column">
          <HeraNavbarLink
            v-for="link in routes"
            :key="link.title"
            :link-url="link"
            :link-text="link.title"
          />
        </b-navbar-nav>
      </b-navbar>
      <div class="content d-flex flex-column">
        <slot />
        <HeraFooter />
      </div>
    </main>
    <Chat v-if="!popoverChatIsVisible"></Chat>
  </div>
</template>

<script>
import HeraNavbar from '@/components/navbar/HeraNavbar.vue';
import HeraFooter from '@/components/footer/HeraFooter';
import Chat from '@/views/admin/Conversations/ChatPopover.vue';
import HeraNavbarLink from '@/components/navbar/HeraNavbarLink';
import { dragscroll } from 'vue-dragscroll';

export default {
  name: 'AdminDefaultLayout',
  directives: {
    dragscroll,
  },
  components: {
    HeraNavbar,
    HeraFooter,
    Chat,
    HeraNavbarLink,
  },
  computed: {
    routes: function() {
      let routes = [];
      for (let i in this.$router.options.routes) {
        if (!this.$router.options.routes.hasOwnProperty(i)) {
          continue;
        }
        let route = this.$router.options.routes[i];
        if (route.hasOwnProperty('title')) {
          routes.push(route);
        }
      }
      return routes;
    },
    popoverChatIsVisible() {
      return (
        this.$route.name !== 'conversations' &&
        this.$route.name !== 'conversationsRedirect'
      );
    },
    isDraggable() {
      return this.$route.meta.isDraggable;
    },
  },
};
</script>
<style lang="scss" scoped>
#nav-left {
  width: 220px;
  min-width: 220px;
  padding: 0;
  margin-right: 15px;
  margin-top: 30px;
  display: block;
  border-right: 1px solid #ebedf8;
  .navbar-nav {
    width: 100%;
    position: sticky;
    top: 0px;
  }
}
.content {
  & > :first-child {
    flex-grow: 1;
  }
  width: calc(100% - 235px);
}
.isDraggable {
  height: 100vh;
  overflow-y: auto;
}
.with-padding {
  padding-bottom: 60px;
}
</style>
