/* eslint-disable prettier/prettier */
import { ifAuthorized } from '@/router/guards';
import Charts from '@/views/admin/Charts/Charts';

export default [
  {
    path: '/charts',
    title: 'Charts',
    component: Charts,
    beforeEnter: ifAuthorized,
  },
];
