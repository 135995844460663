<template>
  <div class="file-upload-field">
    <input
      :id="id"
      ref="input"
      v-validate="validationRule"
      type="file"
      :multiple="multiple"
      :accept="fileAcceptString"
      :name="name"
      :data-vv-as="validateAs"
      tabindex="0"
      :disabled="disabled"
      @change="onChange"
    />
    <label
      :for="id"
      class="btn btn-primary px-4 mb-0"
      :class="{ 'image-preview-mode': imagePreviewMode }"
    >
      <template v-if="!imagePreviewMode">
        {{ label }}
      </template>
      <div
        v-else
        class="image-preview-holder w-100 h-100"
        :class="{
          'is-empty': isEmpty,
          'is-loading': isLoading,
          'border border-danger': errors.first(name),
        }"
        :style="{ backgroundImage: `url(${imagePreviewUri})` }"
      ></div>
    </label>
    <br v-show="help" />
    <em v-show="help" :id="id + 'Help'" class="form-text text-muted">
      {{ help }}
    </em>
    <span
      v-show="!hideErrorText && errors.first(name)"
      :id="id + 'Help'"
      class="form-text text-danger error-text"
      v-text="errors.first(name)"
    >
    </span>
  </div>
</template>

<script>
import { cloneDeep, isEmpty } from 'lodash';
import { mapActions } from 'vuex';
import defaultImage from '@/assets/images/image-placeholder.png';
export default {
  inject: ['$validator'],
  props: {
    // Support Multiple File?
    multiple: {
      type: Boolean,
      default: false,
    },
    // Pass the files info when it's done
    done: {
      type: Function,
      default: () => {},
    },
    outputAs: {
      type: String,
      validator: val => ['binary', 'base64'].indexOf(val) !== -1,
      default: 'base64',
    },
    preupload: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: 'Upload',
    },
    id: {
      type: String,
      default: null,
    },
    accept: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: null,
    },
    validateAs: {
      type: String,
      default: 'File Input',
    },
    sizeCeil: {
      type: Number,
      default: 5000,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    hideErrorText: {
      type: Boolean,
      default: false,
    },
    predefinedFileUrl: {
      type: String,
      default: '',
    },
    help: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    imagePreviewMode: {
      type: Boolean,
      default: false,
    },
    value: {
      type: null,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      fileInfo: {},
    };
  },
  computed: {
    validationRule() {
      let rule = [];
      if (this.isRequired && this.isEmpty) {
        rule.push('required');
      }
      if (this.sizeCeil) {
        rule.push(`size:${this.sizeCeil}`);
      }
      if (this.accept && this.accept.length) {
        rule.push(`ext:${this.accept.join(',')}`);
      }
      return rule.join('|');
    },
    fileAcceptString() {
      return this.accept ? this.accept.map(ext => `.${ext}`).join(',') : '';
    },
    isBinary() {
      return this.outputAs === 'binary';
    },
    imagePreviewUri() {
      return (
        this.fileInfo.objectURL ||
        this.predefinedFileUrl ||
        this.fileInfo.display_uri ||
        defaultImage
      );
    },
    isEmpty() {
      return isEmpty(this.fileInfo) && this.imagePreviewUri === defaultImage;
    },
  },
  watch: {
    value(newVal, oldVal) {
      if (oldVal && !newVal) {
        this.fileInfo = {};
        this.$refs.input.value = '';
      }
    },
  },
  beforeDestroy() {
    if (this.fileInfo) {
      URL.revokeObjectURL(this.fileInfo.objectURL);
    }
  },
  methods: {
    ...mapActions(['systemNotify', 'fileUpload']),
    generateOutputInfo(file) {
      return {
        name: file.name,
        type: file.type,
        rawSize: file.size,
        size: Math.round(file.size / 1000) + ' kB',
        file: file,
        objectURL: URL.createObjectURL(file),
      };
    },
    onChange(e) {
      let filesInfo = [];
      // get the files
      this.$validator
        .validate(this.name)
        .then(result =>
          result
            ? Promise.resolve(e)
            : Promise.reject(this.errors.first(this.name))
        )
        .then(e => {
          this.isLoading = true;
          let files = e.target.files;
          let promises = [...files].map(file => {
            return new Promise(resolve => {
              let reader = new FileReader();
              if (this.isBinary) {
                reader.readAsArrayBuffer(file);
              } else {
                reader.readAsDataURL(file);
              }
              reader.onload = () => {
                resolve({
                  ...cloneDeep(this.generateOutputInfo(file)),
                  outputType: this.outputAs,
                  output: this.isBinary
                    ? new Blob([reader.result], { type: file.type })
                    : reader.result,
                });
              };
            });
          });
          return Promise.all(promises);
        })
        .then(files => {
          filesInfo = cloneDeep(files);
          this.fileInfo = filesInfo[0];
          this.$emit('filesSelected', filesInfo);
          return Promise.all(
            files.map(file =>
              this.preupload
                ? this.fileUpload(file.file)
                : Promise.resolve(file)
            )
          );
        })
        .then(uploads => {
          let results = uploads.map((el, i) => {
            return {
              ...el,
              ...cloneDeep(filesInfo[i]),
            };
          });
          this.fileInfo = results[0];
          if (this.multiple) {
            this.done(results);
          } else {
            this.done(this.fileInfo);
            this.$emit('input', {
              id: this.fileInfo.file_id,
              display_uri: this.fileInfo.display_uri,
            });
          }
          this.$emit('filesUploaded', results);
        })
        .catch(error => {
          this.$emit('uploadError');
          if (error !== this.errors.first(this.name)) {
            this.systemNotify({
              text: error,
              type: 'error',
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    }, // onChange()
  },
};
</script>
<style lang="scss" scoped>
.file-upload-field {
  input[type='file'] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  label {
    font-size: 0.75rem;
    font-weight: bold !important;
    &.image-preview-mode {
      background-color: #eee !important;
      border-color: transparent !important;
      display: block;
      min-height: 2.5rem;
      min-width: 2.5rem;
      width: 100%;
      height: 100%;
      padding: 0 !important;

      .image-preview-holder {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-color: transparent;
        &.is-empty {
          background-size: 50%;
        }
      }
    }
  }
  &:focus {
    outline: none;
    label {
      box-shadow: inset 0 1px 1px rgba($primary, 0.075),
        0 0 0 0.2rem rgba($primary, 0.25) !important;
    }
  }
  &:focus-within {
    outline: none;
    label {
      box-shadow: inset 0 1px 1px rgba($primary, 0.075),
        0 0 0 0.2rem rgba($primary, 0.25) !important;
    }
  }
  .form-text {
    font-size: 0.75rem;
  }
}
</style>
