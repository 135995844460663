<template>
  <b-navbar id="nav-top">
    <b-navbar-brand to="/">
      <logo color="black" size="158" />
    </b-navbar-brand>
    <b-navbar-nav class="ml-auto">
      <HeraNavbarUserItem />
    </b-navbar-nav>
  </b-navbar>
</template>
<script>
import logo from '../common/logo.vue';
import HeraNavbarUserItem from '../../components/navbar/HeraNavbarUserItem';

export default {
  name: 'HeraNavbar',
  components: {
    HeraNavbarUserItem,
    logo,
  },
  data() {
    return {};
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
#nav-top {
  min-height: 70px;
  max-height: 100px;
  background-color: white;
  border-bottom: 2px solid #ebedf8;
}
.navbar-brand {
  padding: 1.2rem 1rem;
}
</style>
