import { BASE_URL } from '@/api/config.js';
import { authorizedGetRequest, fileDownload } from '@/api/requests.js';

const ORDERS = BASE_URL + 'ecommerce/orders/administrative';

export const getOrders = (
  accessToken,
  pageToken = null,
  pageSize = 20,
  filters = {}
) =>
  authorizedGetRequest(ORDERS)(accessToken, {
    params: { page: pageToken, page_size: pageSize, ...filters },
  });

const exportAllOrders = (accessToken, fileName, filters = {}) => {
  return fileDownload(accessToken, `${ORDERS}/export`, fileName, {
    queryParams: { ...filters },
  });
};
export default {
  orders: {
    getOrders,
    exportAllOrders,
  },
};
