<template>
  <div class="tournaments-page-wrapper">
    <div class="container-fluid">
      <div class="row my-4 align-items-center">
        <h3 class="mb-0 pb-0 col">Tournaments</h3>
      </div>
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body pb-0 px-4">
              <div class="row px-0 pt-3">
                <div class="col-12">
                  <div class="section-description">Recent HERA tournaments</div>
                </div>
              </div>
              <div class="row mb-3">
                <div
                  v-for="filter in allTournamentFilters"
                  :key="filter.name"
                  class="col-auto"
                >
                  <label class="d-block">{{ filter.label }}:</label>
                  <autosuggest
                    v-if="filter.name === 'country'"
                    id="country"
                    v-model="filters[filter.name]"
                    :disabled="!tournaments.length"
                    placeholder="Start typing..."
                    display-property="text"
                    :source="suggestCountriesRequest"
                    @resultPicked="onFilterChange"
                  />
                  <dropdown
                    v-else
                    v-model="filters[filter.name]"
                    :list="getFiltersOptions(filter)"
                    empty-text="All"
                    :empty-option="true"
                    :could-be-empty="true"
                    class="input-like w-auto"
                    :disabled="
                      !getFiltersOptions(filter).length && !tournaments.length
                    "
                    @input="onFilterChange"
                  >
                    <template slot="chosen" slot-scope="chosen">
                      <span v-if="filter.name === 'teamSize'">
                        {{ chosen.chosenItem }}&nbsp;v&nbsp;{{
                          chosen.chosenItem
                        }}
                      </span>
                      <span v-else-if="filter.name === 'status'">
                        {{ distinctTournamentsStatus[chosen.chosenItem] }}
                      </span>
                      <span v-else-if="filter.name === 'game'">
                        {{ chosen.chosenItem.name }}
                      </span>
                      <span v-else-if="filter.name === 'platform'">
                        {{ chosen.chosenItem.name }}
                      </span>
                      <span v-else>{{ chosen.chosenItem }}</span>
                      <span class="sr-only">Choose option</span>
                    </template>
                    <template slot="item" slot-scope="options">
                      <span v-if="filter.name === 'teamSize'">
                        {{ options.item }}&nbsp;v&nbsp;{{ options.item }}
                      </span>
                      <span v-else-if="filter.name === 'status'">
                        {{ distinctTournamentsStatus[options.item] }}
                      </span>
                      <span v-else-if="filter.name === 'squad_size'">
                        {{ distinctTournamentsSquad[options.item] }}
                      </span>
                      <span v-else-if="filter.name === 'game'">
                        {{ options.item.name }}
                      </span>
                      <span v-else-if="filter.name === 'platform'">
                        {{ options.item.name }}
                      </span>
                      <span v-else>
                        {{ options.item }}
                      </span>
                    </template>
                  </dropdown>
                </div>
                <div class="col-auto">
                  <label class="d-block">&nbsp;</label>
                  <button
                    v-if="isFiltered"
                    class="btn mx-2 bg-transparent text-primary py-0"
                    @click.self.prevent="clearFilters"
                  >
                    Clear filters
                  </button>
                </div>
              </div>
            </div>
            <div class="card-body border-top border-muted pt-0 px-0">
              <infiniteScroll
                :list="tournaments"
                list-name="list"
                :is-disabled="isLoading || nextPageToken === null"
                :activation-gap="100"
                @ScrollToBottom="fetchTournaments"
              >
                <table
                  slot-scope="{ list }"
                  class="table table-hover mx-0 px-0 tournaments-list"
                >
                  <caption
                    v-if="!list.length && isLoading"
                    class="text-center text-muted"
                  >
                    Fetching tournaments...
                  </caption>
                  <caption
                    v-else-if="!list.length"
                    class="text-center text-muted"
                  >
                    No tournaments to show
                  </caption>
                  <template v-else>
                    <thead>
                      <tr>
                        <th
                          class="border-top-0 border-bottom-0 pr-2 pl-4 tournament-name section-description"
                        >
                          Name
                        </th>
                        <th
                          class="border-top-0 border-bottom-0 px-2 section-description"
                        >
                          Status
                        </th>
                        <th
                          class="border-top-0 border-bottom-0 px-2 section-description"
                        >
                          Rank
                        </th>
                        <th
                          class="border-top-0 border-bottom-0 px-2 section-description"
                        >
                          Game
                        </th>
                        <th
                          class="border-top-0 border-bottom-0 px-2 section-description"
                        >
                          Platforms
                        </th>
                        <th
                          class="border-top-0 border-bottom-0 px-2 section-description"
                        >
                          N. of clans
                        </th>
                        <th
                          class="border-top-0 border-bottom-0 px-2 section-description"
                        >
                          Location
                        </th>
                        <th
                          class="border-top-0 border-bottom-0 px-2 section-description"
                        >
                          Pin
                        </th>
                        <th
                          class="border-top-0 border-bottom-0 px-2 section-description"
                        >
                          Special Event
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <ListItem
                        v-for="tournament in list"
                        :key="tournament.id"
                        v-bind="{ tournament }"
                      />
                    </tbody>
                  </template>
                </table>
              </infiniteScroll>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { uniqBy } from 'lodash';

import { distinctTournamentsStatus } from '@/constants/tournaments';

import infiniteScroll from '@/components/common/infiniteScroll.vue';
import dropdown from '@/components/form/HeraFormDropdown.vue';
import autosuggest from '@/components/form/HeraAutosuggest';
import ListItem from './ListItem';

export default {
  name: 'TournamentsList',
  components: {
    dropdown,
    infiniteScroll,
    autosuggest,
    ListItem,
  },
  data() {
    return {
      filters: {},
      tournaments: [],
      distinctTournamentsStatus,
      next_page_token: undefined,
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters(['platforms', 'games']),

    isFiltered() {
      const allFilters = Object.keys(this.filters);
      return (
        allFilters.length && allFilters.some(filter => this.filters[filter])
      );
    },
    allTournamentFilters() {
      return [
        {
          name: 'platform',
          label: 'Platform',
          options: this.distinctTournamentsPlatforms,
        },
        {
          name: 'game',
          label: 'Game',
          options: this.distinctTournamentsGames,
        },
        {
          name: 'country',
          label: 'Location',
          options: null,
        },
        {
          name: 'status',
          label: 'Status',
          options: this.distinctTournamentsStatus,
        },
        {
          name: 'squad_size',
          label: 'Squad',
          options: this.distinctTournamentsSquad,
        },
        {
          name: 'pinned',
          label: 'Pin',
          options: ['Pinned'],
        },
        {
          name: 'special_event',
          label: 'Special Event',
          options: ['Special Event'],
        },
      ];
    },
    distinctTournamentsPlatforms() {
      return this.platforms;
      // return this.platforms.map(p => p.id);
    },
    distinctTournamentsGames() {
      return this.games;
      // return this.games.map(p => p.id);
    },
    distinctTournamentsSquad() {
      let squads = {};

      for (let i = 1; i <= 10; i++) {
        squads[i] = `${i}x${i}`;
      }

      return squads;
    },
  },
  created() {
    Promise.all([this.getPlatforms(), this.getGames()])
      .then(this.fetchTournaments)
      .catch(this.errorNotify);

    this.initializeFilterValues();
    this.clearCurrentTournament();
  },
  methods: {
    ...mapActions([
      'getTournaments',
      'suggestCountriesRequest',
      'getPlatforms',
      'getGames',
      'errorNotify',
    ]),
    ...mapMutations(['clearCurrentTournament']),
    getFiltersOptions(filter) {
      return filter.name === 'status' || filter.name === 'squad_size'
        ? Object.keys(filter.options)
        : [...filter.options];
    },
    getFilters() {
      return {
        ...this.filters,
        ...{
          platform: this.filters.platform ? this.filters.platform.id : null,
        },
        ...{
          game: this.filters.game ? this.filters.game.id : null,
        },
        ...{
          country: this.filters.country ? this.filters.country.id : null,
        },
        ...{
          pinned: this.filters.pinned === 'Pinned' || null,
        },
        ...{
          is_special_event:
            this.filters.special_event === 'Special Event' || null,
        },
        ...{
          order_by: 'tournament_start:desc',
        },
      };
    },
    onFilterChange() {
      this.tournaments = [];
      this.nextPageToken = undefined;
      this.fetchTournaments();
    },
    clearFilters() {
      this.initializeFilterValues();
      this.onFilterChange();
    },
    initializeFilterValues() {
      this.filters = this.allTournamentFilters.reduce((obj, el) => {
        obj[el.name] = null;
        return obj;
      }, {});
    },
    fetchTournaments() {
      this.isLoading = true;
      return this.getTournaments({
        page: this.nextPageToken,
        ...this.getFilters(),
      })
        .then(({ items, next_page_token }) => {
          this.nextPageToken = next_page_token;
          this.tournaments = uniqBy([...this.tournaments, ...items], 'id');
        })
        .catch(this.errorNotify)
        .finally(() => (this.isLoading = false));
    },
    tournamentDateString(tournament) {
      let date = tournament.tournament_start;
      return date.toLocaleString();
    },
  },
};
</script>
