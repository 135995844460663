<script>
import { cloneDeep, isFunction } from 'lodash';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export default {
  name: 'VirtualScroll',
  props: {
    itemHeight: {
      type: [Number, Function],
      required: true,
    },
    visibleCount: {
      type: [Number, Function],
      required: true,
    },
    visibleInstanceCount: {
      type: [Number, Function],
      default: 1, // equals to instanceCount defaults
    },
    enableInstanceHiding: {
      type: Boolean,
      default: true,
    },
    cacheCount: {
      type: [Number, Function],
      default: 0, // equal to visibleCount if not set
    },
    itemTag: {
      type: String,
      default: 'div',
    },
    itemClass: {
      type: [String, Object],
      default: '',
    },
    wrapperTag: {
      type: String,
      default: 'div',
    },
    wrapperClass: {
      type: [String, Object],
      default: '',
    },
    spyScrollOn: {
      type: null,
      default: '',
    },
    startIndex: {
      type: [Number, Function],
      default: 0,
    },
    scrollOffset: {
      type: Number,
      default: 0,
    },
    scrollXOffset: {
      type: Number,
      default: 0,
    },
    multipleMode: {
      type: Boolean,
      default: false,
    },
    instanceCount: {
      type: Number,
      default: 1,
    },
    spyScrollXOn: {
      type: null,
      default: '',
    },
    hasYScroll: {
      type: Boolean,
      default: true,
    },
    hasXScroll: {
      type: Boolean,
      default: false,
    },
    debounce: {
      type: Number,
      default: 0,
    },
    item: {
      type: Object,
      default: null,
    },
    itemsCount: {
      type: [Number, Function],
      default: 0,
    },
    itemProps: {
      type: Function,
      default() {},
    },
  },
  watch: {
    instanceCount() {
      this.changedProp = 'instanceCount';
      this.initialize();
      this.forceRender();
    },
    visibleInstanceCount() {
      // this.initialize()
      this.forceRender();
    },
    multipleMode() {
      this.changedProp = 'multipleMode';
      this.initialize();
      this.forceRender();
    },
    visibleCount() {
      this.changedProp = 'visibleCount';
      this.forceRender();
    },
    cacheCount() {
      this.changedProp = 'cacheCount';
      this.forceRender();
    },
    startIndex() {
      this.changedProp = 'startIndex';
      this.forceRender();
    },
    scrollOffset() {
      this.changedProp = 'scrollOffset';
      this.forceRender();
    },
    itemsCount() {
      this.changedProp = 'itemsCount';
      this.forceRender();
    },
  },
  created() {
    this.updateObserver = new Subject();
    this.updateObserver
      .pipe(debounceTime(self.debounce))
      .subscribe(this.forceRender);
    this.initialize();
  },
  mounted() {
    const self = this;
    if (this.hasYScroll && !this.scrollYObserver) {
      const $element = this.spyScrollOn
        ? document.body.querySelector(this.spyScrollOn)
        : null;

      this.scrollYObserver = fromEvent($element || window, 'scroll')
        .pipe(debounceTime(self.debounce))
        .subscribe(event => {
          self.onScroll(event, $element);
        });
    }
    if (this.hasXScroll && !this.scrollXObserver) {
      const $element = this.spyScrollXOn
        ? document.body.querySelector(this.spyScrollXOn)
        : null;

      this.scrollXObserver = fromEvent($element || window, 'scroll')
        .pipe(debounceTime(self.debounce))
        .subscribe(event => {
          self.onScrollX(event, $element);
        });
    }
    if (!this.scrollObserver) {
      this.scrollObserver = new Subject();
      this.scrollObserver
        .pipe(debounceTime(self.debounce))
        .subscribe(scrollData => {
          this.forceRender();
          this.$emit('scroll', scrollData);
        });
    }
  },
  beforeDestroy() {
    if (this.scrollYObserver) {
      this.scrollYObserver.unsubscribe();
    }
    if (this.scrollXObserver) {
      this.scrollXObserver.unsubscribe();
    }
    if (this.scrollObserver) {
      this.scrollObserver.unsubscribe();
    }
    this.updateObserver.unsubscribe();
  },
  methods: {
    initialize() {
      let delta;
      if (this.multipleMode) {
        delta = [];
        for (let i = 0; i < this.instanceCount; i += 1) {
          const itemHeight = this.getPropValue('itemHeight', i);
          const visibleCount = this.getPropValue('visibleCount', i);
          const startIndex = this.getPropValue('startIndex', i);
          const cacheCount = this.getPropValue('cacheCount', i);
          const itemsCount = this.getPropValue('itemsCount', i);
          const startPosition = startIndex >= visibleCount ? startIndex : 0;
          const keepInDOM = Math.min(
            visibleCount + (cacheCount || visibleCount),
            itemsCount
          );
          const totalHeight = itemHeight * itemsCount;

          delta[i] = {
            direction: '', // current scroll direction, D: down, U: up
            scrollTop: 0, // current scroll top, use to direction
            scrollLeft: 0, // current scroll left, use to direction
            start: startPosition, // start index
            end: Math.min(startPosition + keepInDOM - 1, itemsCount - 1), // end index
            keepInDOM, // nums keeping in real dom
            totalItems: itemsCount, // all items count, update in getItemsRender
            offsetAll: totalHeight - itemHeight * visibleCount, // cache all the scrollable offset
            paddingTop: 0, // container wrapper real padding-top
            paddingBottom: 0, // container wrapper real padding-bottom
            isVisible: i < this.visibleInstanceCount,
          };
        }
      } else {
        // const itemHeight = this.getPropValue('itemHeight');
        const visibleCount = this.getPropValue('visibleCount');
        const startIndex = this.getPropValue('startIndex');
        const cacheCount = this.getPropValue('cacheCount');
        const itemsCount = this.getPropValue('itemsCount');
        const startPosition = startIndex >= visibleCount ? startIndex : 0;

        const keepInDOM = visibleCount + (cacheCount || visibleCount);

        delta = {
          direction: '', // current scroll direction, D: down, U: up
          scrollTop: 0, // current scroll top, use to direction
          scrollLeft: 0, // current scroll left, use to direction
          start: startPosition, // start index
          end: Math.min(startPosition + keepInDOM - 1, itemsCount - 1), // end index
          keepInDOM, // nums keeping in real dom
          totalItems: itemsCount, // all items count, update in getItemsRender
          offsetAll: 0, // cache all the scrollable offset
          paddingTop: 0, // container wrapper real padding-top
          paddingBottom: 0, // container wrapper real padding-bottom
        };
      }
      this.delta = delta;
    },
    getPropValue(prop = '', instanceIndex = 0) {
      return !prop
        ? null
        : isFunction(this[prop])
        ? this[prop](instanceIndex)
        : this[prop];
    },
    getDelta(instanceIndex) {
      return this.multipleMode
        ? cloneDeep(this.delta[instanceIndex])
        : cloneDeep(this.delta);
    },
    onScroll(event, element) {
      let offsetAll, reachBottom, reachTop;

      const offset =
        (element ? element.scrollTop || 0 : window.pageYOffset || 0) +
        this.scrollOffset;

      if (this.multipleMode) {
        for (let i = 0; i < this.instanceCount; i += 1) {
          const delta = this.getDelta(i);

          if (delta) {
            delta.direction = offset > delta.scrollTop ? 'D' : 'U';
            delta.scrollTop = offset;
            // this.delta[i] = delta;
            if (delta.totalItems > delta.keepInDOM) {
              this.updateZone({ offset, instanceIndex: i, delta });
            } else {
              delta.end = delta.totalItems > 1 ? delta.totalItems - 1 : 1;
              this.delta[i] = delta;
            }
          }
        }
        offsetAll = this.delta[0].offsetAll;
        reachTop = !offset && this.delta[0].totalItems;
        reachBottom = offset >= offsetAll;
      } else {
        const delta = this.getDelta();
        delta.direction = offset > delta.scrollTop ? 'D' : 'U';
        delta.scrollTop = offset;
        if (delta.totalItems > delta.keepInDOM) {
          this.updateZone({ offset, delta });
        } else {
          delta.end = delta.totalItems - 1;
        }

        offsetAll = delta.offsetAll;
        reachTop = !offset && delta.totalItems;
        reachBottom = offset >= offsetAll;
        this.delta = delta;
      }

      this.scrollObserver.next({
        offsetY: offset,
        offsetAll,
        originalEvent: event,
      });
      if (reachTop) {
        this.$emit('reachTop');
      }

      if (reachBottom) {
        this.$emit('reachBottom');
      }
    },
    onScrollX(event, element) {
      const delta = this.getDelta(0);
      const offsetY = delta.scrollTop;
      // let needRerender = false;
      const offsetX =
        (element ? element.scrollLeft || 0 : window.pageXOffset || 0) -
        this.scrollXOffset;

      const offsetAll = delta.offsetAll;
      const offsetXAll = element
        ? element.scrollWidth - element.clientWidth
        : document.body.scrollWidth - document.body.clientWidth;

      const reachLeft = !offsetX;
      const reachRight = offsetX >= offsetXAll;

      if (this.multipleMode) {
        const totalWidth = this.$el.scrollWidth;
        const instanceWidth = totalWidth / this.instanceCount;

        const firstVisibleInstance = Math.floor(offsetX / instanceWidth);

        let updates = [];
        for (let i = 0; i < this.instanceCount; i += 1) {
          const delta = this.getDelta(i);
          if (delta) {
            delta.directionX = offsetX > delta.scrollLeft ? 'R' : 'L';
            delta.scrollLeft = offsetX;
            delta.isVisible =
              firstVisibleInstance + this.visibleInstanceCount > i &&
              i >= firstVisibleInstance;
            if (this.instanceCount > this.visibleInstanceCount) {
              updates[i] = this.updateZone({
                offset: delta.scrollTop,
                instanceIndex: i,
                delta,
              });
            }
          }
        }
        // needRerender = updates.some(el => el);
      }

      this.scrollObserver.next({
        offsetY,
        offsetX,
        offsetAll,
        offsetXAll,
        originalEvent: event,
      });
      if (reachLeft) {
        this.$emit('reachLeft');
      }

      if (reachRight) {
        this.$emit('reachRight');
      }
    },
    updateZone({ offset, delta, instanceIndex = 0 } = {}) {
      // let needRerender = false;
      const itemHeight = this.getPropValue('itemHeight', instanceIndex);
      // const visibleCount = this.getPropValue('visibleCount', instanceIndex);
      // const cacheCount = this.getPropValue('cacheCount', instanceIndex);
      let scrolledFromTopCount = itemHeight
        ? Math.floor(offset / itemHeight)
        : 0;

      const zone = this.getZone(scrolledFromTopCount, delta);
      // const bench = cacheCount || visibleCount;

      const isSameZone = zone.start === delta.start && zone.end === delta.end;

      if (!isSameZone) {
        delta.end = zone.end;
        delta.start = zone.start;
        if (this.multipleMode) {
          this.delta[instanceIndex] = delta;
        } else {
          this.delta = delta;
        }
        this.updateObserver.next(true);
      }
    },
    // return the right zone info base on `start/index`.
    getZone(startIndex, delta) {
      // const delta = this.getDelta(instanceIndex)
      let start, end;
      const index = Math.max(0, parseInt(startIndex, 10));

      const lastPossibleStartIndex = delta.totalItems - delta.keepInDOM;
      const isLast =
        (delta.totalItems >= index && index >= lastPossibleStartIndex) ||
        index > delta.totalItems;

      if (isLast) {
        end = delta.totalItems - 1;
        start = Math.max(0, lastPossibleStartIndex);
      } else {
        start = index;
        end = start + delta.keepInDOM - 1;
      }

      return {
        end,
        start,
        isLast,
      };
    },
    // public method, force render ui list if we needed.
    // call this before the next repaint to get better performance.
    forceRender() {
      window.requestAnimationFrame(() => {
        this.$forceUpdate();
      });
    },
    getItemsRender(h, instanceIndex = 0) {
      const delta = this.getDelta(instanceIndex);

      const itemsCount = this.getPropValue('itemsCount', instanceIndex);
      const itemHeight = this.getPropValue('itemHeight', instanceIndex);

      // item-mode shoud judge from items prop.
      if (this.item) {
        delta.totalItems = itemsCount;
        if (delta.keepInDOM > delta.totalItems) {
          delta.end = delta.totalItems > 1 ? delta.totalItems - 1 : 1;
        }
      }

      let paddingTop, paddingBottom;
      const hasPadding = delta.totalItems > delta.keepInDOM;

      paddingTop = itemHeight * (hasPadding ? delta.start : 0);
      paddingBottom =
        itemHeight * (hasPadding ? delta.totalItems - delta.keepInDOM : 0) -
        paddingTop;

      if (paddingBottom < itemHeight) {
        paddingBottom = 0;
      }

      delta.paddingTop = paddingTop;
      delta.paddingBottom = paddingBottom;

      let renders = [];
      for (
        let i = delta.start;
        i < delta.totalItems && i <= Math.ceil(delta.end);
        i += 1
      ) {
        let slot = h(this.item, {
          ...this.itemProps(i, instanceIndex),
          style: {
            height: itemHeight + 'px',
          },
        });
        renders.push(slot);
      }
      this.delta[instanceIndex] = delta;
      return renders;
    },
  },
  render(h) {
    let render;
    if (this.multipleMode) {
      const renderItems = [];
      for (let i = 0; i < this.instanceCount; i += 1) {
        if (this.delta[i]) {
          const list = this.getItemsRender(h, i);
          const { paddingTop, paddingBottom } = this.delta[i];

          // const list = !this.enableInstanceHiding || this.delta[i].isVisible
          //   ? this.getItemsRender(h, i)
          //   : [];
          renderItems[i] = h(
            this.itemTag,
            {
              class: this.itemClass,
              style: {
                'padding-top': paddingTop + 'px',
                'padding-bottom': paddingBottom + 'px',
              },
              attrs: {
                role: 'group',
              },
            },
            list
          );
        }
      }
      render = h(
        this.wrapperTag,
        {
          class: this.wrapperClass,
          attrs: {
            role: 'group',
          },
        },
        renderItems
      );
    } else {
      const list = this.getItemsRender(h);
      const { paddingTop, paddingBottom } = this.delta;

      render = h(
        this.itemTag,
        {
          ref: 'vsl',
          class: this.itemClass,
          style: {
            'padding-top': paddingTop + 'px',
            'padding-bottom': paddingBottom + 'px',
          },
          attrs: {
            role: 'group',
          },
        },
        list
      );
    }

    return render;
  },
};
</script>
