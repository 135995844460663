import { BASE_URL } from '@/api/config.js';
import {
  authorizedGetRequest,
  authorizedPostRequest,
  authorizedPutRequest,
  authorizedDeleteRequest,
  fileDownload,
} from '@/api/requests.js';

const PAYMENTS = BASE_URL + 'payments';
const SUBSCRIPTIONS = BASE_URL + 'subscriptions';
const getSubscriptions = authorizedGetRequest(`${PAYMENTS}/subscriptions`);
const createSubscription = authorizedPostRequest(`${PAYMENTS}/subscription/`);
const getSubscription = id =>
  authorizedGetRequest(`${PAYMENTS}/subscription/${id}`);
const updateSubscription = id =>
  authorizedPutRequest(`${PAYMENTS}/subscription/${id}`);
const deleteSubscription = id =>
  authorizedDeleteRequest(`${PAYMENTS}/subscription/${id}`);

const getExternalReports = (
  accessToken,
  pageToken = null,
  pageSize = 20,
  filters = {}
) =>
  authorizedGetRequest(`${SUBSCRIPTIONS}/external/report`)(accessToken, {
    params: { page_index: pageToken, page_size: pageSize, ...filters },
  });

const getExternalAudit = (
  accessToken,
  pageToken = null,
  pageSize = 20,
  filters = {}
) =>
  authorizedGetRequest(`${SUBSCRIPTIONS}/external/audittrails`)(accessToken, {
    params: { page_index: pageToken, page_size: pageSize, ...filters },
  });

const exportAllReports = (accessToken, fileName, filters = {}) => {
  return fileDownload(
    accessToken,
    `${SUBSCRIPTIONS}/external/report/export`,
    fileName,
    {
      queryParams: { ...filters },
    }
  );
};
const exportAllAuditReports = (accessToken, fileName, filters = {}) => {
  return fileDownload(
    accessToken,
    `${SUBSCRIPTIONS}/external/audittrails/export`,
    fileName,
    {
      queryParams: { ...filters },
    }
  );
};

const getVouchers = (
  accessToken,
  pageToken = null,
  pageSize = 20,
  filters = {}
) =>
  authorizedGetRequest(`${PAYMENTS}/voucher`)(accessToken, {
    params: { page_index: pageToken, page_size: pageSize, ...filters },
  });

const exportVouchers = (accessToken, fileName, filters = {}) => {
  return fileDownload(accessToken, `${PAYMENTS}/export/voucher`, fileName, {
    queryParams: { ...filters },
  });
};

const updateVoucher = id => authorizedPutRequest(`${PAYMENTS}/voucher/${id}`);
const createVoucher = authorizedPostRequest(`${PAYMENTS}/voucher/`);
const getVoucher = id => authorizedGetRequest(`${PAYMENTS}/voucher/${id}`);
export default {
  subscriptions: {
    getSubscriptions,
    getSubscription,
    createSubscription,
    updateSubscription,
    deleteSubscription,
    getExternalAudit,
    getExternalReports,
    exportAllReports,
    exportAllAuditReports,
    getVouchers,
    exportVouchers,
    updateVoucher,
    createVoucher,
    getVoucher,
  },
};
