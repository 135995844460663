<template>
  <span class="badge badge-hera" :style="{ '--height': height }">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: 'Badge',
  props: {
    height: {
      type: String,
      default: '14px',
    },
  },
};
</script>

<style lang="scss">
.badge-hera {
  --height: 12px;
  background-color: $hera-main-color;
  color: #fff;
  height: var(--height);
  line-height: var(--height);
  border-radius: calc(var(--height) / 2);
  padding: 0 calc(var(--height) / 2);
}
</style>
