<template>
  <div class="subscriptions-page-wrapper">
    <div class="container-fluid">
      <div class="row align-items-center promo-header">
        <AppIcon
          name="back"
          width="16"
          class="ml-3 cursor-pointer"
          @click="$router.go(-1)"
        />
        <h3 class="mb-0 pb-0 col">{{ promoCodeInfo.code }}</h3>
        <button
          :class="{ 'is-loading': isInfoLoading }"
          class="btn btn-sm btn-outline-danger mr-3"
          @click="onDeleteItem"
        >
          Remove
        </button>
      </div>
      <div v-if="isInfoLoading">
        <HeraDummyList icon-name="info" position="center">
          {{ $t('_web_proleagues_dummy-loading', 'Fetching data ...') }}
        </HeraDummyList>
      </div>
      <table v-else class="table table-hover mx-0 px-0">
        <thead>
          <tr>
            <th class="border-top-0 border-bottom-0 px-2 section-description">
              Codename
            </th>
            <th class="border-top-0 border-bottom-0 px-2 section-description">
              Discount
            </th>
            <th class="border-top-0 border-bottom-0 px-2 section-description">
              Expiration <br />date
            </th>
            <th class="border-top-0 border-bottom-0 px-2 section-description">
              Country
            </th>
            <th class="border-top-0 border-bottom-0 px-2 section-description">
              Associated <br />with
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="coupon-list-item">
            <td>{{ promoCodeInfo.code }}</td>
            <td>{{ promoCodeInfo.amount }}</td>
            <td>{{ formattedDateString(promoCodeInfo.activeTo) }}</td>
            <td>{{ promoCodeInfo.countryId }}</td>
            <td>{{ promoCodeInfo.promoter }}</td>
          </tr>
        </tbody>
      </table>

      <div class="row align-items-center mt-5 mb-4">
        <h3 class="ml-3 mb-0 pb-0 ">Users</h3>
        <small class="col">
          {{ usages.length }}
          {{ usages.length > 1 ? 'users' : 'user' }}
        </small>
        <button
          :class="{ 'is-loading': isExporting }"
          class="btn btn-primary ml-auto mr-4"
          @click="exportUsagesToXLS"
        >
          Export to XLS
        </button>
      </div>

      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body border-top border-muted pt-0 px-0">
              <infiniteScroll
                :list="usages"
                list-name="list"
                :is-disabled="isLoading"
                :activation-gap="100"
              >
                <table
                  slot-scope="{ list }"
                  class="table table-hover mx-0 px-0 subscriptions-list"
                >
                  <caption
                    v-if="!list.length && isLoading"
                    class="text-center text-muted"
                  >
                    Fetching promocode usages...
                  </caption>
                  <caption
                    v-else-if="!list.length"
                    class="text-center text-muted"
                  >
                    No users to show, this promocode was not used
                  </caption>
                  <template v-else>
                    <thead>
                      <tr>
                        <th
                          class="border-top-0 border-bottom-0 px-2 section-description"
                        >
                          Used by
                        </th>
                        <th
                          class="border-top-0 border-bottom-0 px-2 section-description"
                        >
                          Subscription
                        </th>
                        <th
                          class="border-top-0 border-bottom-0 px-2 section-description"
                        >
                          Date&time <br />of usage
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="coupon in list"
                        :key="coupon.id"
                        class="coupon-list-item"
                      >
                        <td>{{ coupon.usedBy }}</td>
                        <td>{{ coupon.subscription }}</td>
                        <td>{{ formattedDateString(coupon.usedOn) }}</td>
                      </tr>
                    </tbody>
                  </template>
                </table>
              </infiniteScroll>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import infiniteScroll from '@/components/common/infiniteScroll.vue';
import HeraDummyList from '@/components/common/DummyList';
import AppIcon from '@/components/common/Icon';

export default {
  name: 'PromoCodeInfo',
  components: { AppIcon, HeraDummyList, infiniteScroll },
  data() {
    return {
      isLoading: true,
      isInfoLoading: true,
      isExporting: false,
      usages: [],
      promoCodeInfo: {},
    };
  },
  computed: { ...mapGetters(['coupons']) },
  created() {
    this.fetchCoupon();
    this.fetchUsages();
  },
  methods: {
    ...mapMutations(['removeCoupon']),
    ...mapActions([
      'getCoupon',
      'fetchPromoCodesUsage',
      'deleteCoupon',
      'exportPromoCode',
      'errorNotify',
      'successNotify',
    ]),
    fetchCoupon() {
      this.isInfoLoading = true;
      this.getCoupon(this.$route.params.promocodeId)
        .then(data => {
          this.promoCodeInfo = data;
        })
        .catch(this.errorNotify)
        .finally(() => (this.isInfoLoading = false));
    },
    fetchUsages() {
      this.fetchPromoCodesUsage(this.$route.params.promocodeId)
        .then(data => {
          this.usages = data;
        })
        .catch(this.errorNotify)
        .finally(() => (this.isLoading = false));
    },
    onDeleteItem() {
      if (!confirm('Are you sure? This action cannot be cancelled.')) {
        return;
      }
      this.isLoading = true;

      this.deleteCoupon({ id: this.$route.params.promocodeId })
        .then(() => {
          this.successNotify('Promocode removed!');
          this.$router.push({ name: 'promocodes' });
        })
        .catch(this.errorNotify)
        .finally(() => (this.isLoading = false));
    },
    formattedDateString(query) {
      let expDate = query ? new Date(query * 1000) : null;
      let formattedDate = '';
      if (expDate) {
        formattedDate += `${this.addLeadingZero(expDate.getUTCDate())}/`;
        formattedDate += `${this.addLeadingZero(expDate.getUTCMonth() + 1)}/`;
        formattedDate += `${expDate.getUTCFullYear()}`;
      }
      return formattedDate.length ? formattedDate : '';
    },
    addLeadingZero(num) {
      return (num < 10 ? '0' : '') + num;
    },
    exportUsagesToXLS() {
      this.isExporting = true;

      this.exportPromoCode({
        id: this.promoCodeInfo.id,
        code: this.promoCodeInfo.code,
      })
        .then(() => {})
        .catch(this.errorNotify)
        .finally(() => (this.isExporting = false));
    },
  },
};
</script>

<style scoped lang="scss">
.promo-header {
  margin-bottom: 48px;
  margin-top: 40px;
}
</style>
