<template>
  <li
    class="newsfeed-settings-list-item d-flex align-items-center justify-content-between"
  >
    <span>
      <Icon
        class="handle"
        :class="{ 'mr-4': !isRemovable, 'cursor-move': isDraggable }"
        :fill="$scss.secondaryBackgroundColor"
        width="22"
        name="draggable"
      />
      <span v-if="showIndex" class="mx-3">{{ index + 1 }}</span>
      {{ item.label }}
    </span>
    <div>
      <template v-if="isRemovable && item.id === 'banner'">
        <button
          :disabled="disabled"
          class="btn btn-sm"
          :class="'btn-outline-primary'"
          @click="$emit('editPost', item)"
        >
          Edit
        </button>
        <span class="mx-1"></span>
      </template>
      <button
        v-if="!hideButton"
        :disabled="disabled"
        class="btn btn-sm"
        :class="isRemovable ? 'btn-outline-danger' : 'btn-outline-primary'"
        @click="$emit(isRemovable ? 'removePost' : 'addPost', item)"
      >
        <template v-if="isRemovable">
          Remove
        </template>
        <template v-else>
          Move to Line
        </template>
      </button>
    </div>
  </li>
</template>

<script>
import Icon from '@/components/common/Icon';

export default {
  components: { Icon },
  props: {
    item: {
      type: Object,
      required: true,
    },
    showIndex: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
    isRemovable: {
      type: Boolean,
      default: false,
    },
    isDraggable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideButton: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.newsfeed-settings-list-item {
  height: 3rem;
  padding: 0 1.25rem;
  font-size: 0.75rem;
  font-weight: 700;
  border-bottom: 1px solid $secondary;
}
</style>
