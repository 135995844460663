import { isEqual, cloneDeep, reduce, isObject } from 'lodash';

export const diff = {
  methods: {
    diff(obj1, obj2, path) {
      obj1 = obj1 || {};
      obj2 = obj2 || {};

      let startObj =
        Object.keys(obj1).length > Object.keys(obj2).length
          ? cloneDeep(obj1)
          : cloneDeep(obj2);

      let compareObj =
        Object.keys(obj1).length > Object.keys(obj2).length
          ? cloneDeep(obj2)
          : cloneDeep(obj1);

      return reduce(
        startObj,
        (result, value, key) => {
          let p = path ? path + '.' + key : key;
          if (isObject(value)) {
            let d = this.diff(value, compareObj[key], p);
            return d.length ? result.concat(d) : result;
          }
          return isEqual(value, compareObj[key]) ? result : result.concat(p);
        },
        []
      );
    },
    getObjectDifference(newObj, prevObj) {
      return this.diff(newObj, prevObj).reduce((obj, key) => {
        const propPath = key.split('.');
        const propName = propPath[0];
        const hasProp = newObj.hasOwnProperty(propName);
        let prop = newObj[propName];
        return hasProp
          ? {
              ...obj,
              ...{
                [propName]: prop,
              },
            }
          : obj;
      }, {});
    },
  },
};

export const converter = {
  methods: {
    /**
     * convert seconds to miliseconds
     * @param sec
     * @returns {number}
     */
    secToMsec(sec) {
      return +sec * 1e3;
    },
    /**
     * convert miliseconds to seconds and round it
     * @param msec
     * @returns {number}
     */
    msecToSec(msec) {
      return (+msec / 1e3) | 0;
    },
  },
};

export default {
  diff,
  converter,
};
