import api from '@/api';
import Vue from 'vue';
export const DEFAULT_LEAGUES_STATE = () => ({
  // deprecated code, leagues array are in local component state
  // will be deleted after testing
  // leagues: [],
  currentLeague: {},
});

const getters = {
  // deprecated code, leagues array are in local component state
  // will be deleted after testing
  // leagues: state => state.leagues,
  leagueByID: state => ID => state.leagues.find(el => el.id === ID),
  currentLeague: state => state.currentLeague,
  isLeagueDraft: state =>
    state.currentLeague && state.currentLeague.league_status === 'draft',
  isLeaguePublished: state =>
    state.currentLeague && state.currentLeague.league_status === 'published',
  isSeasonActive: state =>
    state.currentLeague &&
    state.currentLeague.season &&
    state.currentLeague.season.season_status === 'active',
  isLeagueRejected: state =>
    state.currentLeague && state.currentLeague.league_status === 'rejected',
};

const mutations = {
  // deprecated code, leagues array are in local component state
  // will be deleted after testing
  //  setLeagues (state, payload) {
  //   state.leagues = []
  //   for (var i = 0; i < payload.length; i += 1) {
  //     Vue.set(state.leagues, i, {})
  //     state.leagues[i] = {...state.leagues[i], ...payload[i]}
  //   }
  // },
  setCurrentLeague(state, payload) {
    state.currentLeague = payload;
  },
  // deprecated code, leagues array are in local component state
  // will be deleted after testing
  // addLeague (state, payload) {
  //   state.leagues = [payload].concat(state.leagues)
  // },
  // updateLeague (state, payload) {
  //   let index = state.leagues.findIndex(el => el.id === payload.id)
  //   state.leagues[index] = {...state.leagues[index], ...payload}
  // },
  updateCurrentLeague(state, payload) {
    for (let key in payload) {
      Vue.set(state.currentLeague, key, payload[key]);
    }
  },
  clearCurrentLeague(state) {
    state.currentLeague = {};
  },
};

const actions = {
  // getLeagueById({commit, dispatch, getters: {accessToken}}, leagueID){
  //     return api
  //         .getLeagueById(leagueID)(accessToken)()
  //         .then(response => {
  //             commit('setCurrentLeague', response.data)
  //             return response.data
  //         })
  //         .catch(error => {
  //             dispatch('errorNotify', error)
  //         })
  // },
  getLeagueClans(
    {
      dispatch,
      getters: { accessToken },
    },
    payload = { pageToken: null, query: '' }
  ) {
    return api
      .getLeagueClans(payload.pageToken, payload.query)(accessToken)
      .then(response => response.data)
      .catch(error => {
        dispatch('errorNotify', error);
      });
  },
  getLeagueProfiles(
    {
      dispatch,
      getters: { accessToken },
    },
    payload = { pageToken: null, query: '' }
  ) {
    return api
      .getLeagueProfiles(payload.pageToken, payload.query)(accessToken)
      .then(response => response.data)
      .catch(error => {
        dispatch('errorNotify', error);
      });
  },
  getLeagueMatches(
    {
      dispatch,
      getters: { accessToken },
    },
    payload = { pageToken: null, eventId: '' }
  ) {
    return api.battles
      .getList(payload.pageToken, payload.eventId)(accessToken)
      .then(response => response.data)
      .catch(error => {
        dispatch('errorNotify', error);
      });
  },
  getLeagues(
    {
      dispatch,
      getters: { accessToken },
    },
    payload = { pageToken: null, query: '' }
  ) {
    return api
      .getLeagues(payload.pageToken, payload.query)(accessToken)
      .then(response => response.data)
      .catch(error => {
        dispatch('errorNotify', error);
      });
  },
  fetchLeagueDetails(
    {
      dispatch,
      getters: { accessToken, platformByID, gameByID },
      commit,
    },
    id
  ) {
    return api
      .getLeague(id)(accessToken)
      .then(response => {
        const data = response.data;
        if (!data) {
          return null;
        }
        const league = {
          ...data,
          platform: data.platform ? platformByID(data.platform) : null,
          game: data.game ? gameByID(data.game) : null,
          country: {
            text: data.country,
            id: data.country_id,
          },
          countries_restrictions: Array.isArray(data.countries_restrictions)
            ? data.countries_restrictions.map(c => ({ id: c.id, text: c.name }))
            : [],
        };
        commit('setCurrentLeague', league);
        return league;
      })
      .catch(error => {
        dispatch('errorNotify', error);
      });
  },
  updateLeague(
    {
      dispatch,
      commit,
      getters: { accessToken },
    },
    payload
  ) {
    return new Promise(resolve => {
      // commit('updateLeague', payload)
      api
        .updateLeague(payload.leagueId)(accessToken, payload)
        .then(() => dispatch('fetchLeagueDetails', payload.leagueId))
        .then(response => {
          commit('updateCurrentLeague', response);
          resolve(true);
        })
        .catch(error => {
          dispatch('errorNotify', error);
        });
    });
  },
  createLeague(
    {
      commit,
      getters: { accessToken, platformByID, gameByID },
    },
    league
  ) {
    return new Promise((resolve, reject) => {
      api
        .createLeague(accessToken, league)
        .then(response => {
          let {
            id,
            sponsors_logos,
            logo,
            cover,
            portrait_logo,
            rules_link,
            league_status,
          } = response.data;
          // deprecated code, leagues array are in local component state
          // will be deleted after testing
          // commit('addLeague', response.data)
          commit('setCurrentLeague', {
            ...league,
            id,
            sponsors_logos,
            logo,
            cover,
            portrait_logo,
            rules_link,
            league_status,
            platform: league.platform
              ? { ...platformByID(league.platform) }
              : null,
            game: league.game ? { ...gameByID(league.game) } : null,
            country: {
              text: league.country,
              id: league.country_id,
            },
            countries_restrictions: Array.isArray(league.countries_restrictions)
              ? league.countries_restrictions.map(c => ({
                  id: c.id,
                  text: c.name,
                }))
              : [],
          });
          resolve(id);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  publishLeague(
    {
      commit,
      dispatch,
      getters: { accessToken },
    },
    leagueID
  ) {
    return api
      .publishLeague(leagueID)(accessToken)
      .then(() => {
        commit('updateCurrentLeague', { league_status: 'published' });
      })
      .catch(error => {
        dispatch('errorNotify', error);
      });
  },
  rejectLeague(
    {
      commit,
      getters: { accessToken },
    },
    payload
  ) {
    const { leagueID, reason } = payload;
    return api
      .rejectLeague(leagueID)(accessToken, { reason })
      .then(() => {
        commit('updateCurrentLeague', { league_status: 'rejected' });
      })
      .catch(reason => {
        commit('updateCurrentLeague', { league_status: 'rejected' });
        return Promise.reject(reason);
      });
  },
  restartLeague(
    {
      commit,
      getters: { accessToken },
    },
    leagueID
  ) {
    return api
      .restartLeague(leagueID)(accessToken)
      .then(() => {
        commit('updateCurrentLeague', { league_status: 'draft' });
      });
  },
  updateLeagueSchedule({ dispatch, getters }, payload) {
    return api
      .updateLeagueSchedule(payload.leagueID)(
        getters.accessToken,
        payload.schedule
      )
      .catch(error => {
        dispatch('errorNotify', error);
      });
  },
  fetchLeagueSchedule({ dispatch, getters }, leagueID) {
    return api
      .getLeagueScheduleById(leagueID)(getters.accessToken)
      .then(response => response.data)
      .catch(error => {
        dispatch('errorNotify', error);
      });
  },
  fetchLeagueSeasons({ dispatch, getters }, leagueID) {
    return api
      .getSeasons(leagueID)(getters.accessToken)
      .then(response => {
        //commit('LeagueSeasons', response.data)
        return response.data;
      })
      .catch(error => {
        dispatch('errorNotify', error);
      });
  },
  createLeagueSeason(
    {
      dispatch,
      getters: { accessToken },
    },
    payload
  ) {
    const { leagueID } = payload;
    let data = { ...payload };
    delete data.leagueID;
    return api
      .createLeagueSeason(leagueID)(accessToken, data)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        dispatch('errorNotify', error);
      });
  },
  deleteLeagueSeason(
    {
      dispatch,
      getters: { accessToken },
    },
    payload
  ) {
    const { leagueID, id } = payload; // id is  season's id
    return api
      .deleteLeagueSeason(leagueID, id)(accessToken)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        dispatch('errorNotify', error);
      });
  },
  updateLeagueSeason(
    {
      dispatch,
      getters: { accessToken },
    },
    payload
  ) {
    const { leagueID, id } = payload; // id is season's id
    let data = { ...payload };
    delete data.leagueID;
    return api
      .updateLeagueSeason(leagueID, id)(accessToken, data)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        dispatch('errorNotify', error);
      });
  },
};

export default {
  state: DEFAULT_LEAGUES_STATE(),
  getters,
  mutations,
  actions,
};
