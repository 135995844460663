import api from '@/api';

const actions = {
  fetchToken({ getters: { accessToken } }) {
    return api.getToken(accessToken).then(response => response.data);
  },
};

export default {
  actions,
};
