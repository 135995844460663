import Vue from 'vue';
import api from '@/api';

export const INITIAL_BATTLES_STATE = () => {
  return {
    playersPopularForEdit: [],
    countriesPopular: [],
    playersPopularVersion: 0,
  };
};

const getters = {
  playersPopularForEdit: state => state.playersPopularForEdit,
  countriesPopular: state => state.countriesPopular,
  playersPopularVersion: state => state.playersPopularVersion,
};

const mutations = {
  setPopularPeopleList(state, profiles) {
    state.playersPopularForEdit = profiles.map((el, index) => {
      Object.assign(el, el.profile, { index });
      return el;
    });
  },
  setCountriesPopular(state, countries) {
    state.countriesPopular = countries;
  },
  setVersion(state, data) {
    state.playersPopularVersion = data;
  },
  addPopularCountry(state, { index, country }) {
    Vue.set(state.countriesPopular, index, country);
  },
  changePopularCountryCount(state, { country_id, value }) {
    const country = state.countriesPopular.find(
      el => el.country.id === country_id
    );
    if (country) country.count = value;
  },
};

const actions = {
  getPopularPeople(
    {
      getters: { accessToken },
    },
    pageToken
  ) {
    return api.popularPlayers.getPopularPeople(accessToken, {
      params: { pageToken },
    });
  },
  getPopularPeopleForEdit(
    {
      commit,
      getters: { accessToken },
    },
    country_id
  ) {
    return api.popularPlayers
      .getPopularPeopleForEdit(country_id)(accessToken)
      .then(res => {
        const { version, profiles } = res.data;
        commit('setPopularPeopleList', profiles);
        commit('setVersion', version);
      });
  },
  getPopularPeopleCountries(
    {
      commit,
      dispatch,
      getters: { accessToken },
    },
    pageToken
  ) {
    return api.popularPlayers
      .getPopularPeopleCountries(accessToken, { params: { pageToken } })
      .then(res => {
        const { countries } = res.data;
        commit('setCountriesPopular', countries);
      })
      .catch(error => {
        dispatch('errorNotify', error);
      });
  },
  updatePopularPeople(
    {
      commit,
      getters: { accessToken, playersPopularVersion },
    },
    { country_id, props }
  ) {
    const data = {
      ...props,
      version: playersPopularVersion,
    };

    return api.popularPlayers
      .updatePopularPeople(country_id)(accessToken, data)
      .then(res => {
        commit('setVersion', res.data.version);
        return res.data;
      })
      .catch(err => {
        commit('setVersion', err.version);
        throw err;
      });
  },
};

export default {
  state: INITIAL_BATTLES_STATE(),
  getters,
  mutations,
  actions,
};
