<template>
  <div class="container">
    <div
      class="row h-100 justify-content-center text-muted"
      :class="[`align-items-${position}`]"
    >
      <div class="col-12 text-center">
        <AppIcon :name="iconName" :width="size" />
        <div class="w-100 mt-2">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppIcon from './Icon';

export default {
  name: 'HeraDummyList',
  components: {
    AppIcon,
  },
  props: {
    iconName: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '28',
    },
    position: {
      type: String, // 'start', 'end', 'center', 'baseline', 'stretch'
      default: 'end',
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 320px;
  max-height: 50vh;
}
</style>
