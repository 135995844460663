import api from '@/api';

const actions = {
  async fileUpload(
    {
      getters: { accessToken },
    },
    fileData
  ) {
    const preuploadInfo = {
      file_name: fileData.name,
      file_size: fileData.size,
    };
    const { data } = await api.filePreupload(accessToken, preuploadInfo);
    await api.fileUpload(data.upload_uri, accessToken, fileData);
    return data;
  },
};

export default {
  actions,
};
