import api from '@/api';
import { Subject } from 'rxjs';
import Vue from 'vue';

export const INITIAL_CONVERSATION = () => {
  return {
    unreadCount: 0,
    activeConversationUnreadCount: 0,
    lastMsgInConversation: null,
    system: new Subject(),
    conversationSubscribeData: null,
    activePopoverChat: '',
    popoverChatIsOpened: false,
  };
};

const getters = {
  activeConversationUnreadCount: state => state.activeConversationUnreadCount,
  lastMsgInConversation: state => state.lastMsgInConversation,
  system: state => state.system,
  unreadCount: state => state.unreadCount,
  conversationSubscribeData: state => state.conversationSubscribeData,
  activePopoverChat: state => state.activePopoverChat,
  popoverChatIsOpened: state => state.popoverChatIsOpened,
};

const mutations = {
  checkUnreadBadge(state, count) {
    state.activeConversationUnreadCount = +count;
  },
  removeUnreadBadge(state) {
    state.activeConversationUnreadCount = 0;
  },
  setLastMsgInConversation(state, { id, data }) {
    state.lastMsgInConversation = { id, data };
  },
  systemNext(state, data) {
    state.system.next(data);
  },
  addSystemSubscriber(state, subscriber) {
    state.system.subscribe(subscriber);
  },
  messagesNext(state, data) {
    if (data) {
      state.conversationSubscribeData = data;
      state.unreadCount++;
    }
  },
  clearToConversationsDefaultState(state) {
    const defaultState = INITIAL_CONVERSATION();

    Object.keys(defaultState).forEach(key => {
      Vue.set(state, key, defaultState[key]);
    });
  },
  setUnreadCount(state, count) {
    state.unreadCount = +count;
  },
  changeUnreadCount(state, count) {
    if (count === undefined) {
      count = -state.activeConversationUnreadCount;
      state.activeConversationUnreadCount = 0;
    }

    state.unreadCount = Math.max(state.unreadCount + count, 0);
  },
  setActivePopoverChat(state, id) {
    state.activePopoverChat = id;
  },
  removeActivePopoverChat(state) {
    state.activePopoverChat = '';
  },
  showPopoverChat(state) {
    state.popoverChatIsOpened = true;
  },
  hidePopoverChat(state) {
    state.popoverChatIsOpened = false;
  },
};

const actions = {
  createConversation(
    {
      getters: { accessToken },
    },
    login
  ) {
    return api.createConversation(accessToken, login).then(res => res.data);
  },
  createGroupConversation(
    {
      getters: { accessToken },
    },
    logins
  ) {
    return api
      .createGroupConversation(accessToken, logins)
      .then(res => res.data);
  },
  updateConversation(
    {
      getters: { accessToken },
    },
    payload
  ) {
    return api
      .updateConversation(accessToken, payload.id, payload.props)
      .then(res => res.data);
  },
  fetchConversations(
    {
      commit,
      getters: { accessToken },
    },
    { pageToken = null, pageSize = 20 }
  ) {
    return api.getConversations(accessToken, pageToken, pageSize).then(res => {
      commit('setUnreadCount', res.data.unread_count);
      return res.data;
    });
  },
  fetchAllConversations({ dispatch }) {
    return new Promise((resolve, reject) => {
      let conversations = [];
      const recursiveFetch = pageToken =>
        dispatch('fetchConversations', { pageToken })
          .then(data => {
            conversations = conversations.concat(data.items);
            if (data.next_page_token) {
              recursiveFetch(data.next_page_token);
            } else {
              resolve(conversations);
            }
          })
          .catch(reject);
      recursiveFetch();
    });
  },
  getGroupConversationInfo(
    {
      dispatch,
      rootGetters: { username },
    },
    { participants = [], title = '' }
  ) {
    const allParticipants = [...participants, username];

    return !participants.length
      ? Promise.reject('There no participants')
      : new Promise((resolve, reject) => {
          dispatch('fetchAllConversations')
            .then(chatList =>
              chatList.filter(chat => chat.active && chat.group_chat)
            )
            .then(groupChats =>
              groupChats.filter(chat =>
                allParticipants.every(
                  login => chat.participants.findIndex(p => p.id === login) > -1
                )
              )
            )
            .then(conversations =>
              conversations.find(el => {
                return (
                  el.participants.length >= allParticipants.length &&
                  el.title === title
                );
              })
            )
            .then(conversation => {
              conversation
                ? resolve(conversation)
                : dispatch('createGroupConversation', participants)
                    .then(chat => resolve(chat))
                    .catch(reject);
            })
            .catch(reject);
        });
  },
  getConversationInfo(
    {
      commit,
      getters: { accessToken },
    },
    conversationId
  ) {
    return api
      .getConversation(conversationId)(accessToken)
      .then(res => {
        commit('checkUnreadBadge', res.data.unread_count);
        return res.data;
      });
  },
  fetchMessages(
    {
      getters: { accessToken },
    },
    { conversationId, pageToken }
  ) {
    return api
      .getMessages(accessToken, conversationId, pageToken)
      .then(res => res.data);
  },
  sendMessage(
    {
      commit,
      getters: { accessToken },
    },
    { id, data }
  ) {
    return api.postMessage(accessToken, id, data.text).then(res => {
      commit('setLastMsgInConversation', {
        id,
        data: {
          last_message: data.text,
          last_active: data.sent_date,
        },
      });
      return res.data;
    });
  },
  deleteMessage(
    {
      getters: { accessToken },
    },
    { chatId, msgId }
  ) {
    return api.deleteMessage(accessToken, chatId, msgId).then(res => res.data);
  },
  checkConversationAsRead(
    {
      commit,
      getters: { accessToken },
    },
    id
  ) {
    return api.conversationAsRead(accessToken, id).then(res => {
      commit('changeUnreadCount');
      commit('removeUnreadBadge');
      return res.data;
    });
  },
  checkMessageAsRead(
    {
      commit,
      getters: { accessToken },
    },
    { chatId, msgId }
  ) {
    return api.messageAsRead(accessToken, chatId, msgId).then(res => {
      if (res.read) {
        commit('changeUnreadCount', -1);
      }
      return res.data;
    });
  },
  translateMsg(
    {
      getters: { accessToken },
    },
    { conversationId, lang, message_ids }
  ) {
    return api
      .getTranslateMsg(accessToken, conversationId, lang, message_ids)
      .then(res => res.data);
  },
};

export default {
  state: INITIAL_CONVERSATION(),
  getters,
  mutations,
  actions,
};
