<template>
  <b-navbar-nav class="ml-auto">
    <div
      id="navbar-user"
      class="logged-in navbar-user-wrapper d-flex align-content-center"
    >
      <div class="user-info-wrapper col-auto text-right">
        <small class="user-name">{{ username }}</small>
        <router-link class="navbar-user-action" :to="{ name: 'signout' }"
          >Logout</router-link
        >
      </div>
      <UserAvatar :size="avatarSize" :img-url="avatar" class="mr-2" />
    </div>
  </b-navbar-nav>
</template>
<script>
import UserAvatar from '@/components/common/avatar.vue';
import { mapGetters } from 'vuex';
export default {
  name: 'HeraNavbarUserItem',
  components: {
    UserAvatar,
  },
  data() {
    return {
      avatarSize: 37,
    };
  },
  computed: {
    ...mapGetters(['username', 'avatar']),
  },
};
</script>
<style lang="scss">
.navbar-user-wrapper {
  .user-info-wrapper {
    margin-left: 0rem;
    padding-left: 0rem;

    .user-name {
      font-size: 0.75rem;
      display: block;
    }
    .navbar-user-action {
      font-size: 0.75rem;
      font-weight: bold;
      padding: 0rem;
      position: relative;
      top: -0.25rem;
    }
  }
}
</style>
