<template>
  <div class="container-fluid d-flex flex-column">
    <h1>Newsfeed Line</h1>
    <div class="row flex-nowrap flex-grow-1">
      <div class="col-lg-6 col-xl-4 d-flex flex-column flex-grow-1">
        <h2 class="newsfeed-settings-list-header">Posts Line</h2>
        <div class="card h-100">
          <span v-if="isLoading" class="text-center text-muted py-4"
            >Fetching Posts Line...</span
          >
          <Draggable
            v-else
            v-model="line"
            element="ul"
            class="newsfeed-settings-list p-0 m-0"
            v-bind="dragOptions"
            handle=".handle"
            @start="drag = true"
            @end="drag = false"
          >
            <transition-group
              type="transition"
              :name="!drag ? 'flip-list' : null"
            >
              <Item
                v-for="(item, index) in line"
                :key="item.key"
                :item="item"
                show-index
                :index="index"
                is-removable
                is-draggable
                :hide-button="item.id === 'post' && regularPostsInLine < 2"
                @removePost="removePost(index)"
                @editPost="editPost(item)"
              />
            </transition-group>
          </Draggable>
        </div>
      </div>
      <div class="col-lg-6 col-xl-4 d-flex flex-column flex-grow-1">
        <h2 class="newsfeed-settings-list-header">Injection Posts</h2>
        <div class="card h-100">
          <ul class="newsfeed-settings-list p-0 m-0">
            <Item
              v-for="item in INJECTIONS_POSTS"
              :key="item.id"
              :item="item"
              :disabled="isLoading"
              @addPost="addPost(item)"
            />
          </ul>
        </div>
      </div>
    </div>
    <ModalPopup
      v-if="showModal"
      key="createBannerModal"
      :open-modal="showModal"
      title="Banner"
      :sub-title="'Please specify banner data'"
      :auto-hide-delay="1000"
    >
      <div class="form-group w-100">
        <TextInput
          id="bannerUrl"
          v-model="banner.url"
          name="bannerUrl"
          placeholder="Banner Url"
          label="Url:"
          class="mb-0"
          required
        />
      </div>
      <div class="outer banner-image my-2">
        <div
          :style="{
            backgroundImage: bannerImage ? `url(${bannerImage})` : null,
          }"
          class="inner"
          :class="{ 'has-image': bannerImage }"
        ></div>
      </div>
      <fileBase64
        id="bannerfile"
        :accept="fileType"
        name="bannerfile"
        :size-ceil="3e4"
        :done="imgUploaded"
      />

      <template name="actions">
        <div class="card-body">
          <div class="row px-3">
            <div class="controls d-flex justify-content-end">
              <btn
                :disabled="!isBannerValid"
                label="Save"
                class="btn mx-2 bg-transparent text-primary"
                :class="{ 'is-loading': isLoading }"
                @click="banner.key ? editBanner() : addBanner()"
              ></btn>
              <btn
                v-show="!isLoading"
                label="Cancel"
                class="btn mx-2 bg-transparent text-muted"
                @click.stop="toggleModal"
              ></btn>
            </div>
          </div>
        </div>
      </template>
    </ModalPopup>
  </div>
</template>

<script>
import { cloneDeep, uniqueId } from 'lodash';
import { mapActions } from 'vuex';
import Draggable from 'vuedraggable';
import ModalPopup from '@/components/common/ModalTextarea';
import TextInput from '@/components/form/HeraFormTextInput';
import fileBase64 from '@/components/form/fileBase64.vue';
import Item from './Item';
import btn from '@/components/form/HeraFormButton';

const INJECTIONS_POSTS = [
  {
    id: 'post',
    label: 'Regular post',
  },
  {
    id: 'popular_clans',
    label: 'Popular clans',
  },
  {
    id: 'recommended_tournaments',
    label: 'Recommended Tournaments',
  },
  {
    id: 'banner',
    label: 'Banner',
  },
];

const hashReducer = (acc, item) =>
  (acc += item.id + item.url || '' + item.image || '');

const getPostWithUniqueKey = post => ({
  ...post,
  key: uniqueId('injectionpost'),
});

export default {
  name: 'NewsfeedSettings',

  components: {
    Draggable,
    Item,
    ModalPopup,
    TextInput,
    fileBase64,
    btn,
  },

  data() {
    return {
      INJECTIONS_POSTS,

      isLoading: false,
      isUpdating: false,

      line: [],
      savedLine: [],

      showModal: false,

      drag: false,
      dragOptions: {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'newsfeed-settings-list-item_ghost',
      },

      banner: {
        image: null,
        url: null,
        key: null,
      },
    };
  },

  computed: {
    regularPostsInLine() {
      return this.line.reduce(
        (acc, item) => acc + (item.id === 'post' ? 1 : 0),
        0
      );
    },
    saved() {
      return (
        this.line.reduce(hashReducer, '') ===
        this.savedLine.reduce(hashReducer, '')
      );
    },
    bannerImage() {
      const image = String(this.banner.image || '');
      return image.match(/^http/) ? encodeURI(image) : image;
    },
    isBannerValid() {
      return this.banner.image;
    },
    fileType() {
      return ['png', 'bmp', 'jpg', 'jpeg', 'PNG', 'BMP', 'JPG', 'JPEG'];
    },
  },

  watch: {
    line() {
      if (!this.isLoading) {
        this.saveLine();
      }
    },
  },

  created() {
    this.fetchPostsInjections();
  },

  methods: {
    ...mapActions([
      'getPostsInjections',
      'updatePostsInjections',
      'errorNotify',
    ]),

    fetchPostsInjections() {
      this.isLoading = true;
      this.line = [];
      this.getPostsInjections()
        .then(line => {
          this.line = (line || []).reduce((acc, item) => {
            const post = INJECTIONS_POSTS.find(
              post => post.id === item.injectionType
            );
            if (post) {
              var postWithKey = getPostWithUniqueKey(post);
              if (post.id == 'banner') {
                postWithKey.image = item.image;
                postWithKey.url = item.url;
              }
              acc.push(postWithKey);
            }
            return acc;
          }, []);
          this.savedLine = cloneDeep(this.line);
        })
        .catch(this.errorNotify)
        .finally(() => (this.isLoading = false));
    },

    removePost(index) {
      this.line.splice(index, 1);
    },

    cleanupAdnCloseModal() {
      this.banner = {
        image: null,
        url: null,
        key: null,
      };
      this.toggleModal();
    },

    editBanner() {
      let idx = this.line.findIndex(item => item.key == this.banner.key);
      this.line.splice(idx, 1, {
        ...this.line[idx],
        image: this.banner.image,
        url: this.banner.url,
      });
      this.cleanupAdnCloseModal();
    },

    addBanner() {
      const post = INJECTIONS_POSTS.find(post => post.id === 'banner');
      let postWithKey = getPostWithUniqueKey(post);
      postWithKey.image = this.banner.image;
      postWithKey.url = this.banner.url;
      this.line.push(postWithKey);
      this.cleanupAdnCloseModal();
    },

    editPost(post) {
      if (post.id == 'banner') {
        this.banner.image = post.image;
        this.banner.url = post.url;
        this.banner.key = post.key;
        this.toggleModal();
      }
    },

    addPost(post) {
      if (post.id == 'banner') {
        this.banner.image = null;
        this.banner.url = null;
        this.banner.key = null;
        this.toggleModal();
        return;
      }
      this.line.push(getPostWithUniqueKey(post));
    },

    imgUploaded(file) {
      this.banner.image = file.display_uri;
    },

    saveLine() {
      if (this.isUpdating) return;
      this.isUpdating = true;
      const lineToSave = cloneDeep(this.line);
      this.updatePostsInjections(
        lineToSave.map(item => {
          var post = { injectionType: item.id };
          if (item.id == 'banner') {
            post.image = item.image;
            post.url = item.url;
          }
          return post;
        })
      )
        .then(() => {
          this.isUpdating = false; // not move this line to finally!
          this.savedLine = lineToSave;
          this.$nextTick(() => {
            if (!this.saved) {
              this.saveLine();
            }
          });
        })
        .catch(error => {
          this.isUpdating = false;
          this.errorNotify(error);
          /* Server can return error in case of conflict with changes from another user.
          To fix this we need to update the list. */
          this.fetchPostsInjections();
        });
    },

    toggleModal() {
      this.showModal = !this.showModal;
    },
  },
};
</script>

<style lang="scss">
.newsfeed-settings-list {
  list-style-type: none;

  &-header {
    font-size: 1rem;
  }
}

.flip-list-move {
  transition: transform 0.5s;
}

.newsfeed-settings-list-item_ghost {
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.3);
}

.outer {
  position: relative;
  width: 100%;

  &:before {
    display: block;
    content: '';
    width: 100%;
  }
  > .inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #eee url(~@/assets/images/image-placeholder.png) no-repeat
      center;
    &.has-image {
      background-color: transparent;
      background-size: cover;
    }
  }
  &.banner-image {
    max-width: 250px;
    &:before {
      padding-top: (141 / 250) * 100%;
    }
  }
}
</style>
