export const focus = {
  inserted: function(el) {
    if (el.hasAttribute('autofocus')) {
      el.focus();
    }
  },
};

export default {
  focus,
};
