<template>
  <tr class="coupon-list-item">
    <td class="px-2 pl-4 text-truncate subscription-list-item_name">
      <AppIcon
        :name="invoice.isProcessed ? 'checked' : 'close'"
        fill="#07f"
        width="20"
      />
    </td>
    <td class="px-2 text-truncate invoice-list-amount">
      <span>Invoice {{ invoice.invoiceId }}</span>
    </td>
    <td class="px-2 text-truncate invoice-list-amount">
      <span>{{ invoice.magentoOrderId }}</span>
    </td>
    <td class="px-2 text-truncate invoice-list-amount">
      <span>{{ invoice.phOrderId }}</span>
    </td>
    <td class="px-2 text-truncate">
      <span>{{ invoice.login }}</span>
    </td>
    <td class="px-2 text-truncate">
      <span>{{ invoice.countryId }}</span>
    </td>
    <td class="px-2 text-truncate">
      <span>{{ invoice.email }}</span>
    </td>
    <td class="px-2 text-truncate invoice-list-trial-amount">
      <span>{{ createdDate }}</span>
    </td>
    <td class="px-2">
      <span>{{ (invoice.totalAmount - invoice.vatAmount).toFixed(2) }}</span>
    </td>
    <td class="px-2">
      <span>{{ invoice.vatAmount }}</span>
    </td>
    <td class="px-2">
      <span>{{ invoice.totalAmount.toFixed(2) }}</span>
    </td>
    <td class="px-2">
      <span class="disc-desc">{{ invoice.discountDescription }}</span>
    </td>
    <td class="px-2">
      <span>{{ invoice.discountAmount }}</span>
    </td>
    <td class="px-2">
      <span class="text-capitalize">{{ invoice.currency }}</span>
    </td>
    <td class="px-2">
      <span class="text-capitalize">{{ invoice.paymentMethod }}</span>
    </td>
    <td class="px-2">
      <span class="text-capitalize">{{ invoice.source }}</span>
    </td>
    <td class="px-2">
      <span class="text-capitalize">{{ createdExperationDate }}</span>
    </td>
    <td class="px-2">
      <span class="text-capitalize">{{ invoice.itemDescription }}</span>
    </td>
    <td>
      <button
        v-show="invoice.isProcessed"
        class="btn btn-sm btn-outline-danger"
        @click="$emit('download', invoice)"
      >
        Download
      </button>
      <button
        v-show="invoice.isProcessed"
        class="ml-3 btn btn-sm btn-outline-primary"
        @click="toggleModal"
      >
        Resend
      </button>

      <ModalPopup
        v-if="showModal"
        :open-modal="showModal"
        title="Resend invoice email"
        :sub-title="
          'You can specify a custom email, if not, email will be sent on one, used in user profile'
        "
      >
        <TextInput
          v-model.trim="email"
          name="email"
          label="Custom email"
          :autofocus="true"
          :error="errors.first('email')"
        />
        <template name="actions">
          <div class="controls d-flex justify-content-end">
            <btn
              v-show="!isLoading"
              label="Cancel"
              class="btn btn-default"
              @click.stop="toggleModal"
            />
            <btn
              label="Resend"
              class="btn btn-primary"
              :class="{ 'is-loading': isLoading }"
              @click.prevent.stop="resend"
            />
          </div>
        </template>
      </ModalPopup>
    </td>
  </tr>
</template>

<script>
import AppIcon from '@/components/common/Icon';
import ModalPopup from '@/components/common/ModalTextarea';
import btn from '@/components/form/HeraFormButton';
import TextInput from '@/components/form/HeraFormTextInput';

import { mapActions } from 'vuex';

export default {
  name: 'InvoiceListItem',
  components: { AppIcon, ModalPopup, btn, TextInput },
  inject: ['$validator'],
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      email: '',
    };
  },
  computed: {
    createdDate() {
      let expDate = this.invoice.createdOn
        ? new Date(this.invoice.createdOn)
        : null;
      let formattedDate = '';
      if (expDate) {
        formattedDate += `${this.addLeadingZero(expDate.getUTCDate())}/`;
        formattedDate += `${this.addLeadingZero(expDate.getUTCMonth() + 1)}/`;
        formattedDate += `${expDate.getUTCFullYear()}`;
      }
      return formattedDate.length ? formattedDate : '';
    },
    createdExperationDate() {
      let expDate = this.invoice.subscriptionExpiration
        ? new Date(this.invoice.subscriptionExpiration * 1000)
        : null;
      //rebuild
      return expDate
        ? `${this.addLeadingZero(expDate.getUTCDate())}/${this.addLeadingZero(
            expDate.getUTCMonth() + 1
          )}/${expDate.getUTCFullYear()}`
        : '-';
    },
  },
  methods: {
    ...mapActions(['openModal', 'closeModal', 'resendInvoice', 'errorNotify']),
    addLeadingZero(num) {
      return (num < 10 ? '0' : '') + num;
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    resend() {
      this.isLoading = true;

      this.resendInvoice({
        id: this.invoice.id,
        email: this.email,
      })
        .then(() => {})
        .catch(this.errorNotify)
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style lang="scss">
.disc-desc {
  max-width: 100px;
  word-break: break-all;
}
</style>
