'use strict';

import { BASE_URL } from '@/api/config.js';

import {
  authorizedGetRequest,
  authorizedPutRequest,
  authorizedDeleteRequest,
} from '@/api/requests.js';

const PROFILE_BASE = BASE_URL + 'profiles/';
const MY_DETAILS = PROFILE_BASE + 'my';
const CLAN_VALIDATIONS = PROFILE_BASE + 'clans_validations/';

// my details
export const getMyProfile = authorizedGetRequest(MY_DETAILS);
export const getProfiles = (reqSettings = { ignoreParams: true }) =>
  authorizedGetRequest(PROFILE_BASE, reqSettings);
export const setAmbassador = (login = '') =>
  authorizedPutRequest(PROFILE_BASE + `${login}/is_ambassador`);

const getMyProfileAssociations = authorizedGetRequest(
  `${PROFILE_BASE}associations/my`
);

// clan verification
export const getClanValidations = (pageToken = null) =>
  authorizedGetRequest(
    CLAN_VALIDATIONS +
      (pageToken ? `?page=${encodeURIComponent(pageToken)}` : '')
  );
export const approveClanValidation = clan =>
  authorizedPutRequest(CLAN_VALIDATIONS + clan);
export const rejectClanValidation = clan =>
  authorizedDeleteRequest(CLAN_VALIDATIONS + clan);

const putAutofollow = authorizedPutRequest(`${PROFILE_BASE}follow/auto`);

export default {
  getProfiles,
  getMyProfile,
  setAmbassador,
  getClanValidations,
  approveClanValidation,
  rejectClanValidation,
  putAutofollow,
  getMyProfileAssociations,
};
