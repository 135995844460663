import { ifAuthorized } from '@/router/guards';

import SubscriptionsWrapper from '@/views/admin/Subscriptions/';
import Subscriptions from '@/views/admin/Subscriptions/SubscriptionsList';
import SubscriptionEdit from '@/views/admin/Subscriptions/SubscriptionEdit';

export default [
  {
    path: '/subscriptions',
    title: 'Subscription plans',
    component: SubscriptionsWrapper,
    beforeEnter: ifAuthorized,
    children: [
      {
        path: '',
        name: 'subscriptions',
        component: Subscriptions,
      },
      {
        path: 'edit/:subscriptionID?',
        name: 'subscriptionEdit',
        component: SubscriptionEdit,
        props: true,
      },
      {
        path: 'create',
        name: 'subscriptionCreate',
        component: SubscriptionEdit,
      },
    ],
  },
];
