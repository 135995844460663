<template>
  <div class="form-group" :class="{ 'text-right': dir === 'rtl' }">
    <label for="password-input" :dir="dir" v-text="label"></label>
    <div
      class="input-group"
      :dir="dir"
      :class="{ 'is-invalid': error, focused: isFocused }"
    >
      <input
        id="password-input"
        v-focus
        class="form-control bg-transparent"
        :class="{ 'is-invalid': error }"
        aria-describedby="passwordHelp"
        autocomplete="off"
        :tabindex="tabindex"
        :autofocus="autofocus"
        :type="inputType"
        :value="value"
        :placeholder="placeholder"
        @input="updateValue"
        @change="updateValue"
        @blur="onBlur"
        @focus="onFocus"
      />
      <div class="input-group-append">
        <button
          type="button"
          class="btn btn-outline-secondary password-reveal dropdown-toggle-split"
          :class="{ 'is-invalid': error }"
          @click="revealPass"
        >
          <span class="sr-only">Reveal password</span>
        </button>
      </div>
    </div>
    <span
      v-show="error"
      id="passwordHelp"
      class="form-text text-danger error-help"
      v-text="error"
    ></span>
  </div>
</template>
<script>
export default {
  $_veeValidate: {
    name() {
      return this.name;
    },
    value() {
      return this.value;
    },
  },
  props: {
    name: {
      type: String,
      default: null,
    },
    direction: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'password',
      validate: val => {
        return ['text', 'password'].indexOf(val) !== -1;
      },
    },
    error: {
      type: String,
      default: null,
    },
    tabindex: {
      type: Number,
      default: null,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Password',
    },
  },
  data() {
    return {
      inputType: 'password',
      isFocused: false,
    };
  },
  computed: {
    dir() {
      return this.direction;
    },
  },
  methods: {
    revealPass() {
      this.inputType = this.inputType === 'password' ? 'text' : 'password';
    },
    updateValue(e) {
      this.$emit('input', e.target.value);
    },
    onFocus() {
      this.isFocused = true;
      this.$emit('focus');
    },
    onBlur() {
      this.isFocused = false;
      this.$emit('blur');
    },
  },
};
</script>
<style lang="scss" scoped>
.form-group {
  .input-group {
    width: 100%;
  }
}
.error-help {
  max-width: 200px;
}
.password-reveal {
  border-left: 0px;
  background-size: 0.75em 0.5em;
  background-position: center;
  background-repeat: no-repeat;
  &.is-invalid {
    border-color: $hera-error-color;
  }
  &:after {
    content: none;
    display: inline-block;
    font-size: 12px;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
  }
}
#password-input {
  border-right: 0px;
  font-size: 12px;
  font-weight: bold;
  &[type='text'] ~ .input-group-append {
    .password-reveal {
      background-image: url(../../assets/images/hide-pass.svg);
    }
  }
  &[type='password'] ~ .input-group-append {
    .password-reveal {
      background-image: url(../../assets/images/show-pass.svg);
    }
  }
}
body {
  #password-input,
  .btn-outline-secondary {
    box-shadow: none !important;
    // border-color: #575C88!important;
    // color: #fff;
    &.is-invalid {
      border-color: $hera-error-color !important;
    }
  }
  .input-group {
    button {
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
    &.focused {
      border-radius: 0.25rem;
      box-shadow: 0 0 0 0.2rem rgba($hera-main-color, 0.25);
      &.is-invalid {
        box-shadow: 0 0 0 0.2rem rgba($hera-error-color, 0.25);
      }
    }
  }
}

@media (max-width: 575px) and (orientation: landscape) {
  .form-group {
    .input-group {
      width: 100%;
    }
  }
}
@media (min-width: 576px) {
  // .form-group{
  //   max-width: 200px;
  //   .input-group{
  //     width: 100%;
  //   }
  // }
}
@media (min-width: 768px) {
}
@media (min-width: 576px) and (max-width: 825px) and (orientation: landscape) {
}
@media (min-width: 992px) {
  // .form-group{
  //   max-width: 200px;
  //   .input-group{
  //     width: 200px;
  //   }
  // }
}
@media (min-width: 1200px) {
}
@media (min-width: 1441px) {
}
@media (min-width: 1921px) {
}
.input-group[dir='rtl'] {
  input {
    border-radius: 0 !important;
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
    border-left: none !important;
    border-right-width: 1px !important;
    border-right-style: solid !important;
  }
  .input-group-append button {
    border-radius: 0 !important;
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
    border-right: none !important;
    border-left-width: 1px !important;
    border-left-style: solid !important;
  }
}
</style>
