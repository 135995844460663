<template>
  <div class="subscriptions-page-wrapper">
    <div class="container-fluid">
      <div class="row my-4 align-items-center">
        <h3 class="mb-0 pb-0 col">Promocodes</h3>
        <router-link
          class="btn btn-primary ml-auto mr-4"
          :to="{ name: 'promocodeCreate' }"
        >
          Add new promocode
        </router-link>
      </div>
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body pb-0 px-4">
              <div class="row mb-3">
                <div
                  v-for="filter in allCouponFilters"
                  :key="filter.name"
                  class="col-auto"
                >
                  <label class="d-block">{{ filter.label }}:</label>
                  <autosuggest
                    v-if="filter.name === 'country'"
                    id="country"
                    v-model="filters[filter.name]"
                    placeholder="Start typing..."
                    display-property="text"
                    :source="suggestCountriesRequest"
                    @resultPicked="onFilterChange"
                  />
                  <dropdown
                    v-else
                    v-model="filters[filter.name]"
                    :list="getFiltersOptions(filter)"
                    empty-text="All"
                    :empty-option="true"
                    :could-be-empty="true"
                    class="input-like w-auto"
                    :disabled="
                      !getFiltersOptions(filter).length &&
                        !filteredCoupons.length
                    "
                    @input="onFilterChange"
                  >
                    <template slot="chosen" slot-scope="chosen">
                      <span v-if="filter.name === 'teamSize'">
                        {{ chosen.chosenItem }}&nbsp;v&nbsp;{{
                          chosen.chosenItem
                        }}
                      </span>
                      <span v-else>{{ chosen.chosenItem }}</span>
                      <span class="sr-only">Choose option</span>
                    </template>
                    <template slot="item" slot-scope="options">
                      <span v-if="filter.name === 'teamSize'">
                        {{ options.item }}&nbsp;v&nbsp;{{ options.item }}
                      </span>
                      <span v-else>
                        {{ options.item }}
                      </span>
                    </template>
                  </dropdown>
                </div>
              </div>
            </div>
            <div class="card-body border-top border-muted pt-0 px-0">
              <table class="table table-hover mx-0 px-0 subscriptions-list">
                <caption
                  v-if="isLoading && !filteredCoupons.length"
                  class="text-center text-muted"
                >
                  Fetching coupons...
                </caption>
                <caption
                  v-else-if="!filteredCoupons.length"
                  class="text-center text-muted"
                >
                  No coupons to show
                </caption>
                <template v-else>
                  <thead>
                    <tr>
                      <th
                        class="border-top-0 border-bottom-0 px-2 section-description"
                      >
                        Is active
                      </th>
                      <th
                        class="border-top-0 border-bottom-0 px-2 section-description"
                      >
                        Code
                      </th>
                      <th
                        class="border-top-0 border-bottom-0 px-2 section-description"
                      >
                        Discount
                      </th>
                      <th
                        class="border-top-0 border-bottom-0 px-2 section-description"
                      >
                        Expiration <br />date
                      </th>
                      <th
                        class="border-top-0 border-bottom-0 px-2 section-description"
                      >
                        Country
                      </th>

                      <th
                        class="border-top-0 border-bottom-0 px-2 section-description"
                      >
                        Associated <br />with
                      </th>
                      <th
                        class="border-top-0 border-bottom-0 px-2 section-description"
                      ></th>
                      <th
                        class="border-top-0 border-bottom-0 px-2 section-description"
                      >
                        Usage
                      </th>
                      <th
                        class="border-top-0 border-bottom-0 section-description"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <ListItem
                      v-for="coupon in filteredCoupons"
                      :key="coupon.id"
                      :country="filters['country']"
                      v-bind="{ coupon }"
                      @deleteItem="onDeleteItem"
                    />
                  </tbody>
                </template>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { uniqBy, cloneDeep } from 'lodash';
import { mapActions } from 'vuex';
import dropdown from '@/components/form/HeraFormDropdown.vue';
import autosuggest from '@/components/form/HeraAutosuggest';
import ListItem from '../../Coupons/CouponsList/ListItem';

export default {
  name: 'PromoCodesList',
  components: { dropdown, autosuggest, ListItem },
  data() {
    return {
      filters: {},
      nextPageToken: undefined,
      isLoading: false,
      coupons: [],
    };
  },
  computed: {
    filteredCoupons() {
      return this.countryFilter() ? this.coupons : [];
    },
    isFiltered() {
      const allFilters = Object.keys(this.filters);
      return (
        allFilters.length && allFilters.some(filter => this.filters[filter])
      );
    },
    allCouponFilters() {
      return [
        {
          name: 'country',
          label: 'Country',
          options: null,
        },
      ];
    },
  },
  created() {
    this.initializeFilterValues();
    this.fetchData();
  },
  methods: {
    ...mapActions([
      'fetchPromocodes',
      'suggestCountriesRequest',
      'errorNotify',
      'successNotify',
      'deleteCoupon',
    ]),
    fetchData() {
      if (this.nextPageToken === null) return;

      this.isLoading = true;
      this.fetchPromocodes({
        pageToken: this.nextPageToken,
        country: this.countryFilter(),
      })
        .then(res => {
          this.nextPageToken =
            this.nextPageToken === res.next_page_token
              ? null
              : res.next_page_token;

          const newPage = res.items.map(el => ({
            ...el,
            editMode: false,
            key: `coupon_${el.id}`,
          }));

          this.coupons = uniqBy([...this.coupons, ...newPage], 'id');
        })
        .catch(this.errorNotify)
        .finally(() => {
          this.isLoading = false;
        });
    },
    getFiltersOptions(filter) {
      return [...filter.options];
    },
    countryFilter() {
      return this.filters['country'] ? this.filters['country'].id : '';
    },
    onFilterChange() {
      if (this.countryFilter()) {
        this.coupons = [];
        this.nextPageToken = undefined;
        this.fetchData();
      }
    },
    initializeFilterValues() {
      this.filters = this.allCouponFilters.reduce((obj, el) => {
        if (el.name == 'country') {
          obj[el.name] = {
            id: 'SA',
            text: 'Saudi Arabia',
            value: 'Saudi Arabia',
          };
        } else {
          obj[el.name] = null;
        }

        return obj;
      }, {});
    },
    onDeleteItem({ id }) {
      if (!confirm('Are you sure? This action cannot be cancelled.')) {
        return;
      }
      this.isLoading = true;

      this.deleteCoupon({ id })
        .then(() => {
          const index = this.coupons.findIndex(el => el.id === id);
          if (index > -1) {
            const coupons = cloneDeep(this.coupons);
            coupons.splice(index, 1);
            this.coupons = coupons;
          }
          this.successNotify('Promocode removed!');
        })
        .catch(this.errorNotify)
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
