import api from '@/api';
import { cloneDeep } from 'lodash';

const actions = {
  fetchCoupons(
    {
      getters: { accessToken },
    },
    { country, pageToken = null, pageSize = 20, promoter }
  ) {
    return api.coupons
      .getCoupons(country, accessToken, pageToken, pageSize, promoter)
      .then(res => res.data);
  },
  fetchPromocodes(
    {
      getters: { accessToken },
    },
    { country, pageToken = null, pageSize = 20 }
  ) {
    return api.coupons
      .getPromos(country, accessToken, pageToken, pageSize)
      .then(res => res.data);
  },
  fetchPromoCodesUsage(
    {
      getters: { accessToken },
    },
    id
  ) {
    return new Promise((resolve, reject) => {
      api.coupons
        .getUsage(id)(accessToken)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  },
  getCoupon(
    {
      getters: { accessToken },
    },
    id
  ) {
    return new Promise((resolve, reject) => {
      api.coupons
        .getCoupon(id)(accessToken)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  },
  createCoupon(
    {
      getters: { accessToken },
    },
    coupon
  ) {
    const newCoupon = cloneDeep(coupon);
    delete newCoupon.id;
    delete newCoupon.key;

    return api.coupons.createCoupon(accessToken, newCoupon);
  },
  updateCoupon(
    {
      getters: { accessToken },
    },
    coupon
  ) {
    const { id } = coupon;
    const newCoupon = cloneDeep(coupon);
    delete newCoupon.id;
    delete newCoupon.key;
    return api.coupons.updateCoupon(id)(accessToken, {
      ...newCoupon,
    });
  },
  deleteCoupon(
    {
      getters: { accessToken },
    },
    { id }
  ) {
    return api.coupons.deleteCoupon(id)(accessToken);
  },
  exportCoupons(
    {
      getters: { accessToken },
    },
    { country, promoter }
  ) {
    return api.coupons.exportCoupons(
      accessToken,
      'Coupons.csv',
      country,
      promoter
    );
  },
  exportPromoCode(
    {
      getters: { accessToken },
    },
    { id, code }
  ) {
    return api.coupons.exportPromoCode(
      accessToken,
      id,
      `PromoCode_${code}.csv`
    );
  },
};
export default {
  actions,
};
