import NewsfeedSettings from '@/views/admin/NewsfeedSettings';
import { ifAuthorized } from '@/router/guards';

export default [
  {
    path: '/newsfeed-settings',
    title: 'Newsfeed settings',
    name: 'newsfeedSettings',
    component: NewsfeedSettings,
    beforeEnter: ifAuthorized,
  },
];
