'use strict';

import { BASE_URL } from '@/api/config';
import { authorizedPostRequest, authorizedPutRequest } from '@/api/requests';

const FILES_BASE = BASE_URL + 'files/';
const PREUPLOAD = FILES_BASE + 'preupload';

export const filePreupload = authorizedPostRequest(PREUPLOAD);

const fileUpload = (url, accessToken, blob) =>
  authorizedPutRequest(url)(accessToken, blob, {
    headers: {
      'Content-Type': 'application/octet-stream',
      'x-ms-blob-type': 'BlockBlob',
    },
  });

export default {
  filePreupload,
  fileUpload,
};
