'use strict';

import { BASE_URL } from '@/api/config.js';

import {
  authorizedGetRequest,
  authorizedPostRequest,
  authorizedPutRequest,
  authorizedDeleteRequest,
} from '@/api/requests.js';

const CONVERSATIONS = BASE_URL + 'conversations';
const CONVERSATION = BASE_URL + 'conversations/${conversation}';
const MESSAGES = CONVERSATIONS + '/${conversation}/messages';

export const updateConversation = (accessToken, id, props) =>
  authorizedPutRequest(CONVERSATION.replace('${conversation}', id))(
    accessToken,
    props
  );

export const createGroupConversation = (accessToken, logins) =>
  authorizedPostRequest(CONVERSATIONS)(accessToken, { participants: logins });

export const getConversations = (
  accessToken,
  pageToken = null,
  pageSize = 20
) =>
  authorizedGetRequest(CONVERSATIONS)(accessToken, {
    params: { page: pageToken, page_size: pageSize },
  });

export const getConversation = id =>
  authorizedGetRequest(CONVERSATION.replace('${conversation}', id));

export const getMessages = (accessToken, id, pageToken = null) =>
  authorizedGetRequest(MESSAGES.replace('${conversation}', id))(accessToken, {
    params: { page: pageToken },
  });

export const postMessage = (accessToken, id, text) =>
  authorizedPostRequest(MESSAGES.replace('${conversation}', id))(accessToken, {
    text,
  });

export const deleteMessage = (accessToken, chat_id, msg_id) =>
  authorizedDeleteRequest(
    MESSAGES.replace('${conversation}', chat_id) + `/${msg_id}`
  )(accessToken);

export const conversationAsRead = (accessToken, id) =>
  authorizedPutRequest(MESSAGES.replace('${conversation}', id))(accessToken, {
    read: true,
  });

export const messageAsRead = (accessToken, chat_id, msg_id) =>
  authorizedPutRequest(
    MESSAGES.replace('${conversation}', chat_id) + `/${msg_id}`
  )(accessToken, { read: true });

export const getTranslateMsg = (
  accessToken,
  conversationId,
  lang,
  message_ids
) =>
  authorizedPostRequest(
    MESSAGES.replace('${conversation}', conversationId) + `/${lang}`
  )(accessToken, { message_ids });

export default {
  createGroupConversation,
  updateConversation,
  getConversations,
  getConversation,
  getMessages,
  postMessage,
  deleteMessage,
  conversationAsRead,
  messageAsRead,
  getTranslateMsg,
};
