import { BASE_URL } from '@/api/config.js';
import {
  authorizedGetRequest,
  authorizedPostRequest,
  authorizedPutRequest,
  authorizedDeleteRequest,
  fileDownload,
} from '@/api/requests.js';

const PAYMENTS = BASE_URL + 'payments';

const getCoupons = (
  country,
  accessToken,
  pageToken = null,
  pageSize = 20,
  promoter
) =>
  authorizedGetRequest(`${PAYMENTS}/coupons/${country ? country : ''}`, {
    preventCancelRequest: true,
  })(accessToken, {
    params: { page: pageToken, page_size: pageSize, promoter: promoter },
  });

const getPromos = (country, accessToken, pageToken = null, pageSize = 20) =>
  authorizedGetRequest(`${PAYMENTS}/promos/${country}`, {
    preventCancelRequest: true,
  })(accessToken, { params: { page: pageToken, page_size: pageSize } });

// const getPromos = countryCode =>
//   authorizedGetRequest(`${PAYMENTS}/promos/${countryCode}`);

const getCoupon = id => authorizedGetRequest(`${PAYMENTS}/coupon/${id}`);
const createCoupon = authorizedPostRequest(`${PAYMENTS}/coupon/`);
const updateCoupon = id => authorizedPutRequest(`${PAYMENTS}/coupon/${id}`);
const deleteCoupon = id => authorizedDeleteRequest(`${PAYMENTS}/coupon/${id}`);
const getUsage = id => authorizedGetRequest(`${PAYMENTS}/coupon/${id}/usage`);

const exportCoupons = (accessToken, fileName, country, promoter) => {
  let queryParams = '';
  if (country) {
    queryParams += `country_id=${country}`;
  }
  if (promoter) {
    queryParams += `${queryParams ? '&' : ''}promoter=${promoter}`;
  }
  return fileDownload(
    accessToken,
    `${PAYMENTS}/export/coupons${queryParams ? '?' + queryParams : ''}`,
    fileName
  );
};
const exportPromoCode = (accessToken, id, fileName) =>
  fileDownload(accessToken, `${PAYMENTS}/export/promo/${id}`, fileName);

export default {
  coupons: {
    getCoupons,
    getPromos,
    getCoupon,
    createCoupon,
    updateCoupon,
    deleteCoupon,
    getUsage,
    exportPromoCode,
    exportCoupons,
  },
};
