import api from '@/api';
import Vue from 'vue';
import { cloneDeep } from 'lodash';

export const INITIAL_SUBSCRIPTIONS_STATE = () => ({
  subscriptions: [],
});
const mutations = {
  setSubscription(state, payload) {
    const index = state.subscriptions.findIndex(el => el.id === payload.id);
    if (index > -1) {
      Vue.set(state.subscriptions, index, payload);
    }
  },
  removeSubscription(state, id) {
    const index = state.subscriptions.findIndex(el => el.id === id);
    if (index > -1) {
      const subscriptions = cloneDeep(state.subscriptions);
      subscriptions.splice(index, 1);
      state.subscriptions = subscriptions;
    }
  },
};
const actions = {
  fetchExternalReports(
    {
      getters: { accessToken },
    },
    { pageToken = null, pageSize = 20, props }
  ) {
    return api.subscriptions
      .getExternalReports(accessToken, pageToken, pageSize, props)
      .then(res => res.data);
  },

  fetchExtAudit(
    {
      getters: { accessToken },
    },
    { pageToken = null, pageSize = 20, props }
  ) {
    return api.subscriptions
      .getExternalAudit(accessToken, pageToken, pageSize, props)
      .then(res => res.data);
  },

  exportAllReports(
    {
      getters: { accessToken },
    },
    { props }
  ) {
    return api.subscriptions.exportAllReports(
      accessToken,
      `Playhera_max_report_export.csv`,
      props
    );
  },
  exportAllAuditReports(
    {
      getters: { accessToken },
    },
    { props }
  ) {
    return api.subscriptions.exportAllAuditReports(
      accessToken,
      `Playhera_max_AuditTrails_export.csv`,
      props
    );
  },
  fetchSubscriptions(
    {
      getters: { accessToken },
    },
    params
  ) {
    return new Promise((resolve, reject) => {
      api.subscriptions
        .getSubscriptions(accessToken, { params })
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  },
  getSubscription(
    {
      getters: { accessToken },
    },
    id
  ) {
    return new Promise((resolve, reject) => {
      api.subscriptions
        .getSubscription(id)(accessToken)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  },
  addSubscription({ commit, state }, subscription) {
    if (subscription) {
      let newSubs = [subscription, ...state.subscriptions];
      commit('setSubscriptions', newSubs);
    }
  },
  createSubscription(
    {
      getters: { accessToken },
      commit,
      state,
    },
    subscription
  ) {
    const newSubscription = cloneDeep(subscription);
    delete newSubscription.editMode;
    delete newSubscription.id;
    delete newSubscription.key;

    return api.subscriptions
      .createSubscription(accessToken, newSubscription)
      .then(response => {
        const { id } = response.data;
        let newSubs = [subscription, ...state.subscriptions];
        commit('setSubscriptions', newSubs);
        commit('setSubscription', { ...newSubscription, id: id });
      });
  },
  updateSubscription(
    {
      getters: { accessToken },
      commit,
    },
    subscription
  ) {
    const { id } = subscription;
    const newSubscription = cloneDeep(subscription);
    delete newSubscription.editMode;
    delete newSubscription.id;
    delete newSubscription.key;
    return api.subscriptions
      .updateSubscription(id)(accessToken, {
        ...newSubscription,
      })
      .then(() => {
        commit('setSubscription', subscription);
      });
  },
  deleteSubscription(
    {
      getters: { accessToken },
      commit,
    },
    { id }
  ) {
    return api.subscriptions
      .deleteSubscription(id)(accessToken)
      .then(() => {
        commit('removeSubscription', id);
      });
  },
  fetchVouchers(
    {
      getters: { accessToken },
    },
    { pageToken = null, pageSize = 20, props }
  ) {
    return api.subscriptions
      .getVouchers(accessToken, pageToken, pageSize, props)
      .then(res => res.data);
  },
  exportAllVouchers(
    {
      getters: { accessToken },
    },
    { props }
  ) {
    return api.subscriptions.exportVouchers(
      accessToken,
      `Playhera_vouchers_export.csv`,
      props
    );
  },
  createVoucher(
    {
      getters: { accessToken },
    },
    voucher
  ) {
    const newVoucher = cloneDeep(voucher);
    delete newVoucher.id;
    delete newVoucher.key;

    return api.subscriptions.createVoucher(accessToken, newVoucher);
  },
  updateVoucher(
    {
      getters: { accessToken },
    },
    voucher
  ) {
    const { id } = voucher;
    const newVoucher = cloneDeep(voucher);
    delete newVoucher.id;
    delete newVoucher.key;
    return api.subscriptions.updateVoucher(id)(accessToken, {
      ...newVoucher,
    });
  },

  getVoucherbyId(
    {
      getters: { accessToken },
    },
    id
  ) {
    return new Promise((resolve, reject) => {
      api.subscriptions
        .getVoucher(id)(accessToken)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  },
};

export default {
  state: INITIAL_SUBSCRIPTIONS_STATE,
  mutations,
  actions,
};
