import Popular from '@/views/admin/PopularPlayers';
import { ifAuthorized } from '@/router/guards';

const AREA = 'global';

export default [
  {
    path: '/popular/:area?',
    title: 'Popular players',
    name: 'popular',
    component: Popular,
    beforeEnter: ifAuthorized,
    params: {
      area: AREA,
    },
    query: {
      country: AREA,
    },
  },
];
