<template>
  <div class="form-group" :class="{ 'text-right': dir === 'rtl' }">
    <label
      v-if="label && label.length"
      :for="inputID"
      :dir="dir"
      :class="{ 'label-required-field': required }"
      >{{ label }}</label
    ><br v-if="label && label.length" />
    <input
      :id="inputID"
      ref="heraTextInput"
      :dir="dir"
      :type="inputType"
      :disabled="disabled"
      class="form-control bg-transparent"
      :class="[variant, { 'is-invalid': error || misstep }]"
      :value="value"
      :placeholder="placeholder"
      :min="minValue"
      aria-describedby="passwordHelp"
      @change="
        $emit(
          'change',
          inputType === 'number' ? +$event.target.value : $event.target.value
        )
      "
      @blur="$emit('blur', $event)"
      @focus="$emit('focus', $event)"
    />
    <span
      v-show="!hideErrorText && error"
      id="inputHelp"
      class="form-text text-danger error-text"
      v-text="error"
    >
    </span>
  </div>
</template>

<script>
// import { mask } from 'vue-the-mask'
import { fromEvent } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

export default {
  name: 'HeraInput',
  props: {
    error: {
      type: String,
      default: null,
    },
    hideErrorText: {
      type: Boolean,
      default: false,
    },
    misstep: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    dir: {
      type: String,
      default: 'ltr',
    },
    id: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    value: {
      type: null,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    inputType: {
      type: String,
      default: 'text',
    },
    debounce: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
      default: NaN,
    },
    variant: {
      type: String,
      default: 'regular',
      validator(val) {
        return ['regular', 'boundless'].includes(val);
      },
    },
  },
  data() {
    return {
      inputObserver: null,
      inputElement: null,
    };
  },
  computed: {
    inputID() {
      return this.id
        ? this.id + 'InputField'
        : 'InputField' + this.getRandomInt(999);
    },
    minValue() {
      return this.inputType === 'number' ? this.min : null;
    },
  },
  mounted() {
    this.$nextTick(function() {
      if (!this.inputElement) {
        this.inputElement = this.$refs.heraTextInput;
      }
      if (!this.inputObserver && this.inputElement) {
        this.inputObserver = fromEvent(this.inputElement, 'input')
          .pipe(
            debounceTime(this.debounce),
            map(event =>
              this.inputType === 'number'
                ? +event.target.value
                : event.target.value
            )
          )
          .subscribe(value => {
            this.$emit('input', value);
          });
      }
    });
  },
  methods: {
    getRandomInt(max) {
      return Math.floor(Math.random() * Math.floor(max));
    },
  },
};
</script>

<style lang="scss" scoped>
input.form-control {
  font-size: 12px;
  font-weight: bold;
  &:focus {
    border-color: $primary !important;
    box-shadow: inset 0 1px 1px rgba($primary, 0.075),
      0 0 0 0.2rem rgba($primary, 0.25) !important;
  }
  &.is-invalid {
    border-color: $danger !important;
    &:focus {
      box-shadow: inset 0 1px 1px rgba($danger, 0.075),
        0 0 0 0.2rem rgba($danger, 0.25) !important;
    }
  }
  &.boundless {
    border-color: transparent !important;
  }
}
</style>
