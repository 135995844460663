<template>
  <tr class="coupon-list-item">
    <td class="px-2 pl-4 text-truncate subscription-list-item_name">
      {{ coupon.isActive ? '+' : '-' }}
    </td>
    <td class="px-2 text-truncate coupon-list-amount">
      <span>{{ coupon.code }}</span>
    </td>
    <td class="px-2 text-truncate coupon-list-amount">
      <span>{{ coupon.amount || coupon.percent + '%' }}</span>
    </td>
    <td class="px-2 text-truncate coupon-list-trial-amount">
      <span>{{ activeToDate }}</span>
    </td>
    <td class="px-2">
      <span>{{ countryValue ? countryValue.text : '' }}</span>
    </td>
    <td class="px-2">
      <span class="text-capitalize">{{ coupon.promoter }}</span>
    </td>
    <td class="px-2">
      <span> {{ coupon.usedBy }} </span>
    </td>
    <td class="px-2 text-capitalize">
      <span> {{ coupon.usageType }} </span>
    </td>
    <td class="px-2 text-capitalize">
      <span> {{ coupon.usedBy ? 'true' : 'false' }} </span>
    </td>
    <td class="px-2 text-capitalize">
      <span> {{ activatedOnDate }} </span>
    </td>
    <td>
      <router-link
        v-if="$router.currentRoute.name === 'promocodes'"
        :to="{ name: 'promocode', params: { promocodeId: coupon.id } }"
        class="btn btn-link"
      >
        <AppIcon name="info" width="20" />
      </router-link>
      <router-link
        class="btn btn-link"
        :to="{
          name:
            $router.currentRoute.name === 'coupons'
              ? 'couponEdit'
              : 'promocodeEdit',
          params: { couponID: coupon.id, country: countryValue },
        }"
      >
        <AppIcon name="edit" width="15" />
      </router-link>
      <button
        :class="{ 'is-loading': isLoading }"
        class="btn btn-sm btn-outline-danger"
        @click="$emit('deleteItem', coupon)"
      >
        Remove
      </button>
    </td>
  </tr>
</template>

<script>
import AppIcon from '@/components/common/Icon';
import { mapActions } from 'vuex';

export default {
  name: 'CouponListItem',
  components: { AppIcon },
  inject: ['$validator'],
  props: {
    coupon: {
      type: Object,
      required: true,
    },
    country: { type: Object, required: false, default: () => null },
  },

  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    editMode() {
      return this.coupon.editMode;
    },
    activeToDate() {
      let expDate = this.coupon.activeTo
        ? new Date(this.coupon.activeTo * 1000)
        : null;
      let formattedDate = '';
      if (expDate) {
        formattedDate += `${this.addLeadingZero(expDate.getUTCDate())}/`;
        formattedDate += `${this.addLeadingZero(expDate.getUTCMonth() + 1)}/`;
        formattedDate += `${expDate.getUTCFullYear()}`;
      }
      return formattedDate.length ? formattedDate : '';
    },
    activatedOnDate() {
      let expDate = this.coupon.usedOn
        ? new Date(this.coupon.usedOn * 1000)
        : null;
      let formattedDate = '';
      if (expDate) {
        formattedDate += `${this.addLeadingZero(expDate.getUTCDate())}/`;
        formattedDate += `${this.addLeadingZero(expDate.getUTCMonth() + 1)}/`;
        formattedDate += `${expDate.getUTCFullYear()}`;
      }
      return formattedDate.length ? formattedDate : '';
    },
    countryValue: {
      get() {
        return this.coupon.country || this.country;
      },
      set(newValue) {
        if (!newValue) {
          this.$validator.validate(`couponCountry_${this.coupon.key}`);
        } else {
          this.errors.remove(`couponCountry_${this.coupon.key}`);
        }
        this.setSubscription({
          ...this.coupon,
          country: newValue,
          countryId: newValue.id,
        });
      },
    },
  },

  methods: {
    ...mapActions(['errorNotify', 'suggestCountriesRequest']),
    addLeadingZero(num) {
      return (num < 10 ? '0' : '') + num;
    },
  },
};
</script>

<style lang="scss">
.coupon-list-item {
  &:first-child td {
    border-top: none;
  }
  td {
    font-weight: bold;
    font-size: 0.75rem;
    vertical-align: middle;
    &.coupon-list-item_name input {
      width: 7rem;
    }

    &.coupon-list-amount input {
      width: 6rem;
    }
    &.coupon-list-trial-amount input {
      width: 4rem;
    }
    label.switch {
      margin-top: 0.5rem;
      &.disabled {
        opacity: 0.5;
      }
    }
    .custom-checkbox.disabled,
    .custom-checkbox:disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  .form-group {
    margin-bottom: 0;
  }
}
</style>
