import api from '@/api';

const actions = {
  getComplaintsList(
    {
      getters: { accessToken },
    },
    payload
  ) {
    return api.complaints
      .getList(payload.query)(accessToken)
      .then(response => response.data);
  },
  getComplaintsReasons({ getters: { accessToken } }) {
    return api.complaints
      .getReasons()(accessToken)
      .then(response => response.data);
  },
  deleteContent(
    {
      getters: { accessToken },
    },
    payload
  ) {
    return api.complaints.deleteContent(payload.type, payload.id)(accessToken);
  },
  deleteComplaint(
    {
      getters: { accessToken },
    },
    ID
  ) {
    return api.complaints.delete(ID)(accessToken);
  },
};

export default {
  actions,
};
