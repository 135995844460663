'use strict';

import { BASE_URL } from '@/api/config.js';
import { authorizedGetRequest, authorizedPutRequest } from '@/api/requests.js';

const POINTS_BASE = BASE_URL + 'points/settings/';

export const getPoints = (pageToken = null) =>
  authorizedGetRequest(POINTS_BASE)(pageToken);
export const setPoints = (pageToken, data) =>
  authorizedPutRequest(POINTS_BASE)(pageToken, data);

export default {
  getPoints,
  setPoints,
};
