<!-- eslint-disable vue/component-name-in-template-casing -->
<!-- eslint-disable vue/camelcase -->
<template>
  <div class="page-wrapper voucher-edit">
    <div class="container">
      <div class="my-4">
        <h3 class="mb-0 pb-0">{{ voucherID ? 'Edit' : 'Create' }} Voucher</h3>
      </div>
      <div class="card-body px-0 d-flex flex-wrap">
        <div class="col-6 col-lg-5 w-100 m-auto">
          <div>
            <label v-if="!voucherID" for="voucher-title">Title *</label>
            <TextInput
              v-if="!voucherID"
              id="voucher-title"
              v-model="voucher.title"
              v-validate="'required'"
              required
              class="w-100"
              :class="{ 'is-invalid': errors.first('voucher-title') }"
              name="voucher-title"
              data-vv-as="Title"
              data-vv-name="voucher-title"
              :error="errors.first('voucher-title')"
              :disabled="disableFields"
            />
          </div>
          <div>
            <label for="voucher-promoter">Associated with</label>
            <TextInput
              id="voucher-promoter"
              v-model="voucher.promoter"
              class="w-100"
              :class="{ 'is-invalid': errors.first('voucher-promoter') }"
              name="voucher-promoter"
              data-vv-as="Title"
              data-vv-name="voucher-promoter"
              :error="errors.first('voucher-promoter')"
              :disabled="disableFields"
            />
          </div>
          <div>
            <label for="voucher-period">Period *</label>

            <TextInput
              id="voucher-period"
              v-model="voucher.period"
              v-validate="'required'"
              input-type="number"
              required
              class="w-100"
              name="voucher-period"
              data-vv-as="period"
              data-vv-name="voucher-period"
              :error="errors.first('voucher-period')"
            />
          </div>
          <label for="voucher-expiration-date">Expiration Date</label>
          <dateInput
            v-model="voucher.expires_at"
            :first-day-of-week="1"
            :minute-step="5"
            value-type="timestamp"
            type="datetime"
            confirm
            lang="en"
            format="DD/MM/YYYY/HH:mm"
            class="date-time w-100"
          >
          </dateInput>
          <div>
            <label v-if="!voucherID" for="voucher-quantity">Quantity</label>
            <TextInput
              v-if="!voucherID"
              id="voucher-quantity"
              v-model="voucher.quantity"
              v-validate="'required'"
              input-type="number"
              required
              class="w-100
            "
              name="voucher-quantity"
              data-vv-as="Quantity"
              data-vv-name="voucher-quanity"
              :error="errors.first('voucher-quanity')"
            />
          </div>
        </div>
        <div class="card-footer bg-transparent text-right w-100">
          <router-link
            to="/vouchers"
            class="btn btn-link text-danger text-center mr-2"
          >
            Cancel
          </router-link>
          <button
            class="btn btn-primary text-center"
            :class="{ 'is-loading': isLoading }"
            :disabled="isLoading || hasErrors"
            :title="
              isLoading || hasErrors
                ? 'Please fill all the required fields properly.'
                : ''
            "
            @click="save"
          >
            {{ voucherID ? 'Save' : 'Create' }}
          </button>
        </div>
        <div
          v-if="isLoading"
          class="preloader position-absolute d-flex justify-content-center align-items-center"
        >
          <div class="spinner"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { uniqueId } from 'lodash';
import { DateTime } from 'luxon';
import TextInput from '@/components/form/HeraFormTextInput';
import DateInput from '@/components/form/HeraFormDateInput';
import dateInput from 'vue2-datepicker';

export default {
  name: 'VoucherEdit',
  inject: ['$validator'],
  components: { TextInput, DateInput, dateInput },
  data() {
    return {
      isLoading: false,
      voucher: {
        key: `voucher_${uniqueId()}`,
        title: null,
        promoter: null,
        period: null,
        expires_at: null,
        quantity: 1,
      },
      currentDate: DateTime.local().toFormat('dd/MM/yyyy'),
    };
  },

  computed: {
    disableFields() {
      return this.isLoading;
    },
    voucherID() {
      return this.$route.params.voucherID || '';
    },
    expires_atInSeconds() {
      let result = 0;
      if (this.voucher.expires_at && this.voucher.expires_at.length) {
        let dateArr = this.voucher.expires_at.split('/');
        result = Date.UTC(+dateArr[2], +dateArr[1] - 1, dateArr[0]) / 1000;
      }
      return result;
    },
    hasErrors() {
      return Object.values(this.errors.all()).some(errors => errors.length > 0);
    },
  },
  watch: {
    'voucher.expires_at'(val) {
      if (!val) return;
      // if (!val.toString().includes('/')) this.getexpires_atFromString(val);

      // const setDate = DateTime.fromFormat(val, 'dd/MM/yyyy').toSeconds();
      const minDate = DateTime.local().toMillis();

      if (val < minDate) {
        this.errors.add({
          field: 'voucher-expiration-date',
          msg: 'Please choose a date after today',
        });
      } else {
        this.errors.remove('voucher-expiration-date');
      }
    },
  },
  created() {
    if (this.voucherID) {
      this.getVoucherbyId(this.voucherID).then(res => {
        this.voucher = {
          ...res,
          expires_at: res.expires_at ? res.expires_at * 1000 : null,
        };
      });
    }
  },
  methods: {
    ...mapActions([
      'successNotify',
      'errorNotify',
      'getVoucherbyId',
      'createVoucher',
      'updateVoucher',
    ]),
    getexpires_atFromString(str) {
      let expDate = str
        ? DateTime.fromFormat(str, 'dd/MM/yyyy').toJSDate()
        : null;
      let formattedDate = '';
      if (expDate) {
        formattedDate = Math.floor(expDate.getTime() / 1000);
      }
      this.voucher.expires_at = formattedDate;
    },
    getDataForSave() {
      return {
        ...this.voucher,
        expires_at: this.voucher.expires_at
          ? this.voucher.expires_at / 1000
          : null,
      };
    },
    save() {
      this.isLoading = true;
      this.$validator
        .validateAll()
        .then(isValid => {
          if (isValid) {
            if (this.voucherID) {
              return this.updateVoucher(this.getDataForSave());
            } else {
              return this.createVoucher(this.getDataForSave());
            }
          } else {
            return Promise.reject('Form validation failed');
          }
        })
        .then(() => {
          this.successNotify(
            this.voucherID
              ? 'Voucher was successfully updated'
              : 'Voucher was successfully created'
          );
          this.$router.push({ name: 'vouchers' });
        })
        .catch(error => {
          this.errorNotify(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.voucher-edit {
  h3 {
    margin-bottom: 1rem;
  }

  label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .col-6 {
    width: 50%;
    padding-right: 1rem;
  }

  .mt-3 {
    margin-top: 1rem;
  }

  .card-footer {
    margin-top: 1rem;
  }

  .preloader {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .spinner {
    width: 3.5rem;
    height: 3.5rem;
    border: 2px solid transparent;
    border-left-color: #3498db;
    border-bottom-color: #3498db;
    border-radius: 50%;
    animation: spinAround 0.5s infinite linear;
  }

  @keyframes spinAround {
    to {
      transform: rotate(360deg);
    }
  }
}
</style>
