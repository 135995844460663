import api from '@/api';
import Vue from 'vue';
import { CONFIG } from '@/api/config';
export const REGISTRATIONS_DEFAULT_STATE = () => ({
  registrations: [],
});

const getters = {
  registrations(state) {
    let registrations = [];

    state.registrations.forEach(item => {
      let platforms = Object.keys(item.games);
      platforms.forEach(platform => {
        let game = item.games[platform];
        registrations.push({
          ...item,
          platform,
          ...{ platform: platform, game: game },
        });
      });
    });
    return registrations;
  },
};

const mutations = {
  setRegistrations(state, data) {
    state.registrations = [...state.registrations, ...data.items];
  },
  clearRegistrations(state) {
    state.registrations.splice(0);
  },
  clearToRegistrationsDefaultState(state) {
    const defaultState = REGISTRATIONS_DEFAULT_STATE();

    Object.keys(defaultState).forEach(key => {
      Vue.set(state, key, defaultState[key]);
    });
  },
};

const actions = {
  fetchRegistrations({ dispatch, getters, commit }, pageToken) {
    return new Promise(resolve => {
      api
        .getPreregistration(pageToken)(getters.accessToken)
        .then(response => {
          commit('setRegistrations', response.data);
          resolve(response.data);
        })
        .catch(error => dispatch('errorNotify', error));
    });
  },

  clearRegistrations({ commit }) {
    commit('clearRegistrations');
  },
  exportToFile({ getters }) {
    return new Promise((resolve, reject) => {
      window.URL = window.URL || window.webkitURL;

      var xhr = new XMLHttpRequest();
      xhr.open('GET', CONFIG.preregistration + '/export/excel.xlsx', true);
      xhr.setRequestHeader('Authorization', getters.accessToken);
      xhr.responseType = 'arraybuffer';

      xhr.onload = function() {
        if (this.status == 200) {
          let filename = 'registrations.xslx';
          var blob = new Blob([this.response], {
            type: 'application/octet-stream',
          });
          if (typeof window.navigator.msSaveBlob !== 'undefined') {
            window.navigator.msSaveBlob(blob, filename);
          } else {
            let blobURL = window.URL.createObjectURL(blob);
            let tempLink = document.createElement('a');
            tempLink.style.display = 'none';
            tempLink.href = blobURL;
            tempLink.setAttribute('download', filename);

            if (typeof tempLink.download === 'undefined') {
              tempLink.setAttribute('target', '_blank');
            }

            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(blobURL);
            resolve();
          }
        } else {
          reject(this.message);
        }
      };

      xhr.send();
    });
  },
};

export default {
  state: REGISTRATIONS_DEFAULT_STATE(),
  getters,
  mutations,
  actions,
};
