import { ifAuthorized } from '@/router/guards';

import CouponsWrapper from '@/views/admin/Coupons/';
import Coupons from '@/views/admin/Coupons/CouponsList';
import CouponEdit from '@/views/admin/Coupons/CouponEdit';

import PromoCodesWrapper from '@/views/admin/Promocodes/';
import PromoCodes from '@/views/admin/Promocodes/PromoCodesList';
import PromoCodeInfo from '@/views/admin/Promocodes/PromoCodeInfo';

export default [
  {
    path: '/coupons',
    title: 'Coupons',
    component: CouponsWrapper,
    beforeEnter: ifAuthorized,
    children: [
      {
        path: '',
        name: 'coupons',
        component: Coupons,
      },
      {
        path: 'edit/:couponID?',
        name: 'couponEdit',
        component: CouponEdit,
        props: true,
      },
      {
        path: 'create',
        name: 'couponCreate',
        component: CouponEdit,
      },
    ],
  },
  {
    path: '/promocodes',
    title: 'Promocodes',
    component: PromoCodesWrapper,
    beforeEnter: ifAuthorized,
    children: [
      {
        path: '',
        name: 'promocodes',
        component: PromoCodes,
      },
      {
        path: ':promocodeId',
        name: 'promocode',
        component: PromoCodeInfo,
      },
      {
        path: 'edit/:couponID?',
        name: 'promocodeEdit',
        component: CouponEdit,
        props: true,
      },
      {
        path: 'create',
        name: 'promocodeCreate',
        component: CouponEdit,
      },
    ],
  },
];
