import { ifAuthorized } from '@/router/guards';

import InvoicesWrapper from '@/views/admin/Invoices/';
import Invoices from '@/views/admin/Invoices/InvoicesList';

export default [
  {
    path: '/invoices',
    title: 'Invoices',
    component: InvoicesWrapper,
    beforeEnter: ifAuthorized,
    children: [
      {
        path: '',
        name: 'invoices',
        component: Invoices,
      },
    ],
  },
];
