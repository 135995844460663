import { ifAuthorized } from '@/router/guards';
import GamesWrapper from '@/views/admin/Games';
import Games from '@/views/admin/Games/GamesList.vue';
import CreateGame from '@/views/admin/Games/CreateGame.vue';

export default [
  {
    path: '/games',
    title: 'Games',
    component: GamesWrapper,
    beforeEnter: ifAuthorized,
    children: [
      {
        path: '',
        name: 'games',
        component: Games,
      },
      {
        path: 'create/:gameID?',
        name: 'createGame',
        component: CreateGame,
      },
    ],
  },
];
