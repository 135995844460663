'use strict';

import { BASE_URL } from '@/api/config.js';

export const SETTINGS_BASE = BASE_URL + 'settings';
export const CALLING_CODES = `${SETTINGS_BASE}/calling_codes`;
export const SUGGEST_COUNTRIES = `${SETTINGS_BASE}/suggest/countries`;
export const SUGGEST_CITY = `${SETTINGS_BASE}/suggest/city`;
export const GAMES = `${SETTINGS_BASE}/games`;
export const PLATFORMS = `${SETTINGS_BASE}/platforms`;
export const GENRES = `${SETTINGS_BASE}/genres`;
