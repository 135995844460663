<template>
  <tr class="subscription-list-item">
    <td class="px-2 pl-4 text-truncate subscription-list-item_name">
      <Checkbox
        v-model="subscription.enabled"
        :disabled="!editMode"
        class="mb-0"
        :class="{ disabled: !editMode }"
      />
    </td>
    <td class="px-2 text-truncate subscription-list-amount">
      <span>{{ subscription.name }}</span>
    </td>
    <td class="px-2 text-truncate subscription-list-amount">
      <span>{{ subscription.sku }}</span>
    </td>
    <td class="px-2 text-truncate subscription-list-amount">
      <span>{{ subscription.amount }}</span>
    </td>
    <td class="px-2 text-truncate subscription-list-amount">
      <span>{{ subscription.discount }}</span>
    </td>
    <td class="px-2 text-truncate subscription-list-amount">
      <span>{{ subscription.currency }}</span>
    </td>
    <td class="px-2">
      <span class="text-capitalize">{{ subscription.period }}</span>
    </td>
    <td class="px-2 subscription-list-description">
      <span>{{ subscription.description }}</span>
    </td>
    <td class="px-2">
      <span>{{ subscription.country }}</span>
    </td>
    <td class="px-2">
      <Checkbox
        v-model="subscription.hasTrial"
        :disabled="!editMode"
        class="mb-0"
        :class="{ disabled: !editMode }"
      />
    </td>
    <td class="px-2 text-truncate subscription-list-trial-amount">
      <span>{{ subscription.trialDays }}</span>
    </td>
    <td>
      <button
        v-if="editMode"
        :disabled="errorText"
        :class="{ 'is-loading': isLoading, disabled: errorText }"
        class="btn btn-sm btn-primary mr-3"
        @click="onSave"
      >
        {{ createMode ? 'Add' : 'Save' }}
      </button>
      <!-- <button
        v-else
        :disabled="errorText"
        :class="{ 'is-loading': isLoading, disabled: errorText }"
        class="btn btn-sm btn-outline-primary mr-3"
        @click="$emit('editItem', subscription)"
      >
        Edit
      </button> -->
      <router-link
        v-else
        class="btn btn-link"
        :to="{
          name: 'subscriptionEdit',
          params: { subscriptionID: subscription.id, country: countryValue },
        }"
      >
        <AppIcon name="edit" width="15" />
      </router-link>
      <button
        v-if="!createMode"
        :disabled="errorText || isDeleting"
        :class="{
          'is-loading': isLoading || isDeleting,
          disabled: errorText || isDeleting,
        }"
        class="btn btn-sm btn-outline-danger"
        @click="showConfirmationModal = true"
      >
        {{ isDeleting ? 'Deleting...' : 'Delete' }}
      </button>

      <div v-if="showConfirmationModal" @close="onConfirmationModalClosed">
        <div class="delete-modal-container">
          <div class="delete-modal">
            <div class="modal-header">
              <h1 class="modal-title">Delete Subscription</h1>
            </div>
            <div class="modal-body">
              <h3>Are you sure you want to delete this subscription?</h3>
            </div>
            <div class="modal-footer">
              <button class="btn btn-sm btn-outline-danger" @click="onDelete">
                Delete
              </button>
              <button
                class="btn btn-sm btn-secondary"
                @click="showConfirmationModal = false"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import Checkbox from '@/components/form/HeraFormCheckbox';
// import Toggle from '@/components/form/HeraFormSwitch';
import AppIcon from '@/components/common/Icon';
import { mapActions } from 'vuex';

export default {
  name: 'SubscriptionListItem',
  components: { Checkbox, AppIcon },
  inject: ['$validator'],
  props: {
    subscription: {
      type: Object,
      required: true,
    },
    country: { type: Object, required: false, default: () => null },
    createMode: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isLoading: false,
      isDeleting: false,
      showConfirmationModal: false,
    };
  },

  computed: {
    editMode() {
      return this.subscription.editMode;
    },
    countryValue: {
      get() {
        return this.subscription.country || this.country;
      },
      set(newValue) {
        if (!newValue) {
          this.$validator.validate(
            `subscriptionCountry_${this.subscription.key}`
          );
        } else {
          this.errors.remove(`subscriptionCountry_${this.subscription.key}`);
        }
        this.setSubscription({
          ...this.subscription,
          country: newValue,
          countryId: newValue.id,
        });
      },
    },
  },

  methods: {
    ...mapActions([
      'errorNotify',
      'suggestCountriesRequest',
      'deleteSubscription',
    ]),

    onDelete() {
      this.isDeleting = true;
      this.showConfirmationModal = true;
      this.deleteSubscription({ id: this.subscription.id })
        .then(() => {
          this.$emit('subscriptionDeleted', this.subscription.id);
        })
        .catch(error => {
          this.errorNotify(error.message);
        })
        .finally(() => {
          this.isDeleting = false;
        });
    },
    onConfirmationModalClosed(shouldDelete) {
      if (shouldDelete) {
        this.onDelete();
      } else {
        this.showConfirmationModal = false;
      }
    },
  },
};
</script>

<style lang="scss">
.subscription-list-item {
  &:first-child td {
    border-top: none;
  }
  td {
    font-weight: bold;
    font-size: 0.75rem;
    vertical-align: middle !important;
    text-align: center;
    &.subscription-list-item_name input {
      width: 7rem;
    }
    &.subscription-list-description {
      max-width: 250px;
    }
    &.subscription-list-amount input {
      width: 6rem;
    }
    &.subscription-list-trial-amount input {
      width: 4rem;
    }
    label.switch {
      margin-top: 0.5rem;
      &.disabled {
        opacity: 0.5;
      }
    }
    .custom-checkbox.disabled,
    .custom-checkbox:disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  .form-group {
    margin-bottom: 0;
  }
}

.delete-modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-modal {
  z-index: 9999;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 20px;
  box-shadow: 0px 0px 45px rgba(0, 0, 0, 0.5);
}

.delete-modal h1 {
  display: flex;
  justify-content: center;
}

.modal-footer {
  display: flex;
  justify-content: center;
  gap: 14px;
  padding-top: 4px;
}
</style>
