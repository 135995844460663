import api from '@/api';

const actions = {
  fetchRepostsSettingsList({ getters }) {
    return api.getReposts(getters.accessToken).then(response => response.data);
  },
  fetchRepostsSettings(
    {
      getters: { accessToken },
    },
    profileId
  ) {
    return api
      .getRepostsByProfile(profileId)(accessToken)
      .then(response => response.data);
  },
  saveRepostsSettings(
    {
      getters: { accessToken },
    },
    { profileId, data }
  ) {
    return api
      .putRepostsByProfile(profileId)(accessToken, data)
      .then(response => response.data);
  },
};

export default {
  actions,
};
