<template>
  <div :class="{ 'is-popover-chat': isPopover }">
    <div class="chats-list-wrap">
      <DummyList
        v-if="!items.length"
        icon-name="search"
        size="24"
        position="center"
      >
        We couldn`t find anything.
      </DummyList>
      <div v-else ref="chat_list" class="custom-scrollbar p-2 chats-list">
        <infiniteScroll
          :list="items"
          list-name="conversations"
          :is-disabled="scrollIsDisabled || nextPageToken === null"
          :in-container="true"
          @ScrollToBottom="fetchChats"
        >
          <ul slot-scope="{ conversations }" class="list-unstyled mb-0 p-0">
            <template v-if="isPopover">
              <li v-for="item in conversations" :key="item.id" class="card">
                <ChatsListItem
                  :data="item"
                  @click.native="$emit('setActiveDialog', item)"
                />
              </li>
            </template>
            <template v-else>
              <router-link
                v-for="item in conversations"
                :key="item.id"
                tag="li"
                :to="{ name: 'conversations', params: { id: item.id } }"
                class="card"
                :class="{ active: activeConversationId === item.id }"
              >
                <ChatsListItem :data="item" />
              </router-link>
            </template>
          </ul>
        </infiniteScroll>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import ChatsListItem from './ChatsListItem';
import DummyList from '@/components/common/DummyList';
import infiniteScroll from '@/components/common/infiniteScroll';

export default {
  name: 'ChatsList',
  components: {
    infiniteScroll,
    ChatsListItem,
    DummyList,
  },
  props: {
    isPopover: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      chatsType: 'all',
      items: [],
      activeConversation: null,
      nextPageToken: '',
      scrollIsDisabled: false,
      offsetTopPosition: 24, // 24 px add to <LI> item after autoscroll
    };
  },
  computed: {
    ...mapGetters([
      'activeConversationUnreadCount',
      'lastMsgInConversation',
      'conversationSubscribeData',
    ]),
    activeConversationId() {
      return this.isPopover ? '' : this.$route.params.id;
    },
  },
  watch: {
    activeConversationUnreadCount(val) {
      if (!val) {
        (
          this.items.find(msg => msg.id === this.$route.params.id) || {}
        ).unread_count = 0;
      }
    },
    lastMsgInConversation(val) {
      if (val && this.items.length) {
        let conversation = this.items.find(msg => msg.id === val.id);

        if (conversation) {
          Object.assign(conversation, val.data);
        }
      }
    },
    conversationSubscribeData(res) {
      if (!res) {
        return;
      }

      let conversation = this.items.find(item => item.id === res.chat);

      if (conversation) {
        Object.assign(conversation, {
          last_message: res.text,
          sent_date: res.sent_date,
          last_active: res.sent_date,
        });

        if (conversation.id !== this.activeConversationId) {
          conversation.unread_count = +conversation.unread_count + 1; // not use unread_count++
        }

        this.moveConversationToUp(conversation);
      } else {
        this.getConversationInfo(res.chat).then(conversationInfo => {
          conversation = { ...conversationInfo };
          this.moveConversationToUp(conversation);
        });
      }
    },
  },
  mounted() {
    this.fetchChats().then(() => {
      setTimeout(() => {
        const $chatsList = this.$refs.chat_list;

        if ($chatsList) {
          const $li = $chatsList.querySelector('li.active');
          if ($li) {
            $chatsList.scrollTop = $li.offsetTop - this.offsetTopPosition || 0;
          }
        }
      }, 10);
    });
  },

  methods: {
    ...mapMutations(['getConversationInfo']),
    ...mapActions(['fetchConversations']),

    fetchChats() {
      this.scrollIsDisabled = true;

      return new Promise((resolve, reject) => {
        if (this.nextPageToken === null) return reject();

        this.fetchConversations({
          pageToken: this.nextPageToken,
        })
          .then(res => {
            this.nextPageToken =
              this.nextPageToken === res.next_page_token
                ? null
                : res.next_page_token;

            if (res.items && res.items.length) {
              this.items = [...this.items, ...res.items];
              this.scrollIsDisabled = false;
            }

            resolve(res);
          })
          .catch(this.errorNotify);
      });
    },
    moveConversationToUp(conversation) {
      const index = this.items.indexOf(conversation);

      if (index === -1) {
        this.items = [conversation, ...this.items];
      }

      if (index > 0) {
        this.items.splice(index, 1);
        this.items = [conversation, ...this.items];
      }
    },
  },
};
</script>

<style lang="scss">
.is-popover-chat {
  height: 100%;
}

.chats-list-wrap {
  position: relative;
  height: calc(100% - 58px); // header height == 58px
  margin-right: -12px;
  .is-popover-chat & {
    margin-right: 0;
    height: 100%;
  }
}

.chats-list {
  position: relative;
  z-index: 1;
  height: 100%; // header height == 58px
  padding-right: 6px;
  overflow-x: hidden;

  .card {
    margin-bottom: 12px;
    transition: background-color 0.1s;
    border-radius: 4px;

    &.active,
    &:hover {
      background: darken($hera-background-color, 5%);
    }

    &:last-of-type {
      margin-bottom: 0;
    }
    .card-body {
      padding: 12px;
      text-decoration: none;
    }

    .media-body {
      width: calc(100% - 56px);
    }
  }
}

.chat-list-msg-header {
  line-height: 20px;
}

.chats-list-msg-preview {
  margin-right: 12px;
  @include text-multiline-ellipses(2, 18px);
}

.chats-list-title {
  max-width: calc(100% - 90px); // for date
}

.chats-list-badge {
  position: absolute;
  bottom: 12px;
  right: 12px;
}
</style>
