import { BASE_URL } from '@/api/config';

import {
  authorizedGetRequest,
  authorizedPostRequest,
  authorizedPutRequest,
  authorizedDeleteRequest,
} from '@/api/requests';

const ORG_BASE = BASE_URL + 'organizations';

const getOrganizations = authorizedGetRequest(ORG_BASE, {
  ignoreParams: true,
});

const createOrganization = authorizedPostRequest(ORG_BASE);

const getOrganizationProfile = orgID =>
  authorizedGetRequest(`${ORG_BASE}/${orgID}`);

const getOrganizationMembers = orgID =>
  authorizedGetRequest(`${ORG_BASE}/${orgID}/members`);

const setOrganizationDetails = orgID =>
  authorizedPutRequest(`${ORG_BASE}/${orgID}`);

const setOrganizationMember = authorizedPutRequest(`${ORG_BASE}/edit/admins`);

const deleteOrganizationMember = (orgID, member) =>
  authorizedDeleteRequest(`${ORG_BASE}/${orgID}/edit/admins/${member}`);

const deleteOrganizationProfile = orgID =>
  authorizedDeleteRequest(`${ORG_BASE}/${orgID}`);

const getOrganizationTypes = authorizedGetRequest(`${ORG_BASE}/types/all`);

const setOrganizationOwner = authorizedPutRequest(`${ORG_BASE}/edit/owner`);

export default {
  getOrganizations,
  createOrganization,
  getOrganizationProfile,
  getOrganizationMembers,
  setOrganizationDetails,
  setOrganizationMember,
  deleteOrganizationMember,
  deleteOrganizationProfile,
  getOrganizationTypes,
  setOrganizationOwner,
};
