'use strict';
import axios from 'axios';
import qs from 'qs';

export const CONFIG = require(`${process.env.VUE_APP_HERA_ENV}`);

export const BASE_URL = CONFIG.baseURL;
export const WEB_BASE_URL = CONFIG.heraWeb;

export const API = axios.create({
  baseURL: BASE_URL,
  paramsSerializer: params =>
    qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true }),
});

export default {
  CONFIG,
  BASE_URL,
  WEB_BASE_URL,
  API,
};
