<!-- eslint-disable vue/camelcase -->
<template>
  <div class="container-fluid">
    <div class="row my-4 align-items-center">
      <h3 class="mb-0 pb-0 col">Customers</h3>
    </div>
    <div class="row">
      <div class="col">
        <div class="card mt-4">
          <div class="card-body">
            <form @submit.prevent="applyFilters">
              <div class="form-row">
                <div class="form-group col-md-3">
                  <label for="login">Username</label>
                  <input
                    id="login"
                    v-model="login"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="form-group col-md-3">
                  <label for="email">Email</label>
                  <input
                    id="email"
                    v-model="email"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="form-group col-md-3">
                  <label for="country_id">Country ID</label>
                  <input
                    id="country_id"
                    v-model="country_id"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="form-group col-md-3">
                  <label for="purchases_since">Purchases since</label>
                  <input
                    id="purchases_sincee"
                    v-model="purchases_since"
                    type="date"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="costumers-button">
                <div :class="{ disabled: isLoading }">
                  <button
                    type="submit"
                    class="btn btn-primary mr-3"
                    :class="{ disabled: isLoading }"
                  >
                    Apply Filters
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    :class="{ disabled: isLoading }"
                    @click="clearFilters"
                  >
                    Clear Filters
                  </button>
                </div>
              </div>
            </form>
            <div class="flush-div">
              <button
                :class="{ 'is-loading': isExportingAll }"
                class="btn btn-primary ml-auto mr-4"
                @click="exportAll"
              >
                Export to XLS
              </button>
            </div>
          </div>
        </div>

        <div class="card">
          <div
            class="card-body border-top border-muted pt-0 px-0 overflow-auto"
          >
            <table class="table table-hover mx-0 px-0 subscriptions-list">
              <caption v-if="isLoading" class="text-center text-muted">
                Fetching customers...
              </caption>
              <caption
                v-else-if="!costumerList.length"
                class="text-center text-muted"
              >
                No customers to show
              </caption>
              <template v-else>
                <thead>
                  <tr>
                    <th
                      class="border-top-0 border-bottom-0 pr-2 pl-4 section-description"
                    >
                      Username
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 pr-2 pl-4 section-description"
                    >
                      Email
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 pr-2 pl-4 section-description"
                    >
                      Phone
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Country
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Country ID
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Customer Since
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Account Created in
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <ListItem
                    v-for="customer in costumerList"
                    :key="customer.id"
                    v-bind="{ customer }"
                  />
                </tbody>
              </template>
            </table>
          </div>
          <nav
            v-if="total > 0 && !isLoading"
            class="mt-3 d-flex flex-column align-items-center"
          >
            <ul class="pagination justify-content-center">
              <li
                class="page-item-btn"
                :class="{ disabled: isLoading || isFirstPage }"
              >
                <a class="page-link" href="#" @click.prevent="prevPage"
                  >Previous</a
                >
              </li>
              <li class="page-item">
                <span class="page-link"
                  ><input
                    v-model="pageInput"
                    min="1"
                    :max="totalPages"
                    class="form-control-pagination"
                    :placeholder="currentPage"
                    @keyup.enter="goToPageInput"
                  />
                  / {{ totalPages }}</span
                >
              </li>
              <li
                class="page-item-btn"
                :class="{ disabled: isLoading || isLastPage }"
              >
                <a
                  class="page-link"
                  href="#"
                  @click.prevent="goToPage(currentPage + 1)"
                  >Next</a
                >
              </li>
            </ul>
            <span
              v-if="pageInput > totalPages || isNaN(pageInput)"
              class="error-pagination"
              >{{
                pageInput > totalPages
                  ? `Max page is ${totalPages}`
                  : '' || isNaN(pageInput)
                  ? 'Please enter number'
                  : ''
              }}</span
            >
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import ListItem from './ListItem';

export default {
  name: 'CustomerList',
  components: { ListItem },
  data() {
    return {
      nextPageToken: undefined,
      prevPageToken: undefined,
      isLoading: false,
      currentPage: 1,
      itemsPerPage: 20,
      costumerList: [],
      total: 0,
      login: null,
      email: null,
      phone: null,
      country_id: null,
      purchases_since: null,
      createdAt: null,
      isExportingAll: false,
      pageInput: '',
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.total / this.itemsPerPage);
    },
    isFirstPage() {
      return this.currentPage === 1;
    },
    isLastPage() {
      return this.currentPage === this.totalPages;
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions([
      'fetchCustomers',
      'exportAllCustomers',
      'errorNotify',
      'successNotify',
    ]),

    exportAll() {
      this.isExportingAll = true;
      const filterProps = {
        login: this.login,
        email: this.email,
        phone: this.phone,
        country_id: this.country_id,
        purchases_since: this.purchases_since
          ? Math.floor(new Date(this.purchases_since).setHours(0, 0, 0, 0))
          : null,
        createdAt: this.createdAt
          ? Math.floor(new Date(this.createdAt).setHours(0, 0, 0, 0))
          : null,
      };

      const filteredProps = Object.fromEntries(
        Object.entries(filterProps).filter(
          // eslint-disable-next-line no-unused-vars
          ([_, value]) => value !== '' && value !== null
        )
      );
      this.exportAllCustomers({ props: filteredProps })
        .catch(() => this.errorNotify('export failed'))
        .finally(() => (this.isExportingAll = false));
    },
    fetchData() {
      this.isLoading = true;

      const filterProps = {
        login: this.login,
        email: this.email,
        phone: this.phone,
        country_id: this.country_id,
        purchases_since: this.purchases_since
          ? Math.floor(new Date(this.purchases_since).setHours(0, 0, 0, 0))
          : null,
        createdAt: this.createdAt
          ? Math.floor(new Date(this.createdAt).setHours(0, 0, 0, 0))
          : null,
      };

      this.fetchCustomers({
        page: this.currentPage,
        pageToken: this.nextPageToken,
        prevPageToken: this.prevPageToken,
        pageSize: this.itemsPerPage,
        props: filterProps,
      })
        .then(res => {
          this.total = res.total;
          this.nextPageToken = res.next_page_token;
          this.prevPageToken = res.prev_page_token;

          if (res.items) {
            this.costumerList = res.items.map(el => ({
              ...el,
              editMode: false,
              key: `costumer_${el.id}`,
            }));
          } else {
            this.costumerList = [];
          }
        })
        .catch(this.errorNotify)
        .finally(() => {
          this.isLoading = false;
        });
    },

    applyFilters() {
      this.nextPageToken = undefined;
      this.prevPageToken = undefined;
      this.isLoading = true;
      this.currentPage = 1;
      this.fetchData();
    },

    clearFilters() {
      (this.login = null),
        (this.email = null),
        (this.phone = null),
        (this.country_id = null),
        (this.purchases_since = null),
        (this.createdAt = null),
        (this.nextPageToken = undefined);
      this.prevPageToken = undefined;
      this.currentPage = 1;
      this.isLoading = true;
      this.fetchData();
    },

    prevPage() {
      if (this.prevPageToken) {
        this.nextPageToken = this.prevPageToken;
        this.prevPageToken = null;
        this.currentPage--;
        this.pageInput = '';
        this.fetchData();
      }
    },

    goToPage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.currentPage = page;
        this.pageInput = '';
        this.fetchData();
      }
    },

    goToPageInput() {
      const pageNumber = parseInt(this.pageInput, 10);
      if (pageNumber > 0 && pageNumber <= this.totalPages) {
        this.currentPage = pageNumber;
        this.fetchData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-item {
  cursor: not-allowed;
}

.page-link:hover {
  background-color: white !important;
}
.page-item-btn {
  .page-link {
    padding: 0.67rem $pagination-padding-x;
  }
}

.costumers-button {
  display: flex;
  justify-content: space-between;
}

.flush-div {
  display: flex;
  justify-content: flex-end;
  margin-top: -38px;
}
.form-control-pagination {
  width: 30px;
}

.flush-button {
  background-color: red;
  color: white;
}
</style>
