<template>
  <div class="custom-control custom-radio">
    <input
      :id="inputID"
      class="custom-control-input"
      type="radio"
      :name="name"
      :value="val"
      :checked="value === val"
      @change="onChange"
      @input="onInput"
    />
    <label class="custom-control-label" :for="inputID" onclick="">{{
      label
    }}</label>
  </div>
</template>

<script>
export default {
  name: 'HeraRadio',
  model: {
    event: 'change',
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    dir: {
      type: String,
      default: 'ltr',
    },
    id: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    val: {
      type: null,
      default: null,
    },
    value: {
      type: null,
      default: null,
    },
  },
  data() {
    return {
      // checked: false
    };
  },
  computed: {
    inputID() {
      return this.id
        ? this.id + 'InputField'
        : 'InputField' + this.getRandomInt(999);
    },
  },
  methods: {
    getRandomInt(max) {
      return Math.floor(Math.random() * Math.floor(max));
    },
    onChange() {
      this.$emit('change', this.val);
    },
    onInput() {
      this.$emit('input', this.val);
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-control {
  &.custom-control {
    margin-top: 0.5rem;
    min-height: 1rem;
    .custom-control-label {
      cursor: pointer;
      font-weight: bold;
      &:before {
        top: 0rem;
      }
      &:after {
        top: 0rem;
      }
    }
    &.rtl {
      margin-right: 0rem;
      margin-left: 1rem;
      padding-left: 0rem;
      padding-right: 1.5rem;
      > .custom-control-label {
        &::after {
          left: unset;
          right: 0;
          margin-right: -1.5rem;
        }
        &::before {
          left: unset;
          right: 0;
          margin-right: -1.5rem;
          // border-radius: 50%!important;
        }
      }
    }
  }
}
</style>
