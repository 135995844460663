<template>
  <tr class="popular-players-item" :class="{ draggable: draggable }">
    <td class="align-middle">
      <div class="d-flex align-items-center">
        <template v-if="draggable">
          <AppIcon
            class="icon icon-draggable mr-2"
            width="22"
            name="draggable"
          />
          <AppIcon
            class="icon icon-draggable-choice mr-2"
            width="22"
            name="draggable-choice"
          />
        </template>
        <b>{{ index }}</b>
      </div>
    </td>
    <td
      class="pr-2 pl-1 align-middle text-truncate popular-players-item_login-cell"
    >
      <Avatar
        :img-url="player.avatar || player.logo"
        :size="22"
        class="d-inline-block mr-2 profile-Avatar"
      />
      {{ player.name || player.login }}
    </td>
    <td class="pr-2 pl-1 align-middle text-capitalize">
      {{ player.type || '-' }}
    </td>
    <td class="pr-2 pl-1 align-middle">
      {{ player.country }}
    </td>
    <td class="pr-2 pl-1 py-0 align-middle">
      <slot name="button"></slot>
    </td>
  </tr>
</template>

<script>
import Avatar from '@/components/common/avatar.vue';
import AppIcon from '@/components/common/Icon';

export default {
  name: 'ItemPlayer',
  components: {
    Avatar,
    AppIcon,
  },
  props: {
    player: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
};
</script>

<style lang="scss">
.popular-players-item {
  border-radius: 4px;
  td {
    font-weight: bold;
    font-size: 0.75rem;
    &:first-child {
      width: 40px;
      padding-right: 0.3rem;
    }
  }
  &:hover {
    .icon-draggable-choice {
      display: inline-block;
    }
    .icon-draggable {
      display: none;
    }
  }

  &.draggable {
    cursor: pointer;
    td {
      &:first-child {
        width: 70px;
        padding-left: 0.4rem;
      }
    }
  }
  .icon {
    fill: $hera-draggable-icon-fill;
    &-draggable-choice {
      display: none;
    }
  }
  .sortable-chosen {
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
    .icon-draggable-choice {
      display: inline-block;
    }
    .icon-draggable {
      display: none;
    }
  }

  &_login-cell {
    max-width: 13rem;
  }
}
</style>
