<template>
  <div class="custom-control custom-checkbox">
    <input
      :id="inputID"
      class="custom-control-input"
      type="checkbox"
      :name="name"
      :value="value"
      :true-value="val"
      :false-value="null"
      :checked="isChecked"
      @change="onChange"
    />
    <label class="custom-control-label" :for="inputID">{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: 'HeraCheckbox',
  model: {
    prop: 'val',
    event: 'change',
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    dir: {
      type: String,
      default: 'ltr',
    },
    id: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    val: {
      type: null,
      default: false,
    },
    value: {
      type: String,
      default: null,
    },
    trueValue: {
      type: null,
      default: true,
    },
    falseValue: {
      type: null,
      default: false,
    },
  },
  computed: {
    isChecked() {
      return Array.isArray(this.val)
        ? this.val.includes(this.trueValue)
        : this.trueValue === this.val;
    },
    inputID() {
      return this.id
        ? this.id + 'InputField'
        : 'InputField' + this.getRandomInt(9999);
    },
  },
  methods: {
    onChange(event) {
      let checked = event.target.checked;
      if (Array.isArray(this.val)) {
        let newVal = [...this.val];
        if (checked) {
          newVal.push(this.trueValue);
        } else {
          newVal.splice(newVal.indexOf(this.trueValue), 1);
        }
        this.$emit('change', newVal);
      } else {
        this.$emit('change', checked ? this.trueValue : this.falseValue);
      }
    },
    getRandomInt(max) {
      return Math.floor(Math.random() * Math.floor(max));
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-control {
  &.custom-control {
    min-height: 1.125rem;
    .custom-control-input {
      position: absolute;
      left: -999px;
      &:checked {
        ~ .custom-control-label {
          &:before {
            border: 1px solid $primary;
            background-color: $primary;
          }
          &:after {
            background-image: url(../../assets/images/icons/checked.svg);
          }
        }
      }
    }
    .custom-control-label {
      position: absolute;
      &:before {
        width: 1.125rem;
        height: 1.125rem;
        top: 0rem;
        background-color: transparent;
        border: 2px solid #dce0e6;
      }
      &:after {
        width: 1.125rem;
        height: 1.125rem;
        top: 0rem;
      }
    }
    &.rtl {
      margin-right: 0rem;
      margin-left: 1rem;
      padding-left: 0rem;
      padding-right: 1.5rem;
      > .custom-control-label {
        &::after {
          left: unset;
          right: 0;
          margin-right: -1.5rem;
        }
        &::before {
          left: unset;
          right: 0;
          margin-right: -1.5rem;
        }
      }
    }
  }
}
</style>
