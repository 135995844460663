import { BASE_URL } from '@/api/config.js';
import { authorizedGetRequest, authorizedPutRequest } from '@/api/requests.js';

const POPULAR_BASE = BASE_URL + 'popularpeople';
const COUNTRIES = POPULAR_BASE + '/countries';
const EDIT = POPULAR_BASE + '/edit';

export const popularPlayers = {
  getPopularPeople: authorizedGetRequest(POPULAR_BASE),
  getPopularPeopleCountries: authorizedGetRequest(COUNTRIES),
  getPopularPeopleForEdit: country_id =>
    authorizedGetRequest(`${EDIT}/${country_id}`),
  updatePopularPeople: country_id =>
    authorizedPutRequest(`${EDIT}/${country_id}`),
};

export default {
  popularPlayers,
};
