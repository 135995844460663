// import { isTokenValid } from '@/utils';
import { cloneDeep, lowerCase } from 'lodash';
import { isRequestCanceled } from './requestCancel';
import { API } from './config';

const updateTokenInConfig = function(config) {
  const newConfig = cloneDeep(config);
  if (newConfig.headers.common) {
    newConfig.headers.common['Authorization'] = this.$store.getters.accessToken;
  } else {
    newConfig.headers['Authorization'] = this.$store.getters.accessToken;
  }
  return newConfig;
};
// import axios from 'axios';

export function onRequestSuccess(config) {
  const self = this;

  let setToken = updateTokenInConfig.bind(self);

  // const isNeedRefresh = !isTokenValid(self.$store.getters.tokenObject('accessToken')) && isTokenValid(self.$store.getters.tokenObject('refreshToken'))

  return new Promise((resolve, reject) => {
    const token = config.headers.common['Authorization'];
    const onRefreshError = e => {
      //self.$store.dispatch("logout")
      //.then(() => {
      //self.$router.push({name: "signin"});
      reject({ message: e, url: config.url });
      //});
    };
    /*if (
      token &&
      token === self.$store.getters.accessToken &&
      isNeedRefresh &&
      !self.$store.getters.isRefreshProcessing
    ) {
      let refreshPromise = self.$store.dispatch("refreshAccessToken")
      self.$store.commit("changeRefreshStopover", refreshPromise);
      refreshPromise
        .then(() => resolve(setToken(config)))
        .catch(onRefreshError);
    } else */
    if (
      token &&
      token !== self.$store.getters.refreshToken &&
      self.$store.getters.isRefreshProcessing
    ) {
      self.$store.getters.refreshStopover
        .then(() => resolve(setToken(config)))
        .catch(onRefreshError);
    } else {
      resolve(config);
    }
  });
}

export function onResponseSuccess(response) {
  return response;
}

const parseDefaultError = (data, prevError = '') => {
  let result = prevError;

  if (data.fields) {
    result = Object.keys(data.fields).reduce((t, key) => {
      const messages = data.fields[key].map(message => {
        let res = '';
        if (key) {
          res += `<strong>${key}: </strong>`;
        }
        res += '<span>';
        res += message.error
          ? lowerCase(message.error)
          : Object.keys(message.context || {})
              .map(key =>
                key === 'message'
                  ? message.context[key]
                  : `${key}: ${message.context[key]}`
              )
              .join(', ');
        res += '</span>';

        return res;
      });
      return `${t}${messages.join('<br>')}`;
    }, `${result}<br>`);
  } else if (data.message) {
    result = data.message;
  }

  return result;
};

export function onResponseError(error) {
  if (isRequestCanceled(error)) {
    throw { silent: true, message: 'Request cancelled' };
  }

  let text = error.message;

  if (error.response) {
    const { status, data, config } = error.response;
    // let type = 'error'
    text = `<span>${data.message}</span>`;
    let setToken = updateTokenInConfig.bind(this);
    const isRefreshRequest =
      config.headers['Authorization'] === this.$store.getters.refreshToken;
    let refreshChain;

    const { dataError } = data;
    const requestMethod = error.config.method.toUpperCase();
    const hasEndpoint = error.config.url.match(/\/api\/.*$/i);
    const endpoint = hasEndpoint ? hasEndpoint[0] : '';

    switch (status) {
      case 400:
        if (dataError) {
          text = {
            ...data,
            message: this.$t(`_web-${dataError}`, dataError),
          };
        } else {
          text = parseDefaultError(data, text);
        }
        break;
      case 401:
        // do we refreshing tokens right now?
        if (isRefreshRequest) {
          // if we have an error while refreshing token -> bypass this error further
          refreshChain = Promise.reject(
            this.$t('_web_session_expired_error', 'Session expired.')
          );
        } else if (this.$store.getters.isRefreshProcessing) {
          // else if  it's another request so get refreshing promise
          refreshChain = this.$store.getters.refreshStopover;
        } else {
          // otherwise start new refresh process
          let refreshPromise = this.$store.dispatch('refreshAccessToken');
          this.$store.commit('changeRefreshStopover', refreshPromise);
          refreshChain = refreshPromise;
        }
        // after refresh we need to retry failed request
        return refreshChain
          .catch(refreshError => {
            // logout user on any refresh error
            // this.$store.commit('setRefreshProcessing', false);
            // this.$store.commit('changeRefreshStopover');
            this.$store.dispatch('logout').then(() => {
              this.$router.push('/signin');
            });
            throw refreshError;
          })
          .then(() => setToken(config))
          .then(API.request)
          .catch(anotherError => {
            throw anotherError;
          });
      case 403:
        // this.$store.dispatch("logout")
        //   .then(() => {
        //     this.$router.push("/signin");
        //   });
        text = 'Access denied';
        break;
      case 404:
        text = `<strong>${requestMethod}&nbsp${endpoint}</strong><br>Wrong URI or endpoint not implemented yet`;
        break;
      case 500:
        if (error.response.data && error.response.data.error) {
          text = error.response.data.error;
        } else {
          text = 'Internal server error';
        }
        break;
      default:
        text = parseDefaultError(data, text);
    }

    // console.log(type, text)
    // if (skipNext) {
    //   this.$store.dispatch('systemNotify', {
    //     type,
    //     title,
    //     text
    //   })
    // }
    throw { message: text, originalError: error };
  }
  // eslint-disable-next-line
  //return Promise.reject(text)
}

export default {
  onRequestSuccess,
  onResponseSuccess,
  onResponseError,
};
