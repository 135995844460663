<template>
  <tr class="coupon-list-item">
    <td class="px-2 text-truncate invoice-list-amount">
      <span>{{ report.external_id }}</span>
    </td>
    <td class="px-2 text-truncate invoice-list-amount">
      <span>
        {{ new Date(report.created_on * 1000).toLocaleDateString() }} {{ ' ' }}
        {{ new Date(report.created_on * 1000).toLocaleTimeString() }}
      </span>
    </td>
    <td class="px-2 text-truncate">
      <span>{{ report.internal_user_id }}</span>
    </td>
    <td class="px-2 text-truncate">
      <span>{{ report.is_activated ? 'Yes' : 'No' }}</span>
    </td>

    <td class="px-2">
      <span>{{ report.is_expired ? 'Yes' : 'No' }}</span>
    </td>
    <td class="px-2">
      <span>{{ report.merchant }}</span>
    </td>
    <td class="px-2">
      <span class="disc-desc">{{ report.merchant_user_id }}</span>
    </td>
    <td class="px-2 text-truncate">
      <span
        >{{ new Date(report.from_date_time * 1000).toLocaleDateString() }}
        {{ ' ' }}
        {{ new Date(report.from_date_time * 1000).toLocaleTimeString() }}
      </span>
    </td>
    <td class="px-2">
      <span
        >{{ new Date(report.to_date_time * 1000).toLocaleDateString() }}
        {{ ' ' }}
        {{ new Date(report.to_date_time * 1000).toLocaleTimeString() }}
      </span>
    </td>
  </tr>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ReportListItem',
  components: {},
  inject: ['$validator'],
  props: {
    report: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      email: '',
    };
  },
  computed: {},
  created() {},
  methods: {
    ...mapActions(['openModal', 'closeModal', 'resendInvoice', 'errorNotify']),
    addLeadingZero(num) {
      return (num < 10 ? '0' : '') + num;
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    resend() {
      this.isLoading = true;

      this.resendInvoice({
        id: this.invoice.id,
        email: this.email,
      })
        .then(() => {})
        .catch(this.errorNotify)
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style lang="scss">
.disc-desc {
  max-width: 100px;
  word-break: break-all;
}
</style>
