import { ifAuthorized } from '@/router/guards';
import BadgesWrapper from '@/views/admin/Badges/index.vue';
import Ambassadors from '@/views/admin/Badges/Ambassadors.vue';
import ClansVerification from '@/views/admin/Badges/Clans.vue';

export default [
  {
    path: '/badges',
    title: 'Badges',
    component: BadgesWrapper,
    beforeEnter: ifAuthorized,
    children: [
      {
        path: '',
        redirect: {
          name: 'clansVerification',
        },
      },
      {
        path: 'clans',
        name: 'clansVerification',
        component: ClansVerification,
      },
      {
        path: 'ambassadors',
        name: 'ambassadors',
        component: Ambassadors,
      },
    ],
  },
];
