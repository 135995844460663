<template>
  <tr class="tournaments-list-item">
    <td class="pr-2 pl-4 text-truncate tournaments-list-item_name">
      <Avatar
        :img-url="tournament.logo"
        :size="22"
        class="d-inline-block mr-2"
      />
      {{ tournament.title }}
    </td>
    <td class="px-2">{{ distinctTournamentsStatus[tournament.status] }}</td>
    <td class="pr-4 pl-2">
      <template v-if="tournament.rank_type">
        {{
          $t(
            `_web_rank_${tournament.rank_type}`,
            rankLabelByKey(tournament.rank_type)
          )
        }}
      </template>
      <template v-else>
        {{ $t(`_web_rank_not_ranked`, rankLabelByKey('not_ranked')) }}
      </template>
    </td>
    <td class="px-2">{{ game }}</td>
    <td class="px-2">{{ platform }}</td>
    <td class="px-2">{{ tournament.squads_joined }}</td>
    <td class="px-2">{{ tournament.country }}</td>
    <td
      class="position-relative pl-2 pr-4 tournaments-list-item_pin"
      :class="{ 'is-loading': isLoading }"
    >
      <Icon
        v-if="!isLoading"
        :class="isPinningAvailable ? 'cursor-pointer' : 'cursor-disabled'"
        name="pin"
        width="24"
        :fill="pinned ? $scss.mainColor : $scss.disabledColor"
        @click="pinClickHandler"
      />
    </td>
    <td
      class="position-relative pl-2 pr-4 tournaments-list-item_special_event"
      :class="{ 'is-loading': isLoading }"
    >
      <Icon
        v-if="!isLoading"
        class="cursor-pointer"
        name="special-event"
        width="24"
        :fill="isSpecialEvent ? $scss.mainColor : $scss.disabledColor"
        @click="specialEventClickHandler"
      />
    </td>
  </tr>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { distinctTournamentsStatus } from '@/constants/tournaments';
import Avatar from '@/components/common/avatar';
import Icon from '@/components/common/Icon';

const PINNING_STATUSES = [
  'draft',
  'announced',
  'registration_opened',
  'registration_closed',
  'scheduled',
  'started',
  'published',
];

export default {
  components: {
    Avatar,
    Icon,
  },
  props: {
    tournament: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      distinctTournamentsStatus,
      pinned: !!this.tournament.pinned,
      isSpecialEvent: !!this.tournament.is_special_event,
      isLoading: false,
    };
  },

  computed: {
    ...mapGetters(['platformByID', 'gameByID', 'rankLabelByKey']),

    game() {
      return (this.gameByID(this.tournament.game) || {}).name;
    },

    platform() {
      return this.tournament.platforms
        .map(platformId => (this.platformByID(platformId) || {}).name)
        .join(', ');
    },

    isPinningAvailable() {
      return PINNING_STATUSES.includes(this.tournament.status);
    },
  },

  methods: {
    ...mapActions([
      'pinTournament',
      'unpinTournament',
      'errorNotify',
      'markSpecialEventTournament',
      'unmarkSpecialEventTournament',
    ]),

    pinClickHandler() {
      if (!this.isPinningAvailable) {
        return;
      }
      this.isLoading = true;
      (this.pinned ? this.unpinTournament : this.pinTournament)(
        this.tournament.id
      )
        .then(() => (this.pinned = !this.pinned))
        .catch(this.errorNotify)
        .finally(() => (this.isLoading = false));
    },
    specialEventClickHandler() {
      this.isLoading = true;
      (this.isSpecialEvent
        ? this.unmarkSpecialEventTournament
        : this.markSpecialEventTournament)(this.tournament.id)
        .then(() => (this.isSpecialEvent = !this.isSpecialEvent))
        .catch(this.errorNotify)
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style lang="scss">
.tournaments-list-item {
  &:first-child td {
    border-top: none;
  }
  td {
    font-weight: bold;
    font-size: 0.75rem;
  }
  &_name {
    max-width: 12.5rem;
  }
  &_pin {
    text-align: center;
  }
  &_special_event {
    text-align: center;
  }
}
</style>
