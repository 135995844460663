<template>
  <div class="page-wrapper org-list">
    <div class="container">
      <div class="my-4 d-flex justify-content-between align-items-center">
        <h3 class="mb-0 pb-0">Organisations list</h3>
        <router-link
          class="btn btn-primary"
          :to="{ name: 'organizationCreate' }"
        >
          Create Organiser Profile
        </router-link>
      </div>
      <section class="card">
        <header class="card-header bg-transparent d-flex">
          <div class="col-2">
            <TextInput
              v-model="filter.contains"
              label="Search:"
              :debounce="300"
              placeholder="Search..."
              @input="onFilterChangeDebounced"
            />
          </div>
          <div class="col-2">
            <AutoSuggest
              id="country"
              v-model="filter.country"
              class="mw-100"
              label="Country:"
              name="country"
              placeholder="Start typing..."
              display-property="text"
              :source="suggestCountriesRequest"
              @resultPicked="onFilterChange"
            />
          </div>
          <div class="col-2">
            <label>Type:</label>
            <Dropdown
              v-model="filter.orgType"
              empty-option
              class="input-like w-100"
              :list="organizationTypes"
              :is-loading="!organizationTypes.length"
              @change="onFilterChange"
            />
          </div>
          <div class="col-2">
            <AutoSuggest
              id="owner"
              v-model="filter.owner"
              name="owner"
              label="Search owner:"
              placeholder="Search owner..."
              display-property="login"
              :source="suggestProfiles"
              @resultPicked="onFilterChange"
            />
          </div>
          <div class="col">
            <button
              v-show="isFiltered"
              class="btn btn-link mt-4"
              @click.prevent="setDefaultFilter"
            >
              Clear filters
            </button>
          </div>
        </header>
        <div class="card-body pb-0 px-4">
          <infiniteScroll
            :list="organizationList"
            list-name="list"
            :is-disabled="isLoading || nextPageToken === null"
            :activation-gap="100"
            @ScrollToBottom="getOrganizations"
          >
            <table
              id="profiles-list"
              slot-scope="{ list }"
              class="table table-hover mx-0 px-0"
            >
              <caption
                v-if="!list.length && isLoading"
                class="text-center text-muted"
              >
                Fetching organisations...
              </caption>
              <caption v-else-if="!list.length" class="text-center text-muted">
                No Organisations
              </caption>
              <template v-else>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Country</th>
                    <th>City</th>
                    <th>Type</th>
                    <th>Owner</th>
                    <th>reg date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="organization in list" :key="organization.id">
                    <td>{{ organization.name || organization.login }}</td>
                    <td>{{ organization.country }}</td>
                    <td>{{ organization.city }}</td>
                    <td>
                      {{
                        getOrganizationTypeCaption(
                          organization.organizationType || organization.type
                        )
                      }}
                    </td>
                    <td>{{ organization.owner }}</td>
                    <td>
                      {{ secondsToFormat(organization.registration_date) }}
                    </td>
                    <td class="text-right">
                      <router-link
                        class="btn btn-link"
                        :to="{
                          name: 'organizationEdit',
                          params: { organizationID: organization.login },
                        }"
                      >
                        <AppIcon name="edit" width="15" />
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </template>
            </table>
          </infiniteScroll>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { isEqual, debounce } from 'lodash';
import { DateTime } from 'luxon';
import listMixin from '@/mixins/lists';
import AppIcon from '@/components/common/Icon';
import Dropdown from '@/components/form/HeraFormDropdown';
import TextInput from '@/components/form/HeraFormTextInput';
import AutoSuggest from '@/components/form/HeraAutosuggest';
import infiniteScroll from '@/components/common/infiniteScroll.vue';

const defaultFilterState = () => ({
  contains: null,
  country: null,
  orgType: null,
  owner: null,
});

const SEARCH_DEBOUNCE_TIME = 300; //ms

export default {
  name: 'OrganizationsList',
  components: {
    AppIcon,
    Dropdown,
    TextInput,
    AutoSuggest,
    infiniteScroll,
  },
  mixins: [...listMixin],
  data() {
    return {
      filter: defaultFilterState(),
      organizationList: [],
      onFilterChangeDebounced: debounce(
        this.onFilterChange,
        SEARCH_DEBOUNCE_TIME
      ),
    };
  },
  computed: {
    ...mapGetters(['getOrganizationTypeCaption']),
    ...mapState({
      organizationTypes: state => state.organization.organizationTypes,
    }),
    isFiltered() {
      return !isEqual(this.filter, defaultFilterState());
    },
  },
  created() {
    this.getOrganizations();
  },
  methods: {
    ...mapActions([
      'suggestCountriesRequest',
      'fetchOrganizations',
      'suggestProfiles',
    ]),
    onFilterChange() {
      this.organizationList = [];
      this.nextPageToken = undefined;
      this.getOrganizations();
    },
    getOrganizations() {
      const params = {
        page: this.nextPageToken,
        name: this.filter.contains,
        organization_type: this.filter.orgType && this.filter.orgType.value,
        country: this.filter.country && this.filter.country.id,
        owner: this.filter.owner && this.filter.owner.login,
      };
      this.getNextPageData(
        'organizationList',
        this.fetchOrganizations({ params })
      );
    },
    secondsToFormat(seconds) {
      return seconds && typeof seconds === 'number'
        ? DateTime.fromSeconds(seconds, { zone: 'UTC' })
            .toLocal()
            .toFormat('dd-MM-yyyy')
        : '';
    },
    setDefaultFilter() {
      this.filter = defaultFilterState();
      this.onFilterChange();
    },
  },
};
</script>

<style lang="scss">
.org-list {
  table thead th {
    font-size: 0.625rem; // 10px / 16px;
    font-weight: normal;
    text-transform: uppercase;
    color: $hera-secondary-text-color;
  }
  .autosuggest_label {
    height: 1.125rem;
    padding-left: 0 !important; //for replace boostrap class ml-1
  }
}
</style>
