<template>
  <div class="home d-flex w-100 h-100 justify-content-center">
    <div class="container">
      <div class="row my-4 align-items-center">
        <h3 class="mb-3 pb-0 col">Genres</h3>
      </div>
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body pb-0 px-4">
              <div class="row px-0 py-3">
                <div class="col-12">
                  <button
                    class="btn btn-link d-inline-flex align-items-baseline"
                    @click="onAddItem"
                  >
                    <AppIcon
                      name="add"
                      width="14"
                      class="fill-primary mr-2"
                    />&nbsp;Add item
                  </button>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-auto">
                  <div
                    v-if="isLoading && !genres.length"
                    class="text-center text-muted py-4"
                  >
                    Fetching genres...
                  </div>
                  <div v-else-if="genres.length" class="w-100 genres_table">
                    <div
                      class="genre-list_col-headers d-flex align-items-center"
                    >
                      <div class="genre-list_col-headers--handler"></div>
                      <div
                        class="genre-list_col-headers--icon section-description"
                      >
                        Image
                      </div>
                      <div
                        class="genre-list_col-headers--text flex-grow-1 section-description"
                      >
                        Genre name
                      </div>
                      <div
                        class="genre-list_col-headers--actions section-description"
                      >
                        Actions
                      </div>
                    </div>
                    <SortableList
                      v-model="genres"
                      container-tag="div"
                      item-tag="div"
                      handle-tag="div"
                      @end="debouncedOrdering"
                    >
                      <template slot="item" slot-scope="{ item }">
                        <ListItem
                          :key="item.text"
                          :value="item"
                          v-bind="{
                            editMode: item.editMode,
                            isLoading: isLoading && updatingItemId === item.id,
                          }"
                          can-remove-image
                          @input="setGenre"
                          @deleteItem="onDeleteItem"
                          @saveItem="onSaveItem"
                          @editItem="turnEditMode"
                        />
                      </template>
                    </SortableList>
                  </div>
                  <div v-else class="text-center text-muted py-4">
                    Genres list is empty. You could add the first one.<br />
                    <button @click="onAddItem">Add Genre</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { cloneDeep, uniqueId, debounce, get } from 'lodash';
import { mapActions, mapMutations } from 'vuex';
import ListItem from './GenreListItem';
import SortableList from '@/components/common/SortableList';
import AppIcon from '@/components/common/Icon';
const DEFAULT_GENRE = id => ({
  name: '',
  key: `genre_${uniqueId()}`,
  logo: null,
  sort_order: 0,
  id,
  editMode: true,
  published: true,
});
export default {
  name: 'Genres',
  components: {
    ListItem,
    SortableList,
    AppIcon,
  },
  data() {
    return {
      isLoading: false,
      newGenres: {},
      genresBackup: [],
      updatingItemId: null,
    };
  },
  computed: {
    genres: {
      get() {
        return this.$store.state.settings.genres;
      },
      set(newVal) {
        this.setGenres(
          newVal.map((genre, index) => ({ ...genre, sort_order: index }))
        );
      },
    },
  },
  created() {
    this.setLoading();
    this.getGenres()
      .then(this.backupGenres)
      .catch(this.errorNotify)
      .finally(this.unsetLoading);
  },
  methods: {
    ...mapMutations(['setGenres', 'setGenre', 'addGenre', 'removeGenre']),
    ...mapActions([
      'getGenres',
      'updateGenresOrder',
      'deleteGenre',
      'createGenre',
      'errorNotify',
      'successNotify',
      'updateGenre',
    ]),
    // getItemIndex(item) {
    //   return this.genres.findIndex(el => el.id === item.id);
    // },
    setUpdatingItemId(id) {
      this.updatingItemId = id;
    },
    setLoading() {
      this.isLoading = true;
    },
    unsetLoading() {
      this.setUpdatingItemId(null);
      this.isLoading = false;
    },
    backupGenres(genres) {
      this.genresBackup = cloneDeep(genres);
    },
    restoreGenresBackup() {
      this.genres = cloneDeep(this.genresBackup);
    },

    onDeleteItem({ id }) {
      if (!confirm('Are you sure? This action cannot be cancelled.')) {
        return;
      }

      if (this.newGenres[id]) {
        this.removeGenre(id);
        delete this.newGenres[id];
        return;
      }

      this.setLoading();

      this.deleteGenre({ id })
        .catch(error => {
          const errorReason = get(
            error,
            'originalError.response.data.fields["genre/id"][0].error'
          );
          if (errorReason === 'genre_used_in_games') {
            const games = get(
              error,
              'originalError.response.data.fields["genre/id"][0].context',
              []
            );
            if (
              !confirm(
                `This genre is used in the following games: ${games.join(
                  ', '
                )}. Are you sure you want to delete this genre?`
              )
            ) {
              throw { silent: true };
            }
            return this.deleteGenre({ id, force: true });
          } else {
            throw error;
          }
        })
        .then(() => this.successNotify('Genre removed!'))
        .catch(this.errorNotify)
        .finally(this.unsetLoading);
    },

    onAddItem() {
      const genre = DEFAULT_GENRE(this.genres.length);
      this.addGenre(genre);
      this.newGenres[genre.id] = genre;
    },
    getNotSavedGenresIds() {
      return Object.keys(this.newGenres);
    },
    debouncedOrdering: debounce(function() {
      this.updateGenresOrderAction();
    }, 1000),
    updateGenresOrderAction() {
      this.setLoading();
      const excludeIds = this.getNotSavedGenresIds();
      this.updateGenresOrder(excludeIds)
        .then(() => {
          this.successNotify('Genres order updated');
        })
        .then(() => {
          this.backupGenres(this.genres);
        })
        .catch(err => {
          this.restoreGenresBackup();
          this.errorNotify(err);
        })
        .finally(this.unsetLoading);
    },
    onSaveItem(item) {
      this.setUpdatingItemId(item.id);
      this.setLoading();
      if (this.newGenres[item.id]) {
        this.createGenre(item)
          .then(() => {
            delete this.newGenres[item.id];
            this.successNotify('Genre created');
          })
          .then(() => {
            this.turnOffEditMode(item);
            this.backupGenres(this.genres);
          })
          .then(this.updateGenresOrderAction)
          .catch(err => {
            this.restoreGenresBackup();
            this.errorNotify(err);
          })
          .finally(this.unsetLoading);
      } else {
        this.updateGenre(item)
          .then(() => {
            this.successNotify('Genre updated');
          })
          .then(() => {
            this.turnOffEditMode(item);
            this.backupGenres(this.genres);
          })
          .catch(err => {
            this.restoreGenresBackup();
            this.errorNotify(err);
          })
          .finally(this.unsetLoading);
      }
    },
    turnEditMode(item) {
      this.setGenre({ ...item, editMode: true });
    },
    turnOffEditMode(item) {
      this.setGenre({ ...item, editMode: false });
    },
  },
};
</script>
<style lang="scss">
.genre-list {
  &_col-headers {
    &--handler {
      min-width: 2.75rem;
    }
    &--icon {
      min-width: 4.25rem;
    }
    &--actions {
      min-width: 8.375rem;
    }
    div {
      min-height: 1.25rem;
      margin-bottom: 0.5rem;
    }
  }
}
</style>
