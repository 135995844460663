import { ifAuthorized } from '@/router/guards';
import OrganizationsWrapper from '@/views/admin/Organizations';
import OrganizationsList from '@/views/admin/Organizations/OrganizationsList';
import OrganizationEdit from '@/views/admin/Organizations/OrganizationEdit';

export default [
  {
    path: '/organisation',
    title: 'Organisation',
    component: OrganizationsWrapper,
    beforeEnter: ifAuthorized,
    children: [
      {
        path: '',
        name: 'organizationList',
        component: OrganizationsList,
      },
      {
        path: 'edit/:organizationID?',
        name: 'organizationEdit',
        component: OrganizationEdit,
      },
      {
        path: 'create',
        name: 'organizationCreate',
        component: OrganizationEdit,
      },
    ],
  },
];
