<!-- eslint-disable vue/camelcase -->
<template>
  <div class="container-fluid">
    <div class="row my-4 align-items-center">
      <h3 class="mb-0 pb-0 col">Customer orders</h3>
    </div>
    <div class="row">
      <div class="col">
        <div class="card mt-4">
          <div class="card-body">
            <form @submit.prevent="applyFilters">
              <div class="form-row">
                <div class="form-group col-md-3">
                  <p>Username</p>
                  <span class="displayed-text">{{
                    invoicesList[0].login
                  }}</span>
                </div>
              </div>
            </form>
            <div class="flush-div">
              <button
                :class="{ 'is-loading': isExportingAll }"
                class="btn btn-primary ml-auto mr-4"
                @click="exportAll"
              >
                Export to XLS
              </button>
            </div>
          </div>
        </div>

        <div class="card">
          <div
            class="card-body border-top border-muted pt-0 px-0 overflow-auto"
          >
            <table class="table table-hover mx-0 px-0 subscriptions-list">
              <caption v-if="isLoading" class="text-center text-muted">
                Fetching invoices...
              </caption>
              <caption
                v-else-if="!invoicesList.length"
                class="text-center text-muted"
              >
                No invoices to show
              </caption>
              <template v-else>
                <thead>
                  <tr>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Is Processed
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Invoice Id
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Magento Order Number
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      RefId
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Username
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Country
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Email
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Created
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Total without VAT
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Vat Amount
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Total Amount
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Coupon
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Coupon amount
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Currency
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Mode of payment
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Source
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Exp. Date
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Item description
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <ListItem
                    v-for="invoice in invoicesList"
                    :key="invoice.id"
                    v-bind="{ invoice }"
                    @download="downloadInvoice"
                  />
                </tbody>
              </template>
            </table>
          </div>
          <nav v-if="total > 0 && !isLoading" class="mt-3">
            <ul class="pagination justify-content-center">
              <li
                class="page-item"
                :class="{ disabled: isLoading || isFirstPage }"
              >
                <a class="page-link" href="#" @click.prevent="prevPage"
                  >Previous</a
                >
              </li>
              <li class="page-item">
                <span class="page-link"
                  >{{ currentPage }} / {{ totalPages }}</span
                >
              </li>
              <li
                class="page-item"
                :class="{ disabled: isLoading || isLastPage }"
              >
                <a
                  class="page-link"
                  href="#"
                  @click.prevent="goToPage(currentPage + 1)"
                  >Next</a
                >
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import ListItem from './SingleListItem';

export default {
  name: 'SingleOrder',
  components: { ListItem },
  data() {
    return {
      nextPageToken: undefined,
      prevPageToken: undefined,
      isLoading: false,
      isExporting: false,
      currentPage: 1,
      itemsPerPage: 20,
      invoicesList: [],
      total: 0,
      login: '',
      source: '',
      isExportingAll: false,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.total / this.itemsPerPage);
    },
    isFirstPage() {
      return this.currentPage === 1;
    },
    isLastPage() {
      return this.currentPage === this.totalPages;
    },
    sourceOptions() {
      const uniqueSources = [
        ...new Set(this.invoicesList.map(item => item.source)),
      ];
      return uniqueSources;
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions([
      'fetchInvoices',
      'exportInvoice',
      'exportAllInvoices',
      'errorNotify',
      'successNotify',
    ]),

    exportAll() {
      this.isExportingAll = true;
      const loginFromRoute = this.$route.params.login;
      const filterProps = {
        login: loginFromRoute,
        source: 'shop',
      };

      const filteredProps = Object.fromEntries(
        Object.entries(filterProps).filter(
          // eslint-disable-next-line no-unused-vars
          ([_, value]) => value !== '' && value !== null
        )
      );
      this.exportAllInvoices({ props: filteredProps })
        .catch(() => this.errorNotify('export failed'))
        .finally(() => (this.isExportingAll = false));
    },
    fetchData() {
      this.isLoading = true;
      const loginFromRoute = this.$route.params.login;
      const filterProps = {
        login: loginFromRoute,
        source: 'shop',
      };

      const filteredProps = Object.fromEntries(
        Object.entries(filterProps).filter(
          // eslint-disable-next-line no-unused-vars
          ([_, value]) => value !== '' && value !== null
        )
      );

      this.fetchInvoices({
        page: this.currentPage,
        pageToken: this.nextPageToken,
        prevPageToken: this.prevPageToken,

        pageSize: this.itemsPerPage,
        props: filteredProps,
      })
        .then(res => {
          this.total = res.total;
          this.nextPageToken = res.next_page_token;
          this.prevPageToken = res.prev_page_token;

          if (res.items) {
            this.invoicesList = res.items.map(el => ({
              ...el,
              editMode: false,
              key: `invoice_${el.id}`,
            }));
          } else {
            this.invoicesList = [];
          }
        })
        .catch(this.errorNotify)
        .finally(() => {
          this.isLoading = false;
        });
    },

    downloadInvoice({ id, invoiceId }) {
      this.isExporting = true;

      this.exportInvoice({
        id,
        invoiceId,
      })
        .then(() => {})
        .catch(this.errorNotify)
        .finally(() => {
          this.isExporting = false;
        });
    },

    generateSaudiTime(date, isEndDate) {
      if (!date) {
        return null;
      }

      const saudiTimeZone = 'Asia/Riyadh'; // Saudi Arabia's time zone
      const dateObject = new Date(date); // Convert the string to a Date object

      // If isEndDate is true, set the time to 23:59:59 to represent the end of the day
      if (isEndDate) {
        dateObject.setHours(23, 59, 59, 999);
      } else {
        dateObject.setHours(0, 0, 0, 0);
      }

      const saudiTimestamp = dateObject.toLocaleString('en-US', {
        timeZone: saudiTimeZone,
      });
      return new Date(saudiTimestamp).getTime(); // Get the timestamp in milliseconds
    },

    prevPage() {
      if (this.prevPageToken) {
        this.nextPageToken = this.prevPageToken;
        this.prevPageToken = null;
        this.currentPage--;
        this.fetchData();
      }
    },

    goToPage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.currentPage = page;
        this.fetchData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-item {
  cursor: not-allowed;
}

.page-link:hover {
  background-color: white !important;
}

.invoices-button {
  display: flex;
  justify-content: space-between;
}

.flush-div {
  display: flex;
  justify-content: flex-end;
  margin-top: -38px;
}

.flush-button {
  background-color: red;
  color: white;
}
.displayed-text {
  font-size: 12px;
  font-weight: 900;
}
</style>
