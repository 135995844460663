<template>
  <div class="layout-wrapper d-flex flex-column align-items-center h-100 pt-5">
    <section class="logo">
      <div class="container">
        <div class="row">
          <div class="col-auto mx-auto">
            <logo size="300" color="#354052" class="main-logo mb-5" />
          </div>
        </div>
      </div>
    </section>
    <main class="auth-layout">
      <slot />
    </main>
    <HeraFooter />
  </div>
</template>

<script>
import HeraFooter from '@/components/footer/HeraFooter';
import logo from '@/components/common/logo.vue';
export default {
  name: 'AuthLayout',
  components: {
    HeraFooter,
    logo,
  },
};
</script>

<style lang="scss">
.auth-layout {
  width: 100%;
  max-width: 560px;
  a {
    font-size: 0.75rem;
  }
}
</style>
