<template>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-auto mx-auto">
          <span class="copy">COPYRIGHT {{ year }} ALL RIGHTS RESERVED</span>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'HeraFooter',
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style lang="scss">
html {
  position: relative;
  min-height: 100%;
}
.layout-wrapper {
  position: relative;
  .footer {
    bottom: 0;
    width: 100%;
    height: 60px;
    line-height: 60px;
    background-color: transparent;
    span.copy {
      font-size: 11px;
      font-style: normal;
      font-weight: bold;
      line-height: normal;
      text-align: center;
      letter-spacing: 0.15em;
      text-transform: uppercase;
      color: #dce0e6;
    }
  }
}
</style>
