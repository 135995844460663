import Vue from 'vue';
import Router from 'vue-router';

import { ifAuthorized } from '@/router/guards';
import Home from '@/views/admin/Home.vue';

import ComplaintsRoutes from '@/router/routes/complaints';
import BadgesRoutes from '@/router/routes/badges';
// import RanksRoutes from '@/router/routes/ranks';
import GamesRoutes from '@/router/routes/games';
// import PlayersRoutes from '@/router/routes/players';
import OrganizationRouters from '@/router/routes/organization';
import TranslationsRoutes from '@/router/routes/translations';
import Genres from '@/router/routes/genres';
// import ConversationsRoutes from '@/router/routes/conversations';
import AuthRoutes from '@/router/routes/auth';
import PopularPlayers from '@/router/routes/popularPlayers';
import NewsfeedSettings from './routes/newsfeedSettings';
import Platforms from '@/router/routes/platforms';
import Autofollow from '@/router/routes/autofollow';
import TournamentRoutes from '@/router/routes/tournaments';
import SubscriptionsRoutes from '@/router/routes/subscriptions';
import CouponsRoutes from '@/router/routes/coupons';
import InvoicesRoutes from '@/router/routes/invoices';
import externalSubsRoutes from '@/router/routes/externalSubscriptions';
import CustomerRoutes from '@/router/routes/costumers';
import Charts from '@/router/routes/charts';
import OrdersRoutes from '@/router/routes/orders';
import PaidTournamentRoutes from '@/router/routes/paidTournaments';
import vouchersRouter from '@/router/routes/vouchers';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      beforeEnter: ifAuthorized,
    },
    ...ComplaintsRoutes,
    ...BadgesRoutes,
    // ...RanksRoutes,
    ...GamesRoutes,
    ...Genres,
    // ...PlayersRoutes,
    ...OrganizationRouters,
    ...AuthRoutes,
    ...TranslationsRoutes,
    // ...ConversationsRoutes,
    ...PopularPlayers,
    ...vouchersRouter,
    ...NewsfeedSettings,
    ...Platforms,
    ...Autofollow,
    ...TournamentRoutes,
    ...SubscriptionsRoutes,
    ...externalSubsRoutes,
    ...CouponsRoutes,
    ...InvoicesRoutes,
    ...CustomerRoutes,
    ...Charts,
    ...OrdersRoutes,
    ...PaidTournamentRoutes,
    {
      path: '/*',
      redirect: '/',
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
