<template>
  <div class="form-group" :class="{ 'text-right': dir === 'rtl' }">
    <label v-if="label && label.length" for="birthDate" :dir="dir">{{
      label
    }}</label
    ><br v-if="label && label.length" />
    <calendar
      v-model.trim="date"
      v-validate="'required|date_format:DD/MM/YYYY'"
      v-mask="'##/##/####'"
      type="datetime"
      :not-before="from"
      :not-after="to"
      class="w-100"
      :class="{ 'is-invalid': error }"
      aria-describedby="DateHelp"
      :format="'dd/MM/yyyy'"
      :width="'100%'"
      :placeholder="'dd/mm/yyyy'"
      :data-vv-as="label"
      name="birthDate"
    ></calendar>
    <span
      v-show="error"
      id="DateHelp"
      class="form-text text-danger"
      v-text="error"
    >
    </span>
  </div>
</template>

<script>
import calendar from './HeraFormCalendar';

export default {
  name: 'HeraDateInput',
  components: {
    calendar,
  },
  props: {
    error: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    dir: {
      type: String,
      default: 'ltr',
    },
    value: {
      type: String,
      default: null,
    },
    from: {
      type: null,
      default: () => new Date(),
    },
    to: {
      type: null,
      default: null,
    },
  },
  data() {
    return {
      date: this.value,
    };
  },
  watch: {
    date: function() {
      this.$emit('input', this.date);
      this.$emit('change', this.date);
    },
    value: function(val) {
      if (!this.date) this.date = val;
    },
  },
};
</script>

<style lang="scss"></style>
