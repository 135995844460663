import { render, staticRenderFns } from "./HeraFormSwitch.vue?vue&type=template&id=434c8172&scoped=true&"
import script from "./HeraFormSwitch.vue?vue&type=script&lang=js&"
export * from "./HeraFormSwitch.vue?vue&type=script&lang=js&"
import style0 from "./HeraFormSwitch.vue?vue&type=style&index=0&id=434c8172&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "434c8172",
  null
  
)

export default component.exports