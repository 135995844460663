<template>
  <div class="subscriptions-page-wrapper">
    <div class="container-fluid">
      <div class="row my-4 align-items-center">
        <h3 class="mb-0 pb-0 col">Coupons</h3>
        <button
          :class="{ 'is-loading': isExporting }"
          class="btn btn-primary ml-auto mr-4"
          @click="exportUsagesToXLS"
        >
          Export to XLS
        </button>
        <router-link
          class="btn btn-primary ml-auto mr-4"
          :to="{ name: 'couponCreate' }"
        >
          Add new coupon
        </router-link>
      </div>
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body pb-0 px-4 filter-wrapper">
              <div class="row mb-3">
                <div
                  v-for="filter in allCouponFilters"
                  :key="filter.name"
                  class="col-auto"
                >
                  <label class="d-block">{{ filter.label }}:</label>
                  <autosuggest
                    v-if="filter.name === 'country'"
                    id="country"
                    v-model="filters[filter.name]"
                    placeholder="Start typing..."
                    display-property="text"
                    :source="suggestCountriesRequest"
                    @resultPicked="onFilterChange"
                  />
                  <dropdown
                    v-else
                    v-model="filters[filter.name]"
                    :list="getFiltersOptions(filter)"
                    empty-text="All"
                    :empty-option="true"
                    :could-be-empty="true"
                    class="input-like w-auto"
                    :disabled="
                      !getFiltersOptions(filter).length &&
                        !filteredCoupons.length
                    "
                    @input="onFilterChange"
                  >
                    <template slot="chosen" slot-scope="chosen">
                      <span v-if="filter.name === 'teamSize'">
                        {{ chosen.chosenItem }}&nbsp;v&nbsp;{{
                          chosen.chosenItem
                        }}
                      </span>
                      <span v-else>{{ chosen.chosenItem }}</span>
                      <span class="sr-only">Choose option</span>
                    </template>
                    <template slot="item" slot-scope="options">
                      <span v-if="filter.name === 'teamSize'">
                        {{ options.item }}&nbsp;v&nbsp;{{ options.item }}
                      </span>
                      <span v-else>
                        {{ options.item }}
                      </span>
                    </template>
                  </dropdown>
                </div>
              </div>
              <div class="mb-3">
                <div class="filter-associate">
                  <label for="associated">Associate :</label>
                  <input
                    id="associated"
                    v-model="associated"
                    type="text"
                    class="form-control bg-transparent autosuggest_input pl-2"
                  />
                </div>
              </div>
            </div>
            <div class="card-body border-top border-muted pt-0 px-0">
              <infiniteScroll
                :list="filteredCoupons"
                list-name="list"
                :is-disabled="isLoading || nextPageToken === null"
                :activation-gap="100"
                @ScrollToBottom="fetchData"
              >
                <table
                  slot-scope="{ list }"
                  class="table table-hover mx-0 px-0 subscriptions-list"
                >
                  <caption
                    v-if="!list.length && isLoading"
                    class="text-center text-muted"
                  >
                    Fetching coupons...
                  </caption>
                  <caption
                    v-else-if="!list.length"
                    class="text-center text-muted"
                  >
                    No coupons to show
                  </caption>
                  <template v-else>
                    <thead>
                      <tr>
                        <th
                          class="border-top-0 border-bottom-0 px-2 section-description"
                        >
                          Active
                        </th>
                        <th
                          class="border-top-0 border-bottom-0 px-2 section-description"
                        >
                          Code
                        </th>
                        <th
                          class="border-top-0 border-bottom-0 px-2 section-description"
                        >
                          Discount
                        </th>
                        <th
                          class="border-top-0 border-bottom-0 px-2 section-description"
                        >
                          Expiration <br />date
                        </th>
                        <th
                          class="border-top-0 border-bottom-0 px-2 section-description"
                        >
                          Country
                        </th>

                        <th
                          class="border-top-0 border-bottom-0 px-2 section-description"
                        >
                          Associated <br />with
                        </th>
                        <th
                          class="border-top-0 border-bottom-0 px-2 section-description"
                        >
                          Used by
                        </th>
                        <th
                          class="border-top-0 border-bottom-0 px-2 section-description"
                        >
                          Usage
                        </th>
                        <th
                          class="border-top-0 border-bottom-0 px-2 section-description"
                        >
                          Redeemed :
                        </th>
                        <th
                          class="border-top-0 border-bottom-0 px-2 section-description"
                        >
                          Redeemed date :
                        </th>
                        <th
                          class="border-top-0 border-bottom-0 px-2 section-description"
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <ListItem
                        v-for="coupon in list"
                        :key="coupon.id"
                        v-bind="{ coupon }"
                        @deleteItem="onDeleteItem"
                      />
                    </tbody>
                  </template>
                </table>
              </infiniteScroll>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { uniqBy, cloneDeep } from 'lodash';
import { mapActions } from 'vuex';
import infiniteScroll from '@/components/common/infiniteScroll.vue';
import dropdown from '@/components/form/HeraFormDropdown.vue';
import autosuggest from '@/components/form/HeraAutosuggest';
import ListItem from './ListItem';

export default {
  name: 'CouponsList',
  components: {
    dropdown,
    autosuggest,
    infiniteScroll,
    ListItem,
  },
  data() {
    return {
      filters: {},
      nextPageToken: undefined,
      isLoading: false,
      isExporting: false,
      coupons: [],
      associated: '',
    };
  },
  computed: {
    filteredCoupons() {
      return this.coupons;
    },
    isFiltered() {
      const allFilters = Object.keys(this.filters);
      return (
        allFilters.length && allFilters.some(filter => this.filters[filter])
      );
    },
    allCouponFilters() {
      return [
        {
          name: 'country',
          label: 'Country',
          options: null,
        },
      ];
    },
  },
  watch: {
    associated(newValue, oldValue) {
      if (newValue !== oldValue) {
        setTimeout(() => {
          this.coupons = [];
          this.nextPageToken = undefined;
          this.fetchData();
        }, 500);
      }
    },
  },
  created() {
    this.initializeFilterValues();
    this.fetchData();
  },
  methods: {
    ...mapActions([
      'fetchCoupons',
      'suggestCountriesRequest',
      'errorNotify',
      'successNotify',
      'deleteCoupon',
      'exportCoupons',
    ]),
    fetchData() {
      if (this.nextPageToken === null) return;
      this.isLoading = true;
      this.fetchCoupons({
        pageToken: this.nextPageToken,
        country: this.countryFilter(),
        promoter: this.associated,
      })
        .then(res => {
          this.nextPageToken =
            this.nextPageToken === res.next_page_token
              ? null
              : res.next_page_token;

          const newPage = res.items.map(el => ({
            ...el,
            editMode: false,
            key: `coupon_${el.id}`,
          }));

          this.coupons = uniqBy([...this.coupons, ...newPage], 'id');
        })
        .catch(this.errorNotify)
        .finally(() => {
          this.isLoading = false;
        });
    },
    getFiltersOptions(filter) {
      return [...filter.options];
    },
    countryFilter() {
      return this.filters['country'] ? this.filters['country'].id : '';
    },
    onFilterChange() {
      this.coupons = [];
      this.nextPageToken = undefined;
      this.fetchData();
    },
    initializeFilterValues() {
      this.filters = this.allCouponFilters.reduce((obj, el) => {
        if (el.name == 'country') {
          obj[el.name] = {
            id: 'SA',
            text: 'Saudi Arabia',
            value: 'Saudi Arabia',
          };
        } else {
          obj[el.name] = null;
        }

        return obj;
      }, {});
    },
    onDeleteItem({ id }) {
      if (!confirm('Are you sure? This action cannot be cancelled.')) {
        return;
      }
      this.isLoading = true;

      this.deleteCoupon({ id })
        .then(() => {
          const index = this.coupons.findIndex(el => el.id === id);
          if (index > -1) {
            const coupons = cloneDeep(this.coupons);
            coupons.splice(index, 1);
            this.coupons = coupons;
          }
          this.successNotify('Coupon removed!');
        })
        .catch(this.errorNotify)
        .finally(() => (this.isLoading = false));
    },
    exportUsagesToXLS() {
      this.isExporting = true;
      this.exportCoupons({
        country: this.countryFilter(),
        promoter: this.associated,
      })
        .then(() => {})
        .catch(this.errorNotify)
        .finally(() => (this.isExporting = false));
    },
  },
};
</script>
<style lang="scss" scoped>
.filter-wrapper {
  display: flex;
  flex-direction: row;
  gap: 25px;
}
.filter-associate {
  display: flex;
  flex-direction: column;
  input {
    height: 32px;
  }
}
</style>
