<!-- eslint-disable vue/camelcase -->
<template>
  <div class="container-fluid">
    <div class="row my-4 align-items-center">
      <h3 class="mb-0 pb-0 col">External subscription audit trails</h3>
    </div>
    <div class="row">
      <div class="col">
        <div class="card mt-4">
          <div class="card-body">
            <form @submit.prevent="applyFilters">
              <div class="form-row">
                <div class="form-group col-md-3">
                  <label for="externalId">External id</label>
                  <input
                    id="externalId"
                    v-model="externalId"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="form-group col-md-3">
                  <label for="internalUserId">Playhera username</label>
                  <input
                    id="internalUserId"
                    v-model="internalUserId"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="form-group col-md-3">
                  <label for="merchantUserId">Merchant user id</label>
                  <input
                    id="merchantUserId"
                    v-model="merchantUserId"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="form-group col-md-3">
                  <label for="notes">Notes</label>
                  <input
                    id="notes"
                    v-model="notes"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="form-group col-md-3">
                  <label for="merchant">Merchant</label>
                  <input
                    id="merchant"
                    v-model="merchant"
                    type="text"
                    class="form-control"
                  />
                </div>
                <!--  -->
                <div class="form-group col-md-3">
                  <label for="action">Action</label>
                  <select id="action" v-model="action" class="form-control">
                    <option value="create">create</option>
                    <option value="update">update</option>
                    <option value="activate">activate</option>
                    <option value="cancel">cancel</option>
                  </select>
                </div>

                <div class="form-group col-md-3">
                  <label for="dateFrom">Date From</label>
                  <input
                    id="dateFrom"
                    v-model="dateFrom"
                    type="date"
                    class="form-control"
                  />
                </div>
                <div class="form-group col-md-3">
                  <label for="dateTo">Date To</label>
                  <input
                    id="dateTo"
                    v-model="dateTo"
                    type="date"
                    class="form-control"
                  />
                </div>
                <div class="form-group col-md-3">
                  <label for="CreatedAt">Creation date from</label>
                  <input
                    id="CreatedAt"
                    v-model="CreatedAt"
                    type="date"
                    class="form-control"
                  />
                </div>
                <div class="form-group col-md-3">
                  <label for="CreatedTo">Creation date to</label>
                  <input
                    id="CreatedTo"
                    v-model="CreatedTo"
                    type="date"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="invoices-button">
                <div :class="{ disabled: isLoading }">
                  <button
                    type="submit"
                    class="btn btn-primary mr-3"
                    :class="{ disabled: isLoading }"
                  >
                    Apply Filters
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    :class="{ disabled: isLoading }"
                    @click="clearFilters"
                  >
                    Clear Filters
                  </button>
                </div>
              </div>
            </form>
            <div class="flush-div">
              <button
                :class="{ 'is-loading': isExportingAll }"
                class="btn btn-primary ml-auto mr-4"
                @click="exportAll"
              >
                Export to XLS
              </button>
            </div>
          </div>
        </div>

        <div class="card">
          <div
            class="card-body border-top border-muted pt-0 px-0 overflow-auto"
          >
            <table class="table table-hover mx-0 px-0 subscriptions-list">
              <caption v-if="isLoading" class="text-center text-muted">
                Fetching external reports...
              </caption>
              <caption
                v-else-if="!reportList.length"
                class="text-center text-muted"
              >
                No reports to show
              </caption>
              <template v-else>
                <thead>
                  <tr>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      external id
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      subscription id
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      created on
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      PLAYHERA USERNAME
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description cur-pointer"
                    >
                      <div
                        class="d-flex justify-content-between align-items-center"
                      >
                        <!-- <img
                          v-if="sortedBy === 'action'"
                          class="arrow-sort"
                          :src="
                            sort === 'action:asc'
                              ? require('../../../../assets/images/icons/up-arrow.svg')
                              : require('../../../../assets/images/icons/down-arrow.svg')
                          "
                          alt="sort"
                        /> -->
                        <img
                          class="arrow-sort"
                          :class="
                            sort === 'action:asc' ? 'arrow-sort-active' : ''
                          "
                          :src="
                            require('../../../../assets/images/icons/up-arrow.svg')
                          "
                          alt="sorting"
                          @click="handleSortOpt('action', 'asc')"
                        />
                        <img
                          class="arrow-sort"
                          :class="
                            sort === 'action:desc' ? 'arrow-sort-active' : ''
                          "
                          :src="
                            require('../../../../assets/images/icons/down-arrow.svg')
                          "
                          alt="sorting"
                          @click="handleSortOpt('action', 'desc')"
                        />
                      </div>
                      action
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      ip adress
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      merchant
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      merchant user id
                    </th>
                    <th
                      class="border-top-0  border-bottom-0 px-2 section-description"
                    >
                      notes about subscription
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      activation code
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      FROM DATE&TIME
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      TO DATE&TIME
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <ListItemAudit
                    v-for="report in reportList"
                    :key="report.external_id"
                    v-bind="{ report }"
                  /></tbody
              ></template>
            </table>
          </div>
          <nav v-if="total > 0 && !isLoading" class="mt-3">
            <ul class="pagination justify-content-center">
              <li
                class="page-item"
                :class="{ disabled: isLoading || isFirstPage }"
              >
                <a class="page-link" href="#" @click.prevent="prevPage"
                  >Previous</a
                >
              </li>
              <li class="page-item">
                <span class="page-link"
                  >{{ currentPage + 1 }} / {{ totalPages }}</span
                >
              </li>
              <li
                class="page-item"
                :class="{ disabled: isLoading || isLastPage }"
              >
                <a
                  class="page-link"
                  href="#"
                  @click.prevent="goToPage(currentPage + 1)"
                  >Next</a
                >
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import ListItemAudit from './ListItemAudit.vue';

export default {
  name: 'AuditTrail',
  components: { ListItemAudit },
  data() {
    return {
      nextPageToken: undefined,
      prevPageToken: undefined,
      isExportingAll: false,
      total: 0,
      reportList: [],
      currentPage: 0,
      page: null,
      page_size: null,
      isLoading: false,
      externalId: '',
      internalUserId: '',
      merchantUserId: '',
      merchant: '',
      dateFrom: null,
      dateTo: null,
      notes: '',
      action: null,
      CreatedTo: null,
      CreatedAt: null,
      sort: null,
      sortedBy: null,
    };
  },
  computed: {
    needFetch() {
      return Boolean(
        this.externalId ||
          this.internalUserId ||
          this.merchantUserId ||
          this.merchant ||
          this.isActivated ||
          this.isExpired ||
          this.dateFrom ||
          this.dateTo ||
          this.action ||
          this.notes ||
          this.CreatedTo ||
          this.CreatedAt
      );
    },
    totalPages() {
      return Math.ceil(this.total / this.page_size);
    },
    isFirstPage() {
      return this.currentPage === 0;
    },
    isLastPage() {
      return this.currentPage === this.totalPages - 1;
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions([
      'fetchExtAudit',
      'fetchExtAudit',
      'errorNotify',
      'successNotify',
      'exportAllAuditReports',
    ]),

    handleSort(sort) {
      if (this.sort === `${sort}:asc`) {
        this.sort = null;
        this.sortedBy = null;
        this.fetchData();
        return;
      }
      if (this.sortedBy === sort) {
        this.sort = `${sort}:asc`;
        this.sortedBy = sort;
        this.fetchData();
        return;
      }
      this.sort = `${sort}:desc`;
      this.sortedBy = sort;
      this.fetchData();
    },

    handleSortOpt(sort, dir) {
      if (this.sort === `${sort}:${dir}`) {
        this.sort = null;
        this.sortedBy = null;
        this.fetchData();
        return;
      }
      // if (this.sortedBy === sort) {
      //   this.sort = `${sort}:${dir}`;
      //   this.sortedBy = sort;
      //   this.fetchData();
      //   return;
      // }
      this.sort = `${sort}:${dir}`;
      this.sortedBy = sort;
      this.fetchData();
    },

    exportAll() {
      this.isExportingAll = true;
      const filterProps = {
        merchant: this.merchant,
        from_date_time_start: new Date(this.dateFrom).getTime() / 1000,
        to_date_time_end: new Date(this.dateTo).getTime() / 1000,
        merchant_user_id: this.merchantUserId,
        ph_user_id: this.internalUserId,
        notes: this.notes,
        action: this.action,
        external_id: this.externalId,
        created_on_start: new Date(this.CreatedAt).getTime() / 1000,
        created_on_end: new Date(this.CreatedTo).getTime() / 1000,
        orderBy: this.sort,
        page_size: this.total,
      };

      const filteredProps = Object.fromEntries(
        Object.entries(filterProps).filter(
          // eslint-disable-next-line no-unused-vars
          ([_, value]) => value !== '' && value !== null && value !== 0
        )
      );
      this.exportAllAuditReports({ props: filteredProps })
        .catch(() => this.errorNotify('export failed'))
        .finally(() => (this.isExportingAll = false));
    },
    async fetchData() {
      this.isLoading = true;

      const filterProps = {
        merchant: this.merchant,
        from_date_time_start: new Date(this.dateFrom).getTime() / 1000,
        to_date_time_end: new Date(this.dateTo).getTime() / 1000,
        merchant_user_id: this.merchantUserId,
        ph_user_id: this.internalUserId,
        notes: this.notes,
        action: this.action,
        external_id: this.externalId,
        created_on_start: new Date(this.CreatedAt).getTime() / 1000,
        created_on_end: new Date(this.CreatedTo).getTime() / 1000,
        orderBy: this.sort,
      };

      const filteredProps = Object.fromEntries(
        Object.entries(filterProps).filter(
          // eslint-disable-next-line no-unused-vars
          ([_, value]) => value !== '' && value !== null && value !== 0
        )
      );

      this.fetchExtAudit({
        pageToken: this.currentPage,
        props: filteredProps,
      })
        .then(res => {
          this.total = res.total;
          this.page_size = res.page_size;

          if (res.items) {
            this.reportList = res.items;
            this.isLoading = false;
          }
        })
        .catch(this.errorNotify)
        .finally(() => {
          this.isLoading = false;
        });
    },

    applyFilters() {
      if (this.needFetch) {
        this.isLoading = true;
        this.currentPage = 0;
        this.fetchData();
      }
    },

    flush() {
      this.setFlushCache();
    },

    clearFilters() {
      if (this.needFetch) {
        (this.externalId = ''),
          (this.internalUserId = ''),
          (this.merchantUserId = ''),
          (this.merchant = ''),
          (this.isActivated = null),
          (this.isExpired = null),
          (this.dateFrom = null),
          (this.dateTo = null),
          (this.action = null);
        this.notes = '';
        (this.CreatedTo = null), (this.CreatedAt = null), this.fetchData();
      }
    },

    downloadInvoice({ id, invoiceId }) {
      this.isExporting = true;

      this.exportInvoice({
        id,
        invoiceId,
      })
        .then(() => {})
        .catch(this.errorNotify)
        .finally(() => {
          this.isExporting = false;
        });
    },

    generateSaudiTime(date, isEndDate) {
      if (!date) {
        return null;
      }

      const saudiTimeZone = 'Asia/Riyadh'; // Saudi Arabia's time zone
      const dateObject = new Date(date); // Convert the string to a Date object

      if (isEndDate) {
        dateObject.setHours(23, 59, 59, 999);
      } else {
        dateObject.setHours(0, 0, 0, 0);
      }

      const saudiTimestamp = dateObject.toLocaleString('en-US', {
        timeZone: saudiTimeZone,
      });
      return new Date(saudiTimestamp).getTime(); // Get the timestamp in milliseconds
    },

    prevPage() {
      if (this.currentPage > 0) {
        this.currentPage--;
        this.fetchData();
      }
    },

    goToPage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.currentPage = page;
        this.fetchData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-item {
  cursor: not-allowed;
}

.page-link:hover {
  background-color: white !important;
}

.invoices-button {
  display: flex;
  justify-content: space-between;
}
.cur-pointer {
  cursor: pointer;
}
.arrow-sort {
  width: 10px;
  height: 10px;
  // margin-left: -40px;
}

.flush-div {
  display: flex;
  justify-content: flex-end;
  margin-top: -38px;
}
.flush-button {
  background-color: red;
  color: white;
}

.arrow-sort-active {
  background-color: rgba(128, 128, 128, 0.322);
  width: 13px;
  height: 13px;
  padding: 2px;
}
</style>
