import api from '@/api';

export const DEFAULT_POINTS_STATE = () => ({});

const getters = {};

const mutations = {};

const actions = {
  getPoints({ dispatch, getters }) {
    return api
      .getPoints(getters.accessToken)
      .then(res => res.data)
      .catch(err => dispatch('errorNotify', err));
  },
  setPoints({ dispatch, getters }, data) {
    return api
      .setPoints(getters.accessToken, data)
      .then(res => res)
      .catch(err => dispatch('errorNotify', err));
  },
};

export default {
  state: DEFAULT_POINTS_STATE(),
  getters,
  mutations,
  actions,
};
