<template>
  <div
    class="form-group autosuggest"
    :class="{ 'text-right rtl': dir === 'rtl', 'text-left': dir !== 'rtl' }"
  >
    <template v-if="label && label.length">
      <label
        class="autosuggest_label"
        :for="inputID"
        :dir="dir"
        :class="[
          dir === 'rtl' ? 'pr-2' : 'pl-2',
          { 'label-required-field': required },
        ]"
        >{{ label }}</label
      >
      <br />
    </template>
    <div class="autosuggest_input-wrapper" :class="{ loading: loading }">
      <icon
        v-if="!loading && (searchText || (showResults && dropdownMode))"
        name="close"
        fill="black"
        class="autosuggest_clear-icon"
        width="10"
        @click="clearSearchText"
      />
      <input
        :id="inputID"
        v-model="searchText"
        :dir="dir"
        type="text"
        class="form-control bg-transparent autosuggest_input pl-2"
        :class="{ 'is-invalid': error }"
        :disabled="disabled"
        :placeholder="placeholder"
        aria-describedby="autosuggest_help"
        autocomplete="off"
        @focus="e => toggleResultsDropdown(e, true)"
        @blur="e => toggleResultsDropdown(e, false)"
        @keydown.up="onUp"
        @keydown.down="onDown"
        @keydown.enter="onEnter"
        @keydown.esc="onEsc"
      />
      <div
        v-if="showResults"
        class="autosuggest_results-wrapper border rounded"
      >
        <ul class="autosuggest_results m-0 p-0" :dir="dir">
          <li
            v-if="resultsList.length === 0"
            class="autosuggest_results-item p-2 autosuggest_results-no-results"
          >
            No results
          </li>
          <li
            v-for="(item, index) in resultsList"
            :key="index"
            class="autosuggest_results-item p-2"
            :class="{
              'text-right': dir === 'rtl',
              'text-left': dir !== 'rtl',
              active: index === selectedItemIndex,
            }"
            @click.prevent="pickItem(index)"
          >
            <slot name="result-item" v-bind="{ item }">{{
              item[displayProperty]
            }}</slot>
          </li>
        </ul>
      </div>
    </div>
    <div
      v-if="multiple"
      class="autosuggest_badge-wrapper d-flex flex-wrap w-100 mt-2"
    >
      <span
        v-for="(tag, index) in pickedItems"
        :key="tag[displayProperty]"
        class="badge badge-primary px-2 py-1 mr-2 mb-1"
        >{{ tag[displayProperty] }}&nbsp;<icon
          name="close"
          class="badge-delete-icon ml-1"
          fill="white"
          width="10"
          @click="removeTag(index)"
      /></span>
    </div>
    <span
      v-show="error"
      id="autosuggest_help"
      class="form-text error-text text-danger"
      v-text="error"
    >
    </span>
  </div>
</template>

<script>
import { fromEvent } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import icon from '../common/Icon';
export default {
  name: 'AutosuggestInput',
  components: {
    icon,
  },
  model: {
    prop: 'value',
    event: 'resultPicked',
  },
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    dir: {
      type: String,
      default: 'ltr',
    },
    id: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    displayProperty: {
      type: String,
      default: '',
    },
    source: {
      type: null,
      default: null,
    },
    list: {
      type: null,
      default: null,
    },
    value: {
      type: null,
      default: null,
    },
    dropdownMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      keepResultsOpen: false,
      showResults: false,
      searchText: null,
      pickedItems: null,
      selectedItemIndex: -1,
      resultsList: [],
      inputObserver: null,
      inputElement: null,
      enabled: true,
      loading: false,
      tags: [],
    };
  },
  computed: {
    inputID() {
      return this.id ? this.id : 'autosuggest_input' + this.getRandomInt(999);
    },
  },
  watch: {
    searchText(newVal) {
      if (!newVal || !newVal.length) {
        this.clearSearch();
      }
    },
    value(newVal, oldVal) {
      const isEmpty = Array.isArray(oldVal) ? !oldVal.length : !oldVal;
      const isPickedItemsEmpty = Array.isArray(this.pickedItems)
        ? !this.pickedItems.length
        : !this.pickedItems;
      if (isEmpty && newVal && isPickedItemsEmpty) {
        this.initialize();
      }
      if (!newVal) {
        this.clearSearchText();
        this.pickedItems = null;
      }
    },
  },
  created() {
    // window.$vm0 = this
    // console.log(this.value.id)
    if (this.value) {
      this.pickedItems = this.value;
      if (this.multiple && Array.isArray(this.value)) {
        this.clearSearch();
        // this.tags = this.tags.concat(this.value.map(el => el[this.displayProperty]))
      } else {
        this.searchText = this.pickedItems[this.displayProperty];
      }
    }
  },
  mounted() {
    // console.log(this.value.id)
    this.$nextTick(function() {
      if (!this.inputElement) {
        this.inputElement = this.$el.querySelector('.autosuggest_input');
      }
      if (!this.inputObserver && this.inputElement) {
        this.inputObserver = fromEvent(this.inputElement, 'input')
          .pipe(
            debounceTime(700),
            map(event => event.target.value)
          )
          .subscribe(value => {
            if (this.list && !this.source) this.searchInList(value);
            else this.sendRequest(value);
          });
      }
    });
  },
  methods: {
    toggleResultsDropdown(event, isVisible) {
      if (this.dropdownMode && !this.searchText && !this.keepResultsOpen) {
        this.showResults =
          isVisible !== undefined ? isVisible : !this.showResults;
        this.keepResultsOpen = true;
      }
      if (this.showResults && !this.searchText) {
        if (this.list && !this.source) this.searchInList('');
        else this.sendRequest('');
      }
    },
    getRandomInt(max) {
      return Math.floor(Math.random() * Math.floor(max));
    },
    removeTag(index) {
      this.pickedItems.splice(index, 1);
      this.$emit('resultPicked', this.pickedItems);
    },
    pickItem(index) {
      index =
        typeof index === 'number' && index > -1
          ? index
          : this.selectedItemIndex;
      // console.log(index, this.resultsList[index])
      if (this.resultsList && this.resultsList[index]) {
        this.enabled = false;
        this.selectedItemIndex = index;
        if (this.multiple) {
          let { pickedItems } = this;

          if (!pickedItems) {
            pickedItems = [];
          }

          if (
            !pickedItems.some(
              item => item.id === this.resultsList[this.selectedItemIndex].id
            )
          ) {
            this.pickedItems = [
              ...pickedItems,
              this.resultsList[this.selectedItemIndex],
            ];
          }

          // this.tags = this.tags.concat([this.resultsList[this.selectedItemIndex]])
          this.clearSearchText();
        } else {
          this.pickedItems = this.resultsList[this.selectedItemIndex];
          this.searchText = this.pickedItems[this.displayProperty];
          this.closeResults();
        }
        this.$emit('resultPicked', this.pickedItems);
        // this.clearSearchText()
      }
    },
    initialize() {
      this.pickedItems = this.value;
      this.searchText = this.pickedItems[this.displayProperty];
    },
    clearSearch() {
      if (!this.multiple) {
        this.pickedItems = null;
        if (this.value) {
          this.$emit('resultPicked', null);
        }
      }
    },
    clearSearchText() {
      this.searchText = null;
      this.closeResults();
    },
    closeResults() {
      this.showResults = false;
      this.keepResultsOpen = false;
    },
    onUp() {
      if (this.resultsList.length > 0) {
        this.selectedItemIndex =
          this.selectedItemIndex < 1
            ? this.resultsList.length - 1
            : this.selectedItemIndex - 1;
      }
    },
    onDown() {
      if (this.resultsList.length > 0) {
        this.selectedItemIndex =
          this.selectedItemIndex < 0 ||
          this.selectedItemIndex === this.resultsList.length - 1
            ? 0
            : this.selectedItemIndex + 1;
      }
    },
    onEnter() {
      if (this.resultsList.length && this.selectedItemIndex > -1) {
        this.pickItem();
      }
    },
    onEsc() {
      this.closeResults();
    },
    searchInList(value) {
      let filteredResult = [];
      this.list.forEach(function(item) {
        if (item.toLowerCase().startsWith(value.toLowerCase())) {
          filteredResult.push({ id: item });
        }
      });
      this.resultsList = filteredResult;
      this.showResults = true;
    },
    sendRequest(value) {
      if (
        !this.enabled &&
        (!this.pickedItems ||
          (this.pickedItems &&
            this.pickedItems[this.displayProperty] !== value))
      )
        this.enabled = true;

      if (
        (this.searchText && this.searchText.length > 0) ||
        this.dropdownMode
      ) {
        if (this.enabled && !this.loading) {
          this.loading = true;
          this.enabled = false;
          const showAll =
            this.dropdownMode && (!this.searchText || !this.searchText.length);
          Promise.resolve(this.source)
            .then(apiCall => {
              if (typeof apiCall === 'function') {
                return apiCall(this.searchText, showAll);
              } else {
                throw new Error('Wrong API call prop type');
              }
            })
            .then(response => {
              this.resultsList = response;
              this.showResults = true;
            })
            // .catch(error => {
            //   console.log(error);
            // })
            .then(() => {
              this.selectedItemIndex = -1;
              this.loading = false;
              this.enabled = true;
            });
        }
      } else {
        this.showResults = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.autosuggest {
  // border: none;
  // outline: none;
  // box-shadow: none;
  position: relative;
  width: 100%;
  max-width: 304px;
  &:focus-within {
    .autosuggest_label {
      // color: #6D62FC
    }
  }
  .autosuggest_label {
    line-height: normal;
    font-size: 12px;
    // color: #898B9B;
  }

  .autosuggest_input-wrapper {
    position: relative;
    &.loading {
      &:after {
        -webkit-animation: spinAround 0.5s infinite linear;
        animation: spinAround 0.5s infinite linear;
        border: 1px solid black;
        border-radius: 290486px;
        border-right-color: transparent;
        border-top-color: transparent;
        content: '';
        display: block;
        height: 0.675rem;
        width: 0.675rem;
        position: absolute;
        right: 0.5rem;
        top: calc(50% - (0.675rem / 2));
      }
    }
  }

  .autosuggest_clear-icon {
    position: absolute;
    top: calc(50% - 5px);
    right: 0.5rem;
    cursor: pointer;
  }

  .autosuggest_input {
    // border: none!important;
    font-size: 0.75rem;
    font-weight: bold;
    // border-bottom: 1px solid #898B9B!important;
    // outline: none !important;
    // border-radius: 0;
    &:focus,
    &:active {
      // border-bottom-color: #6D62FC!important;
      // box-shadow: none;
    }
  }
  .autosuggest_results-wrapper {
    z-index: 999;
    width: 100%;
    height: auto;
    max-height: 224px;
    position: absolute;
    top: 2.25rem;
    border-radius: 0;
    // border-bottom-left-radius: 10px;
    // border-bottom-right-radius: 10px;
    overflow: hidden;
  }
  .autosuggest_results {
    //background: #E9E9E9;
    background: white;
    height: auto;
    max-height: 224px;
    width: 100%;
    overflow-y: auto;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.5);
    .autosuggest_results-item {
      line-height: 1.375rem;
      // font-size: 1rem;
      letter-spacing: -0.025em;
      color: #323240;
      &.active,
      &:hover {
        background-color: rgba(57, 57, 72, 0.1);
        cursor: pointer;
      }
      &.autosuggest_results-no-results {
        pointer-events: none;
        cursor: initial;
      }
    }
  }
  .autosuggest_badge-wrapper {
    .badge {
      .badge-delete-icon {
        cursor: pointer;
      }
    }
  }
  .autosuggest_help {
    font-size: 0.875rem;
  }
  &.rtl {
    .autosuggest_clear-icon {
      left: 0.5rem;
      right: unset;
    }
    .autosuggest_input-wrapper {
      &.loading {
        &:after {
          left: 0.5rem;
          right: unset;
        }
      }
    }
  }
}
@-webkit-keyframes spinAround {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spinAround {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
</style>
