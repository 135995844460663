<template>
  <div>
    <div
      class="card card-complaints py-3 px-4"
      :class="{ 'card--resolved': resolved }"
    >
      <div class="card-body py-0">
        <div class="row align-items-center">
          <div class="col-1">{{ order }}</div>
          <div class="col-11">
            <div class="row align-items-center">
              <div class="col-2">
                <Avatar :img-url="reporter.avatar" :size="32" class="mr-2" />
                <!-- TODO: convert to router-link -->
                <a
                  :href="`/conversations-redirect/${reporter.name}`"
                  @click.prevent="openPopoverChat($event, reporter.name)"
                >
                  {{ reporter.name }}&nbsp;
                  <AppIcon name="chat" class="ml-2 fill-primary" width="15" />
                </a>
                <!--  -->
              </div>
              <div class="col-1">{{ params.entity_type }}</div>
              <div class="col-3">{{ reportDate }}</div>
              <div class="col-3">{{ reasons[params.reason_id] }}</div>
              <div
                v-if="resolved"
                class="col-2 complaints-status"
                :class="params.status"
              >
                {{ params.status }}
              </div>
              <div v-else class="col-2">
                <dropdown
                  v-model="actions.chosen"
                  class="input-like primary"
                  :list="actions.options"
                  empty-text="Take action"
                  empty-option
                  @change="takeComplaintAction"
                >
                  <template slot="chosen" slot-scope="chosen">
                    <span>{{ chosen.chosenItem.label }}</span>
                    <span class="sr-only">Choose option</span>
                  </template>
                  <template slot="item" slot-scope="options">
                    <span>{{ options.item.label }}</span>
                  </template>
                </dropdown>
              </div>
              <div class="col-1">
                <a
                  href="#"
                  class="link--expand-details"
                  :class="{ expanded: expanded }"
                  @click.prevent="toggleDetails"
                >
                  View
                  <AppIcon
                    name="dropdown-chevron"
                    fill="${$primary}"
                    original
                    class="ml-2"
                    width="12"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div v-show="expanded" class="row mt-4">
          <div
            v-if="params.text"
            class="card complaints-reporter-comment col-12 w-100 mb-2 border-0"
          >
            <div class="card-body py-2">
              <strong>Reporter comment:</strong> {{ params.text }}
            </div>
          </div>
          <div class="card complaints-details col w-100">
            <div class="card-body py-2">
              <div class="row no-gutters align-items-center">
                <div class="col-auto mr-2">
                  <Avatar :img-url="author.logo" :size="32" />
                </div>
                <!-- TODO: convert to router-link -->
                <a
                  :href="`/conversations-redirect/${author.name}`"
                  class="clan-owner"
                  :title="`Start chat with ${author.name}`"
                  @click.prevent="openPopoverChat($event, author.name)"
                >
                  {{ author.name }}
                  <AppIcon name="chat" class="ml-2 fill-primary" width="15" />
                </a>
                <!--  -->
              </div>
              <div v-show="authorText" class="row mt-2">
                <!-- eslint-disable vue/no-v-html -->
                <div class="col complaints-body" v-html="authorText"></div>
              </div>

              <div
                v-show="author.attachments && author.attachments.length"
                class="row no-gutters py-3"
              >
                <div
                  v-for="(attachment, index) in author.attachments"
                  :key="index"
                  class="col-auto mr-3"
                >
                  <a :href="attachment[attachment.type]" target="_blank">
                    <AppIcon
                      name="attachment"
                      original
                      class="mr-1"
                      width="17"
                    />
                    {{ attachmentLabel(groupName, attachment.type) }}</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dropdown from '@/components/form/HeraFormDropdown.vue';
import AppIcon from '@/components/common/Icon';
import Avatar from '@/components/common/avatar.vue';
import filters from './filters.js';
import { mapActions, mapMutations } from 'vuex';
import { WEB_BASE_URL } from '@/api/config';

export default {
  name: 'ComplaintsCard',
  components: {
    dropdown,
    AppIcon,
    Avatar,
  },
  filters: {
    formatDate(value) {
      if (value) {
        return value.toLocaleString();
      }
    },
  },
  props: {
    params: {
      type: Object,
      required: true,
    },
    order: {
      type: Number,
      required: true,
    },
    groupName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      counters: {},
      expanded: false,
      actions: {
        chosen: null,
        options: [],
        // ['Delete user', 'Delete post', 'Ignore']
      },
    };
  },
  computed: {
    isUserEntity() {
      return this.params.entity_type === 'profile';
    },
    reportDate() {
      return new Date(this.params.report_date * 1000).toLocaleString();
    },
    reasons() {
      return filters.reasons.options; //this.getComplaintsReasons()
    },
    resolved() {
      return this.params.status
        ? this.params.status.toLowerCase() !== 'pending'
        : false;
    },
    author() {
      return this.params.extras && this.params.extras.author
        ? {
            logo:
              this.params.extras.author.logo ||
              this.params.extras.author.avatar,
            name:
              this.params.extras.author.name || this.params.extras.author.login,
            text: this.params.extras.text,
            attachments: this.params.extras.attachments,
          }
        : {
            logo: this.params.extras.avatar,
            name: this.params.extras.login,
            text: '',
            attachments: [],
          };
    },
    reporter() {
      return {
        name: this.params.reporter.login || this.params.reporter.name,
      };
    },
    authorText() {
      const profilePattern = /<a\s*href=['"]profile:\/\/([\w \d]*)['"]>([@][\w \d]*)<\/a>/g;
      const hashtagPattern = /<a\s*href=['"]hashtag:\/\/[\w \d]*['"]>([#][\w \d]*)<\/a>/g;

      return this.author.text
        ? this.author.text
            .replace(
              profilePattern,
              `<a href="${WEB_BASE_URL}profiles/$1/profile">$2</a>`
            )
            .replace(hashtagPattern, '$1') // TODO add link to hashtag after implement newsfeed search
        : '';
    },
  },
  created() {
    this.$set(this.actions, 'options', [
      {
        label: `${this.isUserEntity ? 'Block' : 'Delete'} ${
          filters.types.options[this.params.entity_type]
        }`,
        action: this.deleteComplaintEntity,
      },
      {
        label: 'Ignore',
        action: this.deleteComplaintItem,
      },
    ]);
  },
  methods: {
    ...mapMutations(['setActivePopoverChat']),
    ...mapActions([
      'getConversationInfo',
      'deleteContent',
      'deleteComplaint',
      'errorNotify',
      'successNotify',
    ]),
    openPopoverChat(event, login) {
      if (!event.ctrlKey) {
        this.getConversationInfo(login)
          .then(res => {
            this.setActivePopoverChat(res.id);
          })
          .catch(this.errorNotify);
      }
    },
    attachmentLabel(name, type) {
      if (!this.counters[name]) {
        this.counters[name] = {};
      }
      let counter = this.counters[name][type] || 1;
      let label = type + counter;
      counter += 1;
      this.counters[name][type] = counter;
      return label;
    },
    toggleDetails() {
      this.expanded = !this.expanded;
    },
    takeComplaintAction() {
      this.$nextTick(() => {
        this.actions.chosen && this.actions.chosen.action();
      });
    },
    deleteComplaintEntity() {
      const userConfirm = confirm(
        `Are you sure, you want to ${
          this.isUserEntity ? 'block' : 'delete'
        } this ${this.params.entity_type}?`
      );
      if (!userConfirm) {
        this.actions.chosen = null;
        return false;
      }
      this.deleteContent({
        type: this.params.entity_type,
        id: this.params.entity_id,
      })
        .then(() => {
          this.successNotify(
            `${this.params.entity_type} was ${
              this.isUserEntity ? 'blocked' : 'deleted'
            }`
          );
          this.$emit('cardResolved', 'Approved');
        })
        .catch(this.errorNotify)
        .then(() => {
          this.actions.chosen = null;
        });
    },
    deleteComplaintItem() {
      this.deleteComplaint(this.params.id)
        .then(() => {
          this.successNotify(
            `Complaint was ${this.isUserEntity ? 'blocked' : 'deleted'}`
          );
          this.$emit('cardResolved', 'Ignored');
        })
        .catch(this.errorNotify)
        .then(() => {
          this.actions.chosen = null;
        });
    },
  },
};
</script>

<style lang="scss">
.card-complaints {
  font-size: 0.75rem;
  font-weight: bold;
  border-color: $hera-strokes-color;
  border-left: 2px solid $danger;

  &.card--resolved {
    border-left: 1px solid $hera-strokes-color;
  }

  .complaints_datetime {
    text-transform: uppercase;
    letter-spacing: 0.09375rem;
    color: $hera-secondary-text-color;
    font-size: 0.625rem;
  }

  .complaints-details {
    font-size: 0.75rem;
    background: #ebedf8;
    border: none;

    .clan-owner {
      cursor: pointer;
      color: $primary;
    }
  }
  .complaints-reporter-comment {
    font-weight: normal;
    background: #ffd;
  }
  .complaints-status {
    &.approved {
      color: $hera-error-color;
    }

    &.ignored {
      color: $hera-secondary-text-color;
    }
  }

  .complaints-body {
    font-size: 0.75rem;
    font-weight: bold;
  }

  .complaints-attachments {
    font-size: 0.75rem;
  }
}

.link--expand-details {
  & svg {
    transition: all 0.2s;
  }

  &.expanded svg {
    transform: rotate(-180deg);
  }
}

.dropdown-toggle {
  background-color: $primary;
}
</style>
