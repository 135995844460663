import Vue from 'vue';
import tokens from './authTokens.js';
import api from '@/api';
import { isTokenValid } from '@/utils';

export const INITIAL_USER_STATE = () => ({
  avatar: {},
  details: {},
});

const modules = {
  avatar: {},
  details: {},
  tokens,
};

const getters = {
  username: state => state.details.name || state.details.login,
  avatar: state => state.avatar.original,
};

const mutations = {
  setLogin(state, payload) {
    state.details.login = payload;
  },
  setUserDetails(state, payload) {
    state.details = { ...state.details, ...payload };
  },
  clearToUserDefaultState(state) {
    const userState = INITIAL_USER_STATE();

    Object.keys(userState).forEach(key => {
      Vue.set(state, key, userState[key]);
    });
  },
  setAvatar(state, payload) {
    state.avatar = { ...state.avatar, ...{ original: payload } };
  },
};

const actions = {
  getMyDetails: ({ getters, commit }) => {
    return new Promise((resolve, reject) => {
      api
        .getMyProfile(getters.accessToken)
        .then(response => {
          const details = { ...{}, ...response.data };
          let avatar = details.avatar;
          // console.log(games, cover, avatar)
          delete details.avatar;
          // console.log(games, cover, avatar)
          commit('setUserDetails', details);
          commit('setAvatar', avatar);
          resolve();
        })
        .catch(error => reject(error));
    });
  },
  setDefaultState: ({ commit, rootState }) => {
    if (rootState.user) {
      commit('clearToUserDefaultState');
    }
    if (rootState.clans) {
      commit('clearToClansDefaultState');
    }
    if (rootState.translations) {
      commit('clearToTranslationsDefaultState');
    }

    if (rootState.status) {
      commit('clearToFlagsDefaultState');
    }
    if (rootState.settings) {
      commit('clearToSettingsDefaultState');
    }
    if (rootState.preregistration) {
      commit('clearToRegistrationsDefaultState');
    }
    if (rootState.notifications) {
      commit('clearToNotificationsDefaultState');
    }
    if (rootState.conversations) {
      commit('clearToConversationsDefaultState');
    }
    if (rootState.tournaments) {
      commit('clearToTournamentsDefaultState');
    }
    if (rootState.battles) {
      commit('clearToBattlesDefaultState');
    }

    commit('setRefreshProcessing', false);
    commit('changeRefreshStopover');
  },
  logout: ({ getters, dispatch }) => {
    return new Promise(resolve => {
      dispatch('cancelAllRequests');
      dispatch('setDefaultState');
      if (isTokenValid(getters.tokenObject('accessToken'))) {
        api.signOut(getters.accessToken);
      }
      dispatch('clearAllTokens');
      resolve();
    });
  },
  getMyProfileAssociations(
    {
      getters: { accessToken },
    },
    params = {}
  ) {
    return api
      .getMyProfileAssociations(accessToken, { params })
      .then(({ data }) => data);
  },
  switchAccount(
    {
      getters: { accessToken },
      commit,
    },
    { accountId, accountType }
  ) {
    return api
      .switchAccount(accessToken, { id: accountId, type: accountType })
      .then(({ data }) => {
        commit('setToken', {
          type: 'accessToken',
          token: data.access_token,
          expires: data.access_token_expires,
        });
        commit('setToken', {
          type: 'refreshToken',
          token: data.refresh_token,
          expires: data.refresh_token_expires,
        });
        commit('setIsAdmin', data.permissions);
        return data;
      });
  },
};

export default {
  modules,
  state: INITIAL_USER_STATE(),
  getters,
  mutations,
  actions,
};
