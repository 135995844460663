<template>
  <div class="page-wrapper subscription-edit">
    <div class="container">
      <div class="my-4">
        <h3 class="mb-0 pb-0">
          {{ couponID ? 'Edit' : 'Create' }}
          {{
            $router.currentRoute.name.includes('coupon')
              ? 'Coupon'
              : 'Promocode'
          }}
        </h3>
      </div>
      <div class="card-body px-0 d-flex flex-wrap">
        <div class="col-6 col-lg-4">
          <TextInput
            v-if="$router.currentRoute.name.includes('promo')"
            id="coupon-name"
            v-model="coupon.code"
            v-validate="'required'"
            required
            class="w-100"
            :class="{ 'is-invalid': errors.first('coupon-name') }"
            label="Code:"
            name="coupon-name"
            data-vv-as="Name"
            data-vv-name="coupon-name"
            :error="errors.first('coupon-name')"
            :disabled="disableFields"
          />
          <AutoSuggest
            id="country"
            v-model="coupon.country"
            v-validate="'required'"
            class="mw-100"
            required
            label="Country:"
            name="country"
            placeholder="Start typing..."
            display-property="text"
            data-vv-name="country"
            :source="suggestCountriesRequest"
            :error="errors.first('country')"
            :disabled="disableFields"
          />
          <label for="coupon-usageType">Usage type:</label>

          <Dropdown
            v-model="coupon.UsageType"
            v-validate="'required'"
            required
            class="input-like w-100"
            empty-option
            name="coupon-usageType"
            :class="{ 'is-invalid': errors.first('type') }"
            data-vv-name="UsageType"
            :list="UsageTypesList"
          />
          <TextInput
            v-if="$router.currentRoute.name.includes('coupon')"
            id="coupon-quantity"
            v-model="coupon.quantity"
            input-type="number"
            class="coupon-edit__date w-100 mt-3"
            :class="{ 'is-invalid': errors.first('coupon-quantity') }"
            label="Quantity:"
            name="coupon-quantity"
            data-vv-as="Quantity"
            data-vv-name="coupon-quantity"
            :error="errors.first('coupon-quantity')"
            :disabled="disableFields"
          />
          <DateInput
            id="coupon-activeTo"
            v-model="coupon.activeTo"
            class="w-100"
            :class="{ 'is-invalid': errors.first('coupon-activeTo') }"
            label="Active to:"
            :max-date="currentDate"
            data-vv-as="Active to"
            data-vv-name="coupon-activeTo"
            :error="errors.first('coupon-activeTo')"
          />
        </div>
        <div class="col-6 col-lg-4 second-column">
          <label for="coupon-typeOfDiscount">Discount type:</label>

          <Dropdown
            v-model="typeOfDiscount"
            v-validate="'required'"
            required
            class="input-like w-100"
            empty-option
            name="coupon-typeOfDiscount"
            :class="{ 'is-invalid': errors.first('type') }"
            data-vv-name="typeOfDiscount"
            :list="DiscountTypesList"
            :disabled="coupon.amount !== 0 || coupon.percent !== 0"
          />
          <TextInput
            v-if="typeOfDiscount.value === 'amount'"
            id="coupon-amount"
            v-model="coupon.amount"
            v-validate="'required'"
            input-type="number"
            required
            class="w-100 mt-3"
            :class="{ 'is-invalid': errors.first('coupon-amount') }"
            label="Amount:"
            name="coupon-amount"
            data-vv-as="Amount"
            data-vv-name="coupon-amount"
            :error="errors.first('coupon-amount')"
            :disabled="
              disableFields || (coupon.percent !== null && coupon.percent !== 0)
            "
          />
          <div>
            <TextInput
              v-if="typeOfDiscount.value === 'percent'"
              id="coupon-percent"
              v-model="coupon.percent"
              v-validate="'required'"
              required
              input-type="number"
              class="w-100 mt-3"
              :class="{ 'is-invalid': errors.first('coupon-percent') }"
              label="Percent:"
              name="coupon-percent"
              data-vv-as="Percent"
              data-vv-name="coupon-percent"
              :error="errors.first('coupon-percent')"
              :disabled="
                disableFields || (coupon.amount !== null && coupon.amount !== 0)
              "
            />
          </div>
          <div>
            <label for="coupon-promoter">Associated with:</label>
            <input
              id="coupon-promoter"
              v-model="coupon.promoter"
              class="w-100 form-control px-2 h-auto"
              :class="{ 'is-invalid': errors.first('coupon-promoter') }"
              name="coupon-promoter"
              placeholder="Start typing..."
              data-vv-as="Associated with"
              data-vv-name="coupon-promoter"
              :error="errors.first('coupon-promoter')"
              :disabled="disableFields"
              type="text"
              @input="coupon.promoter = $event.target.value"
            />
          </div>
        </div>
        <div class="card-footer bg-transparent text-right w-100">
          <router-link
            :to="{
              name: $router.currentRoute.name.includes('promo')
                ? 'promocodes'
                : 'coupons',
            }"
            class="btn btn-link text-danger text-center mr-2"
          >
            Cancel
          </router-link>
          <button
            class="btn btn-primary text-center"
            :class="{ 'is-loading': isLoading }"
            :disabled="isLoading || hasErrors"
            :title="
              isLoading || hasErrors
                ? 'Please fill all the required fields properly.'
                : ''
            "
            @click="save"
          >
            {{ couponID ? 'Save' : 'Create' }}
          </button>
        </div>
        <div
          v-if="isLoading"
          class="preloader position-absolute d-flex justify-content-center align-items-center"
        >
          <div class="spinner"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { uniqueId } from 'lodash';
import { DateTime } from 'luxon';
import { diff } from '@/mixins/helpers';
import TextInput from '@/components/form/HeraFormTextInput';
import DateInput from '@/components/form/HeraFormDateInput';
import AutoSuggest from '@/components/form/HeraAutosuggest';
import Dropdown from '@/components/form/HeraFormDropdown';

export default {
  name: 'CouponEdit',
  inject: ['$validator'],
  components: { TextInput, DateInput, AutoSuggest, Dropdown },
  mixins: [diff],
  props: { country: { type: Object, required: false, default: () => null } },
  data() {
    return {
      isLoading: false,
      coupon: {
        key: `coupon_${uniqueId()}`,
        activeTo: null, // could be null
        code: null, // only for promo
        quantity: 1, // default = 1
        country: null,
        amount: 0,
        percent: 0, //for future
        promoter: null,
        UsageType: { text: 'subscription', value: 'subscription' },
      },
      UsageTypesList: [
        { text: 'subscription', value: 'subscription' },
        { text: 'any', value: 'any' },
        { text: 'shop', value: 'shop' },
        { text: 'tournaments', value: 'tournament' },
      ],
      DiscountTypesList: [
        { text: 'amount', value: 'amount' },
        { text: 'percent', value: 'percent' },
      ],
      typeOfDiscount: { text: 'amount', value: 'amount' },
      currentDate: DateTime.local().toFormat('dd/MM/yyyy'),
    };
  },

  computed: {
    disableFields() {
      return this.isLoading || !!this.couponID;
    },
    couponID() {
      return this.$route.params.couponID || '';
    },
    activeToInSeconds() {
      let result = 0;
      if (this.coupon.activeTo && this.coupon.activeTo.length) {
        let dateArr = this.coupon.activeTo.split('/');
        result = Date.UTC(+dateArr[2], +dateArr[1] - 1, dateArr[0]) / 1000;
      }
      return result;
    },
    hasErrors() {
      return Object.values(this.errors.all()).some(errors => errors.length > 0);
    },
  },
  watch: {
    'coupon.activeTo'(val) {
      if (!val) return;
      if (!val.toString().includes('/')) this.getActiveToDateFromString(val);

      const setDate = DateTime.fromFormat(val, 'dd/MM/yyyy').toSeconds();
      const minDate = DateTime.local().toSeconds();
      if (setDate < minDate) {
        this.errors.add({
          field: 'coupon-activeTo',
          msg: 'Please choose the date after today',
        });
      } else {
        this.errors.remove('coupon-activeTo');
      }
    },
  },
  created() {
    this.fetchCoupon();
  },
  methods: {
    ...mapActions([
      'suggestCountriesRequest',
      'suggestProfiles',
      'successNotify',
      'errorNotify',
      'getCoupon',
      'createCoupon',
      'updateCoupon',
    ]),
    getActiveToDateFromString(str) {
      let expDate = str ? new Date(str * 1000) : null;
      let formattedDate = '';
      if (expDate) {
        formattedDate += `${this.addLeadingZero(expDate.getUTCDate())}/`;
        formattedDate += `${this.addLeadingZero(expDate.getUTCMonth() + 1)}/`;
        formattedDate += `${expDate.getUTCFullYear()}`;
      }
      this.coupon.activeTo = formattedDate.length
        ? formattedDate.toString()
        : '';
    },
    fetchCoupon() {
      if (this.couponID) {
        this.isLoading = true;
        this.getCoupon(this.couponID)
          .then(data => {
            data.country = this.country;
            this.coupon = data;
          })
          .catch(this.errorNotify)
          .finally(() => (this.isLoading = false));
      }
    },
    getDataForSave() {
      return {
        ...this.coupon,
        amount: this.coupon.amount === 0 ? null : this.coupon.amount,
        percent: this.coupon.percent === 0 ? null : this.coupon.percent,
        UsageType: this.coupon.UsageType.value,
        // types available: [coupon, promo]
        type: this.$router.currentRoute.name.includes('promo')
          ? 'promo'
          : 'coupon',
        activeTo: this.activeToInSeconds ? this.activeToInSeconds : null,
        countryID: this.coupon.country.id,
        promoter: this.coupon.promoter,
      };
    },
    save() {
      this.isLoading = true;
      this.$validator
        .validateAll()
        .then(isValid =>
          isValid
            ? (this.couponID ? this.updateCoupon : this.createCoupon)(
                this.getDataForSave()
              )
            : Promise.reject('Form validation failed')
        )
        .then(() => {
          const entity = this.$router.currentRoute.name.includes('promo')
            ? 'Promocode'
            : 'Coupon';
          this.successNotify(
            this.couponID
              ? entity + ' was successfully updated'
              : entity + ' was successfully created'
          );
          this.$router.push({
            name: this.$router.currentRoute.name.includes('promo')
              ? 'promocodes'
              : 'coupons',
          });
        })
        .catch(this.errorNotify)
        .finally(() => (this.isLoading = false));
    },
    addLeadingZero(i) {
      return `${i < 10 ? '0' : ''}${i}`;
    },
  },
};
</script>

<style lang="scss">
.coupon-edit {
  h6 {
    font-size: 12px;
    font-weight: 100;
  }

  .mb-22 {
    margin-bottom: 22px;
  }
  .mb-29 {
    margin-bottom: 29px;
  }

  .switcher-label {
    font-size: 0.75rem;
  }

  .autosuggest_label {
    padding-left: 0 !important; //for replace boostrap class ml-1
  }

  .preloader {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $modal-backdrop-color;
    z-index: 2;
    border-radius: inherit;
  }

  .spinner {
    $size: 3.5rem;
    width: $size;
    height: $size;
    border: 2px solid transparent;
    border-left-color: $primary;
    border-bottom-color: $primary;
    border-radius: 50%;
    transform: translateX(-50%);
    animation: spinAround 0.5s infinite linear;
  }
}

#coupon-promoter {
  height: 32px !important;
  font-size: 0.75rem;
  color: #495057;
  font-weight: bold;
  line-height: 1.5;
}

.second-column {
  margin-top: -2.4px;
}
</style>
