'use strict';

import { BASE_URL } from '@/api/config.js';
import { authorizedGetRequest, authorizedPutRequest } from '@/api/requests.js';

export const REPOSTS_BASE = BASE_URL + 'platforms';

export const getReposts = authorizedGetRequest(`${REPOSTS_BASE}/reposts`);
export const getRepostsByProfile = profileId =>
  authorizedGetRequest(`${REPOSTS_BASE}/reposts/${profileId}`);
export const putRepostsByProfile = profileId =>
  authorizedPutRequest(`${REPOSTS_BASE}/reposts/${profileId}`);

export default {
  getReposts,
  getRepostsByProfile,
  putRepostsByProfile,
};
