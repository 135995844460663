import { ifAuthorized } from '@/router/guards';

import OrdersWrapper from '@/views/admin/Orders/';
import Orders from '@/views/admin/Orders/OrderList';
import SingleOrder from '@/views/admin/Orders/OrderList/SingleOrder';
import SingleOrderMagento from '@/views/admin/Orders/OrderList/SingleOrderMagento';

export default [
  {
    path: '/orders',
    title: 'Orders',
    component: OrdersWrapper,
    beforeEnter: ifAuthorized,
    children: [
      {
        path: '',
        name: 'orders',
        component: Orders,
      },
      {
        path: 'single/:magento',
        name: 'SingleOrderMagento',
        component: SingleOrderMagento,
      },
      {
        path: ':login',
        name: 'SingleOrder',
        component: SingleOrder,
      },
    ],
  },
];
