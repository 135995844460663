<template>
  <div class="row mb-4">
    <div class="col">
      <div class="row bg-light pt-3">
        <div class="col">
          <TextInput
            v-model="key"
            v-validate="'required'"
            data-vv-name="translate_key"
            data-vv-as="Key"
            :error="errors.first('translate_key')"
            label="Key:"
            placeholder="Translation key"
            required
            :disabled="isLoading"
          />
        </div>
        <div
          v-for="locale in locales"
          :key="locale.value"
          class="col"
          :dir="locale.value === 'ar' ? 'rtl' : 'ltr'"
          :class="{ 'text-right': locale.value === 'ar' }"
        >
          <TextInput
            v-model="translations[locale.value]"
            :label="locale.text"
            placeholder="Translation"
            :disabled="isLoading"
          />
        </div>
      </div>
      <div class="row bg-light pt-1 pb-3">
        <div class="col text-center">
          <small>
            <em class="text-muted"
              >Fill these fields to add new translation key and press "Save"
              button</em
            >
          </small>
        </div>
      </div>
      <div class="row bg-light pb-3">
        <div class="col text-center">
          <Btn
            class="btn-primary"
            :class="{ 'is-loading': isLoading }"
            label="Save"
            @click="addNewTranslation"
          />
          <hr />
          <div class="col p-2">
            <div
              class="row p-3 d-flex align-items-center justify-content-center"
            >
              <input
                ref="fileInput"
                type="file"
                :disabled="isLoading"
                @change="handleFileChange"
              />
              <Btn
                class="btn-primary"
                :class="{ 'is-loading': isLoading }"
                label="Bulk Save"
                @click="bulkSaveTranslations"
              />
            </div>
            <div class="navigation-text">
              <span v-if="errorMsg" class="errormsg">{{ errorMsg }}</span>
              <small>
                <em class="text-muted"
                  >{{
                    `The first row of the CSV file should have a column named "key" and other columns
                for
                each locale.`
                  }}
                  <br />{{
                    `Supported locale ${formatLocales}. Ex. first row 'key', 'en', 'ar', and the second row '_test','ENtest','ARtest'`
                  }}</em
                >
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Papa from 'papaparse';
import TextInput from '@/components/form/HeraFormTextInput';
import Btn from '@/components/form/HeraFormButton';

export default {
  inject: ['$validator'],

  components: {
    TextInput,
    Btn,
  },

  props: {
    locales: {
      type: Array,
      required: true,
    },
    formatLocales: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      key: '',
      translations: {},
      isLoading: false,
      errorMsg: '',
    };
  },

  methods: {
    ...mapActions([
      'saveTranslation',
      'errorNotify',
      'successNotify',
      'bulkSaveTranslationsAction',
    ]),

    addNewTranslation() {
      this.$validator
        .validateAll()
        .then(valide => {
          if (!valide) {
            throw { silent: true, text: 'Validation fail' };
          }
          const invalidLocales = Object.keys(this.translations).filter(
            locale => !this.formatLocales.includes(locale)
          );
          if (invalidLocales.length > 0) {
            throw {
              silent: true,
              text: 'Invalid locales: ' + invalidLocales.join(', '),
            };
          }

          this.isLoading = true;
          return Promise.all(
            Object.entries(this.translations)
              .filter(([, value]) => value && value.length)
              .map(([locale, value]) =>
                this.saveTranslation({
                  key: this.key,
                  locale,
                  value,
                })
              )
          );
        })
        .then(() => {
          this.successNotify(`The translation has been added`);
          this.$emit('add', {
            translations: { ...this.translations },
            key: this.key,
          });
        })
        .catch(this.errorNotify)
        .finally(() => (this.isLoading = false));
    },

    handleFileChange(event) {
      const file = event.target.files[0];

      if (file) {
        this.errorMsg = '';
        if (!file.name.toLowerCase().endsWith('.csv')) {
          this.isLoading = false;
          this.errorMsg = 'Invalid file format. Please select a CSV file.';
          this.errorNotify({
            silent: true,
            text: 'Invalid file format. Please select a CSV file.',
          });
          event.target.value = null;
          return;
        }

        this.readAndSaveCSVFile(file);
      }
    },

    parseCSVData(csvContent) {
      const parsedData = Papa.parse(csvContent, {
        header: true,
        skipEmptyLines: true,
      });
      if (parsedData.errors.length > 0) {
        this.errorMsg = `CSV Parsing Error: File is empty`;
        this.isLoading = false;
        throw new Error('Error parsing CSV file');
      }
      return parsedData.data;
    },
    readAndSaveCSVFile(file) {
      const reader = new FileReader();

      reader.onload = e => {
        const csvContent = e.target.result;
        const parsedData = this.parseCSVData(csvContent);
        Promise.all(parsedData)
          .then(() => {
            this.successNotify('Translations from CSV file have been added');
          })
          .catch(this.errorNotify)
          .finally(() => (this.isLoading = false));
      };

      reader.readAsText(file);
    },

    bulkSaveTranslations() {
      this.isLoading = true;

      const file = this.$refs.fileInput.files[0];

      if (!file) {
        this.errorMsg = 'No file selected';
        this.isLoading = false;
        throw { text: 'No file selected' };
      }

      const reader = new FileReader();

      reader.onload = e => {
        const csvContent = e.target.result;
        const parsedData = this.parseCSVData(csvContent);

        const firstRowColumns = Object.keys(parsedData[0] || {});
        if (!firstRowColumns.includes('key')) {
          this.errorMsg =
            'The "key" column is required in the first row of the CSV file.';
          this.isLoading = false;
          throw {
            text:
              'The "key" column is required in the first row of the CSV file.',
          };
        }
        const invalidColumns = firstRowColumns.filter(
          column => column !== 'key' && !this.formatLocales.includes(column)
        );
        if (invalidColumns.length > 0) {
          this.errorMsg = `Unsupported columns: ${invalidColumns.join(
            ', '
          )}. Supported locales: ${this.formatLocales.join(', ')}`;
          this.isLoading = false;
          throw { text: this.errorMsg };
        }
        const translations = parsedData.reduce((acc, row) => {
          const id = row.key;

          Object.entries(row).forEach(([locale, value]) => {
            if (
              locale !== 'key' &&
              value &&
              value.length &&
              this.formatLocales.includes(locale)
            ) {
              if (!acc[locale]) {
                acc[locale] = {};
              }

              acc[locale][id] = value;
            }
          });

          return acc;
        }, {});
        this.bulkSaveTranslationsAction({ translations })
          .then(() => {
            this.successNotify(`The translation has been added in bulk`);
          })
          .catch(this.errorNotify)
          .finally(() => {
            this.isLoading = false;
            this.file = null;
          });
      };

      reader.readAsText(file);
    },
  },
};
</script>
<style lang="scss" scoped>
.navigation-text {
  display: flex;
  flex-direction: column;
}

.errormsg {
  font-size: small;
  color: red;
}
</style>
