<template>
  <div class="games-page-wrapper">
    <div class="container">
      <div class="row my-4 align-items-center">
        <h3 class="mb-0 pb-0 col w-100">{{ title }}</h3>
        <div class="col text-right">
          <!--   TODO temporary commented TH-1459
       <button class="btn mx-2 btn-danger" :class="{'is-loading': isLoading}" v-if="game.published" @click.prevent="">Reject</button>-->
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <div class="row mb-3 px-3 pt-3">
                <div class="col-12 col-lg-4">
                  <div class="section-description">
                    Game options
                  </div>
                  <div class="form-group w-100">
                    <textInput
                      id="gameName"
                      v-model="game.name"
                      v-validate="'required'"
                      name="gameName"
                      placeholder="Game Name"
                      label="Game Name:"
                      class="mb-0"
                      required
                      data-vv-as="Game Name"
                      :error="errors.first('gameName')"
                      @input="updateID"
                    />
                  </div>
                  <div class="form-group w-100">
                    <autosuggest
                      id="gameGenres"
                      v-model="game.genres"
                      name="gameGenres"
                      dropdown-mode
                      multiple
                      display-property="name"
                      placeholder="Game Genres"
                      label="Game Genres:"
                      class="mb-0"
                      :source="filterGenres"
                    />
                  </div>
                  <div class="form-group w-100">
                    <label class="label-required-field">Platforms:</label>
                    <checkbox
                      v-for="(platform, index) in publishedPlatforms"
                      :id="platform.id"
                      :key="index"
                      v-model="game.platforms"
                      v-validate="'required'"
                      data-vv-name="platform"
                      data-vv-as="Please select at least one platform"
                      :label="platform.name"
                      :true-value="platform.id"
                    />
                    <span
                      v-show="errors.first('platform')"
                      id="platformsHelp"
                      class="form-text text-danger"
                      v-text="'Please select at least one platform'"
                    >
                    </span>
                  </div>
                </div>
                <div class="col-12 col-lg-4">
                  <div class="section-description">
                    Combinations
                  </div>
                  <label class="label-required-field">N of players:</label>
                  <div
                    v-for="(item, index) in game.squads"
                    :key="index"
                    class="form-group row no-gutters w-100 d-flex align-items-center justify-content-between mb-2"
                  >
                    <div class="col flex-grow-1">
                      <textInput
                        :id="'nPlayers' + index"
                        v-model.number="game.squads[index]"
                        v-validate="'required|numeric'"
                        :name="'nPlayers' + index"
                        class="mb-0"
                        required
                        data-vv-as="N of players"
                        :error="errors.first('nPlayers' + index)"
                      />
                    </div>
                    <div
                      class="col trash-icon-holder ml-2"
                      :class="{ 'is-hide': game.squads.length === 1 }"
                    >
                      <AppIcon
                        v-if="game.squads.length > 1"
                        name="trash"
                        width="16"
                        class="fill-light"
                        @click="removeNplayers($event, index)"
                      />
                    </div>
                  </div>
                  <div class="w-100">
                    <button
                      class="btn bg-transparent border-0 text-black"
                      :class="{ disabled: !canAddGameSquads }"
                      @click.prevent="addNPlayers"
                    >
                      <AppIcon
                        name="add"
                        width="12"
                        class="fill-primary"
                      />&nbsp;<small>Add</small>
                    </button>
                  </div>
                </div>
                <div class="col-12 col-lg-4">
                  <div class="section-description">
                    Game modes
                  </div>
                  <label class="label-required-field">Modes:</label>
                  <div
                    v-for="(item, index) in game.modes"
                    :key="index"
                    class="form-group row no-gutters w-100 d-flex align-items-center justify-content-between mb-2"
                  >
                    <div class="col flex-grow-1">
                      <textInput
                        :id="'modes' + index"
                        v-model="game.modes[index]"
                        v-validate="'required'"
                        :name="'modes' + index"
                        class="mb-0"
                        data-vv-as="Mode"
                        :error="errors.first('modes' + index)"
                      />
                    </div>
                    <div
                      class="col trash-icon-holder ml-2"
                      :class="{ 'is-hide': game.modes.length === 1 }"
                    >
                      <AppIcon
                        v-if="game.modes.length > 1"
                        name="trash"
                        width="16"
                        class="fill-light"
                        @click="removeModes($event, index)"
                      />
                    </div>
                  </div>
                  <div class="w-100">
                    <button
                      class="btn bg-transparent border-0 text-black"
                      :class="{ disabled: !canAddGameMode }"
                      @click.prevent="addModes"
                    >
                      <AppIcon
                        name="add"
                        width="12"
                        class="fill-primary"
                      />&nbsp;<small>Add</small>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body border-top border-muted">
              <div class="row mb-3 px-3 pt-3">
                <div class="col">
                  <div class="section-description">
                    Game description
                  </div>
                  <div class="row">
                    <div class="col-12 col-lg-4">
                      <p>
                        <strong class="game-img-label label-required-field"
                          >Portrait BG</strong
                        >
                      </p>
                      <div class="outer game-logo my-2">
                        <div
                          :style="{
                            backgroundImage: portraitCoverUri
                              ? `url(${portraitCoverUri})`
                              : null,
                          }"
                          class="inner"
                          :class="{ 'has-image': portraitCoverUri }"
                        ></div>
                      </div>
                      <fileBase64
                        id="coverfile"
                        :done="setPortraitCover"
                        :is-required="!portraitCoverUri"
                        validate-as="Game portrait background"
                        name="cover"
                        :preupload="false"
                      />
                    </div>
                    <div class="col-12 col-lg-4">
                      <p>
                        <strong class="game-img-label label-required-field"
                          >Landscape BG</strong
                        >
                      </p>
                      <div class="outer game-cover my-2">
                        <div
                          :style="{
                            backgroundImage: coverUri
                              ? `url(${coverUri})`
                              : null,
                          }"
                          class="inner"
                          :class="{ 'has-image': coverUri }"
                        ></div>
                      </div>
                      <fileBase64
                        id="bgfile"
                        :done="setCover"
                        :is-required="!coverUri"
                        validate-as="Game landscape background"
                        name="portrait_cover"
                        :preupload="false"
                      />
                    </div>

                    <div class="col-12 col-lg-4">
                      <p>
                        <strong class="game-img-label label-required-field"
                          >Game avatar</strong
                        >
                      </p>
                      <div class="outer game-avatar my-2">
                        <div
                          :style="{
                            backgroundImage: logoUri ? `url(${logoUri})` : null,
                          }"
                          class="inner"
                          :class="{ 'has-image': logoUri }"
                        ></div>
                      </div>
                      <fileBase64
                        id="logofile"
                        :done="setLogo"
                        :is-required="!logoUri"
                        validate-as="Game Landscape background"
                        name="logo"
                        :preupload="false"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body border-top border-muted">
              <div class="row px-3">
                <div class="col text-right">
                  <button
                    class="btn mx-2 bg-transparent text-muted"
                    @click.prevent="$router.go(-1)"
                  >
                    Cancel
                  </button>
                  <button
                    v-if="!gameID"
                    class="btn mx-2 bg-transparent text-primary"
                    :class="{ 'is-loading': isLoading }"
                    @click.prevent="onCreate"
                  >
                    <AppIcon
                      name="draft"
                      width="21"
                      class="fill-primary mr-2"
                    />Draft
                  </button>
                  <button
                    v-if="gameID && isChanged"
                    class="btn mx-2 btn-primary"
                    :class="{ 'is-loading': isLoading }"
                    @click.prevent="onSave"
                  >
                    Save
                  </button>
                  <button
                    v-if="canPublish"
                    class="btn mx-2 btn-primary"
                    :class="{ 'is-loading': isLoading }"
                    @click.prevent="onPublish"
                  >
                    Publish
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppIcon from '@/components/common/Icon';

import { mapGetters, mapActions } from 'vuex';
import textInput from '@/components/form/HeraFormTextInput.vue';
import autosuggest from '@/components/form/HeraAutosuggest.vue';
import checkbox from '@/components/form/HeraFormCheckbox.vue';
import fileBase64 from '@/components/form/fileBase64.vue';
import { isEqual, cloneDeep } from 'lodash';
export default {
  name: 'CreateGame',
  inject: ['$validator'],

  components: {
    textInput,
    checkbox,
    fileBase64,
    AppIcon,
    autosuggest,
  },
  data() {
    return {
      game: {
        id: null,
        logo: null,
        cover: null,
        portrait_cover: null,
        published: false,
        name: null,
        platforms: [],
        squads: [1],
        modes: [''],
      },
      idChanged: false,
      isLoading: false,
      // availablePlatforms: [...this.platforms]
    };
  },
  computed: {
    ...mapGetters(['gameByID', 'publishedPlatforms', 'genreByID']),
    title() {
      return this.gameID ? 'Edit ' + this.game.name : 'Create New Game';
    },
    gameID() {
      return this.$route.params.gameID;
    },
    isChanged() {
      return !isEqual(this.game, this.gameByID(this.gameID));
    },
    canAddGameMode() {
      return !!String(this.game.modes[this.game.modes.length - 1] || '').trim();
    },
    canAddGameSquads() {
      return !!String(
        this.game.squads[this.game.squads.length - 1] || ''
      ).trim();
    },
    canPublish() {
      return (
        this.gameID &&
        !this.game.published &&
        this.game.logo &&
        this.game.cover &&
        this.game.portrait_cover &&
        this.game.platforms.length
      );
    },
    logoUri() {
      const logo = String(this.game.logo || '');
      return logo.match(/^http/) ? encodeURI(logo) : logo;
    },
    coverUri() {
      const cover = String(this.game.cover || '');
      return cover.match(/^http/) ? encodeURI(cover) : cover;
    },
    portraitCoverUri() {
      const portraitCover = String(this.game.portrait_cover || '');
      return portraitCover.match(/^http/)
        ? encodeURI(portraitCover)
        : portraitCover;
    },
  },
  created() {
    Promise.all([this.getPlatforms(), this.getGames(), this.getGenres()])
      .then(() => {
        if (this.gameID) {
          this.game = cloneDeep(this.gameByID(this.gameID));
          this.game.genres = (this.game.genres || []).map(id =>
            this.genreByID(id)
          );
        }
      })
      .catch(this.errorNotify);
  },
  methods: {
    ...mapActions([
      'createGame',
      'publishGame',
      'updateGame',
      'getPlatforms',
      'getGames',
      'getGenres',
      'errorNotify',
      'successNotify',
    ]),
    onTouchID(event) {
      // console.log(event)
      if (event.target.defaultValue !== event.target.value) {
        this.idChanged = true;
      }
    },
    updateID() {
      if ((this.game.id === null || !this.idChanged) && !this.gameID) {
        this.game.id = this.game.name
          .toLowerCase()
          .split(' ')
          .join('');
      }
    },
    setLogo(file) {
      this.game.logo = file.output;
    },
    setCover(file) {
      this.game.cover = file.output;
    },
    setPortraitCover(file) {
      this.game.portrait_cover = file.output;
    },
    addNPlayers() {
      if (this.canAddGameSquads) {
        this.game.squads = this.game.squads.concat(['']);
      }
    },
    removeNplayers(event, index) {
      this.game.squads.splice(index, 1);
    },
    addModes() {
      if (this.canAddGameMode) {
        this.game.modes = this.game.modes.concat(['']);
      }
    },
    removeModes(event, index) {
      this.game.modes.splice(index, 1);
    },
    onCreate() {
      this.isLoading = true;
      let newGame = { ...{}, ...this.game };
      newGame.publish = false;
      // newGame.id = window.btoa(newGame.name).slice(0, 8)
      this.$validator
        .validateAll()
        .then(result =>
          result
            ? Promise.resolve(newGame)
            : Promise.reject('Form validation failed')
        )
        .then(game => this.createGame(game))
        .then(() => {
          this.successNotify(`Your changes saved`);
          this.isLoading = false;
          this.$router.push({ name: 'games', hash: `#${newGame.id}` });
        })
        .catch(error => {
          this.errorNotify(error);
          this.isLoading = false;
        });
    },
    onSave() {
      let newGame = { ...{}, ...this.game };
      if (
        newGame.published &&
        !confirm(
          'This game is already published. After saving changes game become unpublish again. Do you really want to make changes?'
        )
      ) {
        return;
      }
      this.isLoading = true;

      this.$validator
        .validateAll()
        .then(result =>
          result
            ? Promise.resolve(newGame)
            : Promise.reject('Form validation failed')
        )
        .then(game => {
          if (game.cover && game.cover.match(/^http/)) {
            delete game.cover;
          }
          if (game.portrait_cover && game.portrait_cover.match(/^http/)) {
            delete game.portrait_cover;
          }
          if (game.logo && game.logo.match(/^http/)) {
            delete game.logo;
          }
          return this.updateGame({
            id: this.gameID,
            game: this.getGameDataForSave(game),
          });
        })
        .then(() => {
          this.successNotify(`Your changes saved`);
          this.isLoading = false;
          this.$router.push({ name: 'games', hash: `#${newGame.id}` });
        })
        .catch(error => {
          this.errorNotify(error);
          this.isLoading = false;
        });
    },
    onPublish() {
      //this.validatePlatforms();
      this.isLoading = true;
      this.$validator
        .validateAll()
        .then(result =>
          result
            ? Promise.resolve(encodeURI(this.game.id))
            : Promise.reject('Form validation failed')
        )
        .then(id => this.publishGame(id))
        .then(() => {
          this.successNotify(`Game published`);
          this.isLoading = false;
          this.$router.push({ name: 'games', hash: `#${this.game.id}` });
        })
        .catch(error => {
          this.errorNotify(error);
          this.isLoading = false;
        });
    },
    Base64Encode(str, encoding = 'utf-8') {
      // eslint-disable-next-line
      let bytes = new (TextEncoder || TextEncoderLite)(encoding).encode(str)
      // eslint-disable-next-line
      return base64js.fromByteArray(bytes)
    },
    filterGenres(search, showAll) {
      return this.$store.state.settings.genres.filter(
        el => showAll || el.name.toLowerCase().indexOf(search) > -1
      );
    },

    getGameDataForSave(game) {
      const data = cloneDeep(game);
      if (data.id) {
        delete data.id;
      }
      return { ...data, genres: data.genres.map(({ id }) => id) };
    },
  },
};
</script>

<style lang="scss">
.games-page-wrapper {
  .section-description {
    text-transform: uppercase;
    font-size: 0.625rem;
    color: #b4bac6;
    letter-spacing: 0.09375rem;
    margin-bottom: 1.25rem;
  }
  label,
  .game-img-label {
    font-size: 0.75rem;
  }

  .custom-control {
    &.custom-checkbox {
      margin-bottom: 0.75rem;
      label {
        font-weight: bold;
      }
    }
  }
  .outer {
    position: relative;
    width: 100%;

    &:before {
      display: block;
      content: '';
      width: 100%;
    }
    > .inner {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #eee url(~@/assets/images/image-placeholder.png) no-repeat
        center;
      &.has-image {
        background-color: transparent;
        background-size: cover;
      }
    }
    &.game-logo {
      max-width: 150px;
      &:before {
        padding-top: (27 / 19) * 100%;
      }
    }
    &.game-cover {
      max-width: 250px;
      &:before {
        padding-top: (141 / 250) * 100%;
      }
    }
    &.game-avatar {
      max-width: 128px;
      &:before {
        padding-top: 100%;
      }
      > .inner {
        &.has-image {
          background-size: contain !important;
        }
      }
    }
  }
  .trash-icon-holder {
    width: 1rem !important;
    height: 2rem; // (32px / $font-size-base)
    flex-grow: 0;
    align-self: flex-start;
    display: flex;
    align-items: center;
    &.is-hide {
      width: 0;
      padding-left: 1rem;
    }
  }
}
</style>
