<template>
  <div class="games-list-page-wrapper">
    <div class="container">
      <div class="row my-4 align-items-center">
        <h3 class="mb-0 pb-0 col">Games</h3>
        <div class="col text-right">
          <router-link
            class="btn btn-primary"
            :to="{ name: 'createGame', params: { gameID: null } }"
            >Create Game</router-link
          >
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <div class="row mb-3 px-3 pt-3">
                <div
                  v-for="(game, index) in games"
                  :id="game.id"
                  :key="index"
                  class="game-wrapper"
                  @click="goToGameEditing(game.id)"
                >
                  <div class="outer">
                    <div class="inner" :style="setBackground(game)"></div>
                  </div>
                  <div
                    class="mt-2 mb-4 row justify-content-between align-items-center"
                  >
                    <div class="col-auto game-name">{{ game.name }}</div>
                    <div v-if="!game.published" class="col-auto draft-icon">
                      <AppIcon name="draft" width="21" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AppIcon from '@/components/common/Icon';
export default {
  name: 'Games',
  components: {
    AppIcon,
  },
  computed: {
    ...mapGetters(['games']),
  },
  created() {
    this.getGames().catch(this.errorNotify);
  },
  updated() {
    if (this.$route.hash) {
      let newGameElement = document.getElementById(this.$route.hash.slice(1));
      if (newGameElement) {
        newGameElement.scrollIntoView(true);
      }
    }
  },
  methods: {
    ...mapActions(['getGames', 'errorNotify']),
    goToGameEditing(ID) {
      this.$router.push({ name: 'createGame', params: { gameID: ID } });
    },
    setBackground({ portrait_cover }) {
      if (!portrait_cover) {
        return null;
      }

      const backgroundImage = `url(${encodeURI(portrait_cover)})`;
      return { backgroundImage };
    },
  },
};
</script>

<style lang="scss">
.game-wrapper {
  width: calc(20% - 2rem);
  margin: 0 1rem;
  cursor: pointer;
  .outer {
    position: relative;
    width: 100%;
    &:before {
      display: block;
      content: '';
      width: 100%;
      padding-top: (27 / 19) * 100%;
    }
    > .inner {
      position: absolute;
      background: #eee no-repeat center;
      background-size: cover;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .game-name {
    font-size: 0.875rem;
    width: calc(100% - 51px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .draft-icon {
    line-height: 1.3125rem;
    height: 1.3125rem;
  }
}
</style>
