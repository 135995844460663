import { ifAuthorized } from '@/router/guards';

import TournamentsWrapper from '@/views/admin/Tournaments/';
import Tournaments from '@/views/admin/Tournaments/TournamentsList';

export default [
  {
    path: '/tournaments',
    title: 'Tournaments',
    component: TournamentsWrapper,
    beforeEnter: ifAuthorized,
    children: [
      {
        path: '',
        name: 'tournaments',
        component: Tournaments,
      },
    ],
  },
];
