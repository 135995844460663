'use strict';

import { BASE_URL } from '@/api/config.js';

import {
  authorizedGetRequest,
  authorizedPutRequest,
  simpleGetRequest,
} from '@/api/requests.js';

const CLAN_BASE = BASE_URL + 'clans/';

export const getClans = (pageToken = null, query = '') =>
  authorizedGetRequest(
    CLAN_BASE +
      `?${query}` +
      (pageToken ? `&page=${encodeURIComponent(pageToken)}` : ``),
    { ignoreParams: true }
  );

export const setClanVerification = (clan = '') =>
  authorizedPutRequest(CLAN_BASE + `${clan}/validated`);

export const suggestClans = query =>
  simpleGetRequest(`${CLAN_BASE}?contains=${query}`);

export default {
  getClans,
  setClanVerification,
  suggestClans,
};
