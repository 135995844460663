<template>
  <div class="complaints-page-wrapper">
    <div class="container">
      <div class="row my-4 align-items-center">
        <h3 class="mb-0 pb-0 col">Complaints</h3>
      </div>
      <div class="row">
        <div class="col">
          <div class="card-filters py-2 px-4">
            <div class="card-body py-0">
              <div class="row align-items-center">
                <div class="col-1"></div>
                <div class="col-11">
                  <div class="row align-items-center">
                    <!--Search-->
                    <div class="col-2">
                      <textInput
                        v-model="filter.reporter"
                        class="text-input"
                        placeholder="Search..."
                        :debounce="debounce"
                        @input="onFilterChange"
                      >
                      </textInput>
                    </div>
                    <!--Type-->
                    <div class="col-1">
                      <dropdown
                        id="entity_type_filter"
                        v-model="filter.type"
                        class="input-like w-auto"
                        :list="Object.keys(types)"
                        empty-text="All"
                        :empty-option="true"
                        :could-be-empty="true"
                        @itemChanged="onFilterChange"
                      >
                        <template slot="chosen" slot-scope="chosen">
                          <span>{{ types[chosen.chosenItem] }}</span>
                          <span class="sr-only">Choose option</span>
                        </template>
                        <template slot="item" slot-scope="options">
                          <span>{{ types[options.item] }}</span>
                        </template>
                      </dropdown>
                    </div>
                    <!--Date-->
                    <div class="col-3">
                      <dateInput
                        v-model="filter.date"
                        :first-day-of-week="1"
                        :minute-step="5"
                        value-type="timestamp"
                        type="datetime"
                        confirm
                        range
                        lang="en"
                        format="DD/MM/YYYY"
                        @input="onFilterChange"
                      >
                      </dateInput>
                    </div>
                    <!--Reason-->
                    <div class="col-3">
                      <dropdown
                        v-model="filter.reason"
                        class="input-like"
                        :list="Object.keys(reasons)"
                        empty-text="All"
                        :empty-option="true"
                        :could-be-empty="true"
                        @input="onFilterChange"
                      >
                        <template slot="chosen" slot-scope="chosen">
                          <span>{{ reasons[chosen.chosenItem] }}</span>
                          <span class="sr-only">Choose option</span>
                        </template>
                        <template slot="item" slot-scope="options">
                          <span>{{ reasons[options.item] }}</span>
                        </template>
                      </dropdown>
                    </div>
                    <!--Actions-->
                    <div class="col-2">
                      <!-- Commented cause it has no effect -->
                      <!-- <dropdown class="input-like primary"
                        :list="filters.actions.options" 
                        v-model="filter.action" 
                        emptyText="All" 
                        :emptyOption="true"
                        :couldBeEmpty="true" 
                        @input="onFilterChange">
                        <template slot="chosen" slot-scope="chosen">
                          <span>{{chosen.chosenItem}}</span>
                          <span class="sr-only">Choose option</span>
                        </template>
                        <template slot="item" slot-scope="options">
                          <span>{{options.item}}</span>
                        </template>
                      </dropdown> -->
                    </div>
                    <div class="col-1"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-filters card-labels py-3 px-4">
            <div class="card-body py-0">
              <div class="row align-items-center">
                <div class="col">#</div>
                <div class="col-11">
                  <div class="row align-items-center">
                    <div class="col-2">Reporter</div>
                    <div class="col-1">Type</div>
                    <div class="col-3">Date and time</div>
                    <div class="col-3">Reason</div>
                    <div class="col-2">Actions</div>
                    <div class="col-1">Details</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <infiniteScroll
        v-if="complaintsList.length"
        :list="complaintsList"
        list-name="list"
        :is-disabled="isLoading || nextPageToken === null"
        :activation-gap="100"
        @ScrollToBottom="fetchData"
      >
        <template slot-scope="{ list }">
          <div v-for="(complaint, index) in list" :key="index" class="row mb-2">
            <div class="col">
              <ComplaintsCard
                :key="index"
                :params="complaint"
                :order="index + 1"
                :group-name="`group-${index}`"
                @cardResolved="status => resolveCard(index, status)"
              />
            </div>
          </div>
        </template>
      </infiniteScroll>
      <div v-else-if="isLoading" class="row mb-2">
        <div class="col-12">
          <p class="text-center text-muted">Fetching data</p>
        </div>
      </div>
      <div v-else class="row my-2">
        <div class="col-12">
          <p class="text-center text-muted">No complaints to show</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dateInput from 'vue2-datepicker';
import textInput from '@/components/form/HeraFormTextInput.vue';
import dropdown from '@/components/form/HeraFormDropdown.vue';
import ComplaintsCard from './complaints-card';
import filters from './filters.js';
import { mapActions, mapMutations } from 'vuex';
import listMixin from '@/mixins/lists';
import { parseObjectToUri } from '@/utils';
import infiniteScroll from '@/components/common/infiniteScroll.vue';

export default {
  name: 'Complaints',
  components: {
    textInput,
    dateInput,
    dropdown,
    ComplaintsCard,
    infiniteScroll,
  },
  mixins: [...listMixin],
  data() {
    return {
      filter: {
        reporter: null,
        date: [],
        type: null,
        reason: null,
        action: null,
      },
      debounce: 700,
      filters: filters,
      complaintsList: [],
    };
  },
  computed: {
    reasons() {
      return filters.reasons.options; //this.getComplaintsReasons()
    },
    types() {
      return filters.types.options;
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapMutations(['setActivePopoverChat']),
    ...mapActions([
      'getComplaintsList',
      'getComplaintsReasons',
      'errorNotify',
      'successNotify',
      'getConversationInfo',
    ]),
    fetchData() {
      return this.getNextPageData(
        'complaintsList',
        this.getComplaintsList({
          query: parseObjectToUri({
            page: this.nextPageToken,
            entity_type: this.filter.type,
            reporter: this.filter.reporter,
            date_from: this.filter.date[0]
              ? (new Date(this.filter.date[0]).valueOf() / 1000) ^ 0
              : null,
            date_to: this.filter.date[1]
              ? (new Date(this.filter.date[1]).valueOf() / 1000) ^ 0
              : null,
            reason: this.filter.reason,
            action: this.filter.action,
          }),
        })
      );
    },
    onFilterChange() {
      this.$nextTick(() => {
        this.complaintsList = [];
        this.nextPageToken = undefined;
        this.fetchData();
      });
    },
    resolveCard(i, status) {
      this.$set(this.complaintsList[i], 'status', status);
    },
  },
};
</script>
<style lang="scss">
#entity_type_filter {
  max-width: 100%;
  & > button {
    /* TODO: fix text cropping*/
    max-width: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis !important;
  }
}
</style>
<style lang="scss" scoped>
.card-filters {
  font-size: 0.75rem;
  font-weight: bold;
  background: transparent;
  border: none;

  .text-input {
    background: #fff;
    margin: 0;
  }

  .input-like {
    background: #fff;
  }

  &.card-labels {
    font-size: 0.75rem;
    color: $hera-secondary-text-color;
    text-transform: uppercase;
  }

  .mx-datepicker-range {
    width: 100%;
  }
}
</style>
