import api from '@/api';
import Vue from 'vue';
import { parseObjectToUri } from '@/utils';

export const DEFAULT_TOURNAMENTS_STATE = () => ({
  // deprecated code, tournaments array are in local component state
  // will be deleted after testing
  // tournaments: [],
  currentTournament: {},
  currentRoundIndex: 0,
  currentGroupIndex: 0,
  currentGroupParticipants: [],
  isGroupSwapping: false,
  groupSwapData: {
    from: {},
    to: {},
  },
});

const rejectableStatuses = [
  'announced',
  'registration_opened',
  'registration_closed',
  'scheduled',
];

const getters = {
  // deprecated code, tournaments array are in local component state
  // will be deleted after testing
  // tournaments: state => state.tournaments,
  tournamentByID: state => ID => state.tournaments.find(el => el.id === ID),
  currentTournament: state => state.currentTournament,
  currentRoundIndex: state => state.currentRoundIndex,
  currentGroupIndex: state => state.currentGroupIndex,
  currentGroupParticipants: state => state.currentGroupParticipants,
  hasGroupStage: state =>
    ['round_robin_playoff'].includes(state.currentTournament.tournament_type),
  isDraft: state =>
    state.currentTournament && state.currentTournament.status === 'draft',
  isRejected: state =>
    state.currentTournament && state.currentTournament.status === 'rejected',
  isRejectable: state =>
    state.currentTournament &&
    rejectableStatuses.includes(state.currentTournament.status),
  isScheduled: state =>
    state.currentTournament && state.currentTournament.status === 'scheduled',
  isStarted: state =>
    state.currentTournament && state.currentTournament.status === 'started',
  isPublished: state =>
    state.currentTournament && state.currentTournament.status === 'published',
  hasGrid: state =>
    state.currentTournament && state.currentTournament.status
      ? ![
          'draft',
          'announced',
          'registration_opened',
          'registration_closed',
          'rejected',
        ].includes(state.currentTournament.status)
      : false,
  isGroupSwapping: state => state.isGroupSwapping,
  groupSwapData: state => state.groupSwapData,
  isGroupSwapSource: state =>
    state.isGroupSwapping &&
    state.currentGroupIndex === state.groupSwapData.from.groupIndex,
  isGroupSwapTarget: state =>
    state.isGroupSwapping &&
    state.currentGroupIndex !== state.groupSwapData.from.groupIndex,
};

const mutations = {
  // deprecated code, tournaments array are in local component state
  // will be deleted after testing
  // setTournaments (state, payload) {
  //     state.tournaments = []
  //     for (var i = 0; i < payload.length; i += 1) {
  //         Vue.set(state.tournaments, i, {})
  //         state.tournaments[i] = {...state.tournaments[i], ...payload[i]}
  //     }
  // },
  setCurrentTournament(state, payload) {
    state.currentTournament = payload;
  },
  setCurrentRound(state, index) {
    state.currentRoundIndex = index;
  },
  setCurrentGroup(state, index) {
    state.currentGroupIndex = index;
  },
  setCurrentGroupParticipants(state, participants) {
    state.currentGroupParticipants = participants;
  },
  updateCurrentGroupParticipant(state, payload) {
    state.currentGroupParticipants.splice(
      payload.index,
      1,
      payload.participant
    );
    // Vue.set(state.currentGroupParticipants, payload.index, payload.participant)
  },
  updateCurrentTournament(state, payload) {
    for (let key in payload) {
      Vue.set(state.currentTournament, key, payload[key]);
    }
  },
  clearCurrentTournament(state) {
    state.currentTournament = {};
  },
  // deprecated code, tournaments array are in local component state
  // will be deleted after testing
  // addTournament (state, payload) {
  //     state.tournaments = [payload].concat(state.tournaments)
  // },
  // updateTournament (state, payload) {
  //     // let index = state.tournaments.findIndex(el => el.id === payload.id)
  //     // state.tournaments[index] = {...state.tournaments[index], ...payload}
  // },
  clearToTournamentsDefaultState(state) {
    const defaultState = DEFAULT_TOURNAMENTS_STATE();

    Object.keys(defaultState).forEach(key => {
      Vue.set(state, key, defaultState[key]);
    });
  },
  setGroupSwapping(state) {
    state.isGroupSwapping = true;
  },
  cancelGroupSwapping(state) {
    state.isGroupSwapping = false;
  },
  updateSwapFrom(state, payload) {
    state.groupSwapData.from = payload;
  },
  updateSwapTo(state, payload) {
    state.groupSwapData.to = payload;
  },
  // swapGroupPlayers (state) {
  //   const fromPlayer = {}
  // }
};

const actions = {
  getTournaments(
    {
      getters: { accessToken },
    },
    params
  ) {
    return api
      .getTournaments(accessToken, { params })
      .then(response => response.data);
  },
  getTournamentClans(
    {
      dispatch,
      getters: { accessToken, currentTournament },
    },
    payload
  ) {
    const { pageToken, status } = payload;
    const { id: tournamentID } = currentTournament;
    return api
      .getTournamentClans(pageToken, tournamentID, status)(accessToken)
      .then(response => response.data)
      .catch(error => {
        dispatch('errorNotify', error);
      });
  },
  approveClanOnTournament(
    {
      getters: { accessToken, currentTournament },
    },
    clanID
  ) {
    return api.approveClanOnTournament(clanID, currentTournament.id)(
      accessToken
    );
  },
  rejectClanOnTournament(
    {
      getters: { accessToken, currentTournament },
    },
    { clanID, reason }
  ) {
    const data = { ...reason };
    return api.rejectClanOnTournament(clanID, currentTournament.id)(
      accessToken,
      data,
      { headers: { 'Content-Type': 'application/json' } }
    );
  },
  approveAllClans({ getters: { accessToken, currentTournament } }) {
    return api.approveAllClansOnTournament(currentTournament.id)(accessToken);
  },
  publishTournament(
    {
      commit,
      getters: { accessToken },
    },
    tournamentID
  ) {
    return api
      .publishTournament(tournamentID)(accessToken)
      .then(() => {
        commit('updateCurrentTournament', { status: 'published' });
      });
  },
  announceTournament(
    {
      commit,
      getters: { accessToken },
    },
    tournamentID
  ) {
    return api
      .announceTournament(tournamentID)(accessToken)
      .then(() => {
        commit('updateCurrentTournament', { status: 'announced' });
      });
  },
  rejectTournament(
    {
      commit,
      getters: { accessToken },
    },
    payload
  ) {
    const { tournamentID, reason } = payload;
    const data = { reason };
    return api
      .rejectTournament(tournamentID)(accessToken, data)
      .then(() => {
        commit('updateCurrentTournament', { status: 'rejected' });
      });
  },
  restartTournament(
    {
      commit,
      getters: { accessToken },
    },
    tournamentID
  ) {
    return api
      .restartTournament(tournamentID)(accessToken)
      .then(() => {
        commit('updateCurrentTournament', { status: 'draft' });
      });
  },
  drawTournament(
    {
      commit,
      dispatch,
      getters: { accessToken },
    },
    tournamentID
  ) {
    return api
      .drawTournament(tournamentID)(accessToken)
      .then(() => {
        commit('updateCurrentTournament', { status: 'scheduled' });
      })
      .catch(error => dispatch('errorNotify', error));
  },
  updateTournamentCustomSettings({ getters }, payload) {
    return api.updateTournamentCustomSettingsById(payload.tournamentID)(
      getters.accessToken,
      payload.custom
    );
  },
  fetchTournamentCustomSettings({ getters }, tournamentID) {
    return api
      .getTournamentCustomSettingsById(tournamentID)(getters.accessToken)
      .then(response => response.data);
  },
  fetchTournamentDetails(
    {
      dispatch,
      getters: { accessToken, platformByID, gameByID },
      commit,
    },
    id
  ) {
    return api
      .getTournament(id)(accessToken)
      .then(response => {
        let tournament = {
          ...response.data,
          ...{
            platforms: response.data.platforms
              ? response.data.platforms.map(platform => platformByID(platform))
              : [],
          },
          ...{
            game: response.data.game
              ? { ...gameByID(response.data.game) }
              : null,
          },
          ...{
            id: response.data.id,
          },
          ...{
            country: {
              id: response.data.country_id,
              text: response.data.country,
            },
          },
        };
        commit('setCurrentTournament', tournament);
        return tournament;
      })
      .catch(error => dispatch('errorNotify', error));
  },
  createTournament(
    {
      commit,
      getters: { accessToken, platformByID, gameByID },
    },
    tournament
  ) {
    return new Promise((resolve, reject) => {
      api
        .createTournament(accessToken, tournament, {
          'follow-redirect': 10,
          maxBodyLength: 5e8,
          maxContentLength: 5e8,
        })
        .then(response => {
          let {
            id,
            sponsor_logo,
            logo,
            cover,
            portrait_cover,
            rules,
            status,
          } = response.data;
          // deprecated code, tournaments array are in local component state
          // will be deleted after testing
          // commit('addTournament', response.data)
          commit('setCurrentTournament', {
            ...tournament,
            ...{
              id,
              sponsor_logo,
              logo,
              cover,
              portrait_cover,
              status,
              rules,
            },
            ...{
              platforms: tournament.platforms.map(platform =>
                platformByID(platform)
              ),
            },
            ...{
              game: tournament.game ? { ...gameByID(tournament.game) } : null,
            },
            ...{
              country: { id: tournament.country_id, text: tournament.country },
            },
          });
          resolve(id);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateTournament(
    {
      dispatch,
      commit,
      getters: { accessToken },
    },
    tournament
  ) {
    // deprecated code, tournaments array are in local component state
    // will be deleted after testing
    // commit('updateTournament', tournament)
    return api
      .updateTournament(tournament.id)(accessToken, tournament, {
        'follow-redirect': 10,
        maxBodyLength: 5e8,
        maxContentLength: 5e8,
      })
      .then(() => dispatch('fetchTournamentDetails', tournament.id))
      .then(response => {
        // console.warn(response)
        // let { id, sponsor_logo, logo, cover, portrait_cover, rules } = response
        commit('updateCurrentTournament', response);
      });
  },
  updateTournamentOptions({ getters }, payload) {
    return api
      .updateTournamentConfig(payload.tournamentID)(
        getters.accessToken,
        payload.options
      )
      .catch(error => Promise.reject(error));
  },
  fetchTournamentOptions({ dispatch, getters }, tournamentID) {
    return api
      .getTournamentConfigById(tournamentID)(getters.accessToken)
      .then(response => {
        let tournament = {
          ...response.data,
          ...{
            city: { id: response.data.city_id, text: response.data.city },
          },
        };
        return tournament;
      })
      .catch(error => dispatch('errorNotify', error));
  },
  updateTournamentSchedule({ dispatch, getters }, payload) {
    return api
      .updateTournamentSchedule(payload.tournamentID)(
        getters.accessToken,
        payload.schedule
      )
      .catch(error => dispatch('errorNotify', error));
  },
  fetchTournamentSchedule({ dispatch, getters }, tournamentID) {
    return api
      .getTournamentScheduleById(tournamentID)(getters.accessToken)
      .then(response => response.data)
      .catch(error => dispatch('errorNotify', error));
  },
  fetchTournamentRounds(
    {
      getters: { accessToken },
    },
    tournamentID
  ) {
    return api
      .getTournamentPlayOffRounds(tournamentID)(accessToken)
      .then(response => response.data);
    // .catch(error => dispatch('errorNotify', error))
  },
  fetchTournamentMatches(
    {
      getters: { accessToken },
    },
    payload
  ) {
    const { tournamentID, roundID, from, to } = payload;
    const query = parseObjectToUri({ from, to });
    return api
      .getTournamentRoundMatches(tournamentID, roundID, query)(accessToken)
      .then(response => response.data);
  },
  fetchTournamentMatchesTable(
    {
      getters: { accessToken },
    },
    payload
  ) {
    const { tournamentID, roundID, query } = payload;
    return api
      .getTournamentRoundMatchesTable(tournamentID, roundID)(accessToken, {
        params: query,
      })
      .then(response => response.data);
  },
  updatePlayOffRoundStart(
    {
      getters: { accessToken, currentTournament },
    },
    payload
  ) {
    const tournamentID = currentTournament.id;
    const { roundID, data } = payload;
    return api
      .updatePlayOffRoundStart(tournamentID, roundID)(accessToken, data)
      .then(response => response.data);
  },
  updatePlayOffMatchStart(
    {
      getters: { accessToken, currentTournament },
    },
    payload
  ) {
    const tournamentID = currentTournament.id;
    const { roundID, matchID, data } = payload;
    return api
      .updatePlayOffMatchStart(tournamentID, roundID, matchID)(
        accessToken,
        data
      )
      .then(response => response.data);
  },
  proceedPlayOffMatch(
    {
      getters: { accessToken, currentTournament },
    },
    payload
  ) {
    const tournamentID = currentTournament.id;
    const { roundID, matchID } = payload;
    return api
      .proceedPlayOffMatch(tournamentID, roundID, matchID)(accessToken)
      .then(response => response.data);
  },
  startPlayOffMatch(
    {
      getters: { accessToken, currentTournament },
    },
    payload
  ) {
    const tournamentID = currentTournament.id;
    const { roundID, matchID } = payload;
    return api
      .forceStartPlayOffMatch(tournamentID, roundID, matchID)(accessToken)
      .then(response => response.data);
  },
  finishPlayOffMatch(
    {
      getters: { accessToken, currentTournament },
    },
    payload
  ) {
    const tournamentID = currentTournament.id;
    const { roundID, matchID } = payload;
    return api
      .forceFinishPlayOffMatch(tournamentID, roundID, matchID)(accessToken)
      .then(response => response.data);
  },
  restartPlayOffMatch(
    {
      getters: { accessToken, currentTournament },
    },
    payload
  ) {
    const tournamentID = currentTournament.id;
    const { roundID, matchID } = payload;
    return api
      .forceRestartPlayOffMatch(tournamentID, roundID, matchID)(accessToken)
      .then(response => response.data);
  },
  startPlayOffRound(
    {
      getters: { accessToken, currentTournament },
    },
    payload
  ) {
    const tournamentID = currentTournament.id;
    const { roundID } = payload;
    return api
      .forceStartPlayOffRound(tournamentID, roundID)(accessToken)
      .then(response => response.data);
  },
  finishPlayOffRound(
    {
      getters: { accessToken, currentTournament },
    },
    payload
  ) {
    const tournamentID = currentTournament.id;
    const { roundID } = payload;
    return api
      .finishPlayOffRound(tournamentID, roundID)(accessToken)
      .then(response => response.data);
  },
  restartPlayOffRound(
    {
      getters: { accessToken, currentTournament },
    },
    payload
  ) {
    const tournamentID = currentTournament.id;
    const { roundID } = payload;
    return api
      .forceRestartPlayOffRound(tournamentID, roundID)(accessToken)
      .then(response => response.data);
  },
  swapPlayOffPlayers(
    {
      getters: { accessToken, currentTournament },
    },
    payload
  ) {
    const tournamentID = currentTournament.id;
    const { roundID, players } = payload;
    return api
      .swapPlayOffPlayers(tournamentID, roundID)(accessToken, players)
      .then(response => response.data);
  },
  fetchTournamentGroups(
    {
      getters: { accessToken },
    },
    tournamentID
  ) {
    return api
      .getTournamentGroups(tournamentID)(accessToken)
      .then(response => response.data);
  },
  fetchTournamentGroupRounds(
    {
      getters: { accessToken },
    },
    tournamentID
  ) {
    return api
      .getTournamentGroupRounds(tournamentID)(accessToken)
      .then(response => response.data);
  },
  fetchTournamentGroupRoundMatches(
    {
      getters: { accessToken },
    },
    payload
  ) {
    const { tournamentID, roundID } = payload;
    return api
      .getTournamentGroupRoundMatches(tournamentID, roundID)(accessToken)
      .then(response => response.data);
  },
  updateGroupRoundStart(
    {
      getters: { accessToken, currentTournament },
    },
    payload
  ) {
    const tournamentID = currentTournament.id;
    const { roundID, data } = payload;
    return api
      .updateGroupRoundStart(tournamentID, roundID)(accessToken, data)
      .then(response => response.data);
  },
  updateGroupMatchStart(
    {
      getters: { accessToken, currentTournament },
    },
    payload
  ) {
    const tournamentID = currentTournament.id;
    const { roundID, matchID, data } = payload;
    return api
      .updateGroupMatchStart(tournamentID, roundID, matchID)(accessToken, data)
      .then(response => response.data);
  },
  proceedGroupMatch(
    {
      getters: { accessToken, currentTournament },
    },
    payload
  ) {
    const tournamentID = currentTournament.id;
    const { roundID, matchID } = payload;
    return api
      .proceedGroupMatch(tournamentID, roundID, matchID)(accessToken)
      .then(response => response.data);
  },
  startGroupMatch(
    {
      getters: { accessToken, currentTournament },
    },
    payload
  ) {
    const tournamentID = currentTournament.id;
    const { roundID, matchID } = payload;
    return api
      .forceStartGroupMatch(tournamentID, roundID, matchID)(accessToken)
      .then(response => response.data);
  },
  restartGroupMatch(
    {
      getters: { accessToken, currentTournament },
    },
    payload
  ) {
    const tournamentID = currentTournament.id;
    const { roundID, matchID } = payload;
    return api
      .forceRestartGroupMatch(tournamentID, roundID, matchID)(accessToken)
      .then(response => response.data);
  },
  finishGroupMatch(
    {
      getters: { accessToken, currentTournament },
    },
    payload
  ) {
    const tournamentID = currentTournament.id;
    const { roundID, matchID } = payload;
    return api
      .forceFinishGroupMatch(tournamentID, roundID, matchID)(accessToken)
      .then(response => response.data);
  },
  startGroupRound(
    {
      getters: { accessToken, currentTournament },
    },
    payload
  ) {
    const tournamentID = currentTournament.id;
    const { roundID } = payload;
    return api
      .forceStartGroupRound(tournamentID, roundID)(accessToken)
      .then(response => response.data);
  },
  restartGroupRound(
    {
      getters: { accessToken, currentTournament },
    },
    payload
  ) {
    const tournamentID = currentTournament.id;
    const { roundID } = payload;
    return api
      .forceRestartGroupRound(tournamentID, roundID)(accessToken)
      .then(response => response.data);
  },
  finishGroupRound(
    {
      getters: { accessToken, currentTournament },
    },
    payload
  ) {
    const tournamentID = currentTournament.id;
    const { roundID } = payload;
    return api
      .finishGroupRound(tournamentID, roundID)(accessToken)
      .then(response => response.data);
  },
  swapGroupPlayers(
    {
      getters: { accessToken, currentTournament },
    },
    players
  ) {
    const tournamentID = currentTournament.id;
    // tournament is scheduled, group first round is 0
    const roundID = 0;

    return api
      .swapGroupPlayers(tournamentID, roundID)(accessToken, players)
      .then(response => response.data);
  },
  pinTournament(
    {
      getters: { accessToken },
    },
    tournamentID
  ) {
    return api
      .pinTournament(tournamentID)(accessToken)
      .then(res => res.data);
  },
  unpinTournament(
    {
      getters: { accessToken },
    },
    tournamentID
  ) {
    return api
      .unpinTournament(tournamentID)(accessToken)
      .then(res => res.data);
  },
  markSpecialEventTournament(
    {
      getters: { accessToken },
    },
    tournamentID
  ) {
    return api
      .markSpecialEventTournament(tournamentID)(accessToken)
      .then(res => res.data);
  },
  unmarkSpecialEventTournament(
    {
      getters: { accessToken },
    },
    tournamentID
  ) {
    return api
      .unmarkSpecialEventTournament(tournamentID)(accessToken)
      .then(res => res.data);
  },
};

export default {
  state: DEFAULT_TOURNAMENTS_STATE(),
  getters,
  mutations,
  actions,
};
