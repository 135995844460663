<!-- eslint-disable vue/camelcase -->
<template>
  <div class="container-fluid">
    <div class="row my-4 align-items-center">
      <h3 class="mb-0 pb-0 col">Tournament Fees</h3>
    </div>
    <div class="row">
      <div class="col">
        <div class="card mt-4">
          <div class="card-body">
            <form @submit.prevent="applyFilters">
              <div class="form-row">
                <div class="form-group col-md-3">
                  <label for="login">User Paid</label>
                  <input
                    id="login"
                    v-model="login"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="form-group col-md-3">
                  <label for="created_since">Order placed</label>
                  <input
                    id="created_since"
                    v-model="created_since"
                    type="datetime-local"
                    class="form-control"
                  />
                </div>
                <div class="form-group col-md-3">
                  <label for="status">Status of order</label>
                  <select id="status" v-model="status" class="form-control">
                    <option value=""></option>
                    <option value="placed">Placed</option>
                    <option value="reserved">Reserved</option>
                    <option value="processing">Processing</option>
                    <option value="failed">Failed</option>
                    <option value="success">Success</option>
                    <option value="cancelled">Cancelled</option>
                    <option value="delivery_failed">DeliveryFailed</option>
                  </select>
                </div>
                <div class="form-group col-md-3">
                  <label for="tname">Tournament Name</label>
                  <input
                    id="tname"
                    v-model="tname"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="Orders-button">
                <div :class="{ disabled: isLoading }">
                  <button
                    type="submit"
                    class="btn btn-primary mr-3"
                    :class="{ disabled: isLoading }"
                  >
                    Apply Filters
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    :class="{ disabled: isLoading }"
                    @click="clearFilters"
                  >
                    Clear Filters
                  </button>
                </div>
              </div>
            </form>
            <div class="flush-div">
              <button
                :class="{ 'is-loading': isExportingAll }"
                class="btn btn-primary ml-auto mr-4"
                @click="exportAll"
              >
                Export to XLS
              </button>
            </div>
          </div>
        </div>

        <div class="card">
          <div
            class="card-body border-top border-muted pt-0 px-0 overflow-auto"
          >
            <table class="table table-hover mx-0 px-0 subscriptions-list">
              <caption v-if="isLoading" class="text-center text-muted">
                Fetching Orders...
              </caption>
              <caption
                v-else-if="!ordersList.length"
                class="text-center text-muted"
              >
                No Orders to show
              </caption>
              <template v-else>
                <thead>
                  <tr>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      User Paid
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Tournament Name
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Order placed
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Order processed
                    </th>

                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Grand total
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Item type
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Status of order
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Participant status
                    </th>
                    <th
                      class="border-top-0 border-bottom-0 px-2 section-description"
                    >
                      Country ID
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <ListItem
                    v-for="orders in ordersList"
                    :key="orders.id"
                    v-bind="{ orders }"
                  />
                </tbody>
              </template>
            </table>
          </div>
          <nav
            v-if="total > 0 && !isLoading"
            class="mt-3 d-flex flex-column align-items-center"
          >
            <ul class="pagination justify-content-center">
              <li
                class="page-item-btn"
                :class="{ disabled: isLoading || isFirstPage }"
              >
                <a class="page-link" href="#" @click.prevent="prevPage"
                  >Previous</a
                >
              </li>
              <li class="page-item">
                <span class="page-link"
                  ><input
                    v-model="pageInput"
                    min="1"
                    :max="totalPages"
                    class="form-control-pagination"
                    :placeholder="currentPage"
                    @keyup.enter="goToPageInput"
                  />
                  / {{ totalPages }}</span
                >
              </li>
              <li
                class="page-item-btn"
                :class="{ disabled: isLoading || isLastPage }"
              >
                <a
                  class="page-link"
                  href="#"
                  @click.prevent="goToPage(currentPage + 1)"
                  >Next</a
                >
              </li>
            </ul>
            <span
              v-if="pageInput > totalPages || isNaN(pageInput)"
              class="error-pagination"
              >{{
                pageInput > totalPages
                  ? `Max page is ${totalPages}`
                  : '' || isNaN(pageInput)
                  ? 'Please enter number'
                  : ''
              }}</span
            >
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import ListItem from './ListItem';

export default {
  name: 'PaidTournamentList',
  components: { ListItem },
  data() {
    return {
      nextPageToken: undefined,
      prevPageToken: undefined,
      isLoading: false,
      isExporting: false,
      currentPage: 1,
      itemsPerPage: 20,
      ordersList: [],
      total: 0,
      magento_order_id: null,
      status: null,
      login: null,
      created_since: null,
      isExportingAll: false,
      pageInput: '',
      tname: null,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.total / this.itemsPerPage);
    },
    isFirstPage() {
      return this.currentPage === 1;
    },
    isLastPage() {
      return this.currentPage === this.totalPages;
    },
    sourceOptions() {
      const uniqueSources = [
        ...new Set(this.ordersList.map(item => item.source)),
      ];
      return uniqueSources;
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions([
      'fetchOrders',
      'exportAllOrders',
      'errorNotify',
      'successNotify',
    ]),

    exportAll() {
      this.isExportingAll = true;
      const filterProps = {
        magento_order_id: this.magento_order_id,
        status: this.status,
        login: this.login,
        type: 1,
        item_name: this.tname,
        created_since: this.generateSaudiTime(this.created_since, false),
      };

      const filteredProps = Object.fromEntries(
        Object.entries(filterProps).filter(
          // eslint-disable-next-line no-unused-vars
          ([_, value]) => value !== '' && value !== null
        )
      );
      this.exportAllOrders({ props: filteredProps })
        .catch(() => this.errorNotify('export failed'))
        .finally(() => (this.isExportingAll = false));
    },
    fetchData() {
      this.isLoading = true;

      const filterProps = {
        magento_order_id: this.magento_order_id,
        status: this.status,
        login: this.login,
        type: 1,
        item_name: this.tname,
        created_since: this.generateSaudiTime(this.created_since, false),
      };

      const filteredProps = Object.fromEntries(
        Object.entries(filterProps).filter(
          // eslint-disable-next-line no-unused-vars
          ([_, value]) => value !== '' && value !== null
        )
      );

      this.fetchOrders({
        page: this.currentPage,
        pageToken: this.nextPageToken,
        prevPageToken: this.prevPageToken,
        pageSize: this.itemsPerPage,
        props: filteredProps,
      })
        .then(res => {
          this.total = res.total;
          this.nextPageToken = res.next_page_token;
          this.prevPageToken = res.prev_page_token;

          if (res.items) {
            this.ordersList = res.items.map(el => ({
              ...el,
              editMode: false,
              key: `Order_${el.id}`,
            }));
          } else {
            this.ordersList = [];
          }
        })
        .catch(this.errorNotify)
        .finally(() => {
          this.isLoading = false;
        });
    },

    applyFilters() {
      this.nextPageToken = undefined;
      this.prevPageToken = undefined;
      this.isLoading = true;
      this.currentPage = 1;
      this.fetchData();
    },

    clearFilters() {
      (this.magento_order_id = null),
        (this.status = null),
        (this.login = null),
        (this.created_since = null),
        this.fetchData();
    },

    generateSaudiTime(date, isEndDate) {
      if (!date) {
        return null;
      }

      const saudiTimeZone = 'Asia/Riyadh'; // Saudi Arabia's time zone
      const dateObject = new Date(date); // Convert the string to a Date object

      // If isEndDate is true, set the time to 23:59:59 to represent the end of the day
      if (isEndDate) {
        dateObject.setHours(23, 59, 59, 999);
      } else {
        dateObject.setHours(0, 0, 0, 0);
      }

      const saudiTimestamp = dateObject.toLocaleString('en-US', {
        timeZone: saudiTimeZone,
      });
      return new Date(saudiTimestamp).getTime(); // Get the timestamp in milliseconds
    },

    prevPage() {
      if (this.prevPageToken) {
        this.nextPageToken = this.prevPageToken;
        this.prevPageToken = null;
        this.currentPage--;
        this.pageInput = '';
        this.fetchData();
      }
    },

    goToPage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.currentPage = page;
        this.pageInput = '';
        this.fetchData();
      }
    },

    goToPageInput() {
      const pageNumber = parseInt(this.pageInput, 10);
      if (pageNumber > 0 && pageNumber <= this.totalPages) {
        this.currentPage = pageNumber;
        this.fetchData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-item {
  cursor: not-allowed;
}

.page-link:hover {
  background-color: white !important;
}
.page-item-btn {
  .page-link {
    padding: 0.67rem $pagination-padding-x;
  }
}

.Orders-button {
  display: flex;
  justify-content: space-between;
}

.flush-div {
  display: flex;
  justify-content: flex-end;
  margin-top: -38px;
}
.form-control-pagination {
  width: 30px;
}
.flush-button {
  background-color: red;
  color: white;
}
</style>
