<template>
  <AppIcon
    name="logo"
    :width="size"
    :original="color === 'original'"
    :fill="fill"
  />
</template>

<script>
import AppIcon from './Icon';
export default {
  name: 'Logo',
  components: {
    AppIcon,
  },
  props: {
    size: {
      type: String,
      default: '72',
    },
    color: {
      type: String,
      default: 'original',
    },
  },
  computed: {
    fill() {
      return this.color && this.color !== 'original' ? this.color : '';
    },
  },
};
</script>
