<template>
  <div
    class="float-dropbox"
    :class="[{ show: !isHidden }, positionClass, `regard-${regard}`]"
    :style="calculatePosition"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Popover',
  props: {
    position: {
      type: null,
      default: null,
    },
    closest: {
      type: Node,
      default: null,
    },
    float: {
      type: Array,
      default: () => [], // ex: ['right', 'bottom'], ['top'], ['left']
    },
    regard: {
      type: String,
      default: 'top', // top or bottom
    },
  },
  data() {
    return {
      positionClass: '',
      calculatePosition: null,
      isHidden: true,
    };
  },
  watch: {
    position(position) {
      if (!this.closest || !position) {
        this.$emit('toggle', false);
        return (this.isHidden = true);
      }

      this.$emit('toggle', true);
      this.isHidden = false;

      let classList = [];

      if (this.float.length) {
        classList = [...this.float.map(float => `float-dropbox-${float}`)];
      }

      this.calculatePosition = {
        '--width': `${position.width}px`,
        '--height': `${position.height}px`,
      };

      const dialogBounds = this.closest.getBoundingClientRect();
      const scrollLeft = this.closest.scrollLeft;
      const scrollTop = this.closest.scrollTop;

      if (this.regard === 'top') {
        this.calculatePosition['--top'] = `${position.top -
          dialogBounds.top +
          scrollTop}px`;
      } else if (this.regard === 'bottom') {
        this.calculatePosition['--bottom'] = `${this.closest.scrollHeight -
          scrollTop -
          dialogBounds.height +
          (dialogBounds.bottom - position.bottom)}px`;
      }

      this.calculatePosition['--left'] = `${position.left -
        dialogBounds.left +
        scrollLeft}px`;

      if (!this.float.includes('left') && !this.float.includes('right')) {
        const floatX =
          position.left < dialogBounds.left + dialogBounds.width / 2
            ? 'left'
            : 'right';
        classList.push(`float-dropbox-${floatX}`);
      }

      if (!this.float.includes('bottom') && !this.float.includes('top')) {
        const floatY =
          position.top < dialogBounds.top + dialogBounds.height / 2
            ? 'top'
            : 'bottom';
        classList.push(`float-dropbox-${floatY}`);
      }

      this.positionClass = classList.join(' ');
    },
  },
  mounted() {
    document.addEventListener('mousedown', this.hideDropBox);
  },
  beforeDestroy() {
    document.removeEventListener('mousedown', this.hideDropBox);
  },
  methods: {
    hideDropBox(e) {
      if (
        !this.isHidden &&
        this.$el !== e.target &&
        !this.$el.contains(e.target) &&
        !this.inBounds(this.position, { x: e.pageX, y: e.pageY })
      ) {
        this.$emit('toggle', false);
        this.isHidden = true;
      }
    },
    inBounds(bounds, coors) {
      return bounds
        ? bounds.left < coors.x &&
            bounds.right > coors.x &&
            bounds.top < coors.y &&
            bounds.bottom > coors.y
        : false;
    },
  },
};
</script>

<style lang="scss">
.float-dropbox {
  --top: 0;
  --left: 0;
  --bottom: 0;

  --width: 0;
  --height: 0;

  --translate-x: 0;
  --translate-y: 0;

  $arrow-offset-x: 20px;
  $arrow-size: 10px;

  @mixin dropbox-style {
    background-color: $hera-background-color;
    border: 1px solid $hera-strokes-color;
  }

  position: absolute;
  z-index: 999;
  min-width: 100px;
  max-width: 200px;
  top: -999px;
  left: calc(var(--left) + calc(var(--width) / 2));
  padding: 2px;
  border-radius: 4px;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
  @include dropbox-style;

  display: none;
  transform: translate(var(--translate-x), var(--translate-y));

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    width: $arrow-size;
    height: $arrow-size;
    @include dropbox-style;
  }

  &.show {
    display: block;

    &.float-dropbox-left {
      --translate-x: #{0 - $arrow-offset-x};
      &:after {
        left: 0;
        transform: translateX($arrow-offset-x) rotate(45deg);
        margin-left: -($arrow-size/2);
      }
    }
    &.float-dropbox-right {
      --translate-x: calc(-100% + #{$arrow-offset-x});
      &:after {
        right: 0;
        transform: translateX(#{0 - $arrow-offset-x}) rotate(45deg);
        margin-right: -($arrow-size/2);
      }
    }
    &.float-dropbox-top {
      &.regard-top {
        top: calc(var(--top) + var(--height));
        margin-top: $arrow-size;
      }
      &.regard-bottom {
        top: unset;
        bottom: var(--bottom);
        --translate-y: 100%;
        margin-bottom: #{0-$arrow-size};
      }
      &:after {
        top: -$arrow-size/2;
        @include dropbox-style;
        border-bottom-width: 0;
        border-right-width: 0;
      }
    }
    &.float-dropbox-bottom {
      &.regard-top {
        top: var(--top);
        --translate-y: -100%;
        margin-top: #{0-$arrow-size};
      }
      &.regard-bottom {
        top: unset;
        bottom: calc(var(--bottom) + var(--height));
        margin-bottom: $arrow-size;
      }

      &:after {
        bottom: -$arrow-size/2;
        border-top-width: 0;
        border-left-width: 0;
      }
    }
  }
}
</style>
