import { BASE_URL } from '@/api/config.js';
import { authorizedGetRequest, fileDownload } from '@/api/requests.js';

const CUSTOMER = BASE_URL + 'invoices/customers';

export const getCustomerss = (
  accessToken,
  pageToken = null,
  pageSize = 20,
  filters = {}
) =>
  authorizedGetRequest(CUSTOMER)(accessToken, {
    params: { page: pageToken, page_size: pageSize, ...filters },
  });

const exportAllCustomers = (accessToken, fileName, filters = {}) => {
  return fileDownload(accessToken, `${CUSTOMER}/export`, fileName, {
    queryParams: { ...filters },
  });
};
export default {
  customer: {
    getCustomerss,
    exportAllCustomers,
  },
};
