import { BASE_URL } from '@/api/config.js';
import {
  authorizedGetRequest,
  authorizedPostRequest,
  authorizedPutRequest,
  authorizedDeleteRequest,
} from '@/api/requests.js';

const LEAGUES = BASE_URL + 'leagues';

export const createLeague = authorizedPostRequest(`${LEAGUES}/draft`);
export const publishLeague = ID =>
  authorizedPutRequest(LEAGUES + `/${ID}/publish`);
export const getLeague = ID => authorizedGetRequest(`${LEAGUES}/${ID}/draft`);
//TODO: change veryLongStringQuery by obj
//    params: {
//        page: pageToken,
//        query
//    }

const getLeaguesWrap = (path, pageToken = null, query = '') => {
  let uri = `${LEAGUES}/${path}?${query || ''}`;

  if (pageToken) {
    uri += `&page=${pageToken}`;
  }

  return authorizedGetRequest(uri);
};

export const getLeagues = (pageToken, query) =>
  getLeaguesWrap('draft', pageToken, query);
export const getLeagueClans = (pageToken, query) =>
  getLeaguesWrap('leaderboards/clans', pageToken, query);
export const getLeagueProfiles = (pageToken, query) =>
  getLeaguesWrap('leaderboards/profiles', pageToken, query);

export const getLeagueById = ID =>
  authorizedGetRequest(LEAGUES + `/${ID}/draft`);
export const updateLeague = ID =>
  authorizedPutRequest(LEAGUES + `/${ID}/draft`);
export const rejectLeague = ID =>
  authorizedPutRequest(LEAGUES + `/${ID}/reject`);
export const restartLeague = ID =>
  authorizedPutRequest(LEAGUES + `/${ID}/restart`);
export const updateLeagueSchedule = ID =>
  authorizedPutRequest(LEAGUES + `/${ID}/schedule`);
export const getLeagueScheduleById = ID =>
  authorizedGetRequest(LEAGUES + `/${ID}/schedule`);
export const getSeasons = ID => authorizedGetRequest(LEAGUES + `/${ID}/season`);
export const createLeagueSeason = leagueID =>
  authorizedPostRequest(LEAGUES + `/${leagueID}/season`);
export const deleteLeagueSeason = (ID, seasonID) =>
  authorizedDeleteRequest(LEAGUES + `/${ID}/season/${seasonID}`);
export const updateLeagueSeason = (ID, seasonID) =>
  authorizedPutRequest(LEAGUES + `/${ID}/season/${seasonID}`);

export default {
  createLeague,
  publishLeague,
  getLeague,
  getLeagues,
  getLeagueClans,
  getLeagueProfiles,
  getLeagueById,
  updateLeague,
  rejectLeague,
  restartLeague,
  updateLeagueSchedule,
  getLeagueScheduleById,
  getSeasons,
  createLeagueSeason,
  deleteLeagueSeason,
  updateLeagueSeason,
};
