<template>
  <div class="auth">
    <section class="title">
      <div class="container">
        <div class="row">
          <div class="col-auto mx-auto">
            <h1>Password reset</h1>
          </div>
        </div>
      </div>
    </section>
    <section class="password-reset">
      <div class="container">
        <div class="row no-gutters">
          <div class="col-12 m-0 p-0">
            <div class="card">
              <div class="card-body">
                <form @submit.prevent="formSubmit">
                  <HeraFormPhoneInput
                    v-model.trim="login"
                    v-validate="'required|numeric'"
                    name="phone"
                    type="tel"
                    label="Phone"
                    :tabindex="1"
                    :autofocus="true"
                    :error="errors.first('phone')"
                  />
                  <div class="form-group">
                    <div
                      class="form-row d-flex justify-content-end align-items-center"
                    >
                      <div class="col-auto">
                        <router-link :to="{ name: 'signin' }"
                          >Back to login</router-link
                        >
                      </div>
                      <div class="col-auto text-right">
                        <HeraFormSubmitBtn
                          id="submitCode"
                          :tabindex="2"
                          label="Reset Password"
                          :loading="isLoading"
                          class="btn-primary"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import HeraFormPhoneInput from '@/components/form/HeraFormPhoneInput';
import HeraFormSubmitBtn from '@/components/form/HeraFormSubmitBtn';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'SignIn',
  components: {
    HeraFormPhoneInput,
    HeraFormSubmitBtn,
  },
  data() {
    return {
      login: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(['callingCodes']),
  },
  inject: ['$validator'],
  created() {
    if (!this.callingCodes.length) {
      this.getCallingCodes();
    }
  },
  methods: {
    ...mapActions([
      'requestPass',
      'sendVerificationCode',
      'errorNotify',
      'successNotify',
      'getCallingCodes',
    ]),
    formSubmit() {
      this.isLoading = true;
      this.$validator
        .validateAll()
        .then(result =>
          result
            ? Promise.resolve({ login: '+' + this.login })
            : Promise.reject()
        )
        .then(this.requestPass)
        .then(this.sendVerificationCode)
        .then(() => {
          // if(response){
          //   throw ('Auth Error: Unexpected ansver from server!')
          // } else{
          this.isLoading = false;
          this.$router.push({ name: 'verifyCode', params: { from: 'reset' } });
          // }
        })
        .catch(error => {
          this.isLoading = false;
          this.errorNotify(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.auth {
  h1 {
    font-size: 36px;
  }
  section.password-reset {
    .card {
      border-color: #ebedf8;
      .card-body {
        padding: 3.75rem 6rem;
      }
    }
  }
  label {
    font-size: 12px;
  }
}
</style>
