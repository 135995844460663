import { ifAuthorized } from '@/router/guards';
import PlatformsWrapper from '@/views/admin/Platforms';
import Platforms from '@/views/admin/Platforms/PlatformsList';

export default [
  {
    path: '/platforms',
    title: 'Platforms',
    component: PlatformsWrapper,
    beforeEnter: ifAuthorized,
    children: [
      {
        path: '',
        name: 'platforms',
        component: Platforms,
      },
    ],
  },
];
