<template>
  <tr class="coupon-list-item">
    <td class="px-2 pl-4 text-truncate subscription-list-item_name">
      <span>{{ customer.login }}</span>
    </td>
    <td class="px-2 pl-4 text-truncate subscription-list-item_name">
      <span>{{ customer.email }}</span>
    </td>
    <td class="px-2 text-truncate invoice-list-amount">
      <span> <span v-if="customer.phone">+</span> {{ customer.phone }}</span>
    </td>
    <td class="px-2 text-truncate invoice-list-amount">
      <span>{{ customer.country }}</span>
    </td>
    <td class="px-2 text-truncate invoice-list-amount">
      <span>{{ customer.country_id }}</span>
    </td>
    <td class="px-2 text-truncate">
      <span>{{ createdDate }}</span>
    </td>
    <td class="px-2 text-truncate">
      <span>{{ createdAt }}</span>
    </td>
    <td>
      <router-link
        :to="{
          name: 'OrderDetails',
          params: { login: customer.login, phone: customer.phone },
        }"
        class="btn btn-sm btn-outline-info"
      >
        View Orders
      </router-link>
    </td>
  </tr>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'CustomersListItem',
  inject: ['$validator'],
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
    };
  },
  computed: {
    createdDate() {
      let expDate = this.customer.customer_since
        ? new Date(this.customer.customer_since)
        : null;
      return expDate
        ? `${this.addLeadingZero(expDate.getUTCDate())}/${this.addLeadingZero(
            expDate.getUTCMonth() + 1
          )}/${expDate.getUTCFullYear()}`
        : '-';
    },
    createdAt() {
      let expDate = this.customer.created_at
        ? new Date(this.customer.created_at * 1000)
        : null;
      return expDate
        ? `${this.addLeadingZero(expDate.getUTCDate())}/${this.addLeadingZero(
            expDate.getUTCMonth() + 1
          )}/${expDate.getUTCFullYear()}`
        : '-';
    },
  },
  methods: {
    ...mapActions([]),
    addLeadingZero(num) {
      return (num < 10 ? '0' : '') + num;
    },
  },
};
</script>

<style lang="scss">
.disc-desc {
  max-width: 100px;
  word-break: break-all;
}
</style>
