import api from '@/api';
import Vue from 'vue';
import { cloneDeep, isObject } from 'lodash';

export const DEFAULT_SETTINGS_STATE = () => ({
  callingCodes: [],
  games: [],
  platforms: [],
  genres: [],
});

const getters = {
  platforms: state => state.platforms,
  publishedPlatforms: state =>
    state.platforms.filter(({ published }) => published),
  platformByName: state => name => state.platforms.find(el => el.name === name),
  platformByID: state => ID => state.platforms.find(el => el.id === ID),
  callingCodes: state => state.callingCodes,
  countryByCode: state => code =>
    state.callingCodes.find(el => el.code === code),
  countryByName: state => name =>
    state.callingCodes.find(el => el.name === name),
  games: state => state.games,
  publishedGames: state => state.games.filter(game => game.published),
  gameByID: state => ID => state.games.find(el => el.id === ID),
  genreByID: state => ID => state.genres.find(el => el.id === ID),
};

const mutations = {
  setCallingCodes(state, payload) {
    state.callingCodes = [];
    if (Array.isArray(payload)) {
      for (var i = 0; i < payload.length; i += 1) {
        Vue.set(state.callingCodes, i, payload[i]);
      }
    }
  },
  setPlatforms(state, payload) {
    state.platforms = [];
    for (var i = 0; i < payload.length; i += 1) {
      Vue.set(state.platforms, i, {});
      state.platforms[i] = { ...state.platforms[i], ...payload[i] };
    }
  },
  setGames(state, payload) {
    state.games = [];
    for (var i = 0; i < payload.length; i += 1) {
      Vue.set(state.games, i, {});
      state.games[i] = { ...state.games[i], ...payload[i] };
    }
  },
  publishGame(state, gameId) {
    const index = state.games.findIndex(el => el.id === gameId);
    if (index > -1) {
      state.games[index].publish = true;
    }
  },
  clearToSettingsDefaultState(state) {
    const defaultState = DEFAULT_SETTINGS_STATE();

    Object.keys(defaultState).forEach(key => {
      if (key !== 'callingCodes') {
        Vue.set(state, key, defaultState[key]);
      }
    });
  },
  setGenres(state, payload) {
    state.genres = cloneDeep(payload);
  },
  addGenre(state, genre) {
    state.genres = [cloneDeep(genre), ...cloneDeep(state.genres)];
  },
  setGenre(state, payload) {
    const index = state.genres.findIndex(el => el.id === payload.id);
    if (index > -1) {
      Vue.set(state.genres, index, payload);
    }
  },
  updateGenreId(state, { id, genre }) {
    const index = state.genres.findIndex(el => el.id === genre.id);
    if (index > -1) {
      Vue.set(state.genres[index], 'id', id);
    }
  },
  publishGenre(state, id) {
    const index = state.genres.findIndex(el => el.id === id);
    if (index > -1) {
      Vue.set(state.genres[index], 'published', true);
    }
  },
  removeGenre(state, id) {
    const index = state.genres.findIndex(el => el.id === id);
    if (index > -1) {
      const genres = cloneDeep(state.genres);
      genres.splice(index, 1);
      state.genres = genres;
    }
  },
};

const actions = {
  getCallingCodes({ dispatch, commit }) {
    api
      .getCallingCodes()
      // .then(JSON.parse)
      .then(
        data => commit('setCallingCodes', data.data),
        error => Promise.reject(error)
      )
      .catch(error => dispatch('errorNotify', error));
  },
  getGames({ commit, getters }, published = false) {
    return api
      .getGames(published)(getters.accessToken)
      .then(data => {
        commit('setGames', data.data);
        commit('changeStatus', 'games');
      });
  },
  getPlatforms({ dispatch, commit, getters }) {
    return (
      api
        .getPlatforms(getters.accessToken)
        // .then(JSON.parse)
        .then(
          data => {
            commit('setPlatforms', data.data);
            commit('changeStatus', 'platforms');
            return data.data;
          }
          // error => Promise.reject(error)
        )
        // TODO remove catch
        .catch(error => dispatch('errorNotify', error))
    );
  },
  createGame(
    {
      getters: { accessToken },
    },
    game
  ) {
    const genres = (game.genres || []).map(({ id }) => id);
    return new Promise((resolve, reject) => {
      api
        .createGame(accessToken, { ...game, genres })
        .then(resolve, reject)
        .catch(error => {
          reject(error);
        });
    });
  },
  publishGame({ commit, getters }, gameId) {
    return new Promise((resolve, reject) => {
      api
        .publishGame(gameId)(getters.accessToken)
        .then(() => commit('publishGame', gameId))
        .then(resolve, reject)
        .catch(error => reject(error));
    });
  },

  updateGame(
    {
      getters: { accessToken },
    },
    { id, game }
  ) {
    return api
      .updateGame(id)(accessToken, game)
      .then(res => res.data);
  },

  suggestCountriesRequest(_state, query) {
    return new Promise((resolve, reject) => {
      return api
        .suggestCountries(query)()
        .then(response => resolve(response.data))
        .catch(reject);
    });
  },
  suggestCitiesRequest(_state, payload) {
    return new Promise((resolve, reject) => {
      return api
        .suggestCities(payload.countryId, payload.query)()
        .then(response => resolve(response.data))
        .catch(reject);
    });
  },
  getCountryById(
    {
      getters: { accessToken },
    },
    country_id
  ) {
    return api
      .getCountryById(country_id)(accessToken)
      .then(res => res.data);
  },

  createPlatform(
    {
      getters: { accessToken },
    },
    platform
  ) {
    return api.createPlatform(accessToken, platform).then(res => res.data);
  },

  updatePlatform(
    {
      getters: { accessToken },
    },
    { id, platform }
  ) {
    return api
      .updatePlatform(id)(accessToken, platform)
      .then(res => res.data);
  },

  updatePlatformsOrder(
    {
      getters: { accessToken },
    },
    order
  ) {
    return api.updatePlatformsOrder(accessToken, order).then(res => res.data);
  },

  deletePlatform(
    {
      getters: { accessToken },
    },
    id
  ) {
    return api
      .deletePlatform(id)(accessToken)
      .then(res => res.data);
  },

  getGenres({ getters: { accessToken }, commit }) {
    return api.genres
      .getList(accessToken)
      .then(res => res.data)
      .then(genres =>
        genres.map(el => ({
          ...el,
          logo: isObject(el.logo)
            ? el.logo
            : {
                display_uri: el.logo,
                id: null,
              },
          editMode: false,
          key: `genre_${el.id}`,
        }))
      )
      .then(genres => {
        commit('setGenres', genres);
        return genres;
      });
  },

  createGenre(
    {
      getters: { accessToken },
      commit,
    },
    genre
  ) {
    const newGenre = cloneDeep(genre);
    delete newGenre.editMode;
    delete newGenre.id;
    delete newGenre.key;

    return api.genres
      .create(accessToken, {
        ...newGenre,
        logo: {
          type: 'image',
          token: newGenre.logo.id,
          uri: newGenre.logo.display_uri,
        },
      })
      .then(response => {
        const { id } = response.data;
        commit('updateGenreId', { id, genre });
        commit('publishGenre', id);
      });
  },

  updateGenre(
    {
      getters: { accessToken },
      commit,
    },
    genre
  ) {
    const { id } = genre;
    const newGenre = cloneDeep(genre);
    delete newGenre.editMode;
    delete newGenre.id;
    delete newGenre.key;
    delete newGenre.display_uri;
    return api.genres
      .updateById(id)(accessToken, {
        ...newGenre,
        logo: {
          type: 'image',
          token: newGenre.logo.id,
          uri: newGenre.logo.display_uri,
        },
      })
      .then(() => {
        commit('setGenre', genre);
      });
  },

  deleteGenre(
    {
      getters: { accessToken },
      commit,
    },
    { id, force = false }
  ) {
    return api.genres
      .deleteById(id)(accessToken, { force })
      .then(() => {
        commit('removeGenre', id);
      });
  },

  updateGenresOrder(
    {
      getters: { accessToken },
      state: { genres },
    },
    excludeIds = []
  ) {
    const ids = genres
      .map(genre => genre.id)
      .filter(id => !excludeIds.includes(id));
    return api.genres
      .updateOrder(accessToken, { list: ids })
      .then(res => res.data);
  },
};

export default {
  state: DEFAULT_SETTINGS_STATE(),
  getters,
  mutations,
  actions,
};
