import { BASE_URL } from '@/api/config.js';
import { authorizedPostRequest } from '@/api/requests.js';

const FLUSH = BASE_URL + 'ecommerce/products/flush_cache';

export const flush = {
  flushCache: authorizedPostRequest(FLUSH),
};

export default {
  flush,
};
