'use strict';

import { BASE_URL } from '@/api/config.js';

import { authorizedGetRequest } from '@/api/requests.js';

const NOTIFICATIONS_BASE = BASE_URL + 'notifications/negotiate/';

export const getHubConnection = hub =>
  authorizedGetRequest(`${NOTIFICATIONS_BASE}${hub}`);

export default {
  getHubConnection,
};
