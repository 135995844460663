import DefaultLayout from './AdminDefaultLayout.vue';
import AuthLayout from './AuthLayout.vue';

export default [
  {
    name: 'default-layout',
    component: DefaultLayout,
  },
  {
    name: 'auth-layout',
    component: AuthLayout,
  },
];
